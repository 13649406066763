/* eslint-disable no-unused-vars */
import { Users } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

import { Column } from '../../../../../components/layouts/columns'
import { TransformationforMask } from '../../../../../utils/masks'
import { Path } from '../../../../../router/paths'

const CardEvent = ({ children, eventName, date, status, staff, jobName, idEvent, CandidatePending = 0 }) => {
  const navigate = useNavigate()
  const transformDate = date.split('-')

  const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']

  const StatusJobOffers = [
    {
      value: 'Aberto',
      color: 'is-success',
      status: 'open',
    },
    {
      value: 'Fechado',
      color: 'is-danger',
      status: 'closed',
    },
    {
      value: 'Deletado',
      color: 'is-success',
      status: 'deleted',
    },
    {
      value: 'Somente Convidados',
      color: 'is-warning',
      status: 'invited',
    },
    {
      value: 'Somente Favoritos',
      color: 'is-warning',
      status: 'favorite',
    },
  ]

  return (
    <Column.Content>
      <div>
        <button
          onClick={() => {
            navigate(Path.ClientStaffEvents(idEvent))
          }}
          className="box"
          style={{
            display: 'flex',
            width: '100%',
            minHeight: 100,
            // padding: '0 1.5rem 0 1.5rem',
            borderColor: '#000',
          }}
        >
          <div
            style={{
              display: 'flex',
              height: 140,
              flexDirection: 'column',
              justifyContent: 'center',
              gap: '0.25rem',
              borderRight: '.15rem dashed',
              borderRightColor: '#b5b5b5', // Define explicitamente a cor da borda
              padding: '0 1.5rem 0 0',
            }}
          >
            <div
              style={{
                fontSize: '2rem',
                marginBottom: -10,
              }}
            >
              {transformDate[2]}
            </div>
            <div
              style={{
                fontSize: '1.25rem',
              }}
            >
              {months[Number(transformDate[1]) - 1]}
            </div>
            <div>{transformDate[0]}</div>
          </div>
          <div
            style={{
              flex: 1,
              textAlign: 'start',
              padding: '0.75rem 0 0.75rem 2rem',
            }}
          >
            <p className="subtitle is-5">{TransformationforMask(eventName, 'title')}</p>
            <div>
              <p>{jobName}</p>
              <p className={`tag ${StatusJobOffers.find((item) => item.status === status)?.color}`}>
                {StatusJobOffers.find((item) => item.status === status)?.value}
              </p>
              <br />
              <p className={`has-text-danger`}>
                {CandidatePending > 0 ? ` ${CandidatePending} Candidaturas Pendentes Aprovação` : ''}{' '}
              </p>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: '0.5rem',
            }}
          >
            <div>
              <Users size={30} />
            </div>
            <p
              style={{
                borderRadius: '1rem',
                backgroundColor: '#f5f5f5',
                padding: '0.25rem',
                width: '4rem',
              }}
            >
              {staff}
            </p>
          </div>
        </button>
      </div>
    </Column.Content>
  )
}

export default CardEvent
