import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import {
  addDocument,
  deleteSubDocument,
  generateIdDocument,
  getDocuments,
  getDocumentsWithQuerys,
  getSubDocuments,
  setDocument,
} from '../../../../firebase/firestore'
import { TryCatch } from '../../../../utils/general'
import { createSchemaOrdemDeServico, createSchemaOrdemDeServicoOmie, getActivePlan } from '../_utils'
import { ConvertToTimestamp, GenerateDate, SomarDiasAData, TimestampNow } from '../../../../utils/dates'

export const useBillingClients = () => {
  const { id } = useParams()
  const { user, loading, setLoading, setAlerts, infosContext, setDataContext, dataContext, formContext } =
    useGlobalContext()

  const userName = user.user.nome

  const [search, setSearch] = useState('')
  const [modalHistory, setModalHistory] = useState(false)
  // const { FetchClientInfos } = useAdminClients()

  const navigate = useNavigate()

  const getData = async () => {
    const query = [{ field: 'idClient', operator: '==', value: id }]
    const getInvoicesProssesing = await getDocumentsWithQuerys('_billings', query)
    const getInvoicesClient = await getSubDocuments('clientes', id, 'invoices')

    const invoices = [...getInvoicesProssesing, ...getInvoicesClient].sort(
      (a, b) => ConvertToTimestamp(b.dt_created) - ConvertToTimestamp(a.dt_created),
    )
    setDataContext({ invoices })
  }

  const cancelBilling = async (values) => {
    const idBilling = values.id
    // console.log(idBilling)
    // console.log(values)
    await deleteSubDocument('clientes', id, 'invoices', idBilling)
    values.billingError = JSON.stringify([
      [`Pedido de cancelamento de OS feito por: ${user.user.nome}`, TimestampNow(), 'systemKEAP'],
      ...(values.billingError ? JSON.parse(values.billingError) : []),
    ])
    values.status = 'canceled'
    await addDocument('_billings', values)
  }

  const createBilling = async () => {
    const dayPayment = 15 // ajuste de data de pagamento
    const nContaCorrente = 10747786857
    const codCategoria = '1.01.02'
    const startColumnOmie = '10'

    const today = new Date()

    // console.log(formContext)

    const idClient = formContext.id

    const listPlans = formContext.plans

    const planActive = getActivePlan(listPlans, today)

    if (planActive === null) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Cliente não possui planos para faturar',
          msg: 'Selecione um plano para realizar o faturamento do cliente',
        },
      ])
    }

    // Calcula a data desejada para faturamento
    const desiredInvoiceDate = GenerateDate({
      day: Number(formContext.dt_invoices),
    })

    // Calcula a data do mês anterior
    const previousInvoiceDate = GenerateDate({
      day: Number(formContext.dt_invoices),
      month: String(((today.getMonth() - 1 + 12) % 12) + 1).padStart(2, '0'),
      year: today.getFullYear() - (today.getMonth() === 0 ? 1 : 0),
    })

    // console.log(today < new Date(desiredInvoiceDate))

    // console.log(previousInvoiceDate)

    // Define a data de faturamento correta
    const dtInvoice = formContext?.dt_lastInvoice
      ? formContext.dt_lastInvoice // Última data de faturamento registrada
      : today < new Date(desiredInvoiceDate)
        ? previousInvoiceDate // Data do mês anterior se ainda não passou
        : desiredInvoiceDate // Data do mês atual se já passou

    // gera a data do próximo faturamento
    // através dos dias que o plano tem
    const dtNextInvoice = SomarDiasAData(Number(planActive ? planActive.typeExpired : '1'), dtInvoice)

    const clientsActive = [
      {
        plan: planActive,
        day_invoices: formContext.dt_invoices,
        dt_invoice: dtInvoice,
        idClient,
        cCodClientOmie: formContext.cCodClientOmie,
        type_invoices: formContext.type_invoices,
        responsavel_email: formContext.responsavel_email,
        dt_lastInvoice: formContext.dt_nextInvoice || '1972-01-01',
        ...(planActive && {
          dt_nextInvoice: dtNextInvoice,
        }),
        ...(planActive && {
          descNF: `  - Serviço de assinatura referente ao plano ${planActive.name || ''}
- Período de ${dtInvoice} a ${dtNextInvoice}
`,
        }),
      },
    ]

    // console.log(clientsActive)

    const getOS = await getDocuments('_billings')
    const osInBillings = getOS
      .map((doc) => {
        if (today <= new Date(doc.dt_nextInvoice)) {
          return doc.idClient
        } else {
          return null
        }
      })
      .filter((client) => client !== null)
    // console.log('... Coletando OS ja processadas: ' + osInBillings.length)

    // verfica se pode criar a os
    const clientsFromInvoices = clientsActive.filter((client) => !osInBillings.includes(client.idClient))

    // console.log('... clientes para faturar: ' + clientsFromInvoices.length)

    if (clientsFromInvoices.length === 0) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Cliente Já Faturado',
          msg: 'Cancele o faturamento anterior para criar um novo',
        },
      ])
    }
    const enrichedInvoices = clientsFromInvoices.map((values) => {
      return {
        ...values,
        dataOmie: JSON.stringify(
          createSchemaOrdemDeServicoOmie({
            ...values,
            startColumnOmie,
            codCategoria,
            nContaCorrente,
            dayPayment,
            userName,
          }),
        ),
      }
    })
    // console.log('... Criando data para requestOMIE')
    const createInvoices = enrichedInvoices.map((values) => {
      return createSchemaOrdemDeServico({
        ...values,
        startColumnOmie,
        codCategoria,
        nContaCorrente,
        dayPayment,
        userName,
      })
    })

    // cria as OS na collection
    const promisses = createInvoices.map(async (values) => {
      // console.log('... gerando invoice')
      const idOS = await generateIdDocument('_billings')
      // const incluirServicoOmie = false;
      const data = { ...values, idOS }
      // console.log(data)
      // console.log('... id gerado ', idOS)
      await setDocument('_billings', idOS, data)
      // console.log('... invoice gerada ', idOS)
    })

    await Promise.all(promisses)
      .then((response) => {
        setAlerts((prev) => [
          ...prev,
          {
            type: 'success',
            title: 'Pedido de Faturamento enviado',
            msg: 'o Pedido de faturamento foi enviado, aguadar o processo finalizar',
          },
        ])
        return FetchGetData()
      })
      .catch((error) => {
        throw new Error(error.message)
      })
  }

  const setHistoryBilling = async (values) => {
    setModalHistory(true)
    const history = JSON.parse(values.billingError)
    let reqOmie = JSON.parse(values.dataOmie)
    reqOmie = JSON.stringify(reqOmie, null, 2)

    setDataContext((prev) => ({ ...prev, history, reqOmie }))
  }

  const HandleSetHistoryBilling = async (values) => {
    await TryCatch(
      async () => {
        await setHistoryBilling(values)
      },
      setLoading,
      setAlerts,
    )
  }

  const HandleCreateBilling = async () => {
    await TryCatch(createBilling, setLoading, setAlerts)
  }

  const HandleCancelBilling = async (values) => {
    await TryCatch(
      async () => {
        await cancelBilling(values)
      },
      setLoading,
      setAlerts,
    )
  }

  const FetchGetData = async () => {
    await TryCatch(getData, setLoading, setAlerts)
  }

  useEffect(() => {
    FetchGetData()
  }, [])

  const invoices = (dataContext?.invoices || []).sort((a, b) => Number(b.cNumOS) - Number(a.cNumOS))

  const dataFiltered =
    search !== ''
      ? invoices.filter((invoices) => {
          const searchString =
            `${invoices.cNumOS}||${invoices.status}||${invoices.dt_created}||${invoices.value}`.toLowerCase()
          return searchString.includes(search.toLowerCase())
        })
      : invoices

  return {
    id,
    search,
    setSearch,
    navigate,
    infosContext,
    loading,
    setLoading,
    dataFiltered,
    FetchGetData,
    HandleCancelBilling,
    HandleSetHistoryBilling,
    modalHistory,
    setModalHistory,
    dataContext,
    HandleCreateBilling,
    formContext,
  }
}
