import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

import { useGlobalContext } from '../../../../../context/ContextGlobal'
import { TryCatch } from '../../../../../utils/general'
import { addDocument, setDocument } from '../../../../../firebase/firestore'
import { Path } from '../../../../../router/paths'
import { schemaConsent } from '../utils/schemas'
import { DateTimeNow } from '../../../../../utils/dates'

const useAddConsents = () => {
  const {
    user,
    setAlerts,
    // dataContext,
    // setDataContext,
    // infosContext,
    // setInfosContext,
    formContext,
    setFormContext,
    loading,
    setLoading,
    // statusLoading,
    // setStatusLoading,
  } = useGlobalContext()

  const navigate = useNavigate()

  const getForm = () => {
    setFormContext({
      sendInRegister: 'false',
      txtButton: 'Li e Aceito os Termos',
      revogar: 'not_expired',
      notDelete: 'true',
    })
  }

  const FetchGetForm = async () => {
    await TryCatch(getForm, setLoading, setAlerts)
  }

  const AddConsent = async () => {
    const newConsentimento = { created_by: user.user.name, dt_created: DateTimeNow(), ...schemaConsent(formContext) }

    const idConsent = await addDocument('consentimentos', newConsentimento)
    const sendInRegister = newConsentimento?.sendInRegister || null
    if (sendInRegister === 'true') {
      const newConsent = {
        name: formContext?.name,
        singDate: '',
        status: false,
        type: 'consent',
      }
      await setDocument('_defaultRegister', idConsent, newConsent)
    }

    setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Consentimento Criado',
        msg: 'Novo Consentimento criado com sucesso.',
      },
    ])
    return navigate(Path.AdminConsents)
  }
  const HandleAddConsents = async () => {
    await TryCatch(AddConsent, setLoading, setAlerts)
  }

  useEffect(() => {
    FetchGetForm()
  }, [])

  return { user, loading, setLoading, formContext, setFormContext, HandleAddConsents }
}

export default useAddConsents
