import React from 'react'

// o children será o texto que é escrito no botão
// as colores recebe todas as cores do bulma sem o "IS-"
// o action recebe o função que ao apertar o botão ela será executada

export const ButtonContentDropDownButton = ({
  children,
  className = '',
  color = 'is-light',
  colorText = null,
  size = null,
  disabled = null,
  loading = null,
}) => {
  return (
    <div className="dropdown-trigger">
      <button
        className={`button ${color} ${size} ${colorText} ${className} ${loading && 'is-loading'}`}
        aria-haspopup="true"
        aria-controls="dropdown-menu"
        disabled={disabled}
      >
        {children}
      </button>
    </div>
  )
}
