export const schemaConsent = (value) => {
  return {
    created_by: value?.create_by || '',
    dt_created: value?.dt_created || '',
    name: value?.name || '',
    revogar: value?.revogar || '',
    termo: value?.termo || '',
    txtButton: value?.txtButton || '',
    sendInRegister: value?.sendInRegister || '',
    syncField: value?.syncField || '',
    notDelete: value?.notDelete || '',
  }
}
