import { ChevronRight, Gift, RotateCw } from 'lucide-react'

import { Path } from '../../../router/paths'
import useCollaborator from '../_hooks/useCollaborator'

export const PlanCard = ({ plan, user }) => {
  const { token } = useCollaborator()

  const hasAtive = (user?.allPlans ?? []).some((item) => item.id === plan.id)

  return (
    <a
      href={hasAtive ? Path.CollabPlans(token) : Path.CollabSignPlanSelected(token, plan.id)}
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: '1rem 0',
        padding: '1.5rem',
        borderRadius: '12px',
        // background: 'linear-gradient(135deg,rgb(255, 255, 255),rgb(233, 233, 233))',
        color: '#333',
        textDecoration: 'none',
        boxShadow: '0 12px 24px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        position: 'relative',
      }}
      className="box"
    >
      <div
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          padding: '0.3rem 0.6rem',
          backgroundColor: '#ff6f61',
          color: '#fff',
          fontWeight: 'bold',
          fontSize: '0.9rem',
          borderRadius: '5px',
        }}
        className={`${hasAtive ? 'has-background-primary' : 'has-background-danger'}`}
      >
        {hasAtive ? 'PLANO ATIVO' : 'PLANO DISPONÍVEL'}
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '1rem',
          marginTop: 60,
        }}
        className="has-text-link"
      >
        <h3
          style={{
            fontSize: '1.5rem',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            letterSpacing: '1px',
          }}
          className="has-text-link"
        >
          {plan.name}
        </h3>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1.5rem',
          alignItems: 'center',
          marginBottom: '1.5rem',
        }}
      >
        <div
          style={{
            color: '#000',
            padding: '0.5rem 1rem',
            borderRadius: '30px',
            fontWeight: 'bold',
            fontSize: '1rem',
            textTransform: 'uppercase',
            marginBottom: '1rem',
          }}
          className="has-background-primary"
        >
          Apenas R$ {plan.value}
        </div>

        <div
          style={{
            display: 'flex',
            gap: '1rem',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <RotateCw />
          <p style={{ fontSize: '1rem' }}>
            Cobrança a cada <b>{plan.typeExpired} Dias</b>
          </p>
        </div>
        {Number(plan?.trialDays) > 0 && (
          <div
            style={{
              display: 'flex',
              gap: '1rem',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Gift />
            <p style={{ fontSize: '1rem' }}>
              <b>{plan.trialDays}</b> dias grátis!
            </p>
          </div>
        )}

        {/* <div
          style={{
            display: 'flex',
            gap: '1rem',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <SearchCheck />
          <p style={{ fontSize: '1rem' }}>
            Taxa de Análise: <b>R$ {plan.admission}</b>
          </p>
        </div> */}
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 60,
        }}
      >
        <ChevronRight style={{ color: '#4caf50', cursor: 'pointer', fontSize: '1.8rem' }} />
        <span
          style={{
            fontSize: '1rem',
            fontWeight: 'bold',
            color: '#4caf50',
            textTransform: 'uppercase',
            letterSpacing: '0.5px',
          }}
        >
          Escolher plano
        </span>
      </div>
    </a>
  )
}
