import React from 'react'

import { Button } from '../../../components/ui/button'
import { Page } from '../../../components/layouts/page'
import { Column } from '../../../components/layouts/columns'
import { Path } from '../../../router/paths'

const PrivacyPolicy = () => {
  return (
    <Page.Root>
      <Page.Content>
        <div>
          <Column.Root className="mt-6" style={{ color: 'rgb(151, 151, 151)' }}>
            <Column.Content style={{ padding: '0 2rem 0 2rem' }}>
              <h1 className="title is-size-3 has-text-centered">Política de Privacidade</h1>

              <p className="subtitle is-size-5 mt-4">
                Sua privacidade é importante para nós. Esta política de privacidade descreve as informações que
                coletamos e como as usamos, bem como as opções que você tem para proteger sua privacidade.
              </p>

              <h2 className="subtitle is-size-4 mt-6">1. Coleta de Informações</h2>
              <p>
                Coletamos informações pessoais como nome, e-mail, e outras informações fornecidas diretamente pelo
                usuário durante o processo de registro e uso do aplicativo.
              </p>

              <h2 className="subtitle is-size-4 mt-6">2. Uso das Informações</h2>
              <p>
                Usamos as informações coletadas para fornecer, melhorar e personalizar a experiência do usuário. Também
                podemos usar as informações para enviar notificações importantes e ofertas personalizadas, caso o
                usuário tenha optado por recebê-las.
              </p>

              <h2 className="subtitle is-size-4 mt-6">3. Proteção das Informações</h2>
              <p>
                Adotamos medidas razoáveis para proteger as informações pessoais dos usuários, como criptografia e
                controle de acesso. No entanto, nenhuma transmissão de dados pela internet é 100% segura.
              </p>

              <h2 className="subtitle is-size-4 mt-6">4. Compartilhamento de Informações</h2>
              <p>
                Não vendemos nem alugamos suas informações pessoais a terceiros. Podemos compartilhar suas informações
                com parceiros de confiança para melhorar a experiência do usuário, mas sempre sob acordos de
                confidencialidade.
              </p>

              <h2 className="subtitle is-size-4 mt-6">5. Seus Direitos</h2>
              <p>
                Você tem o direito de acessar, corrigir ou excluir suas informações pessoais a qualquer momento. Caso
                queira exercer esses direitos, entre em contato conosco por meio do nosso suporte.
              </p>

              <h2 className="subtitle is-size-4 mt-6">6. Alterações nesta Política</h2>
              <p>
                Podemos atualizar esta Política de Privacidade periodicamente. Recomendamos que você revise essa página
                regularmente para se manter informado sobre como estamos protegendo suas informações.
              </p>

              <h2 className="subtitle is-size-4 mt-6">7. Contato</h2>
              <p>
                Se você tiver alguma dúvida sobre esta Política de Privacidade ou sobre o tratamento de suas informações
                pessoais, entre em contato conosco através do e-mail: <strong>keapstaff@gmail.com</strong>
              </p>

              {/* Botão de retorno ou ações adicionais */}
              <div style={{ height: 100 }}></div>
              <Button.A className="is-link is-fullwidth mt-6" link={Path.CollabLogin}>
                Voltar para a Página Inicial
              </Button.A>
              <div style={{ height: 400 }}></div>
            </Column.Content>
          </Column.Root>
        </div>
      </Page.Content>
    </Page.Root>
  )
}

export default PrivacyPolicy
