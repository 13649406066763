import React, { useEffect } from 'react'

export const ModalRoot = ({ children, active = false }) => {
  const classe = `modal ${active ? 'is-active' : ''}`

  // Efeito para gerenciar a rolagem do fundo da página
  useEffect(() => {
    // Desativa a rolagem do corpo da página quando o modal estiver ativo
    if (active) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }

    // Cleanup: Quando o componente for desmontado ou o modal for fechado
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [active]) // O efeito depende do estado de 'active'

  return (
    <div className={classe}>
      <div className="modal-background"></div>
      {children}
    </div>
  )
}
