import React from 'react'

import { Page } from '../../../../components/layouts/page'
import { Icon } from '../../../../components/icons'
import { Column } from '../../../../components/layouts/columns'
import { Button } from '../../../../components/ui/button'
import { Table } from '../../../../components/ui/table'
import { Input } from '../../../../components/ui/input'
import useLogSystem from './hooks/useLogSystem'
import { ConvertTimestampToDateTime } from '../../../../utils/dates'

export const AdminLogSystem = () => {
  const { search, loading, logsFiltered, setSearch, FetchGetData } = useLogSystem()

  return (
    <>
      <Page.Root className="is-fullwidth">
        <Column.Root className="mt-6 pt-5">
          <Column.Content>
            <p className="subtitle is-size-3">Logs do Sistema</p>
          </Column.Content>
          <Column.Content className="has-text-right">
            <Button.Root align="right">
              <Button.Content>
                <Button.Bt
                  color="is-dark"
                  colorText="has-text-white"
                  action={() => {
                    FetchGetData()
                  }}
                  loading={loading}
                >
                  <Icon size={15}>sync</Icon>
                </Button.Bt>
              </Button.Content>
            </Button.Root>
          </Column.Content>
        </Column.Root>
        <Column.Root className="is-mobile">
          <Column.Content>
            <Input.Root>
              <Input.Contents alignIcon={'left'}>
                <Input.Prompt value={search} setValue={setSearch} placeholder="Pesquisar..."></Input.Prompt>
                <Input.Icon align="is-left">
                  <Icon size={20}>search</Icon>
                </Input.Icon>
              </Input.Contents>
            </Input.Root>
          </Column.Content>
        </Column.Root>
        <Column.Root className="is-mobile">
          <Column.Content>
            <Table.Root className="is-fullwidth is-hoverable is-narrow has-text-centered">
              <Table.Head>
                <Table.Row>
                  <Table.Cell>Time</Table.Cell>
                  <Table.Cell>Situação</Table.Cell>
                  <Table.Cell>Origem</Table.Cell>
                  <Table.Cell size={300} className="has-text-left">
                    Mensagem
                  </Table.Cell>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {logsFiltered
                  .sort((a, b) => b.time - a.time)
                  .map((value, index) => {
                    return (
                      <>
                        <Table.Row>
                          <Table.Cell className="is-size-7 is-vcentered">
                            {ConvertTimestampToDateTime(value.time)}
                          </Table.Cell>
                          <Table.Cell className="is-size-7 is-vcentered">{value.type}</Table.Cell>
                          <Table.Cell className="is-size-7 is-vcentered">{value.origin}</Table.Cell>
                          <Table.Cell size={300} className="is-size-7 is-vcentered has-text-left">
                            {value.message}
                          </Table.Cell>
                        </Table.Row>
                      </>
                    )
                  })}
              </Table.Body>
            </Table.Root>
          </Column.Content>
        </Column.Root>
        <Page.Content></Page.Content>
      </Page.Root>
    </>
  )
}
