import { useParams, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

import { useGlobalContext } from '../../../../../context/ContextGlobal'
import { Path } from '../../../../../router/paths'
import { getDocument, updateDocument } from '../../../../../firebase/firestore'
import { TryCatch } from '../../../../../utils/general'
import { schemaNotices } from '../utils/schemas'

const useEditNotices = () => {
  const { id } = useParams()
  const {
    // user,
    setAlerts,
    // dataContext, tudo que for dados da página, e.g: listas
    // setDataContext,
    // infosContext, todas as informações para a página funcionar
    // setInfosContext,
    formContext,
    setFormContext,
    loading,
    setLoading,
    // statusLoading,
    // setStatusLoading,
  } = useGlobalContext()

  const navigate = useNavigate()

  const getForm = async () => {
    const getConsent = await getDocument('notices', id)
    const newConsent = schemaNotices(getConsent)
    setFormContext(newConsent)
  }

  const fetchGetForm = async () => {
    await TryCatch(getForm, setLoading, setAlerts)
  }

  const editConsent = async () => {
    const newNotice = schemaNotices(formContext)
    await updateDocument('notices', id, newNotice)

    setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Aviso Editado',
        msg: `O Aviso "${newNotice?.name || ''}" foi editado com sucesso.`,
      },
    ])

    setFormContext({})
    return navigate(Path.AdminNotices)
  }

  const handleEditConsent = async () => {
    await TryCatch(editConsent, setLoading, setAlerts)
  }

  useEffect(() => {
    fetchGetForm()
  }, [])

  return { loading, formContext, setFormContext, handleEditConsent }
}

export default useEditNotices
