import React from 'react'

import { Page } from '../../../components/layouts/page'
import { Column } from '../../../components/layouts/columns'
import { Form } from '../../../components/ui/form'
import { CollaboratorsForm } from './_components/form'
import { useAddAdminCollaborators } from './_hooks'

export const AddAdminCollaborators = () => {
  const { handleAddCollaborators } = useAddAdminCollaborators()

  return (
    <Page.Section className="is-fullheight-with-navbar mt-6 pt-6">
      <Form.Root
        action={() => {
          handleAddCollaborators()
        }}
        className="mt-5"
      >
        <Column.Root>
          <Column.Content>
            <h1 className="subtitle is-size-3">Adicionar Staff</h1>
          </Column.Content>
        </Column.Root>

        <CollaboratorsForm />
      </Form.Root>
    </Page.Section>
  )
}
