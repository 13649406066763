import React from 'react'
// o children será o texto que é escrito no botão
// as colores recebe todas as cores do bulma sem o "IS-"
// o action recebe o função que ao apertar o botão ela será executada

export const ButtonContentDropdownOptionContent = ({ children, className = '' }) => {
  return (
    <div className={`dropdown-menu ${className}`} id="dropdown-menu" role="menu">
      <div className="dropdown-content">{children}</div>
    </div>
  )
}
