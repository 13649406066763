import { Gift, RotateCw, Tag } from 'lucide-react'

import { Column } from '../../../components/layouts/columns'

export const PlanDescription = ({ plan, user }) => {
  // return (
  //   <Column.Root
  //     className="mt-6"
  //     style={{
  //       color: 'rgb(151, 151, 151)',
  //     }}
  //   >
  //     <Column.Content
  //       style={{
  //         display: 'flex',
  //         flexDirection: 'column',
  //         alignItems: 'center',
  //         padding: '0 2rem 0 2rem',
  //         paddingInline: 40,
  //       }}
  //     >
  //       <div
  //         style={{
  //           display: 'flex',
  //           flexDirection: 'column',
  //           alignItems: 'center',
  //           gap: '1rem',
  //           margin: '0 0 2rem 0',
  //         }}
  //       >
  //         <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
  //           <Tag color="#2e333d" size={60} />
  //           <p className="title is-4">{plan?.name}</p>
  //         </div>
  //       </div>
  //       <p
  //         style={{
  //           textAlign: 'justify',
  //         }}
  //       >
  //         {plan?.desc}
  //       </p>
  //       <div
  //         style={{
  //           margin: '2rem 0 0 0',
  //         }}
  //       >
  //         <p>
  //           Pagamento via <b>{plan?.payment}</b>
  //         </p>
  //         <p>
  //           Valor: R$ <b>{plan?.value}</b>
  //         </p>
  //         <div
  //           style={{
  //             display: 'flex',
  //             gap: '1rem',
  //             padding: '0 0 .5rem 0',
  //           }}
  //         >
  //           <RotateCw />
  //           <p>
  //             Cobrança a cada <b>{plan?.typeExpired} Dias</b>
  //           </p>
  //         </div>
  //         <div
  //           style={{
  //             display: 'flex',
  //             gap: '1rem',
  //             padding: '0 0 .5rem 0',
  //           }}
  //         >
  //           <Gift />
  //           <p>
  //             Este plano tem <b>{plan?.trialDays} Dias Gratis!</b>
  //           </p>
  //         </div>
  //         {user?.hasTrial && (
  //           <p className="has-text-danger">
  //             Os Dias gratuitos não serão mais aplicados a seu cadastros, pois já foram aplicados
  //           </p>
  //         )}

  //         {user?.admissionPayment && (
  //           <p className="has-text-danger">A Taxa de analise ja foi paga, então não será cobrada novamente</p>
  //         )}
  //       </div>
  //     </Column.Content>
  //   </Column.Root>
  // )

  return (
    <Column.Root
      style={{
        color: '#333',
        padding: '0 2rem 0 2rem',
      }}
    >
      <Column.Content
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {/* Título e ícone de plano */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1rem',
            margin: '0 0 2rem 0',
            textAlign: 'center',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
            <Gift size={60} className="has-text-primary" />
            <h3
              style={{
                marginTop: 0,
                fontSize: '2rem',
                fontWeight: 'bold',
                textTransform: 'uppercase',
              }}
              className="has-text-primary"
            >
              {plan?.name}
            </h3>
          </div>
        </div>

        {/* Descrição do plano */}
        <p
          style={{
            textAlign: 'center',
            marginBottom: '2rem',
            fontSize: '1.1rem',
            lineHeight: '1.6',
            color: '#555',
            whiteSpace: 'pre-line',
          }}
        >
          {plan?.desc}
        </p>

        {/* Detalhes do plano */}
        <div>
          <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
            Pagamento via <b>{plan?.payment}</b>
          </p>
          <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
            Valor: <b>R$ {plan?.value}</b>
          </p>

          <div
            style={{
              display: 'flex',
              gap: '1rem',
              paddingBottom: '1rem',
            }}
          >
            <RotateCw />
            <p style={{ fontSize: '1rem' }}>
              Cobrança a cada <b>{plan?.typeExpired} Dias</b>
            </p>
          </div>

          {Number(plan?.trialDays) > 0 && (
            <div
              style={{
                display: 'flex',
                gap: '1rem',
                paddingBottom: '1rem',
              }}
            >
              <Gift />
              <p style={{ fontSize: '1rem' }}>
                Este plano tem <b>{plan?.trialDays} Dias Grátis!</b>
              </p>
            </div>
          )}

          {Object.keys(plan?.cupomSelected ?? {}).length > 0 && (
            <div
              style={{
                display: 'flex',
                gap: '1rem',
                paddingBottom: '1rem',
              }}
              className="has-text-danger"
            >
              <Tag />
              <p style={{ fontSize: '1rem' }}>
                Cupom de <b> R$ {plan?.cupomSelected?.value}</b> por <b>{plan?.cupomSelected?.daysExpired}</b> Dias!
              </p>
            </div>
          )}

          {/* Avisos e mensagens sobre o plano */}
          {/* {user?.hasTrial && (
            <p
              style={{
                color: '#d9534f',
                fontSize: '1rem',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              Os Dias gratuitos não serão mais aplicados a seu cadastro, pois já foram usados.
            </p>
          )}

          {user?.admissionPayment && (
            <p
              style={{
                color: '#d9534f',
                fontSize: '1rem',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              A Taxa de Análise já foi paga e não será cobrada novamente.
            </p>
          )} */}
        </div>
      </Column.Content>
    </Column.Root>
  )
}
