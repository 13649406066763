import { useState } from 'react'

const useFormSectors = () => {
  const [checkAlls, setCheckAlls] = useState({})

  const handleCheckAll = async (permitions, setPermissions, newValue, field) => {
    // console.log(permitions)
    const allPermissions = Object.keys(permitions || {})
    const newPermissions = permitions

    allPermissions.forEach((perm) => {
      const permToLower = perm.toLowerCase()
      const permissionToLower = field.toLowerCase()
      if (permToLower.includes(permissionToLower)) {
        //   // console.log(checkAlls)
        //   // console.log(newPermissions)
        //   // console.log(newPermissions[perm])
        newPermissions[perm] = newValue
        //   // console.log(newPermissions[perm])
      }
    })

    setPermissions(newPermissions)
  }

  return { checkAlls, setCheckAlls, handleCheckAll }
}

export default useFormSectors
