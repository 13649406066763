import React from 'react'

import { Column } from '../../../../../components/layouts/columns'
import { Button } from '../../../../../components/ui/button'
import { Input } from '../../../../../components/ui/input'
import { Select } from '../../../../../components/ui/select'
import { Path } from '../../../../../router/paths'
import { Table } from '../../../../../components/ui/table'
import useFormReports from './hooks/useAdminReports'
import { Icon } from '../../../../../components/icons'
import { dataStatusCandidacyOptions } from '../utils/dataStatusCandidacyOptions'
import { statusStaff } from '../../../../client/events/_utils/statusCandidate'

export const FormReports = ({ values, setValues, loading, disabledList }) => {
  const { HandleAddColumn, HandleDeleteColumn, HandleAddFilters, HandleDeleteFilter, optionsColumns } = useFormReports(
    values,
    setValues,
  )

  return (
    <>
      <Column.Root>
        <Column.Content>
          <Input.Root className="mb-5">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Nome do Relatório
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={values}
                setValue={setValues}
                field={'name'}
                required={true}
                disabled={disabledList?.name}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>

          <Input.Root className="mb-5">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Descrição
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={values}
                setValue={setValues}
                field={'description'}
                required={true}
                disabled={disabledList?.description}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
      </Column.Root>

      <Column.Root>
        <Column.Content>
          <Input.Root className="mb-5">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              Filtrar Status da candidatura
            </Input.Label>

            <Select.Root>
              <Select.Search
                color="is-grey"
                className="is-fullwidth is-rounded"
                value={values}
                setValue={setValues}
                field={'selectFilter'}
                disabled={disabledList?.selectColumn}
                options={dataStatusCandidacyOptions || []}
                limitOptions={999}
              ></Select.Search>
            </Select.Root>
          </Input.Root>
        </Column.Content>
        <Column.Content size="is-1">
          <Button.Bt
            className="mt-5"
            type={'button'}
            loading={loading}
            action={() => {
              HandleAddFilters()
            }}
          >
            Adicionar
          </Button.Bt>
        </Column.Content>
      </Column.Root>

      <Table.Root style={{ marginTop: -30 }} className="table is-striped is-narrow is-hoverable is-fullwidth">
        <Table.Head>
          <Table.Row>
            <Table.Cell>Filtro</Table.Cell>
            <Table.Cell>Ação</Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {(values?.filters || []).map((value) => {
            return (
              <>
                <Table.Row>
                  <Table.Cell className="is-vcentered">{`${value.value} - ${statusStaff[Number(value.value)].status}`}</Table.Cell>
                  <Table.Cell className="is-vcentered">
                    <Button.Content>
                      <Button.Bt
                        className={'mx-2'}
                        color="is-danger"
                        loading={loading}
                        title="Deletar Filtro"
                        action={async () => {
                          await HandleDeleteFilter(value)
                        }}
                      >
                        <Icon size={15}>trash</Icon>
                      </Button.Bt>
                    </Button.Content>
                  </Table.Cell>
                </Table.Row>
              </>
            )
          })}
        </Table.Body>
      </Table.Root>

      <Column.Root className="mt-6">
        <Column.Content>
          <Input.Root className="mb-5">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              Adicionar Coluna
            </Input.Label>

            <Select.Root>
              <Select.Search
                color="is-grey"
                className="is-fullwidth is-rounded"
                value={values}
                setValue={setValues}
                field={'selectColumn'}
                disabled={disabledList?.selectColumn}
                options={optionsColumns || []}
                limitOptions={999}
              ></Select.Search>
            </Select.Root>
          </Input.Root>
        </Column.Content>
        <Column.Content size="is-1">
          <Button.Bt
            className="mt-5"
            type={'button'}
            loading={loading}
            action={() => {
              HandleAddColumn()
            }}
          >
            Adicionar
          </Button.Bt>
        </Column.Content>
      </Column.Root>

      <Table.Root style={{ marginTop: -30 }} className="table is-striped is-narrow is-hoverable is-fullwidth">
        <Table.Head>
          <Table.Row>
            <Table.Cell size={50}>Nº</Table.Cell>
            <Table.Cell>Nome da Coluna</Table.Cell>
            <Table.Cell>Dados da Coluna</Table.Cell>
            <Table.Cell>Ação</Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {(values?.columns || []).map((value) => {
            return (
              <>
                <Table.Row>
                  <Table.Cell className="is-vcentered">{value.index}</Table.Cell>
                  <Table.Cell className="is-vcentered">
                    <Input.Prompt
                      value={value.name}
                      setValue={(v) => {
                        setValues((prev) => {
                          const newColumnData = (prev?.columns || []).map((item) => {
                            if (value.index === item.index) {
                              return { ...item, name: v }
                            }
                            return { ...item }
                          })

                          return {
                            ...prev,
                            columns: newColumnData,
                          }
                        })
                      }}
                    />
                  </Table.Cell>
                  <Table.Cell className="is-vcentered">{value.column.label}</Table.Cell>
                  <Table.Cell className="is-vcentered">
                    <Button.Content>
                      <Button.Bt
                        className={'mx-2'}
                        color="is-danger"
                        loading={loading}
                        title="Deletar Coluna"
                        action={async () => {
                          await HandleDeleteColumn(value)
                        }}
                      >
                        <Icon size={15}>trash</Icon>
                      </Button.Bt>
                    </Button.Content>
                  </Table.Cell>
                </Table.Row>
              </>
            )
          })}
        </Table.Body>
      </Table.Root>

      <Column.Root className="mt-6 mb-6">
        <Column.Content>
          <Button.Root align="right">
            <Button.Content>
              <Button.A color="is-light" link={Path.AdminReports} loading={loading}>
                Cancelar
              </Button.A>
            </Button.Content>
            <Button.Content>
              <Button.Bt className="" color="is-success" colorText="has-text-white" type={'submit'} loading={loading}>
                Salvar
              </Button.Bt>
            </Button.Content>
          </Button.Root>
        </Column.Content>
      </Column.Root>
    </>
  )
}
