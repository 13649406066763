import { useEffect, useState } from 'react'

import { useGlobalContext } from '../../../../../context/ContextGlobal'
import { TryCatch } from '../../../../../utils/general'
import { getSubDocuments } from '../../../../../firebase/firestore'
export const useClientPayments = () => {
  const {
    user,
    setAlerts,
    loading,
    setLoading,
    dataContext,
    setDataContext, // contexto que guarda dados de lista para a pagina
    infosContext,
    setInfosContext, // contexto que guarda dados adicionais para a pagina funcionar
    // formContext,
    // setFormContext, // contexto que guarda dados de formularios da pagina
  } = useGlobalContext()

  const plans = infosContext?.plans || []

  const plansData = plans.sort((a, b) => {
    return new Date(a.dtStart) - new Date(b.dtStart)
  })

  const [search, setSearch] = useState('')
  // const navigate = useNavigate();
  const clientId = user.client.id

  const getDatas = async () => {
    const getPayments = await getSubDocuments('clientes', clientId, 'payments')
    // const query = [{ field: 'codigo_cliente_fornecedor', operator: '==', value: Number(user.client.cCodClientOmie) }]
    // const getPaymentsProssesing = await getDocumentsWithQuerys('_payments', query)
    // salva todas as informações coletadas no context.
    setDataContext({ payments: [...getPayments] })
  }

  const getInfos = async () => {
    // coleta as permissões globais em CONFIG/PERMISSIONS
    const getPlans = await getSubDocuments('clientes', clientId, 'plans')

    setInfosContext({ plans: getPlans })
  }

  const FetchGetDatas = async () => {
    await TryCatch(getDatas, setLoading, setAlerts)
  }

  const FetchGetInfos = async () => {
    await TryCatch(getInfos, setLoading, setAlerts)
  }

  const payments = (dataContext?.payments || []).sort((a, b) => Number(b.numero_pedido) - Number(a.numero_pedido))

  const dataFiltered =
    search !== ''
      ? payments.filter((pay) => {
          const searchString =
            `${pay.data}|${pay.description}|${pay.value}|${pay.status ? 'pago' : 'pendente'}`.toLowerCase()
          return searchString.includes(search.toLowerCase())
        })
      : payments

  useEffect(() => {
    FetchGetDatas()
    FetchGetInfos()
  }, [])

  return {
    user,
    loading,
    search,
    setSearch,
    dataFiltered,
    assinaturasDatas: plansData,
    dataContext,
    setDataContext,
    infosContext,
    FetchGetDatas,
    FetchGetInfos,
  }
}
