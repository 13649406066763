import { Icon } from '../../../../components/icons'
import { Button } from '../../../../components/ui/button'
import { Modal } from '../../../../components/ui/modal'
import { CopyText } from '../../../../utils/general'
import { ClientsFormModalInfoCandidacyLayout } from '../_form/clientsFormModalInfoCandidacyLayout'

export const ModalInfoCandidacy = ({
  active,
  setActive,
  values,
  actions = {
    setBlocked: () => {},
    setFavorite: () => {},
  },
  infosContext,
}) => {
  return (
    <Modal.Root active={active}>
      <Modal.Contents>
        <Modal.Header>
          <p className="modal-card-title" style={{ marginBottom: -15 }}>
            Candidatura
            <p className="tag is-ligth ml-2">Id: {values.id}</p>
            <Button.Bt
              className="is-small mt-1 ml-1"
              action={() => {
                CopyText(values.id)
              }}
            >
              <Icon size={10}>copy</Icon>
            </Button.Bt>
            <br />
            <p className={`tag ${values?.status?.color} mx-2`}>{values?.status?.status}</p>
            <br />
            {values?.motivo && <p className={`tag is-light mx-2`}>Motivo: {values?.motivo}</p>}
          </p>
          <Modal.HeaderClose setActive={setActive}></Modal.HeaderClose>
        </Modal.Header>
        <Modal.Body>
          <ClientsFormModalInfoCandidacyLayout
            formContext={values}
            infosContext={infosContext}
          ></ClientsFormModalInfoCandidacyLayout>
        </Modal.Body>
        <Modal.Footer>
          {/* {// console.log(values?.favorite)} */}
          <Button.Bt
            color={values?.favorite ? 'is-danger' : 'is-light'}
            title="Favoritar"
            action={() => {
              actions.setFavorite(values?.uuid)
            }}
          >
            <Icon>favorite</Icon>
          </Button.Bt>
          <Button.Bt
            color={values?.blocked ? 'is-danger' : 'is-light'}
            title="Bloquear Candidaturas"
            action={() => {
              actions.setBlocked(values?.uuid)
            }}
          >
            <Icon>block</Icon>
          </Button.Bt>
        </Modal.Footer>
        <p className={`mx-2 is-size-7`} style={{ position: 'absolute', bottom: 40, right: 15 }}>
          Candidatura criada em:
          <p className={`tag mx-1`}>{values?.dt_created}</p>
        </p>

        {values?.userUpdate && values?.dtUpdate && (
          <p className={`mx-2 is-size-7`} style={{ position: 'absolute', bottom: 15, right: 15 }}>
            Ultima Modificação:
            <p className={`tag mx-1`}>{values?.userUpdate}</p>em:
            <p className={`tag mx-1`}>{values?.dtUpdate}</p>
          </p>
        )}
      </Modal.Contents>
    </Modal.Root>
  )
}
