import { useNavigate, useParams } from 'react-router-dom'

import { updateDocument, updateSubDocument } from '../../../firebase/firestore'
import { useGlobalContext } from '../../../context/ContextGlobal'
// import { TryCatch } from '../../../utils/general'
import { Path } from '../../../router/paths'
import { TryCatch } from '../../../utils/general'
// import { Path } from '../../../router/paths'

// const { useGlobalContext } = require("../../../context/ContextGlobal")

const useCollaboratorPlans = () => {
  const { user, setUser, setAlerts, setLoading, loading } = useGlobalContext()

  const { token } = useParams()

  const navigate = useNavigate()
  console.log(token)

  const CancellPlanInCollab = async (plan) => {
    // cria um novo plano
    const newPlan = {
      status: 'canceled',
    }

    const updateCollab = {
      lastPayment: '',
    }

    await updateSubDocument('colaboradores', user.id, 'plans', plan.id, newPlan)

    await updateDocument('colaboradores', user.id, updateCollab)

    await setUser((prev) => ({ ...prev, ...updateCollab }))

    setAlerts((prev) => [
      ...prev,
      {
        title: 'Assinatura Cancelada',
        msg: `Sua assinatura foi cancelada com sucesso!`,
        type: 'success',
      },
    ])

    return navigate(Path.CollabHome(token))
  }

  const HandleCancellPlanInCollab = async (plan) => {
    await TryCatch(
      async () => {
        await CancellPlanInCollab(plan)
      },
      setLoading,
      setAlerts,
    )
  }

  return { loading, HandleCancellPlanInCollab }
}

export default useCollaboratorPlans
