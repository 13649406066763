import { useEffect, useState } from 'react'
// import { useEffect, useState, useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

// import { getDocuments } from '../../../../firebase/firestore'
import { useGlobalContext } from '../../../../context/ContextGlobal'
// import { TratamentoError } from '../../../../utils/erros's
import { TryCatch } from '../../../../utils/general'
import { GetPaginationCollection } from '../../../../firebase/functions'
import { deleteDocument, getDocuments } from '../../../../firebase/firestore'
import { Path } from '../../../../router/paths'

export const useAdminCollaborators = () => {
  const { setAlerts, dataContext, setDataContext, setInfosContext, loading, setLoading } = useGlobalContext()
  const [paginationValue, setPaginationValue] = useState(1)
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState({ status: '1' })
  const [searchParams, setSearchParams] = useSearchParams()
  const collaborators = dataContext?.colaboradores?.data ?? []

  const collaboratorsBasicData = collaborators.map((collaborator) => {
    return {
      id: collaborator.id,
      name: collaborator.name,
      email: collaborator.email,
      cpf: collaborator.cpf,
      status: collaborator.status,
    }
  })

  const collaboratorsList =
    search !== ''
      ? Object.values(collaboratorsBasicData).filter((collaborator) => {
          const dataSearch = `.*${search}.*`
          const dataSearchRegEx = new RegExp(dataSearch, 'i')

          const searchString = `${collaborator.name}|${collaborator.email}|${collaborator.positions}|${collaborator.status}`
          return searchString.match(dataSearchRegEx)
        })
      : Object.values(collaboratorsBasicData)

  const getDatas = async () => {
    if (location.pathname === Path.AdminCollaborators) {
      setSearchParams({ page: paginationValue, query: search })
    }

    const collectionName = 'colaboradores'
    const fieldsFiltered = ['nome', 'email', 'cpf']
    const orderBy = 'dt_created'
    const limitResultPerPage = 100
    const value = String(search).toLowerCase()
    let querys = []
    if (value !== '') {
      querys = fieldsFiltered.map((field) => [
        { field, operator: '>=', value },
        { field, operator: '<=', value: value + '\uf8ff' },
      ])
    }
    console.log(status?.status)

    querys.push({ field: 'status', operator: '==', value: Number(status?.status) || 0 })

    console.log(querys)
    const getCollaboratorPagination = await GetPaginationCollection(
      paginationValue,
      limitResultPerPage,
      collectionName,
      orderBy,
      querys,
    )

    return setDataContext({ colaboradores: getCollaboratorPagination })
  }

  const handleDeleteCollaborator = async (id) => {
    const collaborator = dataContext?.colaboradores?.data?.find((collaboratorData) => collaboratorData.id === id)

    if (!collaborator) {
      return setAlerts((prev) => [
        ...prev,
        {
          title: 'O id inválido',
          msg: `O colaborador com o id ${id} não existe.`,
          type: 'error',
        },
      ])
    }

    await deleteDocument('colaboradores', id)

    setAlerts((prev) => [
      ...prev,
      {
        title: `Colaborador deletado com sucesso`,
        msg: `O colaborador ${collaborator.nome} foi deletado com sucesso.`,
        type: 'success',
      },
    ])

    await getDatas()

    setDataContext((prevState) => {
      return {
        ...prevState,
        colaboradores: {
          ...prevState.colaboradores,
        },
      }
    })
  }

  const nextPage = async () => {
    const currentPage = dataContext?.colaboradores?.pagination?.currentPage
    setPaginationValue(currentPage + 1)
    await getDatas()
  }

  const previousPage = async () => {
    const currentPage = dataContext?.colaboradores?.pagination?.currentPage
    setPaginationValue(currentPage - 1)
    await getDatas()
  }

  const getInfos = async () => {
    const getPositions = await getDocuments('cargos')

    setInfosContext({
      cargos: getPositions,
    })
  }

  const FetchGetDatas = async () => {
    await TryCatch(getDatas, setLoading, setAlerts)
  }

  const FetchGetInfos = async () => {
    await TryCatch(getInfos, setLoading, setAlerts)
  }

  useEffect(() => {
    FetchGetDatas()
    FetchGetInfos()
  }, [paginationValue, status])

  return {
    loading,
    getDatas,
    search,
    setSearch,
    dataContext,
    paginationValue,
    setPaginationValue,
    nextPage,
    previousPage,
    collaboratorsList,
    handleDeleteCollaborator,
    searchParams,
    FetchGetDatas,
    FetchGetInfos,
    status,
    setStatus,
  }
}
