import React from 'react'

import { Page } from '../../../../components/layouts/page'
import { Column } from '../../../../components/layouts/columns'
import { Form } from '../../../../components/ui/form'
import { FormPlansLayout } from './forms/formsPlansLayout'
import { useClientAddPlans } from './hooks/useAddPlans'
import { Path } from '../../../../router/paths'
import { Button } from '../../../../components/ui/button'

export const AAddPlans = () => {
  const { loading, formContext, setFormContext, HandleAddPlan } = useClientAddPlans()

  return (
    <Page.Section className="is-fullheight-with-navbar">
      <Form.Root
        action={() => {
          HandleAddPlan()
        }}
      >
        <Column.Root>
          <Column.Content>
            <p className="subtitle is-size-3">Adicionar Plano</p>
          </Column.Content>
        </Column.Root>
        <FormPlansLayout loading={loading} values={formContext} setValues={setFormContext} disabledList={{}} />
        <Column.Root className="mt-5 mb-6">
          <Column.Content>
            <Button.Root align="right">
              <Button.Content>
                <Button.A color="is-light" link={Path.AdminPlans} loading={loading}>
                  Cancelar
                </Button.A>
              </Button.Content>
              <Button.Content>
                <Button.Bt className="" color="is-success" colorText="has-text-white" type={'submit'} loading={loading}>
                  Salvar
                </Button.Bt>
              </Button.Content>
            </Button.Root>
          </Column.Content>
        </Column.Root>
      </Form.Root>
    </Page.Section>
  )
}
