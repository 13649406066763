import { useNavigate } from 'react-router-dom'

import { useGlobalContext } from '../../../../../context/ContextGlobal'
import { DateTimeNow } from '../../../../../utils/dates'
import { NewUser } from '../../../../../utils/requests'
import { TryCatch } from '../../../../../utils/general'
import { Path } from '../../../../../router/paths'

export const useClientAddUser = () => {
  const {
    user,
    setAlerts,
    loading,
    setLoading,
    // dataContext,
    // setDataContext, // contexto que guarda dados de lista para a pagina
    infosContext,
    // setInfosContext, // contexto que guarda dados adicionais para a pagina funcionar
    formContext,
    setFormContext, // contexto que guarda dados de formularios da pagina
  } = useGlobalContext()

  const sectors = infosContext?.sectors || []
  const navigate = useNavigate()
  const clientId = user.client.id

  const addUser = async () => {
    if (!user?.user?.permissions?.users_add) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Permissão Negada',
          msg: 'Você não possui permissão para adicionar usuarios',
        },
      ])
    }

    if (user?.client?.responsavel?.email === formContext?.email) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Usuário Administrador',
          msg: 'Você não pode adicionar outro usuário com o mesmo email do administrador.',
        },
      ])
    }

    const newUserData = {
      ...formContext,
      createBy: user.user.nome,
      dtCreated: DateTimeNow(),
      type: 'client',
    }

    // console.log('entrei')
    await NewUser(newUserData, clientId, setAlerts)
    await setFormContext({})
    navigate(Path.ClientUsers)
  }

  const HandleAddUser = async () => {
    await TryCatch(addUser, setLoading, setAlerts)
  }

  return {
    sectors,
    HandleAddUser,
    formContext,
    setFormContext,
    loading,
    setLoading,
  }
}
