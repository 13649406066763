/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'

import { useGlobalContext } from '../../../../../context/ContextGlobal'
import { getDocuments, deleteDocument, addDocument } from '../../../../../firebase/firestore'
import { TryCatch } from '../../../../../utils/general'
import { DateTimeNow } from '../../../../../utils/dates'

const useAdminReasons = () => {
  const {
    user,
    setAlerts,
    dataContext,
    setDataContext,
    // infosContext, todas as informações para a página funcionar
    // setInfosContext,
    formContext,
    setFormContext,
    loading,
    setLoading,
    // statusLoading,
    // setStatusLoading,
  } = useGlobalContext()
  const [search, setSearch] = useState('')

  // coleta uma lista de motivos
  const getData = async () => {
    const getReasons = await getDocuments('reasons')

    setDataContext({ motivos: getReasons })
  }

  const FetchGetData = async () => {
    await TryCatch(getData, setLoading, setAlerts)
  }

  const addReason = async () => {
    const newReason = {
      label: formContext.newReason,
      value: formContext.newReason,
      created_by: user?.user?.nome,
      dt_created: DateTimeNow(),
    }
    const idReason = await addDocument('reasons', newReason)

    setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Docuemnto Criado',
        msg: 'Novo Documento criado com sucesso.',
      },
    ])

    await getData()
    setFormContext({})
  }

  const deleteReason = async (reason) => {
    if (!user?.user?.permissions?.motivos_excluir) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Permissão Negada',
          msg: 'Você não possui permissão para apagar Motivos',
        },
      ])
    }

    const idReason = reason?.id || null

    if (!idReason) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'ID do motivo não encontrado',
          msg: 'Não conseguimos achar o ID do motivo. Tente novamente mais tarde.',
        },
      ])
    }

    await deleteDocument('reasons', idReason)
    await getData()
    return setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Motivo Excluído',
        msg: 'Motivo excluído da sua base de dados',
      },
    ])
  }

  const handleDeleteReason = async (reason) => {
    await TryCatch(
      async () => {
        deleteReason(reason)
      },
      setLoading,
      setAlerts,
    )
  }

  const dataContextFiltered =
    search !== ''
      ? (dataContext?.motivos || []).filter((item) => {
          const searchString = `${item.label}|${item.id}`.toLowerCase()
          return searchString.includes(search.toLocaleLowerCase())
        })
      : dataContext?.motivos || []

  // executa quando carrega a pagina e quando é auterado o getUserList
  useEffect(() => {
    FetchGetData()
    setFormContext({})
  }, [])

  return {
    search,
    setSearch,
    loading,
    dataContextFiltered,
    formContext,
    setFormContext,
    FetchGetData,
    addReason,
    handleDeleteReason,
  }
}

export default useAdminReasons
