import React from 'react'

import { Page } from '../../../components/layouts/page'
import { Column } from '../../../components/layouts/columns'
// import { Button } from '../../../components/ui/button'
import { Icon } from '../../../components/icons'
import { Table } from '../../../components/ui/table'
import { Input } from '../../../components/ui/input'
import { Button } from '../../../components/ui/button'
import { usePaymentsClients } from './_hooks/usePaymentClients'
import { colorTagStatusPagamentos } from './_utils'
import { ModalHistoryBilling } from './_modal/modalHistoryBilling'
import { ConvertTimestampToDateTime } from '../../../utils/dates'

export const AdminClientsPayments = () => {
  const {
    loading,
    search,
    setSearch,
    dataFiltered,
    FetchGetData,
    HandleNewPayment,
    modalHistory,
    setModalHistory,
    dataContext,
    HandleSelectPayment,
    HandleDeletePayment,
    HandleCancelPayment,
    HandleReprocessPayment,
    HandleConsultarOsOmie,
    formContext,
  } = usePaymentsClients()

  return (
    <Page.Section className="is-fullheight-with-navbar  mt-6 pt-6">
      <ModalHistoryBilling
        active={modalHistory}
        setActive={setModalHistory}
        loading={loading}
        dataContext={dataContext.paymentSelected}
      ></ModalHistoryBilling>

      <Column.Root>
        <Column.Content>
          <p className="subtitle is-size-3">Pagamentos</p>
        </Column.Content>
        <Column.Content>
          <Button.Root align="right">
            <Button.Content>
              <Button.Bt
                color="is-dark"
                colorText="has-text-white"
                action={async () => {
                  await HandleNewPayment()
                }}
                loading={loading}
              >
                +
              </Button.Bt>
            </Button.Content>
            <Button.Content>
              <Button.Bt
                color="is-dark"
                colorText="has-text-white"
                action={() => {
                  FetchGetData()
                }}
                loading={loading}
              >
                <Icon size={15}>sync</Icon>
              </Button.Bt>
            </Button.Content>
          </Button.Root>
        </Column.Content>
      </Column.Root>
      <Column.Root className="is-mobile">
        <Column.Content>
          <p>Próxima Cobrança: {ConvertTimestampToDateTime(formContext.nextPayment)}</p>
        </Column.Content>
      </Column.Root>

      {/* Barra de pesquisa */}
      <Column.Root className="is-mobile">
        <Column.Content>
          <Input.Root>
            <Input.Contents alignIcon={'left'}>
              <Input.Prompt value={search} setValue={setSearch} placeholder="Pesquisar..."></Input.Prompt>
              <Input.Icon align="is-left">
                <Icon size={20}>search</Icon>
              </Input.Icon>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
      </Column.Root>
      {/* Tabela de dados */}
      <Column.Root className="is-mobile">
        <Column.Content>
          <Table.Root className="is-fullwidth is-hoverable is-narrow has-text-centered">
            <Table.Head>
              <Table.Row key={'table_payments_head'}>
                <Table.Cell>Nome</Table.Cell>
                <Table.Cell>ID Lançamento</Table.Cell>
                <Table.Cell>Criado Em</Table.Cell>
                <Table.Cell>Tipo</Table.Cell>
                <Table.Cell>Valor</Table.Cell>
                <Table.Cell>Situação</Table.Cell>
                <Table.Cell>Pago em</Table.Cell>
                <Table.Cell>Ações</Table.Cell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {dataFiltered.map((value) => {
                return (
                  <>
                    <Table.Row key={value.id}>
                      <Table.Cell className="is-size-7 is-vcentered">{value.name}</Table.Cell>
                      <Table.Cell className="is-size-7 is-vcentered">
                        {value?.responseOmie?.codigo_lancamento_omie || '-'}
                      </Table.Cell>
                      <Table.Cell className="is-size-7 is-vcentered">{String(value.dt_created || '-')}</Table.Cell>
                      <Table.Cell className="is-size-7 is-vcentered">{value.typePayment || '-'}</Table.Cell>
                      <Table.Cell className="is-size-7 is-vcentered">R$ {value.value}</Table.Cell>
                      <Table.Cell className="is-size-7 is-vcentered">
                        <p className={`tag ${colorTagStatusPagamentos(value.status).color}`}>
                          {colorTagStatusPagamentos(value.status).value}
                        </p>
                      </Table.Cell>
                      <Table.Cell className="is-size-7 is-vcentered">{String(value.dt_payment || '-')}</Table.Cell>
                      <Table.Cell className="is-vcentered" style={{ height: 50 }}>
                        <div style={{ marginBottom: 5, marginTop: 5 }}>
                          <Button.Root align="right">
                            <Button.Content>
                              <Button.Bt
                                className={''}
                                color="is-light"
                                action={() => {
                                  HandleSelectPayment(value)
                                }}
                                loading={loading}
                              >
                                <Icon size={15}>history</Icon>
                              </Button.Bt>
                            </Button.Content>

                            {!['canceled', 'canceling'].includes(value?.status) && value?.responseOmie?.cUrlPix && (
                              <Button.Content>
                                <Button.A
                                  className={''}
                                  color="is-light"
                                  link={value?.responseOmie?.cUrlPix}
                                  loading={loading}
                                  openNew
                                >
                                  <Icon size={15}>qrcode</Icon>
                                </Button.A>
                              </Button.Content>
                            )}

                            {!['canceled', 'canceling'].includes(value?.status) && value?.responseOmie?.cUrlPdfDemo && (
                              <Button.Content>
                                <Button.A
                                  className={''}
                                  color="is-light"
                                  link={value?.responseOmie?.cUrlPdfDemo}
                                  loading={loading}
                                  openNew
                                >
                                  <Icon size={15}>print</Icon>
                                </Button.A>
                              </Button.Content>
                            )}

                            {['paymentSuccess'].includes(value?.status) && !value?.responseOmie?.cUrlPdfDemo && (
                              <Button.Content>
                                <Button.Bt
                                  className={''}
                                  color="is-light"
                                  action={async () => HandleConsultarOsOmie(value)}
                                  loading={loading}
                                  openNew
                                >
                                  <Icon size={15}>print</Icon>
                                </Button.Bt>
                              </Button.Content>
                            )}

                            {!['canceled', 'canceling', 'paymentSuccess'].includes(value?.status) && (
                              <Button.Content>
                                <Button.Bt
                                  className={''}
                                  color="is-danger"
                                  colorText="has-text-white"
                                  action={async () => {
                                    await HandleCancelPayment(value)
                                  }}
                                  loading={loading}
                                >
                                  <Icon size={15}>multiply</Icon>
                                </Button.Bt>
                              </Button.Content>
                            )}

                            {!['canceled', 'canceling', 'paymentSuccess'].includes(value?.status) && (
                              <Button.Content>
                                <Button.Bt
                                  className={''}
                                  color="is-warning"
                                  action={async () => {
                                    await HandleReprocessPayment(value)
                                  }}
                                  loading={loading}
                                >
                                  <Icon size={15}>sync</Icon>
                                </Button.Bt>
                              </Button.Content>
                            )}

                            {value?.status === 'canceled' && (
                              <Button.Content>
                                <Button.Bt
                                  className={''}
                                  color="is-danger"
                                  colorText="has-text-white"
                                  action={async () => {
                                    await HandleDeletePayment(value)
                                  }}
                                  loading={loading}
                                >
                                  <Icon size={15}>trash</Icon>
                                </Button.Bt>
                              </Button.Content>
                            )}
                          </Button.Root>
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  </>
                )
              })}
            </Table.Body>
          </Table.Root>
        </Column.Content>
      </Column.Root>
    </Page.Section>
  )
}
