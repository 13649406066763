export const enableDeleteDocument = [
  { title: '', value: null },
  { title: 'Sim', value: true },
  { title: 'Não', value: false },
]

export const collaboratorSchemaValidate = [
  { field: 'photoUrl', label: 'Foto de Perfil', type: 'image', inEditionField: 'photoUrl' },
  { field: 'nome', label: 'Nome Completo', type: 'text', inEditionField: 'name' },
  { field: 'nomeSocial', label: 'Nome Social', type: 'text', inEditionField: 'socialName' },
  { field: 'cpf', label: 'CPF', type: 'text', inEditionField: 'cpf' },
  { field: 'tipoDocumento', label: 'Tipo de Documento', type: 'text', inEditionField: 'docType' },
  { field: 'documento', label: 'Documento', type: 'text', inEditionField: 'document' },
  { field: 'dtNascimento', label: 'Data de Nascimento', type: 'text', inEditionField: 'birthday' },
  { field: 'estadoCivil', label: 'Estado Civil', type: 'text', inEditionField: 'civilStatus' },
  { field: 'logradouro', label: 'Logradouro', type: 'text', inEditionField: 'street' },
  { field: 'numeroEndereco', label: 'Número do Enderço', type: 'text', inEditionField: 'number' },
  { field: 'bairro', label: 'Bairro', type: 'text', inEditionField: 'district' },
  { field: 'complemento', label: 'Complemento', type: 'text', inEditionField: 'complement' },
  { field: 'cidade', label: 'Cidade', type: 'text', inEditionField: 'city' },
  { field: 'uf', label: 'Estado', type: 'text', inEditionField: 'state' },
  { field: 'cep', label: 'CEP', type: 'text', inEditionField: 'zipcode' },
  { field: 'celular', label: 'Celular', type: 'text', inEditionField: 'phone' },
  { field: 'conta', label: 'Conta', type: 'text', inEditionField: 'account' },
  { field: 'agencia', label: 'Agência', type: 'text', inEditionField: 'agency' },
  { field: 'banco', label: 'Banco', type: 'text', inEditionField: 'bank' },
  { field: 'pix', label: 'Pix', type: 'text', inEditionField: 'pix' },
]
