export const TranslatePermissions = (value) => {
  value = String(value).toLocaleLowerCase()
  switch (value) {
    case 'exportsheets':
      return 'Exportar Planilhas'
    case 'viewinfos':
      return 'Visualizar informações'
    case 'viewinfocolab':
      return 'Visualizar informações da Candidatura'
    case 'reopen':
      return 'Reabrir Evento'
    case 'review':
      return 'Avaliar Colaborador'
    case 'view':
      return 'Visualizar'
    case 'update':
      return 'Atualizar / Editar'
    case 'edit':
      return 'Atualizar / Editar'
    case 'save':
      return 'Salvar'
    case 'submit':
      return 'Executar'
    case 'delete':
      return 'Excluir'
    case 'add':
      return 'Adicionar'
    case 'blockunblock':
      return 'Bloquear / Desbloquear'
    case 'openclose':
      return 'Abrir/ Fechar Candidaturas'
    case 'addpoint':
      return 'Adicionar Marcação de Horas'
    case 'delpoint':
      return 'Deletar Marcação de Horas'
    case 'cancel':
      return 'Cancelar Candidaturas'
    case 'approve':
      return 'Aprovar Candidaturas'
    case 'reprove':
      return 'Reprovar Candidaturas'
    case 'checkin':
      return 'Realizar Check-in em Candidaturas'
    case 'checkout':
      return 'Realizar Check-out em Candidaturas'
    case 'exportpayment':
      return 'Exportar Planilha de Pagamentos'
    case 'exportpoints':
      return 'Exportar Planilha de Pontos'
    case 'exportstaff':
      return 'Exportar Planilha de Staff'
    case 'invite':
      return 'Enviar Convites'
    case 'payment':
      return 'Realizar Pagamentos'
    case 'reOpen':
      return 'Reabrir Eventos'
    case 'viewInfoColab':
      return 'Visualizar Informações Pessoais do Colaborador'

    case 'city':
      return 'Cidades'
    case 'users':
      return 'Usuários'
    case 'events':
      return 'Eventos'
    case 'sectors':
      return 'Setores'
    case 'blockeds':
      return 'Colaboradores Bloqueados'
    case 'favorites':
      return 'Colaboradores Favoritados'
    case 'finallyevent':
      return 'Finalização de Eventos'
    case 'historyevent':
      return 'Histórico do Eventos'
    case 'joboffers':
      return 'Vagas do Eventos'
    case 'staffevent':
      return 'Staff do Eventos'
    case 'teams':
      return 'Equipes'
    case 'configurations':
      return 'Configurações'
    case 'payments':
      return 'Pagamentos'
    default:
      return value
  }
}
