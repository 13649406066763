const CardTitleSection = ({ title, children }) => {
  return (
    <div
      className="card-content"
      style={{
        display: 'flex',
        gap: '1rem',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <p className="subtitle mb-0">{title}</p>
      {children}
    </div>
  )
}

export default CardTitleSection
