import { useEffect } from 'react'

import { useGlobalContext } from '../../../../../context/ContextGlobal'
import { GetDataHomeClient } from '../../../../../firebase/functions'
import { TryCatch } from '../../../../../utils/general'
import { ConvertToTimestamp, dataComTraçoParaTimestamp } from '../../../../../utils/dates'

export const useClientHome = () => {
  const {
    user,
    setAlerts,
    loading,
    setLoading,
    dataContext,
    setDataContext, // contexto que guarda dados de lista para a pagina
    // infosContext,
    // setInfosContext, // contexto que guarda dados adicionais para a pagina funcionar
    // formContext,
    // setFormContext, // contexto que guarda dados de formularios da pagina
  } = useGlobalContext()

  const getData = async () => {
    const getDataHomeClient = await GetDataHomeClient(user?.client?.id)

    // console.log(getDataHomeClient)
    return setDataContext({ home: getDataHomeClient })
  }

  const FetchDataHome = async () => {
    await TryCatch(getData, setLoading, setAlerts)
  }

  const dtFreeTrialCheck = dataComTraçoParaTimestamp(user?.client?.dtFreeTrial)

  const jobOffers = (dataContext?.home?.JobOffersInOpen || [])
    .map((item) => {
      return {
        idEvent: item?.eventData?.id,
        eventName: `${item?.eventData?.name} - ${item?.eventData?.round}`,
        jobName: item?.jobOffersData?.positionData?.nome || item?.jobOffersData?.positionData?.name,
        date: item?.jobOffersData?.date,
        status: item?.jobOffersData?.status,
        staff: `${item?.jobOffersData?.filledJob}/${item?.jobOffersData?.amountJob}`,
        candidatesPending: (item?.jobOffersData?.candidacysPending || []).length,
      }
    })
    .sort((a, b) => ConvertToTimestamp(a.date) - ConvertToTimestamp(b.date))

  const dataHomePage = {
    openEvents: dataContext?.home?.eventsOpen,
    closedEvents: dataContext?.home?.eventsCloseds,
    favoriteTeam: dataContext?.home?.favorites,
    pendingCandidacy: dataContext?.home?.candidacysPending,
    events: jobOffers,
  }

  useEffect(() => {
    FetchDataHome()
  }, [])

  return { user, loading, dtFreeTrialCheck, FetchDataHome, dataHomePage }
}
