import React from 'react'

import { Column } from '../../../../../components/layouts/columns'
import { Button } from '../../../../../components/ui/button'
import { Input } from '../../../../../components/ui/input'
import { Select } from '../../../../../components/ui/select'
import { Path } from '../../../../../router/paths'
import { enableDeleteDocument } from '../../../collaborators/_utils/collaboratorSchemaValidate'

export const FormNotices = ({ values, setValues, loading, disabledList }) => {
  return (
    <>
      <Column.Root>
        <Column.Content>
          <Input.Root className="mb-5">
            <Input.Label required className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              Nome do Aviso
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={values}
                setValue={setValues}
                field={'name'}
                required={true}
                disabled={disabledList?.nome}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>

          <Input.Root className="mb-5">
            <Input.Label required className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              Título do Aviso
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={values}
                setValue={setValues}
                field={'title'}
                required={true}
                disabled={disabledList?.nome}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>

          <Input.Root className="mb-5">
            <Input.Label required className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              Mensagem do Aviso
            </Input.Label>
            <Input.Contents>
              <Input.Textarea
                className="is-rounded"
                value={values}
                setValue={setValues}
                field={'message'}
                required={true}
                disabled={disabledList?.termo}
              ></Input.Textarea>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
      </Column.Root>

      <Column.Root>
        <Column.Content>
          <Input.Root className="mb-5">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required>
              Limite de Leitura
            </Input.Label>
            <Input.Prompt type="number" value={values} setValue={setValues} field={'limitRead'}></Input.Prompt>
          </Input.Root>
        </Column.Content>
      </Column.Root>

      <Column.Root>
        <Column.Content>
          <Input.Root className="mb-5">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required>
              Permitir Excluir Aviso
            </Input.Label>
            <Select.Root>
              <Select.Content
                color="is-grey"
                className="is-fullwidth is-rounded"
                value={values}
                setValue={setValues}
                field={'notDelete'}
                disabled={disabledList?.type}
                required
              >
                {enableDeleteDocument.map((option) => (
                  <Select.Option
                    key={`option_enable_del_${option.title}`}
                    value={`${option.value}`}
                    title={`${option.title}`}
                  />
                ))}
              </Select.Content>
            </Select.Root>
          </Input.Root>
        </Column.Content>
      </Column.Root>

      <Column.Root className="mt-5 mb-6">
        <Column.Content>
          <Button.Root align="right">
            <Button.Content>
              <Button.A color="is-light" link={Path.AdminNotices} loading={loading}>
                Cancelar
              </Button.A>
            </Button.Content>
            <Button.Content>
              <Button.Bt className="" color="is-success" colorText="has-text-white" type={'submit'} loading={loading}>
                Salvar
              </Button.Bt>
            </Button.Content>
          </Button.Root>
        </Column.Content>
      </Column.Root>
    </>
  )
}
