import { useParams, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { getDocument, getDocumentsWithQuery, updateDocument } from '../../../../firebase/firestore'
import { uploadFile } from '../../../../firebase/storage'
// import { DateTimeNow } from '../../../../utils/dates'
import { TryCatch } from '../../../../utils/general'
import { resetPassword } from '../../../../firebase/auth'
import { useCollaboratorsForm } from './useCollaboratorsForm'
import { Path } from '../../../../router/paths'
// import { sendNotification } from '../../../../utils/sendNotification'

export const useEditAdminCollaborators = () => {
  const {
    user,
    setAlerts,
    loading,
    setLoading,
    // dataContext,
    // setDataContext, // contexto que guarda dados de lista para a pagina
    infosContext,
    setInfosContext, // contexto que guarda dados adicionais para a pagina funcionar
    formContext,
    setFormContext, // contexto que guarda dados de formularios da pagina
  } = useGlobalContext()

  const { blockUnblockCollaborator } = useCollaboratorsForm()

  const [modalValidate, setModalValidate] = useState(false)

  const { id } = useParams()

  const navigate = useNavigate()

  const getDatas = async () => {
    const collaborator = await getDocument('colaboradores', id)

    const collaboratorFormData = {
      nome: collaborator?.name,
      photoUrl: collaborator?.photoUrl,
      nomeSocial: collaborator?.personal?.socialName,
      cpf: collaborator?.cpf,
      documento: collaborator?.personal?.document,
      tipoDocumento: collaborator?.personal?.docType,
      dtNascimento: collaborator?.personal?.birthday,
      estadoCivil: collaborator?.personal?.civilStatus,
      logradouro: collaborator?.address?.street,
      numeroEndereco: collaborator?.address?.number,
      bairro: collaborator?.address?.district,
      complemento: collaborator?.address?.complement,
      cep: collaborator?.address?.zipcode,
      cidade: collaborator?.address?.city,
      uf: collaborator?.address?.state,
      celular: collaborator?.contact?.phone,
      emergencyContacts: collaborator?.contact?.emergencyContacts,
      conta: collaborator?.banking?.account,
      agencia: collaborator?.banking?.agency,
      banco: collaborator?.banking?.bank,
      pix: collaborator?.banking?.pix,
      consentimentos: collaborator?.consents,
      documentos: collaborator?.documents,
      cargos: collaborator?.positions,
      email: collaborator?.email,
      inEdition: (collaborator?.status > 1 ? collaborator?.inEdition : {}) || {},
      // dt_last_login: DateTimeNow(),
      // version_app: '1.0.0',
      validate: false, // responsável pela validação dos campos
      status: collaborator?.status,
    }

    setFormContext(collaboratorFormData)
  }

  const getInfos = async () => {
    const getDocumentos = await getDocumentsWithQuery('documentos', 'sendInRegister', '==', 'true')

    const documentsWithSyncs = getDocumentos
      .map((doc) => {
        if (doc?.syncField !== undefined && doc?.syncField !== '') {
          return [doc.id, doc.syncField]
        }
        return null
      })
      .filter((item) => item !== null)

    setInfosContext({ documentos: getDocumentos, documentsWithSyncs })
  }

  const editCollaborator = async () => {
    if (!user.user.permissions.colaboradores_editar) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Sem Permissão',
          msg: 'Você não possui permissão para editar colaboradores',
        },
      ])
    }

    const editedCollaborator = {
      name: formContext?.nome,
      photoUrl: formContext?.url_foto,
      cpf: formContext?.cpf,
      personal: {
        socialName: formContext?.nomeSocial,
        document: formContext?.documento,
        docType: formContext?.tipoDocumento,
        birthday: formContext?.dtNascimento,
        civilStatus: formContext?.estadoCivil,
      },
      address: {
        street: formContext?.logradouro,
        number: formContext?.numeroEndereco,
        district: formContext?.bairro,
        complement: formContext?.complemento || '',
        zipcode: formContext?.cep,
        city: formContext?.cidade,
        state: formContext?.uf,
      },
      contact: { phone: formContext?.celular, emergencyContacts: formContext?.emergencyContacts },
      banking: {
        account: formContext?.conta,
        agency: formContext?.agencia,
        bank: formContext?.banco,
        pix: formContext?.pix,
      },
      consents: { ...formContext?.consentimentos },
      documents: { ...formContext?.documentos },
      positions: { ...formContext?.cargos },
      email: formContext?.email,
      inEdition: { ...(formContext?.inEdition ?? {}) },
      // dt_last_login: DateTimeNow(),
      // version_app: '1.0.0',
      status: formContext?.status,
      // notification_token: 'teste',
    }

    // const imageTimestamp = new Date().toISOString()

    const pathUploadFile = `colaboradores/${id}/perfil`

    // verifica se a imagem é um objeto (File), senão deve ser uma string com a url vinda do Firebase
    if (typeof editedCollaborator.photoUrl === 'object') {
      const photoUrl = await uploadFile(editedCollaborator.photoUrl, pathUploadFile)
      editedCollaborator.photoUrl = photoUrl
    }

    await updateDocument('colaboradores', id, editedCollaborator)

    setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Colaborador editado',
        msg: `O colaborador ${editedCollaborator.name} foi editado com sucesso.`,
      },
    ])
  }

  const handlePrintScreen = () => {
    if (!user?.user?.permissions?.colaboradores_imprimir === true) {
      return setAlerts((prev) => [
        ...prev,
        {
          title: 'Sem Permissão',
          msg: 'Você Não Possui permissão para imprimir os dados do colaborador',
          type: 'error',
        },
      ])
    }

    window.print()
  }

  const handleScrollToPositions = () => {
    const positionsTitleElement = document.querySelector('#positions')

    if (positionsTitleElement) {
      // Pega a posição do elemento em relação à viewport
      const rect = positionsTitleElement.getBoundingClientRect()

      // A distância total do topo da página
      const distanceToTop = rect.top + window.scrollY

      window.scrollTo(0, distanceToTop)
    }
  }

  const handleResetPassword = () => {
    if (!user?.user?.permissions?.colaboradores_resetarSenhaAdmin === true) {
      return setAlerts((prev) => [
        ...prev,
        {
          title: 'Sem Permissão',
          msg: 'Você não possui permissão para resetar a senha de administrador do colaborador',
          type: 'error',
        },
      ])
    }

    const sendEmailReset = resetPassword(formContext?.email)
    // console.log(sendEmailReset)

    if (sendEmailReset.success) {
      // const notificationFields = {
      //   title: '🔑 Resetar Senha',
      //   description: 'Verifique o seu e-mail cadastrado para alterar a sua senha.',
      // }
      // await sendNotification(id, notificationFields.title, notificationFields.description)
      return setAlerts((prev) => [
        ...prev,
        {
          title: 'Resete de Senha Enviado',
          msg: `Um E-mail para trocar senha foi enviado ao e-mail do responsavel: ${formContext?.email}`,
          type: 'success',
        },
      ])
    } else {
      return setAlerts((prev) => [
        ...prev,
        {
          title: 'Erro ao enviar E-mail',
          msg: `erro: ${sendEmailReset?.error}`,
          type: 'error',
        },
      ])
    }
  }

  const handleValidateCollaborator = () => {
    if (!user?.user?.permissions?.colaboradores_validar === true) {
      return setAlerts((prev) => [
        ...prev,
        {
          title: 'Sem Permissão',
          msg: 'Você não possui permissão para validar o colaborador',
          type: 'error',
        },
      ])
    }

    const documentsUserStats = formContext?.cargos
      ? Object.values(formContext.cargos).some((obj) => obj.status !== 1)
      : false

    if (documentsUserStats) {
      setAlerts((prev) => [
        ...prev,
        {
          title: 'Cargos Pendentes',
          msg: `Existem cargos "Em Análise". Favor validar cargos em análise antes de aprovar o colaborador.`,
          type: 'error',
        },
      ])

      return navigate(Path.EditAdminCollaboratorPositions(id))
    }

    return setModalValidate(true)
  }

  const handleEditCollaborators = async () => {
    await TryCatch(editCollaborator, setLoading, setAlerts)
  }

  const FetchDatas = async () => {
    await TryCatch(getDatas, setLoading, setAlerts)
  }

  const FetchGetInfos = async () => {
    await TryCatch(getInfos, setLoading, setAlerts)
  }

  useEffect(() => {
    FetchDatas()
    FetchGetInfos()
  }, [])

  return {
    loading,
    formContext,
    modalValidate,
    setModalValidate,
    blockUnblockCollaborator,
    setFormContext,
    handlePrintScreen,
    handleScrollToPositions,
    handleResetPassword,
    handleValidateCollaborator,
    handleEditCollaborators,
    infosContext,
  }
}
