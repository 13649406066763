// import { useState } from 'react'

// import { Button } from '../../../../components/ui/button'

import { Icon } from '../../../../components/icons'
import { Button } from '../../../../components/ui/button'
import { Modal } from '../../../../components/ui/modal'
import { Table } from '../../../../components/ui/table'
import { ConvertTimestampToDateTime } from '../../../../utils/dates'
import { CopyText } from '../../../../utils/general'
import { colorTagStatusPagamentos } from '../_utils'

export const ModalHistoryBilling = ({ loading, active = true, setActive, dataContext = {} }) => {
  // console.log(dataContext)
  // const [req, setReq] = useState(false)

  if (active) {
    return (
      <div>
        <Modal.Root active={active}>
          <Modal.Contents witdh={1000}>
            <Modal.Header>
              <p className="modal-card-title" style={{ marginBottom: -15 }}>
                Histórico de Processamento
                <br />
                <p className="tag is-ligth ml-2">IdPayment: {dataContext.idPayment}</p>
                <Button.Bt
                  className="is-small mt-1 ml-1"
                  action={() => {
                    CopyText(dataContext.idPayment)
                  }}
                >
                  <Icon size={10}>copy</Icon>
                </Button.Bt>
                <br />
                <p className={`tag ${colorTagStatusPagamentos(dataContext?.status).color} mx-2`}>
                  {colorTagStatusPagamentos(dataContext?.status).value}
                </p>
                <br />
              </p>
              <Modal.HeaderClose setActive={setActive}></Modal.HeaderClose>
            </Modal.Header>

            <Modal.Body>
              <>
                <Table.Root className="is-fullwidth is-hoverable is-narrow has-text-centered">
                  <Table.Head>
                    <Table.Row key={'tableEventsHead'}>
                      <Table.Cell>N</Table.Cell>
                      <Table.Cell>Descrição</Table.Cell>
                      <Table.Cell>Data</Table.Cell>
                      <Table.Cell>Processado por</Table.Cell>
                    </Table.Row>
                  </Table.Head>
                  <Table.Body>
                    {(dataContext?.log || [])
                      .sort((a, b) => a.time - b.time)
                      .map((hist, index) => {
                        // console.log(hist)
                        return (
                          <Table.Row key={`history_${index}`}>
                            <Table.Cell>{index}</Table.Cell>
                            <Table.Cell>{hist.status}</Table.Cell>
                            <Table.Cell>{ConvertTimestampToDateTime(hist.time)}</Table.Cell>
                            <Table.Cell>{hist.origin}</Table.Cell>
                          </Table.Row>
                        )
                      })}
                  </Table.Body>
                </Table.Root>
                {/* {req && (
                  <div className="box">
                    <p>Requisição enviada a OMIE</p>
                    <br />
                    <pre>{dataContext?.reqOmie}</pre>
                  </div>
                )} */}
              </>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
            {/* <Modal.Footer>
              <Button.Bt
                action={() => {
                  setReq((prev) => !prev)
                }}
              >
                Requisição OMIE
              </Button.Bt>
            </Modal.Footer> */}
          </Modal.Contents>
        </Modal.Root>
      </div>
    )
  } else {
    return <></>
  }
}
