import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { onAuthStateChanged } from 'firebase/auth'

import { addDocument, getDocument, getSubDocumentsWithQuery } from '../../../firebase/firestore'
import { logIn } from '../../../firebase/auth'
import { useGlobalContext } from '../../../context/ContextGlobal'
// import { TryCatch } from '../../../utils/general'
import { Path } from '../../../router/paths'
import { TimestampNow } from '../../../utils/dates'
import { auth } from '../../../firebase'
// import { Path } from '../../../router/paths'

// const { useGlobalContext } = require("../../../context/ContextGlobal")

const useCollaborator = (authenticar = false) => {
  const { user, setUser, setAlerts, loading, setLoading } = useGlobalContext()

  const { token } = useParams()

  const navigate = useNavigate()

  const Authentication = async () => {
    try {
      onAuthStateChanged(auth, async (user) => {
        let newUser
        if (user && !authenticar) {
          newUser = user
        } else {
          // Busca credenciais de autenticação
          const get = await getDocument('_authentications', token)
          const getAutentication = await logIn(get.email, get.pw)
          console.log(getAutentication)

          if (getAutentication?.error) {
            setAlerts((prev) => [
              ...prev,
              {
                type: 'error',
                msg: getAutentication?.error,
                title: 'Erro na autenticação',
              },
            ])
            return navigate(Path.CollabLogin)
          }

          newUser = getAutentication.success
        }
        console.log(newUser)

        // Busca dados do usuário no Firestore
        const getUser = await getDocument('colaboradores', newUser.uid)
        const getPlans = await getSubDocumentsWithQuery('colaboradores', newUser.uid, 'plans', 'status', '==', 'active')

        console.log(getUser)

        const planActive = getPlans
          .sort((a, b) => {
            if (a.dtEndPlan === undefined) return 1
            if (b.dtEndPlan === undefined) return -1
            return a.dtEndPlan - b.dtEndPlan
          })
          .find((item) => (item.dtEndPlan ?? Infinity) >= TimestampNow())

        if (planActive) {
          const getPlan = await getDocument('plans', planActive.idPlan)

          newUser = {
            ...getUser,
            plans: { ...getPlan, ...planActive },
            allPlans: getPlans,
          }
          return setUser(newUser)
        }

        return setUser(getUser)
      })
    } catch (e) {
      console.log(e.message)

      const newLog = {
        message: 'Error: ' + e.message,
        origin: 'Pagamento web do colaborador',
        time: TimestampNow(),
        type: 'error',
      }

      await addDocument('_systemLogs', newLog)
      navigate(Path.CollabLogin)
    }
  }

  useEffect(() => {
    Authentication()
  }, [])

  return { user, setUser, token, setAlerts, loading, setLoading }
}

export default useCollaborator
