import React from 'react'

import { Page } from '../../../components/layouts/page'
import { Icon } from '../../../components/icons'
import { Column } from '../../../components/layouts/columns'
import { Button } from '../../../components/ui/button'
import { Table } from '../../../components/ui/table'
import { Input } from '../../../components/ui/input'
import { useCollaboratorCandidacys } from './_hooks/useCollaboratorCandidacys'
import { Pagination } from '../../../components/ui/pagination'
import { DataComTraçoParaDataComBarra } from '../../../utils/dates'

export const AdminCollaboratorCandidacys = () => {
  const {
    loading,
    search,
    setSearch,
    dataFiltered,
    FetchGetDatas,
    FetchGetInfos,
    paginationValue,
    setPaginationValue,
    dataContext,
    nextPage,
    previousPage,
    statusStaff,
  } = useCollaboratorCandidacys()

  return (
    <>
      <Page.Root className="is-fullheight-with-navbar mt-6 p-6">
        {/* Infomações dos planos */}
        <Column.Root className="mt-6 pt-5">
          <Column.Root>
            <Column.Content className="my-5">
              <p className="subtitle is-size-4">
                <Icon size={30} className={'mr-3'}>
                  calendar
                </Icon>
                Eventos Candidatados pelo Staff
              </p>
            </Column.Content>
          </Column.Root>
          <Column.Content className="has-text-right">
            <Button.Root align="right">
              <Button.Content>
                <Button.Bt
                  color="is-dark"
                  colorText="has-text-white"
                  action={() => {
                    FetchGetDatas()
                    FetchGetInfos()
                  }}
                  loading={loading}
                >
                  <Icon size={15}>sync</Icon>
                </Button.Bt>
              </Button.Content>
            </Button.Root>
          </Column.Content>
        </Column.Root>
        {/* Barra de pesquisa */}
        <Column.Root className="is-mobile">
          <Column.Content>
            <Input.Root>
              <Input.Contents alignIcon={'left'}>
                <Input.Prompt value={search} setValue={setSearch} placeholder="Pesquisar..."></Input.Prompt>
                <Input.Icon align="is-left">
                  <Icon size={20}>search</Icon>
                </Input.Icon>
              </Input.Contents>
            </Input.Root>
          </Column.Content>
        </Column.Root>
        {/* Lista de Assinaturas do cliente */}
        {loading && (
          <Column.Root className="is-mobile">
            <Column.Content></Column.Content>
            <Column.Content className="is-1">
              <Icon>loading</Icon>
              <p className="is-size-7 has-text-grey">carregando...</p>
            </Column.Content>
            <Column.Content></Column.Content>
          </Column.Root>
        )}
        {!loading && (
          <>
            <Column.Root className="is-mobile">
              <Column.Content>
                <Table.Root className="is-fullwidth is-hoverable is-narrow has-text-centered">
                  <Table.Head>
                    <Table.Row key={'table_payments_head'}>
                      <Table.Cell>Nome do Evento</Table.Cell>
                      <Table.Cell>Data da Candidatura</Table.Cell>
                      <Table.Cell>Cargo</Table.Cell>
                      <Table.Cell>Status</Table.Cell>
                    </Table.Row>
                  </Table.Head>
                  <Table.Body>
                    {dataFiltered?.map((item) => {
                      return (
                        <>
                          <Table.Row key={item.id}>
                            <Table.Cell className="is-size-7 is-vcentered py-3">
                              {item.completed_data.eventData.name}
                            </Table.Cell>
                            <Table.Cell className="is-size-7 is-vcentered">
                              {DataComTraçoParaDataComBarra(item.date)}
                            </Table.Cell>
                            <Table.Cell className="is-size-7 is-vcentered py-3">{item.positionName}</Table.Cell>
                            <Table.Cell className="is-size-7 is-vcentered">
                              <p className={`tag ${statusStaff[item.status].color}`}>
                                {statusStaff[item.status].status}
                              </p>
                            </Table.Cell>
                          </Table.Row>
                        </>
                      )
                    })}
                  </Table.Body>
                </Table.Root>
              </Column.Content>
            </Column.Root>
          </>
        )}
      </Page.Root>
      <Pagination.Functional
        nextPage={nextPage}
        PreviousPage={previousPage}
        paginationData={dataContext?.colaboradores?.pagination}
        valuePage={paginationValue}
        setValuePage={setPaginationValue}
      ></Pagination.Functional>
    </>
  )
}
