import { getDocument } from '../../../../firebase/firestore'
import { ExportToExcel } from '../../../../utils/exportToExcel'
import { TryCatch } from '../../../../utils/general'
import { DestructureData } from './destructureData'
import { GetAllStaffDataEnriched } from './getAllStaff'

const schemaSpreadsheet = (candidate) => {
  const newSchema = [
    { label: 'Id_Colaborador', value: 'candidacy.uuid' },
    { label: 'Id_Candidatura', value: 'candidacy.id' },
    { label: 'Data', value: 'jobOffer.date' },
    { label: 'Vaga', value: 'jobOffer.nome' },
    { label: 'Nome', value: 'candidacy.name' },
    { label: 'CPF', value: 'candidacy.cpf' },
    { label: 'Banco', value: 'candidacy.payment.bank' },
    { label: 'Agencia', value: 'candidacy.payment.agency' },
    { label: 'Conta', value: 'candidacy.payment.account' },
    { label: 'Chave_Pix', value: 'candidacy.payment.pix' },
    { label: 'Situacao', value: 'status.status' },
    { label: 'Cod_Status', value: 'candidacy.status' },
    { label: 'Descricao', value: 'candidacy.financialHistory[].desc' },
    { label: 'Tipo_de_Transacao', value: 'candidacy.financialHistory[].type' },
    { label: 'Valor', value: 'candidacy.financialHistory[].value' },
  ]

  // console.log(newSchema)
  const destructureData = DestructureData(candidate, newSchema)
  // console.log(destructureData)
  return destructureData
}

const ExportPaymentSheet = async (idEvent, setAlerts) => {
  // trocar a coleta do schema pra fora pra poder coletar os filtros novos
  const getInfosEvent = await getDocument('events', idEvent)

  const allStaff = await GetAllStaffDataEnriched(idEvent)

  const filters = [9, 10]

  // filtra a staff pelo status registrados no relatorio
  // [1, 2, 6].includes(candidate.candidacy.status)
  const staffFiltered = allStaff.filter((candidate) => filters.includes(candidate.candidacy.status))

  if (staffFiltered.length === 0) {
    return setAlerts((prev) => [
      ...prev,
      {
        type: 'error',
        title: `Nenhum Membro na Staff`,
        msg: 'Aplicamos o filtro conforme o relatório, mas não encontramos membros na equipe do evento para exportação. Verifique os critérios do filtro ou entre em contato com o suporte para mais informações.',
      },
    ])
  }

  // console.log(staffFiltered)
  // console.log(staffFiltered.length)

  const allData = staffFiltered.flatMap((candidate) => {
    return schemaSpreadsheet(candidate)
  })

  const resumeFinancial = allData.map((item) => {
    const newItem = { ...item } // Criando uma cópia do objeto original

    delete newItem.Id_Colaborador
    delete newItem.Banco
    delete newItem.Agencia
    delete newItem.Conta
    delete newItem.Chave_Pix
    delete newItem.Cod_Status

    return newItem
  })

  const paymentsFinancial = allData.reduce((acc, transaction) => {
    // delete transaction.
    const newTransaction = { ...transaction }

    // console.log(acc)
    const findTransaction = acc.find((item) => item.Id_Colaborador === newTransaction.Id_Colaborador)
    const index = acc.findIndex((item) => item.Id_Colaborador === newTransaction.Id_Colaborador)
    const valueCurrent = newTransaction.Tipo_de_Transacao === 'D' ? -newTransaction.Valor : newTransaction.Valor
    console.log(newTransaction.Cod_Status)
    const haPayment = Number(newTransaction.Cod_Status) === 10 ? 'pago' : 'pendente'

    const msgComment = `Digite apenas os seguintes valores: "pago" ou "pendente"`
    const paymentStatus = { type: 'comment', value: haPayment, msg: msgComment }

    delete newTransaction.Valor
    delete newTransaction.Id_Candidatura
    delete newTransaction.Descricao
    delete newTransaction.Tipo_de_Transacao
    delete newTransaction.Cod_Status

    let valuePayment = {}

    if (findTransaction) {
      const valueTotal = findTransaction.Valor_A_Pagar + valueCurrent
      valuePayment = { ...findTransaction, Valor_A_Pagar: valueTotal, Pagamento_Realizado: paymentStatus }
      acc[index] = valuePayment
    } else {
      acc.push({ ...newTransaction, Valor_A_Pagar: valueCurrent, Pagamento_Realizado: paymentStatus })
    }

    return acc
  }, [])

  console.log(resumeFinancial)
  console.log(paymentsFinancial)
  console.log(allData)

  const spreadSheet = [
    { sheet: resumeFinancial, nameSheet: 'Resumo Financeiro' },
    { sheet: paymentsFinancial, nameSheet: 'Pagamentos' },
  ]

  const nameSpreadSheet = 'Pagamentos_da_staff'

  ExportToExcel(spreadSheet, `${nameSpreadSheet}_${getInfosEvent.name}_${getInfosEvent.round}.xlsx`)

  return setAlerts((prev) => [
    ...prev,
    {
      type: 'success',
      title: `Planilha de Pagamentos Conclúida`,
      msg: 'O Processo de construir sua planilha terminou com exito...',
    },
  ])
}

export const HandleExportPaymentSheet = async (idEvent, setLoading, setAlerts) => {
  const callback = async () => {
    ExportPaymentSheet(idEvent, setAlerts)
  }
  await TryCatch(callback, setLoading, setAlerts)
}
