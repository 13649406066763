import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useGlobalContext } from '../../context/ContextGlobal'
import { Input } from '../../components/ui/input'
// import { SetItemSession } from '../../sessionStorage'
// import imgIllustrator from '../../assets/capaKeap.png'
import { Icon } from '../../components/icons'
import { Button } from '../../components/ui/button'
import { logIn, resetPassword } from '../../firebase/auth'
import { Column } from '../../components/layouts/columns/index'
import { Path } from '../../router/paths'
import keaplogo from '../../assets/Prancheta 5.png'
import { Form } from '../../components/ui/form'
import { TryCatch } from '../../utils/general'

export const Login = () => {
  const { setAlerts, loading, setLoading } = useGlobalContext()
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [senha, setSenha] = useState('')

  const logar = async () => {
    if (email !== '' && senha !== '') {
      const auth = await logIn(email, senha)
      // console.log(auth)
      if ('error' in auth) {
        switch (auth.error) {
          case 'Firebase: Error (auth/invalid-credential).':
            return setAlerts((prev) => [
              ...prev,
              {
                type: 'error',
                title: 'E-mail ou Senha incorretos',
                msg: 'Verifique seu e-mail ou senha, e tente novamente',
              },
            ])
          case 'Firebase: Error (auth/too-many-requests).':
            return setAlerts((prev) => [
              ...prev,
              {
                type: 'error',
                title: 'Usuário Temporariamente Bloqueado',
                msg: 'Aguarde 30 segundos e tente novamente.',
              },
            ])
          default:
            return setAlerts((prev) => [...prev, { type: 'error', title: 'Error:', msg: JSON.stringify(auth) }])
        }
      }
      if (!auth.success.emailVerified) {
        const sendEmail = resetPassword(email)
        if ('success' in sendEmail) {
          return navigate(Path.FirstAccess(email))
        } else {
          throw new Error(JSON.stringify(sendEmail.error))
          // return setAlerts([
          //   ...alerts,
          //   {
          //     type: 'error',
          //     title: 'Error:',
          //     msg: JSON.stringify(sendEmail.error),
          //   },
          // ])
        }
      } else {
        return navigate(Path.SelectAccount)
      }
    } else {
      // console.log('teste')
      throw new Error('Campo Faltantes: Você não preencheu o campo')
      // return setAlerts([
      //   ...alerts,
      //   {
      //     type: 'error',
      //     title: 'Campo Faltantes',
      //     msg: 'Você não preencheu o campo',
      //   },
      // ])
    }
  }

  const HandleLogar = async () => {
    TryCatch(logar, setLoading, setAlerts)
  }

  return (
    <>
      <div className="box has-background-white-bis">
        <Column.Root className="is-vcentered is-centered">
          <Column.Content className="">
            <div className="has-text-centered">
              <img
                src={keaplogo}
                alt="KEAP Staff"
                style={{
                  backgroundSize: 'cover',
                  width: 300,
                  marginBottom: -20,
                  marginTop: -20,
                  // opacity: 0.5,
                }}
              />
            </div>
            <Form.Root
              action={() => {
                HandleLogar()
              }}
            >
              <div className="mx-4">
                <Input.Root>
                  <Input.Contents alignIcon={'left'}>
                    <Input.Prompt
                      className="is-rounded"
                      placeholder="E-mail"
                      type="email"
                      value={email}
                      setValue={setEmail}
                    />
                    <Input.Icon>
                      <Icon size={20}>user</Icon>
                    </Input.Icon>
                  </Input.Contents>
                </Input.Root>
              </div>
              <div className="my-5 mx-4">
                <Input.Root>
                  <Input.Contents alignIcon={'left'}>
                    <Input.Prompt
                      className="is-rounded"
                      placeholder="Senha"
                      type="password"
                      value={senha}
                      setValue={setSenha}
                      color={'is-light'}
                    />
                    <Input.Icon>
                      <Icon size={20}>password</Icon>
                    </Input.Icon>
                  </Input.Contents>
                </Input.Root>
              </div>
              <div className="my-5 mt-6 mx-4">
                <Button.Bt className="is-rounded is-fullwidth " color="is-link" type="submit" loading={loading}>
                  Entrar
                </Button.Bt>
              </div>
              <div className="my-5 mx-4">
                <Button.A className="is-rounded is-fullwidth" color="is-light" link={'/esquecisenha'} loading={loading}>
                  Esqueci minha Senha
                </Button.A>
              </div>
            </Form.Root>

            <Column.Root className="mt-6 mx-4">
              <Column.Content>
                <Button.Bt className="is-rounded is-fullwidth" color="is-light" loading={loading}>
                  Quero Contratar
                </Button.Bt>
              </Column.Content>
              <Column.Content>
                <Button.Bt className="is-rounded is-fullwidth" color="is-light" loading={loading}>
                  Quero Trabalhar
                </Button.Bt>
              </Column.Content>
            </Column.Root>
          </Column.Content>
        </Column.Root>
      </div>
    </>
  )
}
