import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Path } from '../../../../../router/paths'
import { updateSubDocument } from '../../../../../firebase/firestore'
import { useGlobalContext } from '../../../../../context/ContextGlobal'
import { TryCatch } from '../../../../../utils/general'

const useEditSector = () => {
  const { id } = useParams()
  const {
    user,
    setAlerts,
    // dataContext,
    // setDataContext,
    infosContext,
    // setInfosContext,
    // formContext,
    // setFormContext,
    loading,
    setLoading,
    // statusLoading,
    // setStatusLoading,
  } = useGlobalContext()
  const navigate = useNavigate()

  const [setor, setSetor] = useState(Object.values(infosContext?.setores || {}).find((sector) => sector.id === id))
  const [permissions, setPermissions] = useState(setor?.permissions || {})
  const permissionsGlobais = infosContext?.globalPermissions || {}
  const [search, setSearch] = useState('')

  const clientId = user.client.id

  const editSector = async () => {
    const newSetor = { ...setor, permissions: { ...permissions } }
    await updateSubDocument('clientes', clientId, 'sectors', id, newSetor)

    setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Setor Editado',
        msg: `O Setor "${newSetor.nome}" foi salvo com sucesso.`,
      },
    ])
    navigate(Path.AdminSectors)
  }

  const handleEditSector = async () => {
    await TryCatch(editSector, setLoading, setAlerts)
  }

  const filterPermissions =
    search === ''
      ? permissionsGlobais
      : Object.fromEntries(
          Object.entries(permissionsGlobais).filter(
            ([key]) => key.toLowerCase().includes(search.toLowerCase()) && key !== 'id',
          ),
        )

  return {
    loading,
    setor,
    permissions,
    search,
    permissionsGlobais,
    filterPermissions,
    setSearch,
    setPermissions,
    setSetor,
    handleEditSector,
  }
}

export default useEditSector
