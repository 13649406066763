/* eslint-disable no-unused-vars */
import React from 'react'
import {
  Calendar1,
  CalendarCheck,
  CalendarX,
  ChevronRight,
  ClipboardPenLine,
  Megaphone,
  Star,
  UserCheck,
  UserRoundX,
  UserSearch,
} from 'lucide-react'

import { Page } from '../../../components/layouts/page'
import { Icon } from '../../../components/icons'
// import { useGlobalContext } from '../../../context/ContextGlobal'
import { Button } from '../../../components/ui/button'
import { useHomeAdministration } from './hooks'
import { Column } from '../../../components/layouts/columns'
import { phraseGenerator } from '../../../utils/phraseGenerator'
import CardStatusHome from '../../client/home/home/_components/cardStatusHome'
import CardTitleSection from '../../client/home/home/_components/titleCardSection'
import CardEvent from '../../client/home/home/_components/cardEvent'
import { useGlobalContext } from '../../../context/ContextGlobal'
import { TransformationforMask } from '../../../utils/masks'
import { DataComTraçoParaDataComBarra, DateNow } from '../../../utils/dates'
import CardNotification from '../../client/home/home/_components/cardNotification'

// import { logOut } from '../../../firebase/auth'

export const AHome = () => {
  const { user, setAlerts, setLoading } = useGlobalContext()

  // const { faturar } = useHomeAdministration()

  return (
    <>
      <Page.Root>
        <Page.Content
          style={{ padding: '7rem 0 7rem 0', display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
          className="has-text-centered"
        >
          <Column.Root>
            <Column.Content className="has-text-left">
              <p className="subtitle is-size-3">
                Bem-vindo,{' '}
                {`${TransformationforMask(user?.user?.name || user?.user?.nome, 'title').split(' ')[0]} ${TransformationforMask(
                  user?.user?.name || user?.user?.nome,
                  'title',
                )
                  .split(' ')
                  .slice(-1)}`}
                !
              </p>
              <p
                style={{
                  color: '#afafaf',
                }}
              >
                {phraseGenerator()}
              </p>
            </Column.Content>
          </Column.Root>
          <Column.Root style={{ display: 'flex', gap: '5rem' }} className="gap-2">
            <CardStatusHome title={'Clientes Ativos'} value={'0'}>
              <CalendarCheck />
            </CardStatusHome>
            <CardStatusHome title={'Pagamentos Pendentes/Cliente Inativos'} value={'0'}>
              <UserRoundX />
            </CardStatusHome>
            <CardStatusHome title={'Colaboradores Ativos'} value={'0'}>
              <UserCheck />
            </CardStatusHome>
            <CardStatusHome title={'Colaboradores para Análise'} value={'0'}>
              <UserSearch />
            </CardStatusHome>
            <CardStatusHome title={'Cargos em Análise'} value={'0'}>
              <ClipboardPenLine />
            </CardStatusHome>
          </Column.Root>

          <Column.Root style={{ display: 'flex', gap: '1.5rem' }}>
            <Column.Content
              className="card"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: '1rem',
              }}
            >
              {/* <CardTitleSection title={'Vagas em Aberto'}>
                <CalendarCheck size={30} />
              </CardTitleSection> */}
              <div
                style={{
                  padding: '1rem 0 1rem 0',
                  maxHeight: 500,
                  overflow: 'auto',
                }}
              >
                {/* <CardEvent
                  key={`event-aosdkf`}
                  eventName={'teste'}
                  date={'10/20/2025'}
                  status={'open'}
                  staff={'12'}
                  jobName={'teste'}
                  idEvent={'akosdfk'}
                  CandidatePending={'/'}
                /> */}

                {/* <Icon>loading</Icon> */}
              </div>
              {/* <Button.A>
                Visualizar Todos os Eventos <ChevronRight />
              </Button.A> */}
            </Column.Content>

            {/* <Column.Content
              className="card"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: '1rem',
              }}
            >
              <CardTitleSection title={'Comunicados'}>
                <Megaphone size={30} />
              </CardTitleSection>

              <CardNotification type={'warning'} date={DataComTraçoParaDataComBarra(DateNow())}>
                {`Seu período de teste expira em.`}
              </CardNotification>

              <div
                style={{
                  padding: '1rem 0 1rem 0',
                  maxHeight: 500,
                  overflow: 'auto',
                }}
              >
                <CardNotification type={'success'} date={'12/01/2025'}>
                  Você está em período de teste, que acabará em: 12/03/2025
                </CardNotification>
                <CardNotification type={'warning'} date={'12/01/2025'}>
                  Você está em período de teste, que acabará em: 12/03/2025
                </CardNotification>
                <CardNotification type={''} date={'12/01/2025'}>
                  Você está em período de teste, que acabará em: 12/03/2025
                </CardNotification>
              </div>
            </Column.Content> */}
          </Column.Root>
        </Page.Content>
      </Page.Root>
    </>
  )
}
