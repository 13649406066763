import { useNavigate } from 'react-router-dom'

import { useGlobalContext } from '../../../../../context/ContextGlobal'
import { TryCatch } from '../../../../../utils/general'
import { addDocument } from '../../../../../firebase/firestore'
import { Path } from '../../../../../router/paths'
import { schemaReports } from '../utils/schemas'
import { DateTimeNow } from '../../../../../utils/dates'

const useAddReports = () => {
  const {
    user,
    setAlerts,
    // dataContext,
    // setDataContext,
    // infosContext,
    // setInfosContext,
    formContext,
    setFormContext,
    loading,
    setLoading,
    // statusLoading,
    // setStatusLoading,
  } = useGlobalContext()

  const navigate = useNavigate()

  const AddReports = async () => {
    const newItem = {
      created_by: user.user.nome || user.user.name,
      dt_created: DateTimeNow(),
      ...schemaReports(formContext),
    }

    // console.log(newItem)

    if ((newItem?.columns || []).length === 0) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Nenhuma Coluna Selecionada',
          msg: `Selecione pelo menos uma coluna para adicionar o relatório.`,
        },
      ])
    }

    await addDocument('reports', newItem)

    setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Relatório Criado',
        msg: 'Novo Relatório criado com sucesso.',
      },
    ])
    return navigate(Path.AdminReports)
  }
  const HandleAddReports = async () => {
    await TryCatch(AddReports, setLoading, setAlerts)
  }

  return { user, loading, setLoading, formContext, setFormContext, HandleAddReports }
}

export default useAddReports
