import { ArrowLeft, DollarSign, Icon } from 'lucide-react'

import useCollaboratorHistory from '../../_hooks/useCollaboratorHistory'
import { Page } from '../../../../components/layouts/page'
import { Header } from '../../_components/Header'
import { Path } from '../../../../router/paths'
import { Column } from '../../../../components/layouts/columns'
import { HistoryCard } from '../../_components/HistoryCard'

export const CollaboratorHistory = () => {
  const { token, dataContext } = useCollaboratorHistory()

  // Garante que payments é um array válido
  const payments = dataContext?.payments ?? []

  return (
    <Page.Root>
      <Page.Content>
        <Header titlePage="Histórico de Pagamentos">
          <a href={Path.CollabHome(token)}>
            <ArrowLeft className="has-text-link" size={30} />
          </a>
        </Header>

        <Column.Root
          className="mt-6"
          style={{
            color: 'rgb(151, 151, 151)',
          }}
        >
          <Column.Content
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '0 2rem 0 2rem',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
              <DollarSign className="has-text-link" size={60} />
              <p className="subtitle is-4 has-text-link">Histórico de Pagamentos</p>
            </div>

            <div
              style={{
                width: '100%',
                margin: '4rem 0 4rem 0',
              }}
            >
              {payments.length > 0 ? (
                payments.map((payment) => <HistoryCard key={`payment-${payment.id}`} payment={payment} token={token} />)
              ) : payments.length === 0 ? (
                <div
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
                >
                  Nenhuma cobrança encontrada...
                </div>
              ) : (
                <div
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
                >
                  <Icon>loading</Icon>
                </div>
              )}
            </div>
          </Column.Content>
        </Column.Root>
      </Page.Content>
    </Page.Root>
  )
}
