import { Column } from '../../components/layouts/columns'
import { Page } from '../../components/layouts/page'
import LogoKeap from '../../assets/Prancheta 3.png'
import { Button } from '../../components/ui/button'
import useNotAutorization from './_hooks/useNotAutorization'

export const CollabNotAutorization = () => {
  const { not } = useNotAutorization()

  return (
    <Page.Root>
      <Page.Content>
        <Column.Root>
          <Column.Content
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '0 3rem 0 3rem',
            }}
          >
            <img src={LogoKeap} alt="KEAP Staff" style={{ width: '5rem' }} />
          </Column.Content>
        </Column.Root>

        <Column.Root>
          <Column.Content
            style={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <p style={{ textAlign: 'center' }}>{not}</p>
            <Button.A link="keapapp://collab/profile">Voltar ao app</Button.A>
          </Column.Content>
        </Column.Root>
      </Page.Content>
    </Page.Root>
  )
}
