import { getDocument, getSubDocument } from '../../../../firebase/firestore'
import { ExportToExcel } from '../../../../utils/exportToExcel'
import { TryCatch } from '../../../../utils/general'
import { DestructureData } from './destructureData'
import { GetAllStaffDataEnriched } from './getAllStaff'

const schemaSpreadsheet = (values, columns) => {
  const newSchema = columns.map((column) => ({
    label: column?.name || column?.column?.label || 'não encontrado',
    value: column?.column?.value || 'não encontrado',
  }))

  // console.log(newSchema)
  const destructureData = DestructureData(values, newSchema)
  // console.log(destructureData)
  return destructureData
}

const ExportSpreadsheet = async (idClient, idReport, typeReport, idEvent, setAlerts) => {
  let reportSchema = {}
  // console.log(typeReport)
  // console.log(idReport)
  if (typeReport === 'client') {
    reportSchema = await getSubDocument('clients', idClient, 'reports', idReport)
  } else {
    reportSchema = await getDocument('reports', idReport)
  }

  // console.log(reportSchema)

  const columnsReport = reportSchema?.columns || []
  const filtersReport = reportSchema?.filters || []

  if (columnsReport.length === 0) {
    return setAlerts((prev) => [
      ...prev,
      {
        type: 'error',
        title: `Nenhuma coluna encontrada`,
        msg: 'Não encontramos nenhuma coluna no relatório, revise-o ou entre em contato com o suporte.',
      },
    ])
  }

  if (filtersReport.length === 0) {
    return setAlerts((prev) => [
      ...prev,
      {
        type: 'error',
        title: `Nenhuma filtro encontrado`,
        msg: 'Não encontramos nenhum filtro no relatório, revise-o ou entre em contato com o suporte.',
      },
    ])
  }

  // trocar a coleta do schema pra fora pra poder coletar os filtros novos
  const getInfosEvent = await getDocument('events', idEvent)

  setAlerts((prev) => [
    ...prev,
    {
      type: 'success',
      title: `${reportSchema?.name}`,
      msg: 'Estamos coletando as informações necessárias para gerar sua planilha. Esse processo pode levar alguns minutos...',
    },
  ])

  const allStaff = await GetAllStaffDataEnriched(idEvent)

  // console.log(allStaff)
  // console.log(allStaff.length)

  const filters = filtersReport.map((item) => Number(item.value))

  // filtra a staff pelo status registrados no relatorio
  // [1, 2, 6].includes(candidate.candidacy.status)
  const staffFiltered = allStaff.filter((candidate) => filters.includes(candidate.candidacy.status))

  if (staffFiltered.length === 0) {
    return setAlerts((prev) => [
      ...prev,
      {
        type: 'error',
        title: `Nenhum Membro na Staff`,
        msg: 'Aplicamos o filtro conforme o relatório, mas não encontramos membros na equipe do evento para exportação. Verifique os critérios do filtro ou entre em contato com o suporte para mais informações.',
      },
    ])
  }

  // console.log(staffFiltered)
  // console.log(staffFiltered.length)

  const dataSpreadSheet = staffFiltered.flatMap((candidate) => {
    return schemaSpreadsheet(candidate, columnsReport)
  })

  // console.log(dataSpreadSheet)

  const spreadSheet = [{ sheet: dataSpreadSheet, nameSheet: 'Planilha1' }]

  const nameSpreadSheet = reportSchema?.name.replace('Exportar', '').trim().replace(/ /g, '_')

  ExportToExcel(spreadSheet, `${nameSpreadSheet}_${getInfosEvent.name}_${getInfosEvent.round}.xlsx`)

  return setAlerts((prev) => [
    ...prev,
    {
      type: 'success',
      title: `Planilha de Credenciamento Conclúida`,
      msg: 'O Processo de construir sua planilha terminou com exito...',
    },
  ])
}

export const HandleExportSpreadsheet = async (idClient, idReport, typeReport, idEvent, setLoading, setAlerts) => {
  const callback = async () => {
    ExportSpreadsheet(idClient, idReport, typeReport, idEvent, setAlerts)
  }
  await TryCatch(callback, setLoading, setAlerts)
}
