import React from 'react'

import { Page } from '../../../../components/layouts/page'
import { Icon } from '../../../../components/icons'
import { Column } from '../../../../components/layouts/columns'
import { Button } from '../../../../components/ui/button'
import { Table } from '../../../../components/ui/table'
import { Input } from '../../../../components/ui/input'
import { useClientCitys } from './_hooks/useClientCitys'

export const ClientCitys = () => {
  const {
    loading,
    search,
    setSearch,
    formContext,
    setFormContext,
    dataFiltered,
    FetchGetDatas,
    FetchGetInfos,
    HandleDeleteCity,
    HandleGetCepInfo,
    HandleAddCity,
  } = useClientCitys()

  return (
    <>
      <Page.Root className="is-fullwidth">
        <Column.Root className="mt-6 pt-5">
          <Column.Content>
            <p className="subtitle is-size-3">
              <Icon size={40}>map-pin</Icon> Cidades
            </p>
          </Column.Content>
          <Column.Content className="has-text-right">
            <Button.Root align="right">
              <Button.Content>
                <Button.Bt
                  color="is-dark"
                  colorText="has-text-white"
                  action={() => {
                    FetchGetDatas()
                    FetchGetInfos()
                  }}
                  loading={loading}
                >
                  <Icon size={15}>sync</Icon>
                </Button.Bt>
              </Button.Content>
              <Button.Content>
                <Button.Bt
                  className=""
                  color={formContext?.addCity ? 'is-dark' : 'is-success'}
                  colorText="has-text-white"
                  action={() => {
                    setFormContext((prev) => ({ ...prev, addCity: !prev?.addCity }))
                  }}
                  loading={loading}
                >
                  {formContext?.addCity ? 'x' : '+'}
                </Button.Bt>
              </Button.Content>
            </Button.Root>
          </Column.Content>
        </Column.Root>

        {formContext?.addCity && (
          <div className="box my-5">
            <Input.Label className="has-text-grey-light">Adicionar nova cidade</Input.Label>
            <Column.Root className="is-mobile">
              <Column.Content>
                <Input.Root>
                  <Input.Label className="has-text-grey-light ml-3" style={{ marginBottom: 0 }}>
                    Cep
                  </Input.Label>
                  <Input.Contents alignIcon={'left'}>
                    <Input.Prompt
                      value={formContext}
                      setValue={setFormContext}
                      field={'cep'}
                      maskValue="cep"
                      placeholder="Informe qualquer cep da cidade que deseja cadastrar ... "
                    ></Input.Prompt>
                    <Input.Icon align="is-left">
                      <Icon size={20}>map-pin</Icon>
                    </Input.Icon>
                  </Input.Contents>
                </Input.Root>
              </Column.Content>
              <Column.Content size="is-2">
                <Button.Root align="centered" className="mt-5">
                  <Button.Content>
                    <Button.Bt
                      color="is-dark"
                      className={'is-rounded'}
                      loading={loading}
                      action={() => {
                        HandleGetCepInfo()
                      }}
                    >
                      Pesquisar
                    </Button.Bt>
                  </Button.Content>
                </Button.Root>
              </Column.Content>
            </Column.Root>
            <Column.Content>
              {loading ? (
                <Icon>loading</Icon>
              ) : (
                (formContext?.responseCEP || []).length > 0 && (
                  <Table.Root className="is-fullwidth is-hoverable is-narrow has-text-centered">
                    <Table.Head>
                      <Table.Row>
                        <Table.Cell>Cidade</Table.Cell>
                        <Table.Cell>UF</Table.Cell>
                        <Table.Cell>Ações</Table.Cell>
                      </Table.Row>
                    </Table.Head>
                    <Table.Body>
                      {(formContext?.responseCEP || []).map((value) => {
                        // console.log(value)
                        return (
                          <>
                            <Table.Row>
                              <Table.Cell className="is-size-7 is-vcentered">{value.localidade}</Table.Cell>
                              <Table.Cell className="is-size-7 is-vcentered">{value.uf}</Table.Cell>
                              <Table.Cell className="is-vcentered">
                                <div style={{ marginBottom: 10, marginTop: 5 }}>
                                  <Button.Root align="centered">
                                    <Button.Content>
                                      <Button.Bt
                                        size="is-small"
                                        color="is-success"
                                        className={'has-text-white'}
                                        action={() => {
                                          HandleAddCity(value)
                                        }}
                                        loading={loading}
                                      >
                                        +
                                      </Button.Bt>
                                    </Button.Content>
                                  </Button.Root>
                                </div>
                              </Table.Cell>
                            </Table.Row>
                          </>
                        )
                      })}
                    </Table.Body>
                  </Table.Root>
                )
              )}
            </Column.Content>
          </div>
        )}

        <Column.Root className="is-mobile">
          <Column.Content>
            <Input.Root>
              <Input.Contents alignIcon={'left'}>
                <Input.Prompt value={search} setValue={setSearch} placeholder="Pesquisar..."></Input.Prompt>
                <Input.Icon align="is-left">
                  <Icon size={20}>search</Icon>
                </Input.Icon>
              </Input.Contents>
            </Input.Root>
          </Column.Content>
        </Column.Root>
        <Column.Root className="is-mobile">
          <Column.Content>
            <Table.Root className="is-fullwidth is-hoverable is-narrow has-text-centered">
              <Table.Head>
                <Table.Row>
                  <Table.Cell>Nome</Table.Cell>
                  <Table.Cell>Descrição</Table.Cell>
                  <Table.Cell>Ações</Table.Cell>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {dataFiltered.map((value) => {
                  return (
                    <>
                      <Table.Row>
                        <Table.Cell className="is-size-7 is-vcentered">{value.city}</Table.Cell>
                        <Table.Cell className="is-size-7 is-vcentered">{value.uf}</Table.Cell>
                        <Table.Cell className="is-vcentered">
                          <div style={{ marginBottom: 10, marginTop: 5 }}>
                            <Button.Root align="centered">
                              <Button.Content>
                                <Button.Bt
                                  size="is-small"
                                  color="is-danger"
                                  action={() => {
                                    HandleDeleteCity(value.id)
                                  }}
                                  loading={loading}
                                >
                                  <Icon size={15}>trash</Icon>
                                </Button.Bt>
                              </Button.Content>
                            </Button.Root>
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    </>
                  )
                })}
              </Table.Body>
            </Table.Root>
          </Column.Content>
        </Column.Root>
        <Page.Content></Page.Content>
      </Page.Root>
    </>
  )
}
