import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

import { useGlobalContext } from '../../../../../context/ContextGlobal'
import { Path } from '../../../../../router/paths'
import { addSubDocument } from '../../../../../firebase/firestore'
import { TryCatch } from '../../../../../utils/general'

const useAddSector = () => {
  const {
    user,
    setAlerts,
    // dataContext,
    // setDataContext,
    infosContext,
    // setInfosContext,
    // formContext,
    // setFormContext,
    loading,
    setLoading,
    // statusLoading,
    // setStatusLoading,
  } = useGlobalContext()

  const [setor, setSetor] = useState({})
  const [search, setSearch] = useState('')
  const [permissions, setPermissions] = useState(infosContext?.globalPermissionsList || {})
  const permissionsGlobais = infosContext?.globalPermissions || {}

  const navigate = useNavigate()

  const createSector = async () => {
    const newSector = { ...setor, permissions: { ...permissions } }
    await addSubDocument('clientes', user.client.id, 'sectors', newSector)

    setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Setor Criado',
        msg: 'Novo Setor criado com sucesso.',
      },
    ])
    navigate(Path.AdminSectors)
  }

  const handleCreateSector = async () => {
    await TryCatch(createSector, setLoading, setAlerts)
  }

  const filterPermissions =
    search === ''
      ? permissionsGlobais
      : Object.fromEntries(
          Object.entries(permissionsGlobais).filter(
            ([key]) => key.toLowerCase().includes(search.toLowerCase()) && key !== 'id',
          ),
        )

  // const handleClearPermissions = () => {
  //   const newPermissions = {}

  //   Object.keys(permissionsGlobais || {}).forEach((title) => {
  //     Object.keys(permissionsGlobais[title]).forEach((permission) => {
  //       const key = `${title}_${permission}`
  //       newPermissions[key] = false
  //     })
  //   })

  //   // console.log(newPermissions)
  //   setPermissions(newPermissions)
  // }

  // useEffect(() => {
  //   handleClearPermissions()
  // }, [])

  return {
    loading,
    permissionsGlobais,
    setor,
    infosContext,
    search,
    filterPermissions,
    setSetor,
    permissions,
    setPermissions,
    setSearch,
    handleCreateSector,
  }
}

export default useAddSector
