import { useEffect, useState } from 'react'

import { useGlobalContext } from '../../../../../context/ContextGlobal'
import {
  deleteSubDocument,
  getDocument,
  getSubDocuments,
  getSubDocumentsWithQuery,
} from '../../../../../firebase/firestore'
import { TryCatch } from '../../../../../utils/general'

const useSector = () => {
  const [search, setSearch] = useState('')

  const {
    user,
    setAlerts,
    // dataContext,
    // setDataContext,
    infosContext,
    setInfosContext,
    // formContext,
    // setFormContext,
    loading,
    setLoading,
    // statusLoading,
    // setStatusLoading,
  } = useGlobalContext()

  const setores = infosContext?.setores || {}
  const clientId = user.client.id

  const getData = async () => {
    // coleta os setores criados pelo cliente
    const getSetores = await getSubDocuments('clientes', clientId, 'sectors')

    // verifica se o setor está setado em algum usuario, caso sim ele bloqueia o botão de apagar
    const setoresInUse = {}

    const promises = getSetores.map(async (setor) => {
      const getuser = await getSubDocumentsWithQuery(`clientes`, clientId, 'users', 'sectorId', '==', setor.id)

      if (getuser.length > 0) {
        setoresInUse[setor.id] = true
      } else {
        setoresInUse[setor.id] = false
      }
    })

    // Aguarda a resolução de todas as promessas
    await Promise.all(promises)

    // Cria uma nova lista de Setores com o campo 'blocked'
    const sectors = getSetores.map((setor) => {
      return { ...setor, blocked: setoresInUse[setor?.id] || false }
    })

    // coleta as permissões globais em CONFIG/PERMISSIONS
    const getGlobalPermissions = await getDocument('configs', 'permissions')

    const globalPermissionsList = Object.keys(getGlobalPermissions).reduce((objetoFinal, title) => {
      Object.keys(getGlobalPermissions[title]).forEach((item) => {
        if (title !== 'id') {
          objetoFinal[`${title}_${item}`] = false
        }
      })
      return objetoFinal
    }, {})

    // salva todas as informações coletadas no context.
    setInfosContext({
      setores: sectors,
      globalPermissions: getGlobalPermissions,
      globalPermissionsList,
    })
  }

  const FetchGetData = async () => {
    await TryCatch(getData, setLoading, setAlerts)
  }

  const deleteSector = async (id) => {
    if (!user?.user?.permissions?.setores_excluir) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Permissão Negada',
          msg: 'Você não possui permissão para apagar setores',
        },
      ])
    }
    // se o setor não estiver em uso ele deixa deletar, porém se estiver ele mostra uma mensagem no alerts.
    if (setores.find((setor) => setor.id === id)?.blocked) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Setor Bloqueado',
          msg: 'O setor esta em uso por algum usuário, por esse motivo não é possivel deletar',
        },
      ])
    }
    // console.log(clientId)
    // console.log(id)

    await deleteSubDocument('clientes', clientId, 'sectors', id)
    await getData()
    return setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Setor Excluído',
        msg: 'Setor excluído da sua base de dados',
      },
    ])
  }

  const handleDeleteSector = async (sector) => {
    // console.log(sector)
    await TryCatch(
      async () => {
        deleteSector(sector)
      },
      setLoading,
      setAlerts,
    )
  }

  useEffect(() => {
    getData()
  }, [])

  const sectorsFiltered =
    search !== ''
      ? Object.values(setores).filter((setor) => {
          const searchString = `${setor.nome}|${setor.descricao}`.toLowerCase()
          return searchString.includes(search.toLowerCase())
        })
      : Object.values(setores)

  return { search, loading, sectorsFiltered, setSearch, FetchGetData, handleDeleteSector }
}

export default useSector
