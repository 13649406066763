import { useCallback, useEffect, useState } from 'react'

import { Input } from '../../../../components/ui/input'
import { Icon } from '../../../../components/icons'
import { Column } from '../../../../components/layouts/columns'
import { Button } from '../../../../components/ui/button'
import { Modal } from '../../../../components/ui/modal'
import { useValidateCollaboratorModal } from '../_hooks/useValidateCollaboratorModal'
import { collaboratorSchemaValidate } from '../_utils/collaboratorSchemaValidate'
import { Checkbox } from '../../../../components/ui/checkbox'
import { Select } from '../../../../components/ui/select'
import { DateTimeNow } from '../../../../utils/dates'
import { getDocuments } from '../../../../firebase/firestore'
import { Form } from '../../../../components/ui/form'

const CheckBoxItem = ({
  formContext,
  setFormContext,
  field,
  inEditionField,
  isDocument = false,
  label = '',
  userBy = '',
}) => {
  return (
    <Checkbox.Root>
      <Checkbox.Content
        style={{
          transform: 'scale(1.5)',
          marginRight: '5px',
          backgroundColor: formContext?.inEdition?.[inEditionField]?.checked ? '#3273dc' : '#fff',
          border: '2px solid #3273dc',
          borderRadius: '4px',
          cursor: 'pointer',
        }}
        value={formContext?.inEdition?.[inEditionField]?.checked ?? false}
        setValue={(value) => {
          // console.log(value)
          setFormContext((prev) => ({
            ...(prev || {}),
            inEdition: {
              ...(prev?.inEdition || {}),
              // garante que se o check for false, o objeto fica vazio
              [inEditionField]: value
                ? {
                    ...(prev?.inEdition?.[inEditionField] || {}),
                    checked: value,
                    title: label,
                    fieldValue: formContext?.[field],
                    dtChecked: DateTimeNow(),
                    type: isDocument ? 'document' : 'field',
                    checkedBy: userBy,
                  }
                : null,
            },
          }))
        }}
      />
    </Checkbox.Root>
  )
}

const SelectReasonItem = ({ formContext, setFormContext, inEditionField }) => {
  const [reasons, setReasons] = useState([])
  const fetchReasons = useCallback(async () => {
    const getReasons = await getDocuments('reasons')
    setReasons(getReasons)
  }, [])

  useEffect(() => {
    fetchReasons()
  }, [fetchReasons])

  return (
    <Input.Root>
      <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor={inEditionField} required={true}>
        Motivo da recusa
      </Input.Label>
      <Select.Root>
        <Select.Search
          styleControl={{ borderColor: '#f14668' }}
          className="is-fullwidth is-rounded is-danger"
          value={formContext?.inEdition?.[inEditionField]?.reason ?? false}
          setValue={(value) => {
            // console.log(value)
            return setFormContext((prev) => {
              // console.log(prev)
              return {
                ...(prev || {}),
                inEdition: {
                  ...(prev?.inEdition || {}),
                  [inEditionField]: { ...(prev?.inEdition?.[inEditionField] || {}), reason: value },
                },
              }
            })
          }}
          required={true}
          options={reasons.map((reason) => ({ label: reason.label, value: reason.value }))}
        ></Select.Search>
      </Select.Root>
    </Input.Root>
  )
}

const ImageDocumentItem = ({
  formContext,
  setFormContext,
  inEditionField,
  label,
  listDocumentsArray,
  userBy = '',
  infosContext,
}) => {
  // estrutura do documentsWithSyncs: [ [idDoc, syncField] ]
  const documentsWithSyncs = infosContext?.documentsWithSyncs || []

  // procura um documento que foi cadastrado com SyncField
  const documentSync = documentsWithSyncs.find((doc) => inEditionField === doc[1])

  if (!documentSync) return null

  // se ele achar um documento do colaborador que é syncronizado, então ele continua
  const documentData = listDocumentsArray.find((doc) => doc.id === documentSync[0])

  if (!documentData) return null

  return (
    <Column.Root key={`image_validate_${inEditionField}`}>
      {/* <p>{JSON.stringify(formContext.documentos.TZOfbPsePZeWcJfHX3bD.urlSubmit)}</p> */}
      <Column.Content size="is-1" className="is-flex is-justify-content-center mt-5">
        <CheckBoxItem
          formContext={formContext}
          setFormContext={setFormContext}
          inEditionField={documentData.id}
          field={documentData.id}
          label={label}
          isDocument
          userBy={userBy}
        />
      </Column.Content>
      <Column.Content>
        <Input.Root>
          <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor={documentData?.id}>
            {documentData?.name}
          </Input.Label>
          {documentData?.urlSubmit && (
            <>
              <img
                src={documentData.urlSubmit}
                style={{
                  width: '100%', // Largura total da tela
                  height: 'auto', // Ajusta a altura proporcionalmente
                  objectFit: 'cover', // Corta a imagem se for maior que o container, mantendo o aspecto
                  borderColor: formContext?.inEdition?.[documentData.id]?.checked ? '#f14668' : '#fff',
                  borderWidth: 2,
                  borderRadius: 10,
                  borderStyle: 'solid',
                }}
                alt="Imagem"
              />
              <br />
              <Button.Bt
                action={() => {
                  window.open(documentData.urlSubmit, '_target')
                }}
              >
                Vizualizar
              </Button.Bt>
            </>
          )}
          {documentData?.value && (
            <Input.ReadOnly
              id={`readOnly${documentData.id}`}
              className={`is-rounded ${formContext?.inEdition?.[documentData.id]?.checked ? 'is-danger' : 'is-light'}`}
            >
              {documentData.value}
            </Input.ReadOnly>
          )}
        </Input.Root>

        {formContext?.inEdition?.[documentData.id]?.checked && (
          <SelectReasonItem formContext={formContext} setFormContext={setFormContext} field={documentData.id} />
        )}
      </Column.Content>
    </Column.Root>
  )
}

const ItemValidation = ({ item, formContext, setFormContext, userBy = '', infosContext }) => {
  const listDocumentsArray = Object.keys(formContext?.documentos || {}).map((idDoc) => {
    return { id: idDoc, ...(formContext?.documentos?.[idDoc] || {}) }
  })

  return (
    <>
      <Column.Root key={`field_validate_${item}`}>
        <Column.Content size="is-1" className="is-flex is-justify-content-center mt-5">
          <CheckBoxItem
            formContext={formContext}
            setFormContext={setFormContext}
            inEditionField={item.inEditionField}
            field={item.field}
            label={item.label}
            userBy={userBy}
          />
        </Column.Content>
        <Column.Content>
          <Input.Root>
            <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor={item.inEditionField}>
              {item.label}
            </Input.Label>
            {item.type === 'text' && (
              <Input.ReadOnly
                id={`readOnly${item.inEditionField}`}
                className={`is-rounded ${formContext?.inEdition?.[item.inEditionField] ? 'is-danger' : 'is-light'}`}
              >
                {formContext?.[item.field]}
              </Input.ReadOnly>
            )}
            {item.type === 'image' &&
            typeof formContext?.photoUrl === 'string' &&
            formContext?.photoUrl.includes('https://') ? (
              <img
                alt="url_foto"
                src={formContext?.photoUrl}
                style={{
                  width: '300px',
                  height: '300px',
                  objectFit: 'cover',
                  borderRadius: 10,
                  border: formContext?.inEdition?.photoUrl?.checked ? '5px solid hsl(348, 100%, 61%)' : '', // Borda completa
                }}
                className="has-shadow"
              />
            ) : (
              <></>
            )}
          </Input.Root>
          {formContext?.inEdition?.[item.inEditionField]?.checked && (
            <>
              <SelectReasonItem
                formContext={formContext}
                setFormContext={setFormContext}
                inEditionField={item.inEditionField}
              />
              <div
                className="has-background-grey-lighter"
                style={{ height: 1, width: '100%', marginBottom: 20, marginTop: 20 }}
              />
            </>
          )}
        </Column.Content>
      </Column.Root>

      {/* Procure se o syncField do documento, é igual ao field */}
      <ImageDocumentItem
        infosContext={infosContext}
        formContext={formContext}
        setFormContext={setFormContext}
        inEditionField={item.inEditionField}
        label={item.label}
        listDocumentsArray={listDocumentsArray}
      />
    </>
  )
}

export const ValidateCollaboratorModal = ({ active, setActive }) => {
  const {
    user,
    loading,
    infosContext,
    formContext,
    setFormContext,
    setValueCheckbox,
    handleApproveCollab,
    handleUpdateInEdition,
  } = useValidateCollaboratorModal()

  return (
    <Form.Root
      action={() => {
        handleUpdateInEdition()
        setActive(false)
      }}
    >
      <Modal.Root active={active}>
        <Modal.Contents>
          <Modal.Header>
            <p className="modal-card-title" style={{ marginBottom: -15 }}>
              Validar Colaborador
            </p>
            <Modal.HeaderClose
              setActive={() => {
                setFormContext((prev) => ({ ...prev, inEdition: {} }))
                setActive(false)
              }}
            ></Modal.HeaderClose>
          </Modal.Header>
          <Modal.Body>
            {collaboratorSchemaValidate.map((item) => {
              return (
                <ItemValidation
                  key={item}
                  item={item}
                  infosContext={infosContext}
                  formContext={formContext}
                  setFormContext={setFormContext}
                  setValueCheckbox={setValueCheckbox}
                  userBy={user?.user?.name || user?.user?.nome}
                />
              )
            })}
          </Modal.Body>
          {console.log(Object.entries(formContext?.inEdition || {}).some((item) => item[1] === null))}

          <Modal.Footer className="is-justify-content-end">
            {formContext.status === 1 && (
              <>
                <Button.Bt
                  className="is-rounded"
                  color="is-success"
                  colorText="has-text-white"
                  action={() => {
                    handleApproveCollab()
                    setActive(false)
                    // HandleApproveCollab
                    // Somente colocar o status do colaborador para 9
                  }}
                  loading={loading}
                  disabled={Object.entries(formContext?.inEdition || {}).some((item) => item[1] !== null)}
                >
                  Aprovar
                  <Icon size={20} className="ml-3">
                    check
                  </Icon>
                </Button.Bt>
                <Button.Bt
                  className="is-rounded"
                  color="is-danger"
                  colorText="has-text-white"
                  // action={() => {

                  //   // MUDAR A LÓGICA DESSE REPROVAR
                  //   //  coletar dados do  FORMCONTEXT.INEDITION ✅

                  //   // NOVA LÓGICA DEVE TIRAR TUDO QUE É OBJETO VAZIO (ex.: nome: {}, cpf: {}), quando o checkbox é desmarcado ele automaticamente tira do objeto inEdition ✅

                  //   // SEPARAR os objetos COM VALORES TYPE === 'DOCUMENT' E ESSE depois reprovar todos esses documentos REPROVAR (DEIXAR STATUS IGUAL A false igual na validação de cargos)

                  //   // E tudo que for TYPE === 'FIELD', JOGAR NO INEDITION DO COLABORADOR COM ESSA ESTRUTURA:✅
                  //   //
                  //   // { fieldValue: "449.528.498-30", reason:"Incosistente", title: "CPF", type: "correction", dtChecked: '10/03/2025 21:15' }✅
                  //   //
                  //   // JA DEIXEI QUASE TUDO PRONTO, esse objeto é criado ao checkar os checkbox, depois da uma conferida como eu fiz
                  //   //
                  //   // no fim envia uma notificação ao colaborador dizendo que o cadastro dele esta incorreto e que precisa ser corrigido
                  // }}
                  loading={loading}
                  disabled={!Object.entries(formContext?.inEdition || {}).some((item) => item[1] !== null)}
                  type="submit"
                  // disabled={!enableRefuseButton1}
                >
                  Reprovar
                  <Icon size={20} className="ml-3">
                    x
                  </Icon>
                </Button.Bt>
              </>
            )}
          </Modal.Footer>
        </Modal.Contents>
      </Modal.Root>
    </Form.Root>
  )
}
