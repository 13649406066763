import React from 'react'

import { ClientFormUserLayout } from './_form/clientFormUserLayout'
import { Page } from '../../../../components/layouts/page'
import { Column } from '../../../../components/layouts/columns'
import { Form } from '../../../../components/ui/form'
import { useClientAddUser } from './_hooks/useClientAddUser'

export const ClientAddUsers = () => {
  const { sectors, HandleAddUser, formContext, setFormContext, loading } = useClientAddUser()
  // console.log(sectors)
  return (
    <Page.Section className="is-fullheight-with-navbar mt-6 mx-3">
      <Form.Root
        action={() => {
          HandleAddUser()
        }}
      >
        <Column.Root>
          <Column.Content>
            <p className="subtitle is-size-3">Adicionar Usuário</p>
          </Column.Content>
        </Column.Root>
        <ClientFormUserLayout
          loading={loading}
          values={formContext}
          setValues={setFormContext}
          options={sectors}
          disabledList={{}}
        />
      </Form.Root>
    </Page.Section>
  )
}
