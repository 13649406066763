import { useEffect, useState } from 'react'

import { useGlobalContext } from '../../../../../context/ContextGlobal'
import { getDocument, getSubDocuments, updateDocument } from '../../../../../firebase/firestore'
import { DeleteUser } from '../../../../../utils/requests'
import { TryCatch } from '../../../../../utils/general'
import { ClearFormContext } from '../utils/clearData'

const useAdminUsers = () => {
  const {
    user,
    dataContext,
    setDataContext,
    infosContext,
    setInfosContext,
    // formContext,
    setFormContext,
    loading,
    setLoading,
    // statusLoading,
    // setStatusLoading,
    setAlerts,
  } = useGlobalContext()
  const users = dataContext?.usersKeap || []
  const sectors = infosContext?.sectors || []
  const [search, setSearch] = useState('')
  const clientId = user.client.id

  const getData = async () => {
    const getKeapData = await getDocument('clientes', clientId) // Coleta de dados do cadastro da KEAP
    const usersKeapData = getKeapData?.users || {}
    const getKeapDataUsers = await getSubDocuments('clientes', clientId, 'users')
    const usersKeap = Object.values(getKeapDataUsers).map((u) => {
      return { ...u, status: usersKeapData?.[u?.id || null] || false }
    })
    setDataContext({ usersKeap })
  }

  // coleta uma lista de usuarios e de setores
  const getInfos = async () => {
    const getSectors = await getSubDocuments('clientes', clientId, 'sectors')
    setInfosContext({ sectors: getSectors })
  }

  // deleta o usuario no cliente e se não tiver cadastro em cliente, ele apaga o usuario do autenticador
  const delUserKeap = (uid) => {
    if (!user?.user?.permissions?.usuarios_excluir) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Permissão Negada',
          msg: 'Você não possui permissão para excluir usuarios',
        },
      ])
    }
    const userInfo = Object.values(users).find((u) => u.id === uid)

    return DeleteUser(uid, userInfo, clientId, setLoading, setAlerts, async () => {
      await FetchGetData()
      await FetchGetInfos()
    })
  }

  const blockUnblockUserKeap = async (uid) => {
    if (!user?.user?.permissions?.usuarios_bloquear) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Permissão Negada',
          msg: 'Você não possui permissão para bloquear usuarios',
        },
      ])
    }

    const userInfo = Object.values(users).find((u) => u.id === uid)

    await updateDocument('clientes', clientId, {
      [`users.${uid}`]: !userInfo.status,
    })

    if (!userInfo.status) {
      setAlerts((prev) => [
        ...prev,
        {
          type: 'success',
          title: 'Usuário Bloqueado',
          msg: `Usuário ${userInfo.nome} Bloqueado com sucesso.`,
        },
      ])
    } else {
      setAlerts((prev) => [
        ...prev,
        {
          type: 'success',
          title: 'Usuário Desbloqueado',
          msg: `Usuário ${userInfo.nome} Desbloqueado com sucesso.`,
        },
      ])
    }

    await FetchGetData()
    await FetchGetInfos()
  }

  const FetchGetData = async () => {
    await TryCatch(getData, setLoading, setAlerts)
  }

  const FetchGetInfos = async () => {
    await TryCatch(getInfos, setLoading, setAlerts)
  }

  const handleDelUserKeap = async (uid) => {
    await TryCatch(
      async () => {
        await delUserKeap(uid)
      },
      setLoading,
      setAlerts,
    )
  }

  const handleBlockUnblockUserKeap = async (uid) => {
    await TryCatch(
      async () => {
        await blockUnblockUserKeap(uid)
      },
      setLoading,
      setAlerts,
    )
  }

  // executa quando carrega a pagina e quando é auterado o getUserList
  useEffect(() => {
    ClearFormContext(setFormContext)
    FetchGetData()
    FetchGetInfos()
  }, [])

  const usersFiltered =
    search !== ''
      ? Object.values(users).filter((user) => {
          const sectorName = sectors.find((setor) => setor.id === user.sectorId)?.nome || ''
          const status = user?.status ? 'Ativo' : 'Bloqueado'

          const searchString = `${user.nome}|${user.email}|${user.cargo}|${sectorName}|${status}`.toLowerCase()
          return searchString.includes(search.toLocaleLowerCase())
        })
      : Object.values(users)

  return {
    sectors,
    users,
    usersFiltered,
    loading,
    search,
    setSearch,
    FetchGetData,
    FetchGetInfos,
    handleDelUserKeap,
    handleBlockUnblockUserKeap,
  }
}

export default useAdminUsers
