import { Icon } from '../../../../components/icons'
import { Column } from '../../../../components/layouts/columns'
import { Button } from '../../../../components/ui/button'
import { Input } from '../../../../components/ui/input'
import { Table } from '../../../../components/ui/table'

export const ValidationPositionModalForm = ({
  loading,
  formContext,
  documentsList,
  consentsList,
  HandleResendAttachment,
}) => {
  return (
    <div>
      <Column.Root className="is-mobile">
        <Column.Content>
          <Column.Root className="mt-3">
            <Column.Content>
              <p className="subtitle is-size-4">{formContext?.name}</p>
            </Column.Content>
          </Column.Root>
          <Column.Root className="mt-3">
            <Column.Content>
              <p>{formContext?.description}</p>
            </Column.Content>
          </Column.Root>
          <Column.Root className="mt-3">
            <Column.Content>
              <Input.Label className="ml-3 mt-3 has-text-grey" style={{ marginBottom: 0 }} required={false}>
                Critérios mínimos para o cargo:
              </Input.Label>
              <p>{formContext?.dica}</p>
            </Column.Content>
          </Column.Root>
          <Column.Root className="mt-3">
            <Column.Content>
              <Input.Root>
                <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={false}>
                  Minhas Experiências
                </Input.Label>
                <Input.Contents>
                  <Input.Textarea className="is-rounded" value={formContext?.myExperiences}></Input.Textarea>
                </Input.Contents>
              </Input.Root>
            </Column.Content>
          </Column.Root>

          <Column.Root className="mt-3">
            <Column.Content>
              <Input.Root>
                <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={false}>
                  Empresas que Trabalhei
                </Input.Label>
                <Input.Contents>
                  <Input.Textarea className="is-rounded" value={formContext?.myEnterprises}></Input.Textarea>
                </Input.Contents>
              </Input.Root>
            </Column.Content>
          </Column.Root>

          {(consentsList || []).length > 0 && (
            <>
              <Column.Root className="mt-3">
                <Column.Content>
                  <p className="subtitle is-size-4">Consentimentos</p>
                </Column.Content>
              </Column.Root>
              <div className="box" style={{ marginTop: 0 }}>
                <div className="table-container">
                  <Table.Root className="is-fullwidth has-text-centered mb-5 mt-2">
                    <Table.Head>
                      <Table.Row>
                        <Table.Cell>Nome</Table.Cell>
                        <Table.Cell>Data de assinatura</Table.Cell>
                        <Table.Cell>Status</Table.Cell>
                      </Table.Row>
                    </Table.Head>
                    <Table.Body>
                      {(consentsList || []).map((consent) => {
                        return (
                          <Table.Row key={`consent-${consent.id}`}>
                            <Table.Cell>{consent?.name}</Table.Cell>
                            <Table.Cell>{consent?.signDate}</Table.Cell>
                            <Table.Cell>{!consent?.status ? 'Não assinado' : 'Assinado'}</Table.Cell>
                          </Table.Row>
                        )
                      })}
                    </Table.Body>
                  </Table.Root>
                </div>
              </div>
            </>
          )}

          {(documentsList || []).length > 0 && (
            <>
              <Column.Root className="mt-3">
                <Column.Content>
                  <p className="subtitle is-size-4">Documentos</p>
                </Column.Content>
              </Column.Root>
              <div className="box" style={{ marginTop: 0 }}>
                <div className="table-container">
                  <Table.Root className="is-fullwidth has-text-centered mb-5 mt-2">
                    <Table.Head>
                      <Table.Row key={`document-${document?.id}`}>
                        <Table.Cell>Nome</Table.Cell>
                        <Table.Cell>Data de envio</Table.Cell>
                        <Table.Cell>Status</Table.Cell>
                        <Table.Cell>Documento</Table.Cell>
                        <Table.Cell>Ações</Table.Cell>
                      </Table.Row>
                    </Table.Head>
                    <Table.Body>
                      {(documentsList || []).map((document) => {
                        return (
                          <Table.Row key={document?.id}>
                            <Table.Cell>{document?.name}</Table.Cell>
                            <Table.Cell>{document?.sendDate}</Table.Cell>
                            <Table.Cell>{document?.status === true ? 'Enviado' : 'Não enviado'}</Table.Cell>
                            <Table.Cell>
                              {!Object.keys(document).includes('urlSubmit') ? (
                                document.value
                              ) : (
                                <Button.Content>
                                  <Button.A
                                    size="is-small"
                                    link={document?.urlSubmit}
                                    loading={loading}
                                    title="Abrir Anexo"
                                    openNew
                                  >
                                    Visualizar <Icon size={15}>file-blank</Icon>
                                  </Button.A>
                                </Button.Content>
                              )}
                            </Table.Cell>
                            <Table.Cell className="is-vcentered">
                              <div>
                                <Button.Root align="centered">
                                  {document?.status ? (
                                    <>
                                      {/* <Button.Content>
                                        <Button.A
                                          size="is-small"
                                          link={document?.urlSubmit}
                                          loading={loading}
                                          title="Abrir Anexo"
                                          openNew
                                        >
                                          <Icon size={15}>file-blank</Icon>
                                        </Button.A>
                                      </Button.Content> */}
                                      <Button.Content>
                                        <Button.Bt
                                          size="is-small"
                                          color="is-danger"
                                          colorText={'has-text-white'}
                                          action={async () => {
                                            await HandleResendAttachment(document.id, document)
                                          }}
                                          loading={loading}
                                          title="Recusar Anexo"
                                        >
                                          <Icon size={15}>x</Icon>
                                        </Button.Bt>
                                      </Button.Content>
                                    </>
                                  ) : (
                                    <>
                                      <Button.Content>
                                        <Button.A
                                          size="is-small"
                                          link={document?.urlSubmit}
                                          loading={loading}
                                          title="Abrir Anexo"
                                          openNew
                                        >
                                          <Icon size={15}>file-blank</Icon>
                                        </Button.A>
                                      </Button.Content>
                                      {/* <Button.Content>
                                        <Button.Bt
                                          size="is-small"
                                          color="is-danger"
                                          colorText={'has-text-white'}
                                          action={async () => {
                                            await HandleResendAttachment(document.id, document)
                                          }}
                                          loading={loading}
                                          title="Recusar Anexo"
                                        >
                                          <Icon size={15}>x</Icon>
                                        </Button.Bt>
                                      </Button.Content> */}
                                    </>
                                  )}
                                </Button.Root>
                              </div>
                            </Table.Cell>
                          </Table.Row>
                        )
                      })}
                    </Table.Body>
                  </Table.Root>
                </div>
              </div>
            </>
          )}
        </Column.Content>
      </Column.Root>
    </div>
  )
}
