import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useGlobalContext } from '../../../context/ContextGlobal'
import { DateTimeNow } from '../../../utils/dates'
import { logIn, register } from '../../../firebase/auth'
import { getDocuments, setDocument } from '../../../firebase/firestore'
import { TryCatch } from '../../../utils/general'
import { Path } from '../../../router/paths'

const ValidateFormExistFields = (fields, formContext, setMessagesForm) => {
  // Usar reduce para acumular os erros
  const newMessages = fields.reduce((acc, field) => {
    if (!formContext?.[field] || formContext?.[field] === '') {
      // Acumula as mensagens de erro
      acc[field] = { type: 'error', msg: 'Campo obrigatório de preenchimento' }
    }
    return acc
  }, {})

  // Atualiza o estado com todas as mensagens de erro de uma vez
  setMessagesForm((prev) => ({ ...prev, ...newMessages }))

  if (Object.keys(newMessages).length > 0) {
    return false
  }
  return true
}

const validateBirthday = async (dataNascimento) => {
  // Criar um objeto Date para a data atual
  const dataAtual = new Date()

  // Criar um objeto Date a partir da data de nascimento (formato: DD/MM/AAAA)
  const [ano, mes, dia] = dataNascimento.split('-') // Desestrutura para obter dia, mês e ano
  const dataNascimentoObj = new Date(ano, mes - 1, dia) // Mes começa do 0, então subtraímos 1

  // Calcular a diferença entre a data atual e a data de nascimento
  const idade = dataAtual.getFullYear() - dataNascimentoObj.getFullYear()

  // Verificar se a data de nascimento já ocorreu neste ano
  const mesNascimento = dataNascimentoObj.getMonth()
  const diaNascimento = dataNascimentoObj.getDate()
  const mesAtual = dataAtual.getMonth()
  const diaAtual = dataAtual.getDate()

  // Se o mês atual for anterior ao mês de nascimento ou o mês for igual e o dia atual for anterior ao dia de nascimento, subtrai 1 da idade
  if (mesAtual < mesNascimento || (mesAtual === mesNascimento && diaAtual < diaNascimento)) {
    return idade >= 18 // Se a idade calculada for maior ou igual a 18, retorna true, caso contrário, false
  }

  return idade >= 18
}

function validarCPF(cpf) {
  // Cria uma variável local para o CPF e remove caracteres não numéricos
  const cpfValido = cpf.replace(/[^\d]+/g, '')

  // Verifica se o CPF tem 11 dígitos
  if (cpfValido.length !== 11 || /^(\d)\1{10}$/.test(cpfValido)) {
    return false
  }

  // Calcula e valida o primeiro dígito verificador
  let soma = 0
  for (let i = 0; i < 9; i += 1) {
    // Substituindo 'i++' por 'i = i + 1'
    soma += parseInt(cpfValido.charAt(i), 10) * (10 - i) // Passando '10' como radix
  }
  let resto = (soma * 10) % 11
  if (resto === 10 || resto === 11) {
    resto = 0
  }
  if (resto !== parseInt(cpfValido.charAt(9), 10)) {
    // Passando '10' como radix
    return false
  }

  // Calcula e valida o segundo dígito verificador
  soma = 0
  for (let i = 0; i < 10; i += 1) {
    // Substituindo 'i++' por 'i = i + 1'
    soma += parseInt(cpfValido.charAt(i), 10) * (11 - i) // Passando '10' como radix
  }
  resto = (soma * 10) % 11
  if (resto === 10 || resto === 11) {
    resto = 0
  }
  if (resto !== parseInt(cpfValido.charAt(10), 10)) {
    // Passando '10' como radix
    return false
  }

  // Se passou nas verificações, o CPF é válido
  return true
}

const ValidateValuesType = (value, type) => {
  if (type === 'password') {
    return value.length > 6
      ? { type: '', msg: '' }
      : { type: 'error', msg: 'A Senha deve possuir mais de 6 caracteres...' }
  }
  if (type === 'email') {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return emailRegex.test(value) ? { type: '', msg: '' } : { type: 'error', msg: 'Digite um E-mail válido...' }
  }
  if (type === 'cpf') {
    const cpf = value.replace(/\D/g, '')

    // Verifica se o CPF tem 11 dígitos e não é uma sequência repetida
    if (!cpf || cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
      return false
    }

    // Função para calcular o dígito verificador
    const calculateDigit = (number, factor) => {
      let total = 0
      for (let i = 0; i < factor - 1; i += 1) {
        total += parseInt(number[i], 10) * (factor - i)
      }
      const remainder = (total * 10) % 11
      return remainder === 10 ? 0 : remainder
    }

    const digit1 = calculateDigit(cpf, 10)
    const digit2 = calculateDigit(cpf, 11)

    return digit1 === parseInt(cpf[9], 10) && digit2 === parseInt(cpf[10], 10)
      ? { type: '', msg: '' }
      : { type: 'error', msg: 'Digite um CPF válido...' }
  }
  return { type: '', msg: '' }
}

const ValidateValues = (formContext, fields, setMessagesForm) => {
  const newMessages = fields.reduce((acc, field) => {
    // testa e Acumula as mensagens de erro
    const testValue = ValidateValuesType(formContext[field], field)
    acc[field] = testValue
    return acc
  }, {})

  // Atualiza o estado com todas as mensagens de erro de uma vez
  setMessagesForm(newMessages)
  if (Object.values(newMessages).some((v) => v.type === 'error')) {
    return false
  }
  return true
}

const useRegister = () => {
  const { formContext, setFormContext, loading, setLoading, setUser, setAlerts, alerts } = useGlobalContext()

  const [messagesForm, setMessagesForm] = useState({})

  const navigate = useNavigate()

  const schemaNewColab = (values) => {
    const fields = ['name', 'cpf', 'email', 'password', 'confirmPassword', 'birthday']

    if (!ValidateFormExistFields(fields, values, setMessagesForm)) return null

    return {
      name: values.name,
      cpf: values.cpf,
      email: values.email,
      personal: {
        birthday: values.birthday,
      },
      dt_created: DateTimeNow(),
      status: 0,
    }
  }

  async function RegisterNewColab() {
    setMessagesForm({})

    const newcolab = schemaNewColab(formContext)

    const birthday = newcolab?.personal?.birthday || '01-01-2025'

    const hasMaiorIdade = await validateBirthday(birthday)

    if (!hasMaiorIdade) {
      return setAlerts((prev) => [
        ...prev,
        {
          msg: 'Infelizmente, não podemos aceitar cadastros de menores de idade. Para utilizar nossos serviços, é necessário ter 18 anos ou mais. Agradecemos pela compreensão',
          title: 'Menor de Idade',
          type: 'error',
        },
      ])
    }

    const hasValidoCpf = validarCPF(newcolab?.cpf)

    if (!hasValidoCpf) {
      return setAlerts((prev) => [
        ...prev,
        {
          msg: 'O CPF informado é inválido. Por favor, verifique e tente novamente.',
          title: 'CPF Inválido',
          type: 'error',
        },
      ])
    }

    // verifica campos vazios
    if (newcolab === null) return null

    // valida valores
    const fieldsValidations = ['email', 'cpf', 'password']
    if (!ValidateValues(formContext, fieldsValidations, setMessagesForm)) return null

    // verifica senha iguais
    if (formContext?.password !== formContext?.confirmPassword) {
      setFormContext((prev) => {
        const { ...rest } = prev
        return { ...rest }
      })
      setMessagesForm((prev) => ({
        ...prev,
        password: {
          type: 'error',
          msg: 'Senhas diferentes, confirme a senha novamente...',
        },
      }))
      return null
    }

    const registerColab = await register(newcolab.email, formContext.password)

    if (registerColab.error === 'Firebase: Error (auth/email-already-in-use).') {
      return setAlerts((prev) => [
        ...prev,
        {
          msg: 'Seu e-mail ou cpf já estão cadastrados em nossa base de dados',
          title: 'Email ou CPF Existente',
          type: 'error',
        },
      ])
    }
    if (registerColab.error) {
      throw new Error(registerColab.error)
    }

    const uuid = registerColab.uid

    const defaultRegisters = await getDocuments('_defaultRegister')

    const newDocumentsAndConsentiments = { documents: {}, consents: {} }

    defaultRegisters.forEach((item) => {
      if (item.type === 'document') {
        newDocumentsAndConsentiments.documents[item.id] = {
          name: item.name,
          sendDate: '',
          status: false,
          syncField: item?.syncField ?? '',
        }
      }
      if (item.type === 'consent') {
        newDocumentsAndConsentiments.consents[item.id] = {
          name: item.name,
          signDate: '',
          status: false,
          syncField: item?.syncField ?? '',
        }
      }
    })

    const newRegister = {
      ...newcolab,
      ...newDocumentsAndConsentiments,
    }

    await setDocument('colaboradores', uuid, newRegister)

    const authInfos = await logIn(newcolab.email, formContext.password)

    if (authInfos?.error) {
      throw new Error(authInfos.error)
    }

    setUser({ id: uuid, ...newRegister })

    setFormContext({})

    return navigate(Path.CollabSelectPlans)
  }

  const HandleRegisterNewColab = async () => {
    await TryCatch(RegisterNewColab, setLoading, setAlerts)
  }

  return {
    formContext,
    setFormContext,
    messagesForm,
    loading,
    setMessagesForm,
    HandleRegisterNewColab,
    setAlerts,
    alerts,
  }
}

export default useRegister
