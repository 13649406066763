export const createSchemaPlans = (values) => {
  return {
    name: values.name,
    desc: values?.desc || '',
    value: values.value,
    typeExpired: values?.typeExpired || '',
    payment: values?.payment || '',
    admission: values?.admission || 0,
    typePlan: values.typePlan,
    trialDays: values?.trialDays || 0,
    daysExpired: values?.daysExpired || '',
    codCupom: values?.codCupom || '',
    limitAplly: values?.limitAplly || 0,
    apllieds: values?.apllieds || 0,
    ...(values?.nAliqISS && { nAliqISS: values?.nAliqISS ? Number(values.nAliqISS) : 0 }),
    ...(values?.nAliqPIS && { nAliqPIS: values?.nAliqPIS ? Number(values.nAliqPIS) : 0 }),
    ...(values?.nAliqCOFINS && { nAliqCOFINS: values?.nAliqCOFINS ? Number(values.nAliqCOFINS) : 0 }),
    ...(values?.nAliqIR && { nAliqIR: values?.nAliqIR ? Number(values.nAliqIR) : 0 }),
    ...(values?.nAliqINSS && { nAliqINSS: values?.nAliqINSS ? Number(values.nAliqINSS) : 0 }),
    ...(values?.nRedBaseINSS && { nRedBaseINSS: values?.nRedBaseINSS ? Number(values.nRedBaseINSS) : 0 }),
    ...(values?.nRedBasePIS && { nRedBasePIS: values?.nRedBasePIS ? Number(values.nRedBasePIS) : 0 }),
    ...(values?.nRedBaseCOFINS && { nRedBaseCOFINS: values?.nRedBaseCOFINS ? Number(values.nRedBaseCOFINS) : 0 }),
    ...(values?.cRetISS && { cRetISS: values?.cRetISS }),
    ...(values?.cRetPIS && { cRetPIS: values?.cRetPIS }),
    ...(values?.cRetCOFINS && { cRetCOFINS: values?.cRetCOFINS }),
    ...(values?.cRetCSLL && { cRetCSLL: values?.cRetCSLL }),
    ...(values?.cRetIR && { cRetIR: values?.cRetIR }),
    ...(values?.cRetINSS && { cRetINSS: values?.cRetINSS }),
    ...(values?.lDeduzISS && { lDeduzISS: !!values?.lDeduzISS }),
  }
}

export const translatePlan = (value) => {
  switch (value) {
    case 'collab':
      return 'Colaborador'
    case 'coupon':
      return 'Cupom'
    case 'client':
      return 'Cliente'
    default:
      return value
  }
}
