import { Icon } from '../../../../components/icons'
import { Button } from '../../../../components/ui/button'
import { Modal } from '../../../../components/ui/modal'
import { ValidationPositionModalForm } from '../_components/validationPositionModalForm'
import { useValidationPositionModal } from '../_hooks/useValidationPositionModal'

export const ValidationPositionModal = ({
  active,
  setActive,
  HandleResendAttachment,
  HandleApprovePosition,
  HandleRefusePosition,
  HandleUndoPosition,
}) => {
  const { loading, formContext } = useValidationPositionModal()
  const allConsentimentsValid = formContext?.newValidationData?.consentimentsUser.every((item) => item?.status)
  const allDocumentsValid = formContext?.newValidationData?.documentationUser.every((item) => item?.status)

  return (
    <form>
      <Modal.Root active={active}>
        <Modal.Contents witdh={1200}>
          <Modal.Header>
            <p className="modal-card-title" style={{ marginBottom: -15 }}>
              Validação do Cargo
            </p>
            <Modal.HeaderClose setActive={setActive}></Modal.HeaderClose>
          </Modal.Header>
          <Modal.Body>
            <ValidationPositionModalForm
              formContext={formContext?.newValidationData}
              documentsList={formContext?.newValidationData?.documentationUser}
              consentsList={formContext?.newValidationData?.consentimentsUser}
              loading={loading}
              HandleResendAttachment={HandleResendAttachment}
            />
          </Modal.Body>
          <Modal.Footer className="is-justify-content-end">
            {/* Motivo geral para todos os contatos de emergência e itens do objeto */}

            {formContext?.newValidationData?.status === 1 ? (
              <Button.Bt
                type="button"
                colorText="has-text-white"
                color="is-success"
                title="Favoritar"
                disabled
                loading={loading}
              >
                <Icon size={20}>check</Icon>
                Aprovado
              </Button.Bt>
            ) : (
              <Button.Bt
                type="button"
                colorText="has-text-white"
                color="is-success"
                title="Favoritar"
                action={() => HandleApprovePosition(formContext.newValidationData.id)}
                disabled={!allConsentimentsValid || !allDocumentsValid}
                loading={loading}
              >
                <Icon size={20}>check</Icon>
                Aprovar
              </Button.Bt>
            )}

            {formContext?.newValidationData?.status === 1 ? (
              <Button.Bt
                type="button"
                colorText="has-text-white"
                color="is-danger"
                title="Favoritar"
                action={() => HandleUndoPosition(formContext.newValidationData.id)}
                // disabled={modalCollaboratorSubmitDisabled}
                loading={loading}
              >
                <Icon className="mr-1" size={20}>
                  x
                </Icon>
                Desfazer
              </Button.Bt>
            ) : (
              <Button.Bt
                type="button"
                colorText="has-text-white"
                color="is-danger"
                title="Favoritar"
                action={() => HandleRefusePosition(formContext.newValidationData.id)}
                // disabled={modalCollaboratorSubmitDisabled}
                loading={loading}
              >
                <Icon className="mr-1" size={20}>
                  x
                </Icon>
                Recusar
              </Button.Bt>
            )}
          </Modal.Footer>
        </Modal.Contents>
      </Modal.Root>
    </form>
  )
}
