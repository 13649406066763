import React from 'react'
import { CalendarX, CalendarCheck, ClipboardPenLine, Star, Calendar1, Megaphone, ChevronRight } from 'lucide-react'

import { Page } from '../../../../components/layouts/page'
import { Column } from '../../../../components/layouts/columns'
import { Button } from '../../../../components/ui/button'
import { phraseGenerator } from '../../../../utils/phraseGenerator'
import CardStatusHome from './_components/cardStatusHome'
import CardTitleSection from './_components/titleCardSection'
import CardEvent from './_components/cardEvent'
import CardNotification from './_components/cardNotification'
import { useClientHome } from './_hooks/useClientHome'
import { Path } from '../../../../router/paths'
import {
  ConvertTimestampToDateTime,
  DataComTraçoParaDataComBarra,
  DateNow,
  TimestampNow,
} from '../../../../utils/dates'
import { Icon } from '../../../../components/icons'
import { TransformationforMask } from '../../../../utils/masks'

export const ClientHome = () => {
  const { user, dtFreeTrialCheck, dataHomePage } = useClientHome()

  return (
    <>
      <Page.Root>
        <Page.Content
          style={{ padding: '7rem 0 7rem 0', display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
          className="has-text-centered"
        >
          <Column.Root>
            <Column.Content className="has-text-left">
              <p className="subtitle is-size-3">
                Bem-vindo,{' '}
                {`${TransformationforMask(user?.user?.name || user?.user?.nome, 'title').split(' ')[0]} ${TransformationforMask(
                  user?.user?.name || user?.user?.nome,
                  'title',
                )
                  .split(' ')
                  .slice(-1)}`}
                !
              </p>
              <p
                style={{
                  color: '#afafaf',
                }}
              >
                {phraseGenerator()}
              </p>
            </Column.Content>
          </Column.Root>
          <Column.Root style={{ display: 'flex', gap: '5rem' }} className="gap-2">
            <CardStatusHome title={'Eventos Ativos'} value={dataHomePage.openEvents}>
              <CalendarCheck />
            </CardStatusHome>

            <CardStatusHome title={'Eventos Fechados'} value={dataHomePage.closedEvents}>
              <CalendarX />
            </CardStatusHome>

            <CardStatusHome title={'Equipe de Favoritados'} value={dataHomePage.favoriteTeam}>
              <Star />
            </CardStatusHome>

            <CardStatusHome title={'Candidaturas Pendentes'} value={dataHomePage.pendingCandidacy}>
              <ClipboardPenLine />
            </CardStatusHome>
          </Column.Root>

          <Column.Root style={{ display: 'flex', gap: '1.5rem' }}>
            <Column.Content
              className="card"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: '1rem',
              }}
            >
              <CardTitleSection title={'Vagas em Aberto'}>
                <Calendar1 size={30} />
              </CardTitleSection>
              <div
                style={{
                  padding: '1rem 0 1rem 0',
                  maxHeight: 500,
                  overflow: 'auto',
                }}
              >
                {dataHomePage?.events !== undefined ? (
                  (dataHomePage?.events || []).map((event, index) => (
                    <CardEvent
                      key={`event-${index}`}
                      eventName={event.eventName}
                      date={event.date}
                      status={event.status}
                      staff={event.staff}
                      jobName={event.jobName}
                      idEvent={event.idEvent}
                      CandidatePending={event.candidatesPending}
                    />
                  ))
                ) : (
                  <Icon>loading</Icon>
                )}
              </div>
              <Button.A link={Path.ClientEvents}>
                Visualizar Todos os Eventos <ChevronRight />
              </Button.A>
            </Column.Content>

            <Column.Content
              className="card"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: '1rem',
              }}
            >
              <CardTitleSection title={'Comunicado'}>
                <Megaphone size={30} />
              </CardTitleSection>

              {TimestampNow() < dtFreeTrialCheck && (
                <CardNotification type={'warning'} date={DataComTraçoParaDataComBarra(DateNow())}>
                  {`Seu período de teste expira em ${ConvertTimestampToDateTime(dtFreeTrialCheck).split(' ')[0]}.`}
                </CardNotification>
              )}

              <div
                style={{
                  padding: '1rem 0 1rem 0',
                  maxHeight: 500,
                  overflow: 'auto',
                }}
              >
                {/* <CardNotification type={'success'} date={'12/01/2025'}>
                  Você está em período de teste, que acabará em: 12/03/2025
                </CardNotification>
                <CardNotification type={'warning'} date={'12/01/2025'}>
                  Você está em período de teste, que acabará em: 12/03/2025
                </CardNotification>
                <CardNotification type={''} date={'12/01/2025'}>
                  Você está em período de teste, que acabará em: 12/03/2025
                </CardNotification> */}
              </div>
            </Column.Content>
          </Column.Root>
        </Page.Content>
      </Page.Root>
    </>
  )
}
