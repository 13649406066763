import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { useGlobalContext } from '../../../context/ContextGlobal'
import { TryCatch } from '../../../utils/general'
import { getSubDocument } from '../../../firebase/firestore'
import useCollaborator from './useCollaborator'

const useCollaboratorSelectionHistory = () => {
  const { user } = useCollaborator()

  const { setAlerts, setLoading, loading, formContext, setFormContext } = useGlobalContext()

  const { token, paymentId } = useParams()

  const uid = user.id

  // const navigate = useNavigate()

  const getForm = async () => {
    const getPayment = await getSubDocument('colaboradores', uid, 'payments', paymentId)

    setFormContext({ ...getPayment })
  }

  const FetchGetForm = async () => {
    await TryCatch(getForm, setLoading, setAlerts)
  }

  useEffect(() => {
    FetchGetForm()
  }, [])

  return { token, loading, FetchGetForm, formContext }
}

export default useCollaboratorSelectionHistory
