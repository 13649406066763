import { useEffect } from 'react'

import { useAdminClients } from './useAdminClients'
import { useGlobalContext } from '../../../../context/ContextGlobal'
import { dataComTraçoParaTimestamp, DateTimeNow } from '../../../../utils/dates'
import { addSubDocument, generateIdDocument, getDocumentsWithQuery, setDocument } from '../../../../firebase/firestore'
import { Path } from '../../../../router/paths'
import { TryCatch } from '../../../../utils/general'
import { createSchemaClient, createSchemaClientPlans } from '../_utils'
import { createSchemaClientOmie } from '../../../../api/omie/schemas'
import { ClientOmie } from '../../../../api/omie'

export const useAddClients = () => {
  const { loading, setLoading, infosContext, user, formContext, setFormContext, setAlerts } = useGlobalContext()

  const { FetchClientInfos, navigate } = useAdminClients()

  const addNewClient = async () => {
    try {
      if ((formContext?.plans || []).length === 0) {
        return setAlerts((prev) => [
          ...prev,
          {
            title: 'Planos obrigatórios',
            msg: 'A lista de planos está vazia, adicione um plano antes de salvar o cliente.',
            type: 'error',
          },
        ])
      }
      const newClient = createSchemaClient(formContext)

      const clientExist = await getDocumentsWithQuery('clientes', 'cpfCnpj', '==', newClient.cpfCnpj)
      let clientId = null
      if (clientExist.length === 0) {
        // console.log('existe: ', clientExist)
        // gera um id para o cliente
        clientId = await generateIdDocument('clientes')
      } else {
        // se existe ele coleta o id
        clientId = clientExist[0].id
      }
      if (!clientId) return
      // console.log('clientId: ', clientId)

      // para criar um cliente omie, ele precisa do id de integração
      const newClienteOmie = createSchemaClientOmie({ ...formContext, id: clientId, tags: 'Cliente' })

      // console.log(newClienteOmie)
      let createClientOmie
      let cCodClientOmie
      try {
        // tenta criar um cliente na omie
        createClientOmie = await ClientOmie().incluirCliente([newClienteOmie])
      } catch (error) {
        // se eele retornar que ja existe e é um novo cadastro, então segue o fluxo, se não da erro
        if (error.message.includes('102') && clientExist.length === 0) {
          cCodClientOmie = String(error.message).split('nCod')[1].match(/\d+/)[0]
        } else if (error.message.includes('101') && clientExist.length === 0) {
          const errorMessage = String(error.message).toLowerCase()
          // console.log(errorMessage)
          cCodClientOmie = errorMessage.match(/com o id \[([^\]]+)\]/)[1]
          clientId = errorMessage.match(/código de integração \[([^\]]+)\]/)[1]
          // console.log(cCodClientOmie)
          // console.log(clientId)
        } else {
          throw new Error(error.message)
        }
      }
      // se não deu error, então ele pega do response
      if (createClientOmie) {
        cCodClientOmie = createClientOmie.codigo_cliente_omie
      }

      // console.log(cCodClientOmie)
      const newCliente = {
        ...createSchemaClient(formContext),
        freeTrial: formContext?.freeTrial,
        firstPayment: formContext?.firstPayment,
        nextPayment: dataComTraçoParaTimestamp(formContext?.firstPayment),
        lastPayment: 0,
        dtFreeTrial: formContext?.dtFreeTrial ?? '',
        dt_created: DateTimeNow(),
        createdBy: user.user.nome,
        cCodClientOmie,
        status: 'disabled',
      }

      // console.log(newCliente)
      await setDocument('clientes', clientId, newCliente)

      const promises = formContext.plans.map(async (plan) => {
        const newPlan = createSchemaClientPlans({ ...plan, modifieldBy: user.user.nome })
        // console.log(newPlan)
        await addSubDocument('clientes', clientId, 'plans', newPlan)
      })
      Promise.all(promises)
        .finally(() => {
          setAlerts((prev) => [
            ...prev,
            {
              title: 'Cliente Adicionado',
              msg: `Cliente ${newCliente.razaoSocial} adicionado com sucesso`,
              type: 'success',
            },
          ])
          navigate(Path.AdminClientEdit(clientId))
        })
        .catch((error) => {
          // console.log(JSON.stringify(error.message))
          throw new Error(error.message)
        })
    } catch (error) {
      // console.log(JSON.stringify(error.message))
      throw new Error(error.message)
    }
  }

  const FetchAddNewClient = async () => {
    TryCatch(addNewClient, setLoading, setAlerts)
  }

  useEffect(() => {
    FetchClientInfos()
  }, [])

  return { setAlerts, loading, setLoading, infosContext, formContext, setFormContext, FetchAddNewClient }
}
