import React from 'react'

import { Column } from '../../../../components/layouts/columns'
import { Button } from '../../../../components/ui/button'
import { Input } from '../../../../components/ui/input'
import { Select } from '../../../../components/ui/select'
import { useCollaboratorsForm } from '../_hooks/useCollaboratorsForm'
import { Icon } from '../../../../components/icons'
import { TransformationforMask } from '../../../../utils/masks'
import { File } from '../../../../components/ui/file'

export const CollaboratorsForm = () => {
  const {
    loading,
    formContext,
    setFormContext,
    // handleOpenValidateModal,
    // submitFormDisabled,
    // handleValidateCollaboratorCheckbox,
    emergencyContactsGroup,
    emergencyContactsGroupCount,
    handleAddEmergencyContact,
    handleDeleteEmergencyContact,
    handleEmergencyFieldsChange,
    handleCPFField,
    handleZipcodeField,
    // HandleApproveCollab,
    isBlobOrFile,
  } = useCollaboratorsForm()

  // console.log(formContext)
  // const enableRefuseButton1 = Object.keys(formContext).includes('inEdition')

  function convertDateToInputFormat(dateStr) {
    if (!dateStr) return '' // Evita erro caso o valor seja undefined
    const [day, month, year] = dateStr.split('/')
    return `${year}-${month}-${day}`
  }

  function handleDateChange(value) {
    const [year, month, day] = value.split('-') // Pegando do formato yyyy-MM-dd
    setFormContext((prev) => ({ ...prev, dtNascimento: `${day}/${month}/${year}` })) // Convertendo para dd/MM/yyyy
  }

  return (
    <>
      {/* <ValidateCollaboratorModal active={openModal} setActive={handleOpenValidateModal} /> */}
      <Column.Root>
        <Column.Content className=" is-one-quarter has-text-centered">
          {/* <Column.Content className="has-text-centered is-5"> */}
          <p>Foto de Perfil</p>

          {formContext?.photoUrl ? (
            typeof formContext?.photoUrl === 'string' && formContext?.photoUrl.includes('https://') ? (
              <img
                alt="url_foto"
                src={formContext?.photoUrl}
                style={{
                  width: '300px',
                  height: '300px',
                  objectFit: 'cover',
                  borderRadius: '50%',
                }}
                className="has-shadow"
              />
            ) : isBlobOrFile ? (
              <img
                alt="photoUrl"
                src={URL.createObjectURL(formContext?.photoUrl)}
                style={{ width: '50%', borderRadius: '50%' }}
                className="has-shadow"
              />
            ) : (
              <></> // Caso o tipo não seja suportado
            )
          ) : (
            <></>
          )}

          {!formContext?.photoUrl === '' && !formContext?.photoUrl.includes('https://') && (
            <File.Root className="mt-5">
              <File.Input setFile={setFormContext} field={'url_foto'} accepts="image/*" />
              <File.Contents>
                <File.Label>
                  {formContext?.photoUrl?.name
                    ? String(formContext?.photoUrl?.name).slice(0, 20).replace('.png', '') +
                      '.' +
                      String(formContext?.photoUrl?.type).replace('image/', '')
                    : 'Escolha uma imagem...'}
                </File.Label>
              </File.Contents>
            </File.Root>
          )}
          {/* </Column.Content> */}
        </Column.Content>
        <Column.Content>
          <div className="field mt-6">
            <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="nome">
              Nome Completo
            </Input.Label>
            <div className="is-flex w-100 gap-2">
              <Input.Prompt
                id="nome"
                className={`is-rounded `}
                value={formContext}
                setValue={setFormContext}
                field={'nome'}
                required={true}
                disabled={false}
              ></Input.Prompt>
            </div>
          </div>

          <Column.Root className="mt-3">
            <Column.Content>
              <div className="field">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="nomeSocial">
                  Nome Social
                </Input.Label>
                <div className="is-flex w-100 gap-2">
                  <Input.Prompt
                    id="nomeSocial"
                    className={`is-rounded `}
                    value={formContext}
                    setValue={setFormContext}
                    field={'nomeSocial'}
                    required={true}
                    disabled={false}
                  >
                    {formContext?.nomeSocial}
                  </Input.Prompt>
                </div>
              </div>
            </Column.Content>
            <Column.Content>
              <div className="field">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="cpf">
                  CPF
                </Input.Label>
                <div className="is-flex w-100 gap-2">
                  <Input.Prompt
                    id="cpf"
                    className={`is-rounded `}
                    value={formContext.cpf}
                    setValue={(value) => handleCPFField(value)}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </div>
              </div>
            </Column.Content>
            <Column.Content>
              <div className="field">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="estadoCivil">
                  Estado Civil
                </Input.Label>
                <div className="is-flex w-100 gap-2">
                  <Select.Root>
                    <Select.Content
                      id="estadoCivil"
                      color={formContext?.inEdition?.civilStatus ? 'is-danger' : 'is-grey'}
                      className="is-fullwidth is-rounded"
                      value={formContext}
                      setValue={setFormContext}
                      field={'estadoCivil'}
                      disabled={false}
                      required={true}
                    >
                      <Select.Option value={''} title={'Selecione uma opção'} selected={true} disabled={true} />
                      <Select.Option value={'Solteiro(a)'} title={'Solteiro(a)'} />
                      <Select.Option value={'Casado(a)'} title={'Casado(a)'} />
                      <Select.Option value={'Divorcidado(a)'} title={'Divorcidado(a)'} />
                      <Select.Option value={'Viúvo(a)'} title={'Viúvo(a)'} />
                    </Select.Content>
                  </Select.Root>
                </div>
              </div>
            </Column.Content>
          </Column.Root>

          <Column.Root className="mt-3">
            <Column.Content>
              <div className="field">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="dtNascimento">
                  Data de nascimento
                </Input.Label>
                <div className="is-flex w-100 gap-2">
                  <Input.Prompt
                    id="dtNascimento"
                    className={`is-rounded `}
                    value={convertDateToInputFormat(formContext?.dtNascimento)}
                    setValue={(value) => handleDateChange(value)}
                    required={true}
                    disabled={false}
                    type="date"
                  ></Input.Prompt>
                </div>
              </div>
            </Column.Content>
            <Column.Content>
              <div className="field">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="documento">
                  Documento
                </Input.Label>
                <div className="is-flex w-100 gap-2">
                  <Input.Prompt
                    id="documento"
                    className={`is-rounded `}
                    value={formContext}
                    setValue={setFormContext}
                    field={'documento'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </div>
              </div>
            </Column.Content>
            <Column.Content>
              <div className="field">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="tipoDocumento">
                  Tipo de Documento
                </Input.Label>
                <div className="is-flex w-100 gap-2">
                  <Select.Root>
                    <Select.Content
                      id="tipoDocumento"
                      color={formContext?.inEdition?.docType ? 'is-danger' : 'is-grey'}
                      className="is-fullwidth is-rounded"
                      value={formContext}
                      setValue={setFormContext}
                      field={'tipoDocumento'}
                      required={true}
                      disabled={false}
                    >
                      <Select.Option value={''} title={'Selecione uma opção'} selected={true} disabled={true} />
                      <Select.Option
                        value={'RNE ou CRNM'}
                        title={'Documento de Identidade para Estrangeiros (RNE ou CRNM)'}
                      />
                      <Select.Option value={'CNH'} title={'CNH (Carteira Nacional de Habilitação)'} />
                      <Select.Option value={'RG'} title={'RG (Registro Geral)'} />
                    </Select.Content>
                  </Select.Root>
                </div>
              </div>
            </Column.Content>
          </Column.Root>
        </Column.Content>
      </Column.Root>
      <Column.Root>
        <Column.Content className="is-full">
          <Column.Root className="mt-3">
            <Column.Content>
              <h2 className="subtitle is-size-4">Endereço</h2>
            </Column.Content>
          </Column.Root>

          <Column.Root className="mt-3">
            <Column.Content>
              <div className="field">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="cep">
                  CEP
                </Input.Label>
                <div className="is-flex w-100 gap-2">
                  <Input.Prompt
                    id="cep"
                    className={`is-rounded `}
                    value={formContext.cep}
                    setValue={(value) => handleZipcodeField(value)}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </div>
              </div>
            </Column.Content>
            <Column.Content>
              <div className="field">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="numeroEndereco">
                  Número
                </Input.Label>
                <div className="is-flex w-100 gap-2">
                  <Input.Prompt
                    id="numeroEndereco"
                    className={`is-rounded `}
                    value={formContext}
                    setValue={setFormContext}
                    field={'numeroEndereco'}
                    maskSetValue="numero"
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </div>
              </div>
            </Column.Content>
            <Column.Content>
              <div className="field">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="bairro">
                  Bairro
                </Input.Label>
                <div className="is-flex w-100 gap-2">
                  <Input.Prompt
                    id="bairro"
                    className={`is-rounded `}
                    value={formContext}
                    setValue={setFormContext}
                    field={'bairro'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </div>
              </div>
            </Column.Content>
          </Column.Root>

          <Column.Root className="mt-3">
            <Column.Content>
              <div className="field">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="logradouro">
                  Logradouro
                </Input.Label>
                <div className="is-flex w-100 gap-2">
                  <Input.Prompt
                    id="logradouro"
                    className={`is-rounded `}
                    value={formContext}
                    setValue={setFormContext}
                    field={'logradouro'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </div>
              </div>
            </Column.Content>
            <Column.Content>
              <div className="field">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="complemento">
                  Complemento
                </Input.Label>
                <div className="is-flex w-100 gap-2">
                  <Input.Prompt
                    id="complemento"
                    className={`is-rounded `}
                    value={formContext}
                    setValue={setFormContext}
                    field={'complemento'}
                    required={false}
                    disabled={false}
                  ></Input.Prompt>
                </div>
              </div>
            </Column.Content>
            <Column.Content>
              <div className="field">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="cidade">
                  Cidade
                </Input.Label>
                <div className="is-flex w-100 gap-2">
                  <Input.Prompt
                    id="cidade"
                    className={`is-rounded `}
                    value={formContext}
                    setValue={setFormContext}
                    field={'cidade'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </div>
              </div>
            </Column.Content>
            <Column.Content>
              <div className="field">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="cidade">
                  UF
                </Input.Label>
                <div className="is-flex w-100 gap-2">
                  <Select.Root className={`is-rounded `}>
                    <Select.Content
                      color={formContext?.inEdition?.state ? 'is-danger' : 'is-grey'}
                      className="is-fullwidth is-rounded"
                      value={formContext}
                      setValue={setFormContext}
                      field={'uf'}
                      disabled={false}
                      required={true}
                    >
                      <Select.Option value={''} title={'Selecione uma opção'} selected={true} disabled={true} />
                      <Select.Option value={'AC'} title={'AC'} />
                      <Select.Option value={'AL'} title={'AL'} />
                      <Select.Option value={'AP'} title={'AP'} />
                      <Select.Option value={'AM'} title={'AM'} />
                      <Select.Option value={'BA'} title={'BA'} />
                      <Select.Option value={'CE'} title={'CE'} />
                      <Select.Option value={'DF'} title={'DF'} />
                      <Select.Option value={'ES'} title={'ES'} />
                      <Select.Option value={'GO'} title={'GO'} />
                      <Select.Option value={'MA'} title={'MA'} />
                      <Select.Option value={'MT'} title={'MT'} />
                      <Select.Option value={'MS'} title={'MS'} />
                      <Select.Option value={'MG'} title={'MG'} />
                      <Select.Option value={'PA'} title={'PA'} />
                      <Select.Option value={'PB'} title={'PB'} />
                      <Select.Option value={'PR'} title={'PR'} />
                      <Select.Option value={'PE'} title={'PE'} />
                      <Select.Option value={'PI'} title={'PI'} />
                      <Select.Option value={'RJ'} title={'RJ'} />
                      <Select.Option value={'RN'} title={'RN'} />
                      <Select.Option value={'RS'} title={'RS'} />
                      <Select.Option value={'RO'} title={'RO'} />
                      <Select.Option value={'RR'} title={'RR'} />
                      <Select.Option value={'SC'} title={'SC'} />
                      <Select.Option value={'SP'} title={'SP'} />
                      <Select.Option value={'SE'} title={'SE'} />
                      <Select.Option value={'TO'} title={'TO'} />
                    </Select.Content>
                  </Select.Root>
                </div>
              </div>
            </Column.Content>
          </Column.Root>

          <Column.Root className="mt-3">
            <Column.Content>
              <h2 className="subtitle is-size-4">Contato</h2>
            </Column.Content>
          </Column.Root>

          <Column.Root className="mt-3">
            <Column.Content>
              <div className="field">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="celular">
                  Celular
                </Input.Label>
                <div className="is-flex w-100 gap-2">
                  <Input.Prompt
                    id="celular"
                    className={`is-rounded `}
                    value={formContext}
                    setValue={setFormContext}
                    field={'celular'}
                    maskSetValue={'tel'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </div>
              </div>
            </Column.Content>
            <Column.Content>
              <div className="field">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="email">
                  E-mail
                </Input.Label>
                <div className="w-100 gap-2">
                  <Input.Prompt
                    id="email"
                    className={`is-rounded `}
                    value={formContext}
                    setValue={setFormContext}
                    field={'email'}
                    required={true}
                    disabled={formContext?.validate}
                  ></Input.Prompt>
                  {formContext?.validate && (
                    <p className="has-text-danger is-size-7 ml-2">
                      Não é possivel validar/alterar o e-mail, pois ele é chave principal do cadastro do colaborador
                    </p>
                  )}
                </div>
              </div>
            </Column.Content>
          </Column.Root>

          <Column.Root className="mt-3">
            <Column.Content>
              <h2 className="subtitle is-size-4">Dados Bancários</h2>
            </Column.Content>
          </Column.Root>

          <Column.Root className="mt-3">
            <Column.Content>
              <div className="field">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="conta">
                  Conta
                </Input.Label>
                <div className="is-flex w-100 gap-2">
                  <Input.Prompt
                    id="conta"
                    className={`is-rounded `}
                    value={formContext}
                    setValue={setFormContext}
                    field={'conta'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </div>
              </div>
            </Column.Content>
            <Column.Content>
              <div className="field">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="agencia">
                  Agência
                </Input.Label>
                <div className="is-flex w-100 gap-2">
                  <Input.Prompt
                    id="agencia"
                    className={`is-rounded `}
                    value={formContext}
                    setValue={setFormContext}
                    field={'agencia'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </div>
              </div>
            </Column.Content>
            <Column.Content>
              <div className="field">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="banco">
                  Banco
                </Input.Label>
                <div className="is-flex w-100 gap-2">
                  <Input.Prompt
                    id="banco"
                    className={`is-rounded `}
                    value={formContext}
                    setValue={setFormContext}
                    field={'banco'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </div>
              </div>
            </Column.Content>
            <Column.Content>
              <div className="field">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="pix">
                  PIX
                </Input.Label>
                <div className="is-flex w-100 gap-2">
                  <Input.Prompt
                    id="pix"
                    className={`is-rounded `}
                    value={formContext}
                    setValue={setFormContext}
                    field={'pix'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </div>
              </div>
            </Column.Content>
          </Column.Root>

          {!formContext?.validate && (
            <>
              <Column.Root className="mt-3">
                <Column.Content>
                  <h2 className="subtitle is-size-4">Contatos de Emergência</h2>
                </Column.Content>
              </Column.Root>

              <Column.Root>
                <Column.Content>
                  {(emergencyContactsGroup || []).map((emergencyContactGroup, index) => (
                    <Column.Root className="mt-3" key={index}>
                      <Column.Content>
                        <div className="field">
                          <Input.Label
                            className="has-text-grey pl-5"
                            style={{ marginBottom: 0 }}
                            htmlFor={'nomeContatoEmergencia-' + index}
                          >
                            Nome
                          </Input.Label>
                          <div className="is-flex w-100 gap-2">
                            <input
                              id={'nomeContatoEmergencia-' + index}
                              className={`input is-rounded `}
                              type={'text'}
                              name={'name'}
                              value={emergencyContactGroup.name}
                              onChange={(e) => handleEmergencyFieldsChange(index, e)}
                              required={true}
                            />
                          </div>
                        </div>
                      </Column.Content>
                      <Column.Content>
                        <div className="field">
                          <Input.Label
                            className="has-text-grey pl-5"
                            style={{ marginBottom: 0 }}
                            htmlFor={'parentescoContatoEmergencia-' + index}
                          >
                            Parentesco
                          </Input.Label>
                          <div className="is-flex w-100 gap-2">
                            <Select.Root>
                              <div className={`select is-rounded`}>
                                <select
                                  id={'parentescoContatoEmergencia-' + index}
                                  value={emergencyContactGroup.kinship}
                                  name={'kinship'}
                                  onChange={(e) => handleEmergencyFieldsChange(index, e)}
                                  required={true}
                                >
                                  <Select.Option
                                    value={''}
                                    title={'Selecione uma opção'}
                                    selected={false}
                                    disabled={true}
                                  />
                                  <Select.Option value={'Pai'} title={'Pai'} />
                                  <Select.Option value={'Mãe'} title={'Mãe'} />
                                  <Select.Option value={'Filho(a)'} title={'Filho(a)'} />
                                  <Select.Option value={'Irmão(ã)'} title={'Irmão(ã)'} />
                                  <Select.Option value={'Avô(ó)'} title={'Avô(ó)'} />
                                  <Select.Option value={'Neto(a)'} title={'Neto(a)'} />
                                  <Select.Option value={'Tio(a)'} title={'Tio(a)'} />
                                  <Select.Option value={'Sobrinho(a)'} title={'Sobrinho(a)'} />
                                  <Select.Option value={'Primo(a)'} title={'Primo(a)'} />
                                  <Select.Option value={'Padrasto(a)'} title={'Padrasto(a)'} />
                                  <Select.Option value={'Enteado(a)'} title={'Enteado(a)'} />
                                  <Select.Option value={'Cunhado(a)'} title={'Cunhado(a)'} />
                                  <Select.Option value={'Sogro(a)'} title={'Sogro(a)'} />
                                  <Select.Option value={'Genro/Nora'} title={'Genro/Nora'} />
                                  <Select.Option value={'Bisavô(ó)'} title={'Bisavô(ó)'} />
                                  <Select.Option value={'Bisneto(a)'} title={'Bisneto(a)'} />
                                  <Select.Option value={'Padrinho/Madrinha'} title={'Padrinho/Madrinha'} />
                                  <Select.Option value={'Afilhado(a)'} title={'Afilhado(a)'} />
                                  <Select.Option value={'Cônjuge'} title={'Cônjuge'} />
                                  <Select.Option value={'Noivo(a)'} title={'Noivo(a)'} />
                                </select>
                              </div>
                              {/* <Select.Content
                            id={'parentescoContatoEmergencia-' + index}
                            className="is-rounded"
                            value={emergencyContactsGroup}
                            setValue={setEmergencyContactsGroup}
                            field={'parentescoContatoEmergencia-' + index}
                            disabled={false}
                          ></Select.Content> */}
                            </Select.Root>
                          </div>
                        </div>
                      </Column.Content>
                      <Column.Content>
                        <div className="field">
                          <Input.Label
                            className="has-text-grey pl-5"
                            style={{ marginBottom: 0 }}
                            htmlFor={'celularContatoEmergencia-' + index}
                          >
                            Celular
                          </Input.Label>
                          <div className="is-flex w-100 gap-2">
                            <input
                              id={'celularContatoEmergencia-' + index}
                              className={`input is-rounded`}
                              type={'text'}
                              name={'phone'}
                              value={TransformationforMask(emergencyContactGroup.phone, 'tel')}
                              onChange={(e) => handleEmergencyFieldsChange(index, e)}
                              required={true}
                            />
                          </div>
                        </div>
                      </Column.Content>
                      <Column.Content className="direction-rtl">
                        {emergencyContactsGroupCount > 1 && (
                          <Button.Bt
                            className=""
                            color="is-danger"
                            size={'is-small'}
                            colorText=""
                            action={() => handleDeleteEmergencyContact(index)}
                            loading={loading}
                          >
                            <Icon size={16}>trash</Icon>
                          </Button.Bt>
                        )}
                      </Column.Content>
                    </Column.Root>
                  ))}
                </Column.Content>
              </Column.Root>

              <Column.Root>
                <Column.Content className="is-flex is-justify-content-flex-end">
                  <Button.Bt
                    className="is-rounded"
                    color="is-success"
                    colorText="has-text-white"
                    action={handleAddEmergencyContact}
                    loading={loading}
                    disabled={
                      emergencyContactsGroup.length > 0 &&
                      emergencyContactsGroupCount < 2 &&
                      emergencyContactsGroup[0].name === ''
                    }
                  >
                    Adicionar Novo Contato
                  </Button.Bt>
                </Column.Content>
              </Column.Root>
            </>
          )}
        </Column.Content>
      </Column.Root>
    </>
  )
}
