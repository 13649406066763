import React from 'react'
import { Outlet, useLocation, useParams } from 'react-router-dom'

import { Page } from '../../../../components/layouts/page'
import { SideBar } from '../../../../components/layouts/sidebar'
import { Icon } from '../../../../components/icons'
import { Path } from '../../../../router/paths'

export const ClientLayoutSideBarClient = () => {
  const { id } = useParams()
  const location = useLocation()

  return (
    <>
      <Page.Content
        className=""
        style={{
          marginTop: 80,
          marginLeft: 80,
          marginRight: 10,
          marginBottom: 500,
        }}
      >
        <Outlet />
      </Page.Content>

      <Page.SideBar>
        <SideBar.Root>
          <SideBar.List>
            <SideBar.Item title={'Voltar'} link={Path.AClients} activate={location.pathname === Path.AClients}>
              <Icon size={30}>arrowLeft</Icon>
            </SideBar.Item>
            <SideBar.Item
              title={'Informações'}
              link={Path.AdminClientEdit(id)}
              activate={location.pathname === Path.AdminClientEdit(id)}
            >
              <Icon size={30}>info</Icon>
            </SideBar.Item>
            {/* <SideBar.Item
              title={'Faturamentos'}
              link={Path.AdminClientBilling(id)}
              activate={location.pathname === Path.AdminClientBilling(id)}
            >
              <Icon size={30}>bill</Icon>
            </SideBar.Item> */}
            <SideBar.Item
              title={'Pagamentos'}
              link={Path.AdminClientPayments(id)}
              activate={location.pathname === Path.AdminClientPayments(id)}
            >
              <Icon size={30}>dollar</Icon>
            </SideBar.Item>

            <SideBar.Space size={100}></SideBar.Space>
          </SideBar.List>
        </SideBar.Root>
      </Page.SideBar>

      {/* Componente do REACT DOM que move a pagina para cima ao trocar de paginas, só funciona para as paginas que estão em children  */}
    </>
  )
}
