import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { onAuthStateChanged } from 'firebase/auth'

import { useGlobalContext } from '../../context/ContextGlobal'
import { Card } from '../../components/ui/card'
import { Icon } from '../../components/icons'
import { Button } from '../../components/ui/button'
import { logOut } from '../../firebase/auth'
import { Column } from '../../components/layouts/columns'
import { Pagination } from '../../components/ui/pagination'
import { RemoveItemSession, SetItemSession } from '../../utils/sessionStorage'
import { Path } from '../../router/paths'
import { auth } from '../../firebase'
import {
  getDocument,
  getDocumentsWithQuery,
  getSubDocument,
  updateDocument,
  updateSubDocument,
} from '../../firebase/firestore'
import { DateTimeNow, TimestampNow } from '../../utils/dates'

export const SelectAccount = () => {
  const navigate = useNavigate()
  const { user, setUser, setErrors, setLoading, loading } = useGlobalContext()

  const voltar = () => {
    logOut()
    RemoveItemSession({ key: 'user' })
    setUser([])
    navigate(Path.Login)
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setLoading(true)
      if (currentUser) {
        // console.log('auth: ', currentUser) // Usuário autenticado
        const uid = currentUser.uid
        // realiza uma busca em todos os cliente para saber se esta cadastrado
        const queryUsersTrue = await getDocumentsWithQuery('clientes', 'users.' + uid, '==', true, setErrors)
        const queryUsersFalse = await getDocumentsWithQuery('clientes', 'users.' + uid, '==', false, setErrors)

        const queryTotal = { ...queryUsersTrue, ...queryUsersFalse }
        const response = Object.values(queryTotal)

        if (response.length > 0) {
          // coleta os dados do cliente, retorna um array para um caso de um e-mail possuir mais de um cliente
          const clientDocs = await Promise.all(
            response.map(async (client) => {
              const response = await getDocument('clientes', client.id)
              return response
            }),
          )
          // coleta os dados do usuário dentro dos clientes
          const userDatas = await Promise.all(
            clientDocs.map(async (client) => {
              const getStatusUser = await getSubDocument('clientes', client.id, 'users', uid)
              const getSectors = await getSubDocument('clientes', client.id, 'sectors', getStatusUser?.sectorId)
              return {
                client,
                user: {
                  ...getStatusUser,
                  status: client.users[uid],
                  permissions: getSectors?.permissions,
                  auth: { emailVerified: currentUser.emailVerified },
                },
              } // se estiver tudo certo, ele retorna um array com os dados do usuario e de cada empresa.
            }),
          )

          SetItemSession({ key: 'user', value: userDatas })
          setUser(userDatas)
          setLoading(false)
        }
      } else {
        setLoading(false)
        voltar() // Nenhum usuário autenticado
      }
    })

    // Cleanup ao desmontar o componente
    return () => unsubscribe()
  }, [])

  const roleStatusClientPayment = (client) => {
    const dtLastPayment = client?.lastPayment || null // timestamp
    // console.log(client?.nextPayment)
    const dtNextPayment = client?.nextPayment ? client?.nextPayment : null // timestamp

    const hoje = TimestampNow()

    const pagamentoEmDia = hoje >= dtLastPayment && dtNextPayment >= hoje

    const statusSistema = pagamentoEmDia ? 'active' : 'suspended'

    return statusSistema
  }

  const selectionCompany = async (user) => {
    // console.log(user)
    const newStatus = user.client.id === 'keapstaff' ? 'active' : roleStatusClientPayment(user.client)
    // console.log(newStatus)
    await updateDocument('clientes', user.client.id, { status: newStatus })
    await updateSubDocument('clientes', user.client.id, 'users', user.user.id, {
      lastLogin: DateTimeNow(),
      authInfos: user.user.auth,
    })

    user.user.lastLogin = DateTimeNow()
    user.client.status = newStatus

    SetItemSession({ key: 'user', value: user })
    setUser(user)
    if (user?.user?.type === 'admin') {
      navigate(Path.AdministrationHome)
    } else if (user?.user?.type === 'client') {
      navigate(Path.ClientHome)
    }
  }

  return (
    <>
      <Column.Root className="is-mobile has-text-centered">
        <Column.Content>
          <Card.Root>
            <Card.Content>
              <Column.Root className="is-mobile" style={{ marginTop: -30, marginLeft: -30 }}>
                <Column.Content size="is-2">
                  <Pagination.Root align="is-left" className="is-rounded mt-2 is-mobile" size="is-medium">
                    <Pagination.List>
                      <Pagination.Bt action={voltar}>
                        <Icon size={20}>arrowLeft</Icon>
                      </Pagination.Bt>
                    </Pagination.List>
                  </Pagination.Root>
                </Column.Content>
              </Column.Root>
              {loading ? (
                <Column.Root className="is-mobile">
                  <Column.Content></Column.Content>
                  <Column.Content>
                    <Icon>loading</Icon>
                  </Column.Content>
                  <Column.Content></Column.Content>
                </Column.Root>
              ) : (
                <>
                  <Column.Root className="is-mobile has-text-centered" style={{ marginTop: -80 }}>
                    <Column.Content>
                      <p className="is-size-4 has-text-link"> Selecione uma empresa:</p>
                    </Column.Content>
                  </Column.Root>

                  <Column.Root className="is-mobile has-text-centered">
                    <Column.Content>
                      {Array.isArray(user) &&
                        user.map((u, index) => {
                          return (
                            <Button.Bt
                              action={() => {
                                selectionCompany(u)
                              }}
                              className="my-2 is-fullwidth"
                              key={`${index}_button`}
                              disabled={!u.user.status}
                            >
                              <Card.Root className="has-text-left " style={{ width: '100%' }} key={`${index}_cardroot`}>
                                <Card.Header title={String(u.client.nomeFantasia).slice(0, 20) + '...'} />
                                <Card.Content key={`${index}_cardcontent`}>
                                  <Icon className="mr-4" size={20} key={`${index}_iconuser`}>
                                    user
                                  </Icon>
                                  {u.user.nome}
                                  <br />
                                  <p className="has-text-grey-light">
                                    <Icon className="mr-4" size={20} key={`${index}suitcase`}>
                                      suitcase
                                    </Icon>
                                    {String(u.user.cargo)}
                                  </p>
                                  {u.user.type === 'admin' && (
                                    <p className="has-text-grey-light">
                                      <Icon className="mr-4" size={20} key={`${index}_icongear`}>
                                        gear
                                      </Icon>
                                      {String(u.user.type).toUpperCase()}
                                    </p>
                                  )}
                                  {!u.user.status && (
                                    <>
                                      <p className="has-text-danger">Acesso bloqueado pela empresa</p>
                                    </>
                                  )}
                                  {u.client.status === 'suspended' && (
                                    <>
                                      <p className="has-text-danger">{`Cliente Suspenso por débitos pedentes`}</p>
                                    </>
                                  )}
                                  {u.client.status === 'deleted' && (
                                    <>
                                      <p className="has-text-danger">{`Cliente Deletado em: ${u.client.dt_del_dis}`}</p>
                                      <p className="has-text-danger">{u.client.del_dis_reason}</p>
                                    </>
                                  )}
                                  {u.client.status === 'disabled' && (
                                    <>
                                      <p className="has-text-danger">{`Cliente Inativo desde: ${u.client.dt_del_dis}`}</p>
                                      <p className="has-text-danger">{u.client.del_dis_reason}</p>
                                    </>
                                  )}
                                </Card.Content>
                              </Card.Root>
                            </Button.Bt>
                          )
                        })}
                    </Column.Content>
                  </Column.Root>
                </>
              )}
            </Card.Content>
          </Card.Root>
        </Column.Content>
      </Column.Root>
    </>
  )
}
