import React from 'react'

import { Page } from '../../../components/layouts/page'
import { Icon } from '../../../components/icons'
import { Column } from '../../../components/layouts/columns'
import { Button } from '../../../components/ui/button'
import { Table } from '../../../components/ui/table'
import { Input } from '../../../components/ui/input'
// import { Path } from '../../../router/paths'
import { useCollaboratorPayments } from './_hooks/useCollaboratorPayments'
import { Form } from '../../../components/ui/form'
import { colorTagStatusPagamentos } from '../clients/_utils'
import { ModalCollaboratorHistoryBilling } from './_modal/modalCollaboratorHistoryBilling'
import { ConvertTimestampToDateTime } from '../../../utils/dates'

export const AdminCollaboratorPayments = () => {
  const {
    loading,
    search,
    // assinaturasDatas,
    formContext,
    setFormContext,
    // infosContext,
    setSearch,
    dataFiltered,
    FetchGetDatas,
    FetchGetInfos,
    // AddPlanInList,
    // DeletePlansOfList,
    // RemoveEndDate,
    // AddEndDate,
    dataContext,
    HandleEditClient,
    HandleSelectPayment,
    HandleCancelPayment,
    HandleConsultarOsOmie,
    HandleDeletePayment,
    HandleNewPayment,
    HandleReprocessPayment,
    modalHistory,
    setModalHistory,
  } = useCollaboratorPayments()

  return (
    <>
      <Page.Root className="is-fullheight-with-navbar mt-6 p-6">
        <ModalCollaboratorHistoryBilling
          active={modalHistory}
          setActive={setModalHistory}
          loading={loading}
          dataContext={dataContext.paymentSelected}
          key={'modalhistory'}
        />

        <Form.Root
          action={() => {
            HandleEditClient()
          }}
        >
          {/* Infomações dos planos */}
          <Column.Root className="mt-6 pt-5">
            <Column.Root>
              <Column.Content className="my-5">
                <p className="subtitle is-size-4">
                  <Icon size={30} className={'mr-3'}>
                    bill
                  </Icon>
                  Área Financeira do Staff
                </p>
              </Column.Content>
            </Column.Root>
          </Column.Root>

          {/* Lista de Assinaturas do cliente */}
          {/* <Column.Root className="is-mobile mr-6 pr-2">
            <Column.Content>
              <Input.Root className="mb-5">
                <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                  Planos
                </Input.Label>
                <Select.Root>
                  <Select.Content
                    color="is-grey"
                    className="is-fullwidth is-rounded"
                    value={formContext}
                    setValue={setFormContext}
                    field={'select_planos'}
                    // disabled={disabledList?.type}
                  >
                    <Select.Option value={null} title={''} />
                    <Select.Search value={search} setValue={setSearch} />
                    {(infosContext?.plans || []).map((item, index) => {
                      return <Select.Option key={index} value={item.id} title={item.name} />
                    })}
                  </Select.Content>
                </Select.Root>
              </Input.Root>
            </Column.Content>
            <Column.Content size="is-1 mr-5">
              <Button.Bt
                className="mt-5 is-rounded"
                color="is-dark"
                colorText=""
                action={() => {
                  AddPlanInList(formContext.select_planos)
                }}
                loading={loading}
              >
                Adicionar
              </Button.Bt>
            </Column.Content>
          </Column.Root> */}

          <div className="box" style={{ marginTop: -30 }}>
            <div className="table-container">
              <Table.Root className="is-fullwidth has-text-centered mb-5 mt-2">
                <Table.Head>
                  <Table.Row>
                    <Table.Cell>Plano de Assinatura</Table.Cell>
                    <Table.Cell>Valor</Table.Cell>
                    <Table.Cell>Data Término</Table.Cell>
                    <Table.Cell>Ações</Table.Cell>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {(formContext?.plans || []).map((plan, index) => {
                    return (
                      <Table.Row key={`planslist_${plan.index}`}>
                        <Table.Cell className="is-vcentered">
                          <span title={plan.nome}>{plan.name}</span>
                        </Table.Cell>
                        <Table.Cell className="is-vcentered">R$ {plan.value}</Table.Cell>
                        <Table.Cell className="is-vcentered" size={200}>
                          <Input.Prompt
                            type="date"
                            className="is-rounded"
                            value={plan.dtEndPlan}
                            setValue={(value) => {
                              return setFormContext((prev) => {
                                const newPlan = { ...plan, dtEndPlan: value }
                                return {
                                  ...prev,
                                  plans: [...(prev.plans || []).filter((item) => item.index !== plan.index), newPlan],
                                }
                              })
                            }}
                            // required={true}
                            // disabled={disabledList?.dtEnd}
                          ></Input.Prompt>
                          {!plan?.dtEndPlan && <p className="is-size-7 has-text-danger">(Não Expira)</p>}
                        </Table.Cell>
                        <Table.Cell className="is-vcentered"></Table.Cell>
                      </Table.Row>
                    )
                  })}
                </Table.Body>
              </Table.Root>
            </div>
          </div>

          {/* Titulo */}
          <Column.Root className="mt-6 pt-5">
            <Column.Root>
              <Column.Content>
                <h2 className="subtitle is-size-4">Histórico</h2>
              </Column.Content>
            </Column.Root>
            <Column.Content className="has-text-right"></Column.Content>
            <Column.Content className="has-text-right">
              <Button.Root align="right">
                <Button.Content>
                  <Button.Bt
                    color="is-dark"
                    colorText="has-text-white"
                    action={async () => {
                      await HandleNewPayment()
                    }}
                    loading={loading}
                  >
                    +
                  </Button.Bt>
                </Button.Content>
                <Button.Content>
                  <Button.Bt
                    color="is-dark"
                    colorText="has-text-white"
                    action={() => {
                      FetchGetDatas()
                      FetchGetInfos()
                    }}
                    loading={loading}
                  >
                    <Icon size={15}>sync</Icon>
                  </Button.Bt>
                </Button.Content>
              </Button.Root>
            </Column.Content>
          </Column.Root>
          <Column.Root className="is-mobile">
            <Column.Content>
              <p>Próxima Cobrança: {ConvertTimestampToDateTime(formContext.nextPayment)}</p>
            </Column.Content>
          </Column.Root>

          {/* Barra de pesquisa */}
          <Column.Root className="is-mobile">
            <Column.Content>
              <Input.Root>
                <Input.Contents alignIcon={'left'}>
                  <Input.Prompt value={search} setValue={setSearch} placeholder="Pesquisar..."></Input.Prompt>
                  <Input.Icon align="is-left">
                    <Icon size={20}>search</Icon>
                  </Input.Icon>
                </Input.Contents>
              </Input.Root>
            </Column.Content>
          </Column.Root>
          {/* Tabela de dados */}
          <Column.Root className="is-mobile">
            <Column.Content>
              <Table.Root className="is-fullwidth is-hoverable is-narrow has-text-centered">
                <Table.Head>
                  <Table.Row key={'table_payments_head'}>
                    <Table.Cell>Nome</Table.Cell>
                    <Table.Cell>ID Lançamento</Table.Cell>
                    <Table.Cell>Criado Em</Table.Cell>
                    <Table.Cell>Tipo</Table.Cell>
                    <Table.Cell>Valor</Table.Cell>
                    <Table.Cell>Situação</Table.Cell>
                    <Table.Cell>Pago em</Table.Cell>
                    <Table.Cell>Ações</Table.Cell>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {dataFiltered.length > 0 ? (
                    dataFiltered.map((value) => {
                      return (
                        <>
                          <Table.Row key={value.id}>
                            <Table.Cell className="is-size-7 is-vcentered">{value.name}</Table.Cell>
                            <Table.Cell className="is-size-7 is-vcentered">
                              {value?.responseOmie?.codigo_lancamento_omie || '-'}
                            </Table.Cell>
                            <Table.Cell className="is-size-7 is-vcentered">
                              {String(value.dt_created || '-')}
                            </Table.Cell>
                            <Table.Cell className="is-size-7 is-vcentered">{value.typePayment || '-'}</Table.Cell>
                            <Table.Cell className="is-size-7 is-vcentered">R$ {value.value}</Table.Cell>
                            <Table.Cell className="is-size-7 is-vcentered">
                              <p className={`tag ${colorTagStatusPagamentos(value.status).color}`}>
                                {colorTagStatusPagamentos(value.status).value}
                              </p>
                            </Table.Cell>
                            <Table.Cell className="is-size-7 is-vcentered">
                              {String(value.dt_payment || '-')}
                            </Table.Cell>
                            <Table.Cell className="is-vcentered" style={{ height: 50 }}>
                              <div style={{ marginBottom: 5, marginTop: 5 }}>
                                <Button.Root align="right">
                                  <Button.Content>
                                    <Button.Bt
                                      className={''}
                                      color="is-light"
                                      action={() => {
                                        HandleSelectPayment(value)
                                      }}
                                      loading={loading}
                                    >
                                      <Icon size={15}>history</Icon>
                                    </Button.Bt>
                                  </Button.Content>

                                  {!['canceled', 'canceling'].includes(value?.status) &&
                                    value?.responseOmie?.cUrlPix && (
                                      <Button.Content>
                                        <Button.A
                                          className={''}
                                          color="is-light"
                                          link={value?.responseOmie?.cUrlPix}
                                          loading={loading}
                                          openNew
                                        >
                                          <Icon size={15}>qrcode</Icon>
                                        </Button.A>
                                      </Button.Content>
                                    )}

                                  {!['canceled', 'canceling'].includes(value?.status) &&
                                    value?.responseOmie?.cUrlPdfDemo && (
                                      <Button.Content>
                                        <Button.A
                                          className={''}
                                          color="is-light"
                                          link={value?.responseOmie?.cUrlPdfDemo}
                                          loading={loading}
                                          openNew
                                        >
                                          <Icon size={15}>print</Icon>
                                        </Button.A>
                                      </Button.Content>
                                    )}

                                  {['paymentSuccess'].includes(value?.status) && !value?.responseOmie?.cUrlPdfDemo && (
                                    <Button.Content>
                                      <Button.Bt
                                        className={''}
                                        color="is-light"
                                        action={async () => HandleConsultarOsOmie(value)}
                                        loading={loading}
                                        openNew
                                      >
                                        <Icon size={15}>print</Icon>
                                      </Button.Bt>
                                    </Button.Content>
                                  )}

                                  {!['canceled', 'canceling', 'paymentSuccess'].includes(value?.status) && (
                                    <Button.Content>
                                      <Button.Bt
                                        className={''}
                                        color="is-danger"
                                        colorText="has-text-white"
                                        action={async () => {
                                          await HandleCancelPayment(value)
                                        }}
                                        loading={loading}
                                      >
                                        <Icon size={15}>multiply</Icon>
                                      </Button.Bt>
                                    </Button.Content>
                                  )}

                                  {!['canceled', 'canceling', 'paymentSuccess'].includes(value?.status) && (
                                    <Button.Content>
                                      <Button.Bt
                                        className={''}
                                        color="is-warning"
                                        action={async () => {
                                          await HandleReprocessPayment(value)
                                        }}
                                        loading={loading}
                                      >
                                        <Icon size={15}>sync</Icon>
                                      </Button.Bt>
                                    </Button.Content>
                                  )}

                                  {value?.status === 'canceled' && (
                                    <Button.Content>
                                      <Button.Bt
                                        className={''}
                                        color="is-danger"
                                        colorText="has-text-white"
                                        action={async () => {
                                          await HandleDeletePayment(value)
                                        }}
                                        loading={loading}
                                      >
                                        <Icon size={15}>trash</Icon>
                                      </Button.Bt>
                                    </Button.Content>
                                  )}
                                </Button.Root>
                              </div>
                            </Table.Cell>
                          </Table.Row>
                        </>
                      )
                    })
                  ) : (
                    <p className="mt-4">Nenhuma cobrança gerada</p>
                  )}

                  {}
                </Table.Body>
              </Table.Root>
            </Column.Content>
          </Column.Root>

          <div style={{ height: 300 }}></div>
          {/* <Column.Root className="mt-7 mb-6">
            <Column.Content>
              <Button.Root align="right">
                <Button.Content>
                  <Button.A color="is-light" link={Path.AdminCollaborators} loading={loading}>
                    Cancelar
                  </Button.A>
                </Button.Content>
                <Button.Content>
                  <Button.Bt
                    className=""
                    color="is-success"
                    colorText="has-text-white"
                    type={'submit'}
                    loading={loading}
                  >
                    Salvar
                  </Button.Bt>
                </Button.Content>
              </Button.Root>
            </Column.Content>
          </Column.Root> */}
        </Form.Root>
      </Page.Root>
    </>
  )
}
