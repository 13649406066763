export const createSchemaColabOmie = (values) => {
  const cnpjCpf = values.cpf.trim().replace(/[.\-/]/g, '')
  //   const { contact } = values
  //   const { address } = values

  //   const tel1ddd = contact.phone.trim().slice(1, 3)
  //   const tel1 = contact.phone
  //     .trim()
  //     .slice(contact.phone.indexOf(')') + 1)
  //     .replace(' ', '')

  return {
    codigo_cliente_integracao: values.id,
    razao_social: values.name,
    cnpj_cpf: cnpjCpf,
    // telefone1_ddd: tel1ddd,
    // telefone1_numero: tel1,
    // contato: values.name,
    // endereco: address.street,
    // endereco_numero: address.number,
    // bairro: address.district,
    // complemento: address.complement ?? '',
    // estado: address.state,
    // cidade: address.city,
    // cep: address.zipcode,
    email: values.email,
    pessoa_fisica: 'S',
    importado_api: 'S',
    bloquear_faturamento: 'N', // S ou N
    inativo: 'N',
    // dadosBancarios: {
    //   codigo_banco: values.infoBanks_banco,
    //   agencia: values.infoBanks_agencia,
    //   conta_corrente: values.infoBanks_conta,
    //   doc_titular: values.infoBanks_cpf_titular,
    //   nome_titular: values.infoBanks_name_titular,
    //   transf_padrao: 'S',
    //   cChavePix: values.pix,
    // },

    tags: [
      {
        tag: 'Colaborador',
      },
    ],
  }
}
