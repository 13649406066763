import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { getDocument, getDocuments, updateDocument } from '../../../../firebase/firestore'
import { TryCatch } from '../../../../utils/general'
import { DateTimeNow } from '../../../../utils/dates'
import { Path } from '../../../../router/paths'
import { sendNotification } from '../../../../utils/sendNotification'

export const useCollaboratorPositionsForm = () => {
  const {
    user,
    loading,
    setAlerts,
    infosContext,
    // formContext,
    setFormContext,
    setLoading,
  } = useGlobalContext()

  const [search, setSearch] = useState('')
  const [openModal, setOpenModal] = useState(false)

  const { id } = useParams()
  const uuidAsArray = [id]

  const navigate = useNavigate()

  // cargo
  const [position, setPosition] = useState('')
  const isPositionEmpty = position === ''
  const positionList = [...(infosContext?.cargos || [])].reduce((acc, item) => {
    acc[item.id] = { ...item }
    return acc
  }, {})

  // const positionsAdded = formContext?.cargos ?? {}
  // const consents = formContext?.consentimentos ?? {}
  // const documents = formContext?.documentos ?? {}

  const [positionsAdded, setPositionsAdded] = useState({})
  const [consents, setConsents] = useState({})
  const [documents, setDocuments] = useState({})

  const updateDataUser = async () => {
    const userPositions = await getDocument('colaboradores', id)

    setPositionsAdded(userPositions.positions)
    setConsents(userPositions.consents)
    setDocuments(userPositions.documents)
  }

  useEffect(() => {
    updateDataUser()
  }, [])

  const submitFormDisabled = Object.keys(positionsAdded || {}).length > 0

  const [selectedPositionId, setSelectedPositionId] = useState(null)
  const getIdPosition = (id) => {
    selectedPositionId(id)
  }

  const selectPositionFromValidation = async (idPosition) => {
    const getDocumentations = await getDocuments('documentos')
    const getConsentiments = await getDocuments('consentimentos')
    const getPosition = await getDocument('cargos', idPosition)
    const getUser = await getDocument('colaboradores', id)

    const currentPositionUser = getUser.positions[idPosition]

    const currentDocumentationUser = Object.keys(getUser.documents).map((key) => ({
      id: key,
      ...getUser.documents[key],
    }))
    const currentConsentimentsUser = Object.keys(getUser.consents).map((key) => ({ id: key, ...getUser.consents[key] }))

    const documentationUser = getPosition.documentos.map((idDoc) => {
      const doc = currentDocumentationUser.find((values) => values.id === idDoc)
      const docInfo = getDocumentations.find((values) => values.id === idDoc)

      if (!doc) {
        return {
          name: docInfo?.name,
          status: false,
          sendDate: '',
        }
      }

      return doc
    })

    const consentimentsUser = getPosition.consentimentos.map((idCon) => {
      const con = currentConsentimentsUser.find((values) => values.id === idCon)
      const conInfo = getConsentiments.find((values) => values.id === idCon)

      if (!con) {
        return {
          name: conInfo?.name || conInfo?.nome,
          status: false,
          signDate: '',
        }
      }

      return con
    })

    const newValidationData = {
      ...getPosition,
      ...currentPositionUser,
      documentationUser,
      consentimentsUser,
    }

    setFormContext((prev) => ({ ...prev, newValidationData }))
  }

  const HandleSelectPositionFromValidation = async (idPosition) => {
    await TryCatch(
      async () => {
        await selectPositionFromValidation(idPosition)
      },
      setLoading,
      setAlerts,
    )
  }

  const getPositionStatusType = (position) => {
    let positionType

    switch (position) {
      case 1:
        positionType = 'Verificado'
        break
      case 9:
        positionType = 'Bloqueado'
        break
      default:
        positionType = 'Em Análise'
        break
    }

    return positionType
  }

  const getConsentStatusType = (consent) => {
    let positionType

    switch (consent) {
      case true:
        positionType = 'Assinado'
        break
      default:
        positionType = 'Pendente'
    }

    return positionType
  }

  const getDocumentStatusType = (document) => {
    let documentType

    switch (document) {
      case true:
        documentType = 'Enviado'
        break
      default:
        documentType = 'Pendente'
    }

    return documentType
  }

  const addPositionToList = (key) => {
    const position = positionsAdded
    const currentPosition = infosContext?.cargos?.find((currentPosition) => currentPosition.id === key)
    const currentPositionConsentIds = currentPosition?.consentimentos
    const currentPositionDocumentIds = currentPosition?.documentos

    currentPositionConsentIds.map((currentPositionId) => {
      const currentPositionConsent = infosContext?.consentimentos?.find((consent) => consent.id === currentPositionId)

      const currentPositionConsentContent = {
        name: currentPositionConsent.name,
        signDate: '-------',
        status: 0,
      }

      setFormContext((prevState) => {
        return {
          ...prevState,
          consentimentos: {
            ...prevState.consentimentos,
            [currentPositionConsent.id]: {
              ...currentPositionConsentContent,
            },
          },
        }
      })

      return {
        currentPositionConsentContent,
      }
    })

    currentPositionDocumentIds.map((currentPositionId) => {
      const currentPositionDocument = infosContext?.documentos?.find((document) => document.id === currentPositionId)

      const currentPositionDocumentContent = {
        name: currentPositionDocument.name,
        sendDate: '-------',
        status: false,
      }

      setFormContext((prevState) => {
        return {
          ...prevState,
          documentos: {
            ...prevState.documentos,
            [currentPositionDocument.id]: {
              ...currentPositionDocumentContent,
            },
          },
        }
      })

      return {
        currentPositionDocumentContent,
      }
    })

    if (position && Object.keys(position).includes(key)) {
      return setAlerts((prev) => [
        ...prev,
        {
          title: 'Cargo já adicionado',
          msg: 'É permitido inserir apenas cargos diferentes',
          type: 'error',
        },
      ])
    }

    return setFormContext((prevState) => {
      const isMainAsDefault = Object.keys(prevState.cargos).length < 1

      return {
        ...prevState,
        cargos: {
          ...prevState.cargos,
          [key]: {
            id: key,
            name: positionList[key].name,
            main: isMainAsDefault,
            status: 0,
            score: 0,
          },
        },
      }
    })
  }

  const approvePosition = async (idPosition) => {
    const getUser = await getDocument('colaboradores', id)
    const getPositionUser = getUser.positions
    const positionName = getPositionUser[idPosition].name.toUpperCase()

    if (!getPositionUser[idPosition]) {
      return null
    } else {
      const newDocument = {
        [`positions.${idPosition}`]: {
          ...getPositionUser[idPosition],
          status: 1,
          validationBy: user.user.nome || user.user.name,
          validationReason: '',
          validationDate: DateTimeNow(),
        },
      }

      await updateDocument('colaboradores', id, newDocument)

      const notificationFields = {
        title: `Cargo Aprovado`,
        description: `Você está elegível para o cargo de ${positionName}. Verifique se há eventos disponíveis.`,
      }

      const tokenAsArray = [getUser.token]

      await sendNotification(uuidAsArray, tokenAsArray, notificationFields.title, notificationFields.description)
    }

    return location.reload()
  }

  const HandleApprovePosition = async (idPosition) => {
    await TryCatch(
      async () => {
        await approvePosition(idPosition)
      },
      setLoading,
      setAlerts,
    )
  }

  // const rollbackApprovePosition = async (idPosition) => {
  //   const getUser = await getDocument('colaboradores', id)
  //   const getPositionUser = getUser.positions

  //   if (!getPositionUser[idPosition]) {
  //     return null
  //   } else {
  //     const newDocument = {
  //       [`positions.${idPosition}`]: {
  //         ...getPositionUser[idPosition],
  //         status: 2,
  //       },
  //     }
  //     await updateDocument('colaboradores', id, newDocument)
  //   }
  // }

  // const HandleRollbackApprovePosition = async (idPosition) => {
  //   await TryCatch(
  //     async () => {
  //       await rollbackApprovePosition(idPosition)
  //     },
  //     setLoading,
  //     setAlerts,
  //   )
  // }

  const refusePosition = async (idPosition) => {
    const reasonDelete = window.prompt('Qual o motivo da recusa?').toUpperCase()

    const getUser = await getDocument('colaboradores', id)
    const getPositionUser = getUser.positions
    const namePosition = getPositionUser[idPosition].name.toUpperCase()

    if (!getPositionUser[idPosition]) {
      return null
    } else {
      const newDocument = {
        [`positions.${idPosition}`]: {
          ...getPositionUser[idPosition],
          status: 9,
          validationBy: user.user.nome || user.user.name,
          validationReason: reasonDelete,
          validationDate: DateTimeNow(),
        },
      }

      const notificationFields = {
        title: 'Cargo Recusado',
        description: `Sua validação no cargo de ${namePosition} foi recusado. Motivo: ${reasonDelete}. Reenvie o(s) documento(s).`,
      }

      const tokenAsArray = [getUser.token]

      await sendNotification(uuidAsArray, tokenAsArray, notificationFields.title, notificationFields.description)

      await updateDocument('colaboradores', id, newDocument)
    }

    return navigate(Path.EditAdminCollaboratorPositions(id))
  }

  const HandleRefusePosition = async (idPosition) => {
    await TryCatch(
      async () => {
        refusePosition(idPosition)
      },
      setLoading,
      setAlerts,
    )
  }

  const undoPosition = async (idPosition) => {
    const getUser = await getDocument('colaboradores', id)
    const getPositionUser = getUser.positions

    if (!getPositionUser[idPosition]) {
      return null
    } else {
      const newDocument = {
        [`positions.${idPosition}`]: {
          ...getPositionUser[idPosition],
          status: 0,
          validationBy: user.user.nome || user.user.name,
          validationReason: '',
          validationDate: DateTimeNow(),
        },
      }
      await updateDocument('colaboradores', id, newDocument)
    }

    return location.reload()
  }

  const HandleUndoPosition = async (idPosition) => {
    await TryCatch(
      async () => {
        undoPosition(idPosition)
      },
      setLoading,
      setAlerts,
    )
  }

  const blockUnblockPosition = (key) => {
    return setFormContext((prevState) => {
      const currentPositionStatus = prevState.cargos[key].status
      const newPositionStatus = currentPositionStatus === 9 ? 1 : 9 // Bloqueado para verificado e vice-versa

      return {
        ...prevState,
        cargos: {
          ...prevState.cargos,
          [key]: {
            ...prevState.cargos[key],
            status: newPositionStatus,
          },
        },
      }
    })
  }

  const blockUnblockCollaborator = () => {
    return setFormContext((prevState) => {
      const currentCollaboratorStatus = prevState.status
      const newCollaboratorStatus = currentCollaboratorStatus === 9 ? 1 : 9 // Bloqueado para verificado e vice-versa
      return {
        ...prevState,
        status: newCollaboratorStatus,
      }
    })
  }

  const deletePositionFromList = (key) => {
    return setFormContext((prevState) => {
      const newPositionList = Object.fromEntries(
        Object.entries(prevState.cargos).filter(([entryKey]) => entryKey !== key),
      )

      const currentPosition = infosContext?.cargos?.find((currentPosition) => currentPosition.id === key)
      const currentPositionConsentIds = currentPosition?.consentimentos
      const currentPositionDocumentIds = currentPosition?.documentos
      let newConsentsList
      let newDocumentsList

      currentPositionConsentIds.map((currentPositionConsentId) => {
        return (newConsentsList = Object.fromEntries(
          Object.entries(prevState.consentimentos).filter(([entryKey]) => entryKey !== currentPositionConsentId),
        ))
      })

      currentPositionDocumentIds.map((currentPositionDocumentId) => {
        return (newDocumentsList = Object.fromEntries(
          Object.entries(prevState.documentos).filter(([entryKey]) => entryKey !== currentPositionDocumentId),
        ))
      })

      return {
        ...prevState,
        cargos: newPositionList,
        documentos: newDocumentsList,
        consentimentos: newConsentsList,
      }
    })
  }

  const resendAttachment = async (idDocument, documents) => {
    await updateDocument('colaboradores', id, {
      [`documents.${idDocument}`]: {
        ...documents,
        status: false,
      },
    })

    setFormContext((prev) => {
      const newDocuments = (prev?.newValidationData?.documentationUser || []).map((doc) => {
        if (idDocument === doc.id) {
          return { ...doc, status: false }
        }
        return { ...doc }
      })
      return {
        ...prev,
        newValidationData: {
          ...(prev?.newValidationData || {}),
          documentationUser: newDocuments,
        },
      }
    })

    const notificationFields = {
      title: 'Documento Recusado',
      description: 'Foi encontrado irregularidade no(s) documento(s) necessita correção.',
    }
    await sendNotification(id, notificationFields.title, notificationFields.description)

    return setAlerts((prev) => [
      ...prev,
      {
        title: 'Documento Recusado',
        msg: 'Foi enviado ao colaborador para que reenvie o documento novamente.',
        type: 'success',
      },
    ])
  }

  const HandleResendAttachment = async (idDocument, document) => {
    await TryCatch(
      async () => {
        await resendAttachment(idDocument, document)
      },
      setLoading,
      setAlerts,
    )
  }

  // Função para definir uma linha como principal e atualizar o contexto
  const handleMainPosition = (id) => {
    // Atualiza o contexto, configurando a opção selecionada como principal
    setFormContext((prevState) => {
      const cargosAtualizados = Object.keys(prevState.cargos).reduce((acc, key) => {
        acc[key] = {
          ...prevState.cargos[key],
          main: key === String(id), // Marca o item como principal se o ID for o mesmo
        }
        return acc
      }, {})

      return {
        ...prevState,
        cargos: cargosAtualizados,
      }
    })
  }

  return {
    loading,
    search,
    setSearch,
    position,
    isPositionEmpty,
    setPosition,
    positionList,
    getPositionStatusType,
    getConsentStatusType,
    getDocumentStatusType,
    positionsAdded,
    blockUnblockPosition,
    blockUnblockCollaborator,
    consents,
    documents,
    addPositionToList,
    deletePositionFromList,
    HandleApprovePosition,
    HandleRefusePosition,
    HandleUndoPosition,
    // HandleRollbackApprovePosition,
    submitFormDisabled,
    handleMainPosition,
    HandleResendAttachment,
    openModal,
    getIdPosition,
    selectedPositionId,
    HandleSelectPositionFromValidation,
    setSelectedPositionId,
    setOpenModal,
  }
}
