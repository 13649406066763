import { useParams, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

import { useGlobalContext } from '../../../../../context/ContextGlobal'
import { Path } from '../../../../../router/paths'
import { getDocument, updateDocument } from '../../../../../firebase/firestore'
import { TryCatch } from '../../../../../utils/general'
import { schemaReports } from '../utils/schemas'

const useEditReports = () => {
  const { id } = useParams()
  const {
    // user,
    setAlerts,
    // dataContext, tudo que for dados da página, e.g: listas
    // setDataContext,
    // infosContext, todas as informações para a página funcionar
    // setInfosContext,
    formContext,
    setFormContext,
    loading,
    setLoading,
    // statusLoading,
    // setStatusLoading,
  } = useGlobalContext()

  const navigate = useNavigate()

  const getForm = async () => {
    const getReport = await getDocument('reports', id)
    const newConsent = schemaReports(getReport)
    setFormContext(newConsent)
  }

  const fetchGetForm = async () => {
    await TryCatch(getForm, setLoading, setAlerts)
  }

  const editConsent = async () => {
    const updateReport = schemaReports(formContext)

    if ((updateReport?.columns || []).length === 0) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Nenhuma Coluna Selecionada',
          msg: `Selecione pelo menos uma coluna para editar o relatório.`,
        },
      ])
    }

    await updateDocument('reports', id, updateReport)

    setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Relatório Editado',
        msg: `O Relatório "${updateReport?.name || ''}" foi editado com sucesso.`,
      },
    ])

    setFormContext({})

    // console.log(mapValues(data, mappings))

    return navigate(Path.AdminReports)
  }

  const handleEditConsent = async () => {
    await TryCatch(editConsent, setLoading, setAlerts)
  }

  useEffect(() => {
    fetchGetForm()
  }, [])

  return { loading, formContext, setFormContext, handleEditConsent }
}

export default useEditReports
