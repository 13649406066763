import { useEffect, useState } from 'react'

import { useGlobalContext } from '../../../../../context/ContextGlobal'
import { getDocuments, deleteDocument } from '../../../../../firebase/firestore'
import { TryCatch } from '../../../../../utils/general'

const useNotices = () => {
  const {
    user,
    setAlerts,
    dataContext,
    setDataContext,
    // infosContext, todas as informações para a página funcionar
    // setInfosContext,
    // formContext,
    setFormContext,
    loading,
    setLoading,
    // statusLoading,
    // setStatusLoading,
  } = useGlobalContext()
  const [search, setSearch] = useState('')
  const notices = dataContext?.notices || []

  const getData = async () => {
    const getNotices = await getDocuments('notices')

    setDataContext({ notices: getNotices })
  }

  const fetchGetData = async () => {
    await TryCatch(getData, setLoading, setAlerts)
  }

  // deleta o usuario no cliente e se não tiver cadastro em cliente, ele apaga o usuario do autenticador
  const deleteNotice = async (notice) => {
    if (!user?.user?.permissions?.notices_excluir) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Permissão Negada',
          msg: 'Você não possui permissão para apagar Avisos',
        },
      ])
    }

    const blocked = notice?.blocked || false
    if (blocked) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Consentimento em uso',
          msg: 'Você não pode deletar esse aviso.',
        },
      ])
    }

    const idNotices = notice?.id || null

    if (!idNotices) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'ID do aviso não encontrado',
          msg: 'Não conseguimos achar o ID do aviso. Tente novamente mais tarde.',
        },
      ])
    }

    await deleteDocument('notices', idNotices)
    await getData()
    return setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Aviso Excluído',
        msg: 'Aviso excluído da sua base de dados',
      },
    ])
  }

  const handleDeleteNotice = async (notice) => {
    await TryCatch(
      async () => {
        deleteNotice(notice)
      },
      setLoading,
      setAlerts,
    )
  }

  const noticesFiltered =
    search !== ''
      ? Object.values(notices).filter((notice) => {
          const searchString = `${notice?.title}|${notice?.message}|${notice?.notDelete}`.toLowerCase()
          return searchString.includes(search.toLocaleLowerCase())
        })
      : Object.values(notices)

  useEffect(() => {
    fetchGetData()
    setFormContext({})
  }, [])

  return { search, setSearch, loading, noticesFiltered, fetchGetData, handleDeleteNotice }
}

export default useNotices
