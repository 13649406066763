import { useEffect, useState } from 'react'

import { useGlobalContext } from '../../../../../context/ContextGlobal'
import { TryCatch } from '../../../../../utils/general'
import { GetPaginationCollection } from '../../../../../firebase/functions'

const useLogSystem = () => {
  const [search, setSearch] = useState('')

  const {
    // user,
    setAlerts,
    dataContext,
    setDataContext,
    // infosContext,
    // setInfosContext,
    // formContext,
    // setFormContext,
    loading,
    setLoading,
    // statusLoading,
    // setStatusLoading,
  } = useGlobalContext()

  const listLogs = dataContext?.log?.data || []
  //  const clientId = user.client.id

  const getData = async () => {
    // coleta os setores criados pelo cliente
    const getLogs = await GetPaginationCollection(1, 1000, '_systemLogs', 'time')

    // console.log(getLogs)
    // salva todas as informações coletadas no context.
    setDataContext({
      log: getLogs,
    })
  }

  const FetchGetData = async () => {
    await TryCatch(getData, setLoading, setAlerts)
  }

  useEffect(() => {
    getData()
  }, [])

  const logsFiltered =
    search !== ''
      ? Object.values(listLogs).filter((log) => {
          const searchString = `${log.type}|${log.time}|${log.origin}|${log.message}`.toLowerCase()
          return searchString.includes(search.toLowerCase())
        })
      : Object.values(listLogs)

  return { search, setSearch, loading, logsFiltered, FetchGetData }
}

export default useLogSystem
