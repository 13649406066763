import { useEffect } from 'react'

import { useGlobalContext } from '../../../context/ContextGlobal'
import { TryCatch } from '../../../utils/general'
import { getDocumentsWithQuery } from '../../../firebase/firestore'

const useCollaboratorSignPlan = () => {
  const { setAlerts, setLoading, loading, dataContext, setDataContext } = useGlobalContext()

  // const navigate = useNavigate()

  const getData = async () => {
    const getAllPlans = await getDocumentsWithQuery('plans', 'typePlan', '==', 'collab')

    setDataContext({ plans: getAllPlans })
  }

  const FetchGetData = async () => {
    await TryCatch(getData, setLoading, setAlerts)
  }

  useEffect(() => {
    FetchGetData()
  }, [])

  return { loading, FetchGetData, dataContext }
}

export default useCollaboratorSignPlan
