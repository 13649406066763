import React from 'react'

import { Column } from '../../../../components/layouts/columns'
import { Button } from '../../../../components/ui/button'
import { Input } from '../../../../components/ui/input'
import { Select } from '../../../../components/ui/select'
import { Path } from '../../../../router/paths'
import { Table } from '../../../../components/ui/table'
import { Icon } from '../../../../components/icons'
import { useFormClients } from '../_hooks'
import { useGlobalContext } from '../../../../context/ContextGlobal'

export const ClientsLayout = ({ values, setValues, options, disabledList, type = 'add', clientId }) => {
  const { search, setSearch, AddPlanInList, DeletePlansOfList, statusUserAdmin, RemoveEndDate, AddEndDate } =
    useFormClients(values, setValues, options, clientId)

  const { loading } = useGlobalContext()

  return loading ? (
    <>
      <Icon>loading</Icon>
      <p className="has-text-centered has-text-grey-light">Coletando informações, aguarde... </p>
    </>
  ) : (
    <div>
      <Column.Root>
        <Column.Content>
          <Column.Root>
            <Column.Content>
              <Input.Root className="mb-3">
                <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                  CNPJ/CPF
                </Input.Label>
                <Input.Contents>
                  <Input.Prompt
                    className="is-rounded"
                    value={values}
                    setValue={setValues}
                    field={'cpfCnpj'}
                    required={true}
                    disabled={disabledList?.cpfCnpj}
                    maskValue={'cpfCnpj'}
                    maskSetValue={'cpfCnpj'}
                  ></Input.Prompt>
                </Input.Contents>
              </Input.Root>
            </Column.Content>
            {String(values?.cpfCnpj || '').length > 15 && (
              <>
                <Column.Content>
                  <Input.Root className="mb-3">
                    <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
                      Inscricao Municipal
                    </Input.Label>
                    <Input.Contents>
                      <Input.Prompt
                        className="is-rounded"
                        value={values}
                        setValue={setValues}
                        field={'isncMunicipal'}
                        disabled={disabledList?.cpfCnpj}
                        maskValue={'cpfCnpj'}
                        maskSetValue={'cpfCnpj'}
                      ></Input.Prompt>
                    </Input.Contents>
                  </Input.Root>
                </Column.Content>
                <Column.Content>
                  <Input.Root className="mb-3">
                    <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                      Incrição Estadual
                    </Input.Label>
                    <Input.Contents>
                      <Input.Prompt
                        className="is-rounded"
                        value={values}
                        setValue={setValues}
                        field={'isncEstadual'}
                        required={true}
                        disabled={disabledList?.cpfCnpj}
                        maskValue={'cpfCnpj'}
                        maskSetValue={'cpfCnpj'}
                      ></Input.Prompt>
                    </Input.Contents>
                  </Input.Root>
                </Column.Content>
              </>
            )}
          </Column.Root>
          {String(values?.cpfCnpj || '').length > 15 && (
            <Column.Root>
              <Column.Content>
                <Input.Root className="">
                  <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                    Tipo de Atividade
                  </Input.Label>
                  <Select.Root>
                    <Select.Content
                      color="is-grey"
                      className="is-fullwidth is-rounded"
                      value={values}
                      setValue={setValues}
                      field={'tipoAtividade'}
                      disabled={disabledList?.uf}
                      required={true}
                    >
                      <Select.Option value={null} title={''} />
                      {(options?.listAtividades || []).map((item) => {
                        return (
                          <Select.Option
                            key={`activate_${item.cCodigo}`}
                            value={item.cCodigo}
                            title={item.cDescricao}
                          />
                        )
                      })}
                    </Select.Content>
                  </Select.Root>
                </Input.Root>
              </Column.Content>
              <Column.Content>
                <Input.Root className="">
                  <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                    CNAE
                  </Input.Label>
                  <Select.Root>
                    <Select.Search
                      color="is-grey"
                      className="is-fullwidth is-rounded"
                      value={values}
                      setValue={setValues}
                      field={'cnae'}
                      disabled={disabledList?.cnae}
                      required={true}
                      options={options?.optionsCNAE || []}
                    ></Select.Search>
                  </Select.Root>
                </Input.Root>
              </Column.Content>
            </Column.Root>
          )}
          <Column.Root className="mt-3">
            <Column.Content>
              <Input.Root className="mb-3">
                <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                  Razão Social
                </Input.Label>
                <Input.Contents>
                  <Input.Prompt
                    className="is-rounded"
                    value={values}
                    setValue={setValues}
                    field={'razaoSocial'}
                    required={true}
                    disabled={disabledList?.razaoSocial}
                    maskValue={'title'}
                    maskSetValue={'lower'}
                  ></Input.Prompt>
                </Input.Contents>
              </Input.Root>
            </Column.Content>
            <Column.Content>
              <Input.Root className="mb-3">
                <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                  Nome Fantasia
                </Input.Label>
                <Input.Contents>
                  <Input.Prompt
                    className="is-rounded"
                    value={values}
                    setValue={setValues}
                    field={'nomeFantasia'}
                    required={true}
                    disabled={disabledList?.nomeFantasia}
                    maskValue={'title'}
                    maskSetValue={'lower'}
                  ></Input.Prompt>
                </Input.Contents>
              </Input.Root>
            </Column.Content>
          </Column.Root>

          <p className="ml-3 mb-2 has-text-grey-light subtitle" style={{ marginBottom: 0 }} required={true}>
            Endereço da Empresa
          </p>

          <div className="box mb-6">
            <Column.Root>
              <Column.Content>
                <Input.Root className="">
                  <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                    Cep
                  </Input.Label>
                  <Input.Contents>
                    <Input.Prompt
                      className="is-rounded"
                      value={values}
                      setValue={setValues}
                      field={'cep'}
                      required={true}
                      disabled={disabledList?.cep}
                      maskValue={'title'}
                      maskSetValue={'lower'}
                    ></Input.Prompt>
                  </Input.Contents>
                </Input.Root>
                <Input.Error>
                  {values?.cep
                    ? values?.cepError
                      ? values.cepError
                      : ''
                    : 'Digite o cep para preencher automaticamente as informações de endereço'}
                </Input.Error>
              </Column.Content>

              <Column.Content>
                <Input.Root className="">
                  <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                    Endereço
                  </Input.Label>
                  <Input.Contents>
                    <Input.Prompt
                      className="is-rounded"
                      value={values}
                      setValue={setValues}
                      field={'endereco'}
                      required={true}
                      disabled={disabledList?.endereco}
                      maskValue={'title'}
                      maskSetValue={'lower'}
                    ></Input.Prompt>
                  </Input.Contents>
                </Input.Root>
              </Column.Content>
              <Column.Content size="is-2">
                <Input.Root className="">
                  <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                    Número
                  </Input.Label>
                  <Input.Contents>
                    <Input.Prompt
                      className="is-rounded"
                      value={values}
                      setValue={setValues}
                      field={'endereco_numero'}
                      required={true}
                      disabled={disabledList?.endereco_numero}
                    ></Input.Prompt>
                  </Input.Contents>
                  <Input.Error>{values?.cep && !values?.endereco_numero ? 'Campo obrigatório' : ''}</Input.Error>
                </Input.Root>
              </Column.Content>
              <Column.Content>
                <Input.Root className="">
                  <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
                    Complemento
                  </Input.Label>
                  <Input.Contents>
                    <Input.Prompt
                      className="is-rounded"
                      value={values}
                      setValue={setValues}
                      field={'complemento'}
                      disabled={disabledList?.complemento}
                    ></Input.Prompt>
                  </Input.Contents>
                </Input.Root>
              </Column.Content>
            </Column.Root>

            <Column.Root>
              <Column.Content>
                <Input.Root className="">
                  <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                    Bairro
                  </Input.Label>
                  <Input.Contents>
                    <Input.Prompt
                      className="is-rounded"
                      value={values}
                      setValue={setValues}
                      field={'bairro'}
                      required={true}
                      disabled={disabledList?.logradouro}
                      maskValue={'title'}
                      maskSetValue={'lower'}
                    ></Input.Prompt>
                  </Input.Contents>
                </Input.Root>
              </Column.Content>

              <Column.Content>
                <Input.Root className="">
                  <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                    Municipio
                  </Input.Label>
                  <Input.Contents>
                    <Input.Prompt
                      className="is-rounded"
                      value={values}
                      setValue={setValues}
                      field={'municipio'}
                      required={true}
                      disabled={disabledList?.logradouro}
                      maskValue={'title'}
                      maskSetValue={'lower'}
                    ></Input.Prompt>
                  </Input.Contents>
                </Input.Root>
              </Column.Content>

              <Column.Content size="is-2">
                <Input.Root className="">
                  <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                    UF
                  </Input.Label>
                  {values?.manualCompleted ? (
                    <Select.Root>
                      <Select.Content
                        color="is-grey"
                        className="is-fullwidth is-rounded"
                        value={values}
                        setValue={setValues}
                        field={'uf'}
                        disabled={disabledList?.uf}
                        required={true}
                      >
                        <Select.Option value={null} title={''} />
                        <Select.Option value={'AC'} title={'AC'} />
                        <Select.Option value={'AL'} title={'AL'} />
                        <Select.Option value={'AP'} title={'AP'} />
                        <Select.Option value={'AM'} title={'AM'} />
                        <Select.Option value={'BA'} title={'BA'} />
                        <Select.Option value={'CE'} title={'CE'} />
                        <Select.Option value={'DF'} title={'DF'} />
                        <Select.Option value={'ES'} title={'ES'} />
                        <Select.Option value={'GO'} title={'GO'} />
                        <Select.Option value={'MA'} title={'MA'} />
                        <Select.Option value={'MT'} title={'MT'} />
                        <Select.Option value={'MS'} title={'MS'} />
                        <Select.Option value={'MG'} title={'MG'} />
                        <Select.Option value={'PA'} title={'PA'} />
                        <Select.Option value={'PB'} title={'PB'} />
                        <Select.Option value={'PR'} title={'PR'} />
                        <Select.Option value={'PE'} title={'PE'} />
                        <Select.Option value={'PI'} title={'PI'} />
                        <Select.Option value={'RJ'} title={'RJ'} />
                        <Select.Option value={'RN'} title={'RN'} />
                        <Select.Option value={'RS'} title={'RS'} />
                        <Select.Option value={'RO'} title={'RO'} />
                        <Select.Option value={'RR'} title={'RR'} />
                        <Select.Option value={'SC'} title={'SC'} />
                        <Select.Option value={'SP'} title={'SP'} />
                        <Select.Option value={'SE'} title={'SE'} />
                        <Select.Option value={'TO'} title={'TO'} />
                      </Select.Content>
                    </Select.Root>
                  ) : (
                    <Input.ReadOnly className="is-rounded">{values.uf}</Input.ReadOnly>
                  )}
                </Input.Root>
              </Column.Content>
            </Column.Root>
          </div>
          <p className="ml-3 mb-2 has-text-grey-light subtitle" style={{ marginBottom: 0 }} required={true}>
            Informações do Responsavel
          </p>

          <div className="box mb-6">
            <Input.Root className="mb-3">
              <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                Nome do Responsavel
              </Input.Label>
              <Input.Contents>
                <Input.Prompt
                  className="is-rounded"
                  value={values}
                  setValue={setValues}
                  field={'reponsavel_name'}
                  required={true}
                  disabled={disabledList?.descricao}
                  maskSetValue={'lower'}
                ></Input.Prompt>
              </Input.Contents>
            </Input.Root>

            <Column.Root>
              <Column.Content>
                <Input.Root className="mb-3">
                  <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                    CPF do Responsável
                  </Input.Label>
                  <Input.Contents>
                    <Input.Prompt
                      className="is-rounded"
                      value={values}
                      setValue={setValues}
                      field={'responsavel_cpf'}
                      required={true}
                      disabled={disabledList?.descricao}
                      maskSetValue={'cpf'}
                    ></Input.Prompt>
                  </Input.Contents>
                </Input.Root>
              </Column.Content>
              <Column.Content>
                <Input.Root className="mb-3">
                  <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                    Telefone do Responsável
                  </Input.Label>
                  <Input.Contents>
                    <Input.Prompt
                      className="is-rounded"
                      value={values}
                      setValue={setValues}
                      field={'responsavel_telefone'}
                      required={true}
                      disabled={disabledList?.descricao}
                      maskSetValue={'tel'}
                    ></Input.Prompt>
                  </Input.Contents>
                </Input.Root>
              </Column.Content>
              <Column.Content size="">
                <Input.Root className="mb-3">
                  <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                    E-mail do Responsável
                  </Input.Label>
                  <Input.Contents>
                    <Input.Prompt
                      type="email"
                      className="is-rounded"
                      value={values}
                      setValue={setValues}
                      field={'responsavel_email'}
                      required={true}
                      disabled={disabledList?.descricao}
                    ></Input.Prompt>
                    <p className="has-text-danger ml-3">{statusUserAdmin}</p>
                  </Input.Contents>
                </Input.Root>
              </Column.Content>
            </Column.Root>
          </div>

          <p className="ml-3 mb-2 has-text-grey-light subtitle" style={{ marginBottom: 0 }} required={true}>
            Dados Bancários
          </p>

          <div className="box mb-6">
            <Column.Root>
              <Column.Content>
                <Input.Root className="mb-3">
                  <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                    Banco
                  </Input.Label>
                  <Select.Root>
                    <Select.Search
                      color="is-grey"
                      className="is-fullwidth is-rounded"
                      value={values}
                      setValue={setValues}
                      field={'infoBanks_banco'}
                      disabled={disabledList?.uf}
                      required={true}
                      options={options?.optionsBanks || []}
                    ></Select.Search>
                  </Select.Root>
                </Input.Root>
              </Column.Content>
              <Column.Content>
                <Input.Root className="mb-3">
                  <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                    Agência
                  </Input.Label>
                  <Input.Contents>
                    <Input.Prompt
                      className="is-rounded"
                      value={values}
                      setValue={setValues}
                      field={'infoBanks_agencia'}
                      required={true}
                      disabled={disabledList?.descricao}
                      maskSetValue={'tel'}
                    ></Input.Prompt>
                  </Input.Contents>
                </Input.Root>
              </Column.Content>
              <Column.Content size="">
                <Input.Root className="mb-3">
                  <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                    Conta
                  </Input.Label>
                  <Input.Contents>
                    <Input.Prompt
                      className="is-rounded"
                      value={values}
                      setValue={setValues}
                      field={'infoBanks_conta'}
                      required={true}
                      disabled={disabledList?.descricao}
                    ></Input.Prompt>
                  </Input.Contents>
                </Input.Root>
              </Column.Content>
            </Column.Root>
            <Column.Root>
              <Column.Content>
                <Input.Root className="mb-3">
                  <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                    Titular da Conta
                  </Input.Label>
                  <Input.Contents>
                    <Input.Prompt
                      className="is-rounded"
                      value={values}
                      setValue={setValues}
                      field={'infoBanks_name_titular'}
                      required={true}
                      disabled={disabledList?.descricao}
                      maskSetValue={'lower'}
                      maskValue={'title'}
                    ></Input.Prompt>
                  </Input.Contents>
                </Input.Root>
              </Column.Content>
              <Column.Content>
                <Input.Root className="mb-3">
                  <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                    CPF Titular
                  </Input.Label>
                  <Input.Contents>
                    <Input.Prompt
                      className="is-rounded"
                      value={values}
                      setValue={setValues}
                      field={'infoBanks_cpf_titular'}
                      required={true}
                      disabled={disabledList?.descricao}
                      maskSetValue={'cpf'}
                    ></Input.Prompt>
                  </Input.Contents>
                </Input.Root>
              </Column.Content>
              <Column.Content size="">
                <Input.Root className="mb-3">
                  <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                    PIX
                  </Input.Label>
                  <Input.Contents>
                    <Input.Prompt
                      className="is-rounded"
                      value={values}
                      setValue={setValues}
                      field={'infoBanks_pix'}
                      required={true}
                      disabled={disabledList?.descricao}
                    ></Input.Prompt>
                  </Input.Contents>
                </Input.Root>
              </Column.Content>
            </Column.Root>
          </div>

          <p className="ml-3 mb-2 has-text-grey-light subtitle" style={{ marginBottom: 0 }} required={true}>
            Cobrança
          </p>

          <div className="box mb-6">
            <Column.Root>
              <Column.Content size="">
                <Input.Root className="mb-3">
                  <Input.Label
                    className="ml-3 has-text-grey"
                    style={{ marginBottom: 0 }}
                    required={!disabledList?.dtStartContract}
                  >
                    Inicio do Contrato
                  </Input.Label>
                  <Input.Contents>
                    <Input.Prompt
                      className="is-rounded"
                      value={values}
                      setValue={setValues}
                      field={'dtStartContract'}
                      required={!disabledList?.dtStartContract}
                      disabled={disabledList?.dtStartContract}
                      type={'date'}
                    ></Input.Prompt>
                    {disabledList?.dtStartContract && (
                      <Input.Error>Campo somente prenchido na criação do cliente</Input.Error>
                    )}
                  </Input.Contents>
                </Input.Root>
              </Column.Content>
              <Column.Content>
                <Input.Root className="mb-3">
                  <Input.Label
                    className="ml-3 has-text-grey"
                    style={{ marginBottom: 0 }}
                    required={!disabledList?.freeTrial}
                  >
                    Período de Teste
                  </Input.Label>
                  <Select.Root>
                    <Select.Content
                      color="is-grey"
                      className="is-fullwidth is-rounded"
                      value={values}
                      setValue={setValues}
                      field={'freeTrial'}
                      disabled={disabledList?.freeTrial}
                    >
                      <Select.Option value="null" title="" />
                      <Select.Option value="00" title="Sem Período de Teste" />
                      <Select.Option value="01" title="01 Dia" />
                      <Select.Option value="03" title="03 Dias" />
                      <Select.Option value="05" title="05 Dias" />
                      <Select.Option value="07" title="07 Dias" />
                      <Select.Option value="10" title="10 Dias" />
                      <Select.Option value="15" title="15 Dias" />
                      <Select.Option value="20" title="20 Dias" />
                      <Select.Option value="25" title="25 Dias" />
                      <Select.Option value="30" title="30 Dias" />
                    </Select.Content>
                  </Select.Root>
                </Input.Root>
              </Column.Content>
              <Column.Content size="">
                <Input.Root className="mb-3">
                  <Input.Label
                    className="ml-3 has-text-grey"
                    style={{ marginBottom: 0 }}
                    required={!disabledList?.firstPayment}
                  >
                    Primeira Cobrança
                  </Input.Label>
                  <Input.Contents>
                    <Input.Prompt
                      className="is-rounded"
                      value={values}
                      setValue={setValues}
                      field={'firstPayment'}
                      required={!disabledList?.firstPayment}
                      disabled={disabledList?.firstPayment}
                      type={'date'}
                    ></Input.Prompt>
                  </Input.Contents>
                </Input.Root>
              </Column.Content>
              <Column.Content>
                <Input.Root className="mb-3">
                  <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                    Tipo de Pagamento
                  </Input.Label>
                  <Select.Root>
                    <Select.Content
                      color="is-grey"
                      className="is-fullwidth is-rounded"
                      value={values}
                      setValue={setValues}
                      field={'type_invoices'}
                      disabled={disabledList?.type}
                    >
                      <Select.Option value="null" title="" />
                      <Select.Option value="boleto" title="Pagamento via Boleto" />
                      <Select.Option value="pix" title="Pagamento via Pix" />
                    </Select.Content>
                  </Select.Root>
                </Input.Root>
              </Column.Content>
            </Column.Root>
          </div>

          <Column.Root className="is-mobile mr-6 pr-2">
            <Column.Content>
              <Input.Root className="mb-5">
                <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                  Planos
                </Input.Label>
                <Select.Root>
                  <Select.Content
                    color="is-grey"
                    className="is-fullwidth is-rounded"
                    value={values}
                    setValue={setValues}
                    field={'select_planos'}
                    disabled={disabledList?.type}
                  >
                    <Select.Option value={null} title={''} />
                    <Select.Search value={search} setValue={setSearch} />
                    {(options.planos || []).map((item, index) => {
                      return <Select.Option key={index} value={item.id} title={`${item.name} - R$ ${item.value}`} />
                    })}
                  </Select.Content>
                </Select.Root>
              </Input.Root>
            </Column.Content>
            <Column.Content size="is-1 mr-5">
              <Button.Bt
                className="mt-5 is-rounded"
                color="is-dark"
                colorText=""
                action={() => {
                  AddPlanInList(values.select_planos)
                }}
                loading={loading}
              >
                Adicionar
              </Button.Bt>
            </Column.Content>
          </Column.Root>

          <div className="box" style={{ marginTop: -30 }}>
            <div className="table-container">
              <Table.Root className="is-fullwidth has-text-centered mb-5 mt-2">
                <Table.Head>
                  <Table.Row>
                    <Table.Cell>Nº</Table.Cell>
                    <Table.Cell>Nome</Table.Cell>
                    <Table.Cell>Valor</Table.Cell>
                    <Table.Cell>Data Término</Table.Cell>
                    <Table.Cell size={400}>Ações</Table.Cell>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {(values?.plans || []).map((plan, index) => {
                    return (
                      <Table.Row key={`planslist_${plan.index}`}>
                        <Table.Cell className="is-vcentered" size={10}>
                          {plan.index}
                        </Table.Cell>
                        <Table.Cell className="is-vcentered">
                          <span title={plan.nome}>{plan.name}</span>
                        </Table.Cell>
                        <Table.Cell className="is-vcentered">R$ {plan.value}</Table.Cell>
                        <Table.Cell className="is-vcentered" size={200}>
                          <Input.Prompt
                            type="date"
                            className="is-rounded"
                            color={
                              (values?.plans || []).length > 1 &&
                              plan.index !== values.plans.length - 1 &&
                              !plan?.dtEndPlan
                                ? 'is-danger'
                                : 'is_light'
                            }
                            value={plan.dtEndPlan}
                            setValue={(value) => {
                              return setValues((prev) => {
                                const newPlan = { ...plan, dtEndPlan: value }
                                return {
                                  ...prev,
                                  plans: [
                                    ...(prev.plans || []).map((item) => {
                                      if (item.index !== plan.index) {
                                        return item
                                      }
                                      return newPlan
                                    }),
                                  ],
                                }
                              })
                            }}
                            required={(values?.plans || []).length > 1 && plan.index !== values.plans.length - 1}
                            disabled={disabledList?.dtEndPlan}
                          ></Input.Prompt>

                          {!plan?.dtEndPlan && <p className="is-size-7 has-text-danger">(Vitalício)</p>}
                        </Table.Cell>
                        <Table.Cell className="is-vcentered">
                          <Button.Root>
                            {!plan?.dtEndPlan && (
                              <Button.Content>
                                <Button.Bt
                                  className=""
                                  color="is-success"
                                  size={'is-small'}
                                  colorText="has-text-white"
                                  action={() => {
                                    AddEndDate(plan?.id)
                                  }}
                                  loading={loading}
                                  title={'Adicionar Data de Término'}
                                >
                                  <Icon size={15} className="mr-2">
                                    calendar
                                  </Icon>
                                  +
                                </Button.Bt>
                              </Button.Content>
                            )}

                            <Button.Content>
                              <Button.Bt
                                className=""
                                color="is-warning"
                                size={'is-small'}
                                colorText="has-text-white"
                                action={() => {
                                  RemoveEndDate(plan?.id)
                                }}
                                loading={loading}
                                title={'Remover Data de Término'}
                              >
                                <Icon size={15} className="mr-2">
                                  calendar
                                </Icon>
                                X
                              </Button.Bt>
                            </Button.Content>

                            <Button.Content>
                              <Button.Bt
                                className=""
                                color="is-danger"
                                size={'is-small'}
                                colorText="has-text-white"
                                action={() => {
                                  DeletePlansOfList(plan?.id)
                                }}
                                loading={loading}
                              >
                                <Icon size={15}>trash</Icon>
                              </Button.Bt>
                            </Button.Content>
                          </Button.Root>
                        </Table.Cell>
                      </Table.Row>
                    )
                  })}
                </Table.Body>
              </Table.Root>
            </div>
          </div>
        </Column.Content>
      </Column.Root>
      <Column.Root className="mt-5 mb-6">
        <Column.Content>
          <Button.Root align="right">
            <Button.Content>
              <Button.A color="is-light" link={Path.AClients} loading={loading}>
                {type === 'edit' ? 'Voltar' : 'Cancelar'}
              </Button.A>
            </Button.Content>
            <Button.Content>
              <Button.Bt className="" color="is-success" colorText="has-text-white" type={'submit'} loading={loading}>
                Salvar
              </Button.Bt>
            </Button.Content>
          </Button.Root>
        </Column.Content>
      </Column.Root>
    </div>
  )
}
