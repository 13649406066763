import { Column } from '../../../components/layouts/columns'
import LogoKeap from '../../../assets/Prancheta 5.png'

export const Header = ({ children, titlePage, classname }) => {
  return (
    <Column.Root className={classname}>
      <Column.Content
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 2.5rem 0 2.5rem',
          marginTop: 40,
        }}
      >
        {children}
        <p style={{ margin: 0 }}>{titlePage}</p>
        <img src={LogoKeap} alt="KEAP Staff" style={{ width: '5rem' }} />
      </Column.Content>
    </Column.Root>
  )
}
