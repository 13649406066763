import { Column } from '../../../../../components/layouts/columns'

const CardNotification = ({ children, type, date }) => {
  return (
    <Column.Content>
      <article
        className={`message has-text-left ${type === 'error' ? 'is-danger' : type === 'success' ? 'is-primary' : type === 'warning' ? 'is-warning' : ''}`}
      >
        <div className="message-body">
          {children}
          <div
            className="has-text-right"
            style={{
              fontSize: '.75rem',
            }}
          >
            {date}
          </div>
        </div>
      </article>
    </Column.Content>
  )
}

export default CardNotification
