import { Check, ChevronRight, Gift, RotateCw, SearchCheck, ShoppingCart } from 'lucide-react'

import { Path } from '../../../router/paths'

export const PlanSelectCard = ({ plan, user }) => {
  return (
    <a
      href={Path.CollabSelectedPlan(plan.id)}
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: '1rem 0 1rem 0',
        color: 'rgb(151, 151, 151)',
      }}
    >
      <p
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        {user?.plans?.idPlan === plan.id && <Check color="#37f64a" />}
        <b>{plan.name}</b>
      </p>
      <div
        style={{
          display: 'flex',
          gap: '1rem',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <p>
            Pagamento via <b>{plan.payment}</b>
          </p>
          <div
            style={{
              display: 'flex',
              gap: '1rem',
              padding: '0 0 .5rem 0',
            }}
          >
            <RotateCw />
            <p>
              Cobrança a cada <b>{plan.typeExpired} Dias</b>
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              gap: '1rem',
              padding: '0 0 .5rem 0',
            }}
          >
            <ShoppingCart />
            <p>
              Valor: <b>R$ {plan.value}</b>
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              gap: '1rem',
              padding: '0 0 .5rem 0',
            }}
          >
            <Gift />
            <p>
              Este plano tem <b>{plan.trialDays} dias Gratis!</b>
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              gap: '1rem',
              padding: '0 0 .5rem 0',
            }}
          >
            <SearchCheck />
            <p>
              Taxa de Análise: R$ <b>{plan.admission}</b>
            </p>
          </div>
        </div>
        <div>
          <ChevronRight />
        </div>
      </div>

      <hr className={user?.plans?.idPlan === plan.id ? `has-background-success` : 'has-background-grey-lighter'} />
    </a>
  )
}
