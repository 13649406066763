import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useGlobalContext } from '../../../context/ContextGlobal'
import { TryCatch } from '../../../utils/general'
import {
  addDocument,
  generateIdSubDocument,
  getDocument,
  getDocumentsWithQuery,
  getSubDocuments,
  getSubDocumentsWithQuery,
  incrementFieldInDocument,
  setSubDocument,
  updateDocument,
  updateSubDocument,
} from '../../../firebase/firestore'
import { ConvertTimestampToDateTime, DateTimeNow, TimestampNow } from '../../../utils/dates'
import { Path } from '../../../router/paths'
import { createSchemaColabOmie } from '../_utils/omie'
import { ClientOmie } from '../../../api/omie'

const useCollaboratorSelectionSignPlan = () => {
  const { user, alerts, setAlerts, setLoading, loading, formContext, setFormContext } = useGlobalContext()

  const { token, planId } = useParams()

  const navigate = useNavigate()

  const uid = user.id

  // const navigate = useNavigate()

  const getForm = async () => {
    const getPlan = await getDocument('plans', planId)

    setFormContext({ ...getPlan })
  }

  const FetchGetForm = async () => {
    await TryCatch(getForm, setLoading, setAlerts)
  }

  function checkPaymentUntilResolved(idPayment) {
    return new Promise((resolve) => {
      const interval = setInterval(async () => {
        const getPayment = await getSubDocumentsWithQuery(
          'colaboradores',
          uid,
          'payments',
          'idPayment',
          '==',
          idPayment,
        )
        console.log(getPayment)
        if (getPayment.length > 0) {
          const payment = getPayment[0]

          if (payment.status === 'awaitPayment' || payment.status === 'error' || payment.status === 'paymentSuccess') {
            clearInterval(interval)
            resolve(payment) // Retorna o pagamento encontrado
          }
        }
      }, 2000)
    })
  }

  const JoinCollabInPlan = async (plan) => {
    let createPayment = true

    const getUser = await getDocument('colaboradores', uid)

    // cancela planos existentes
    const getPlansInCollab = await getSubDocuments('colaboradores', uid, 'plans')
    const newPlansCanceleds = getPlansInCollab.map(async (p) => {
      await updateSubDocument('colaboradores', uid, 'plans', p.id, {
        status: 'canceled',
      })
    })
    await Promise.all(newPlansCanceleds)

    // cria um novo plano
    const newPlan = {
      idPlan: plan.id,
      idOmie: plan.idOmie,
      name: plan.name,
      value: plan.value,
      payment: plan.payment,
      recurrence: Number(plan.typeExpired || '30'),
      admission: plan.admission,
      dtJoin: TimestampNow(),
      status: 'active',
    }

    const { lastPayment, nextPayment, cupons } = getUser

    const hasTrial = getUser?.hasTrial || false
    const admissionPayment = getUser?.admissionPayment || false
    const trialDays = Number(plan.trialDays || '0')

    const updateCollab = {
      lastPayment,
      nextPayment,
      hasTrial,
      cupons: cupons ?? {},
    }

    if ((!admissionPayment || !hasTrial) && trialDays > 0) {
      // aplica ao collab o periodo de teste
      updateCollab.hasTrial = true

      // cria nova data de cobraça se possuir gratuidade
      const diasTrial = trialDays * 86400000 // 1 dia = 86.400.000 ms
      const dtStart = Number(newPlan.dtJoin) + diasTrial

      // adiciona o ultimo pagamento para não cobrar
      updateCollab.lastPayment = 0
      updateCollab.nextPayment = dtStart
      updateCollab.dtFreeTrial = dtStart
    } else {
      if (!updateCollab.lastPayment) {
        updateCollab.lastPayment = 0
      }
      if (!updateCollab.nextPayment) {
        updateCollab.nextPayment = TimestampNow()
        updateCollab.dtFreeTrial = TimestampNow()
        // updateCollab.nextPayment = Number(updateCollab.lastPayment) + newPlan.recurrence * 86400000;
      }
    }

    let newPlanCupom = {}
    if (Object.keys(formContext?.cupomSelected ?? {}).length > 0) {
      const cupom = formContext?.cupomSelected
      const daysExpired = Number(cupom.daysExpired || '0')
      const dayInTimestamp = 86400000
      // tira um dia para quando verncer o cupom, criar o pagamento da assinatura
      const daysEndPlan = daysExpired * dayInTimestamp // 1 dia = 86.400.000 ms
      const dtEndPlan = TimestampNow() + daysEndPlan - dayInTimestamp
      const nextPaymentUpdate = dtEndPlan + dayInTimestamp
      console.log(dtEndPlan)
      console.log(ConvertTimestampToDateTime(dtEndPlan))
      newPlanCupom = {
        idPlan: cupom.id,
        idOmie: cupom.idOmie,
        name: cupom.name,
        value: cupom.value,
        payment: plan.payment,
        recurrence: daysExpired,
        admission: cupom.admission,
        dtJoin: TimestampNow(),
        status: 'active',
        dtEndPlan,
      }
      // salva no cadastro do colaborador que ele ja usou esse cupom
      updateCollab.cupons[cupom.codCupom] = true

      // se o cupom tiver valor zero, é porque é gratuidade ou tempo de teste
      if (Number(newPlanCupom.value) === 0) {
        updateCollab.nextPayment = nextPaymentUpdate
        updateCollab.dtFreeTrial = getUser?.dtFreeTrial ?? nextPaymentUpdate
        createPayment = false
        updateCollab.admissionPayment = true
      }
    }

    // cria um usuario na omie para o colaborador
    const userOmie = createSchemaColabOmie(user)
    let createClientOmie
    let cCodClientOmie = ''
    try {
      // tenta criar um cliente na omie
      createClientOmie = await ClientOmie().incluirCliente([userOmie])
    } catch (error) {
      // se eele retornar que ja existe e é um novo cadastro, então segue o fluxo, se não da erro
      if (error.message.includes('102')) {
        cCodClientOmie = String(error.message).split('nCod')[1].match(/\d+/)[0]
      } else if (error.message.includes('101')) {
        const errorMessage = String(error.message).toLowerCase()
        cCodClientOmie = errorMessage.match(/com o id \[([^\]]+)\]/)[1]
      } else {
        return setAlerts((prev) => [
          ...prev,
          {
            type: 'error',
            title: 'Criação de cadastro não concluído',
            msg: 'Ocorreu um erro ao Criação de cadastro, contate o suporte da keap para ajudar a concluir o pagamento.',
          },
        ])
      }
    }
    // se não deu error, então ele pega o código da omie e atualiza o cadastro do colaborador
    if (createClientOmie) {
      cCodClientOmie = createClientOmie.codigo_cliente_omie
    }
    console.log(cCodClientOmie)

    if (!cCodClientOmie) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Criação de cadastro não concluído',
          msg: 'Ocorreu um erro ao Criação de cadastro, contate o suporte da keap para ajudar a concluir o pagamento.',
        },
      ])
    }

    // atualiza o colaborador com o codigo da omie
    await updateDocument('colaboradores', user.id, { cCodClientOmie })

    let newPayment = {}

    const idPayment = await generateIdSubDocument('colaboradores', uid, 'payments')

    if (createPayment) {
      const now = TimestampNow()
      const nowLastPayment = getUser?.lastPayment ?? null
      const nowNextPayment = getUser?.nextPayment ?? null

      if ((nowLastPayment === null || nowLastPayment >= now) && (nowNextPayment === null || nowNextPayment <= now)) {
        // se nenhuma das assinaturas forem 0, então ele gera um pagamento
        const plans = [{ ...newPlan }, Object.keys(newPlanCupom ?? {}).length > 0 && { ...newPlanCupom }]

        const planFromPayment = plans
          .sort((a, b) => {
            if (a.dtEndPlan === undefined) return 1 // Move 'a' para o final se não tiver dtEndPlan
            if (b.dtEndPlan === undefined) return -1 // Move 'b' para o final se não tiver dtEndPlan
            return a.dtEndPlan - b.dtEndPlan // Ordena pelo dtEndPlan crescente
          })
          .find((item) => (item.dtEndPlan ?? Infinity) >= TimestampNow())

        newPayment = {
          dt_created: DateTimeNow(),
          errors: 0,
          idPayment,
          idPlan: planFromPayment.idPlan,
          idUser: uid,
          name: planFromPayment.name,
          status: 'createPayment',
          type: updateCollab.lastPayment > 0 ? 'payment' : 'admission',
          typeUser: 'collab',
          typePayment: planFromPayment.payment || 'pix',
          value: planFromPayment.value,
          log: [
            {
              status: `Contratação da assinatura pelo colaborador`,
              time: TimestampNow(),
              origin: 'colaboradores/planSelected',
            },
          ],
        }
        if (planFromPayment.payment === 'pix') {
          await addDocument('_payments', newPayment)
        }
        // adicionar aqui novas formas de pagamento
      }
    } else {
      newPayment = {
        dt_created: DateTimeNow(),
        errors: 0,
        idPayment,
        dt_payment: DateTimeNow(),
        idPlan: newPlanCupom.idPlan,
        idUser: uid,
        name: `Cupom ${formContext?.codCupomSelected} Aplicado`,
        status: 'paymentSuccess',
        type: 'admission',
        typeUser: 'collab',
        typePayment: newPlanCupom.payment || 'pix',
        value: newPlanCupom.value,
        log: [
          {
            status: `Cupom ${formContext?.codCupomSelected} aplicado ao colaborador`,
            time: TimestampNow(),
            origin: 'colaboradores/planSelected',
          },
        ],
      }
    }
    console.log(idPayment)
    console.log(newPayment)

    await setSubDocument('colaboradores', uid, 'plans', newPlan.idPlan, newPlan)

    if (Object.keys(formContext?.cupomSelected ?? {}).length > 0) {
      // salva o cupom no colaborador como uma assinatura
      await setSubDocument('colaboradores', uid, 'plans', newPlanCupom.idPlan, newPlanCupom)
      // aplica no climite do cupom
      await incrementFieldInDocument('plans', newPlanCupom.idPlan, 'apllieds', 1)
    }

    // Gera o pagamento no colaborador
    let awaitPayment
    if (Object.keys(newPayment).length > 0) {
      await setSubDocument('colaboradores', uid, 'payments', idPayment, newPayment)
      awaitPayment = await checkPaymentUntilResolved(idPayment)
      console.log(awaitPayment)
    } else {
      awaitPayment = { status: 'PlanChanged' }
    }

    if (awaitPayment.status === 'error') {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Erro ao gerar pagamento',
          msg: 'Ocorreu um erro ao gerar o pagamento, contate o suporte da keap para ajudar a concluir o pagamento.',
        },
      ])
    }

    // somente deixa o colaborador seguir se ele escolher um plano
    await updateDocument('colaboradores', uid, { emailVerify: true, ...updateCollab })

    if (awaitPayment.status === 'PlanChanged') {
      return navigate(Path.CollabHome(token))
    }

    return navigate(Path.CollabHistorySelected(token, awaitPayment.id))
  }

  const ApplyCupom = async () => {
    const codCupom = formContext?.codCupomSelected?.trim() ?? ''

    // valida se o cupom é valido
    if (!codCupom || /^#+$/.test(codCupom)) {
      return setAlerts((prev) => [...prev, { type: 'error', title: 'Cupom Inválido', msg: 'Digite um código válido.' }])
    }

    setFormContext((prev) => ({
      ...prev,
      cupomSelected: {},
      cupomAplly: false,
    }))

    const getUser = await getDocument('colaboradores', user.id)

    // valida se o cupom ja foi utilizado
    if (Object.keys(getUser?.cupons ?? {}).includes(codCupom)) {
      return setAlerts((prev) => [
        ...prev,
        { type: 'error', title: 'Cupom Já Aplicado', msg: 'O Cupom digitado, ja foi aplicado a seu cadastro...' },
      ])
    }

    const cupons = await getDocumentsWithQuery('plans', 'codCupom', '==', codCupom)

    // valida se existe
    if ((cupons || []).length === 0) {
      return setAlerts((prev) => [
        ...prev,
        { type: 'error', title: 'Cupom Indisponível', msg: 'O Cupom digitado, está indisponível...' },
      ])
    }

    const newCupom = { ...(cupons?.[0] || {}) }

    // valida o limite de uso do cupom foi atingido
    if (Number(newCupom?.limitAplly) <= Number(newCupom?.apllieds)) {
      return setAlerts((prev) => [
        ...prev,
        { type: 'error', title: 'Cupom expirado', msg: 'O Cupom digitado, atingiu o limite de cotas...' },
      ])
    }

    setFormContext((prev) => ({
      ...prev,
      cupomSelected: newCupom,
      cupomAplly: true,
    }))
  }

  const HandleApplyCupom = async () => {
    await TryCatch(ApplyCupom, setLoading, setAlerts)
  }

  const HandleJoinCollabInPlan = async (plan) => {
    await TryCatch(
      async () => {
        await JoinCollabInPlan(plan)
      },
      setLoading,
      setAlerts,
    )
  }

  useEffect(() => {
    FetchGetForm()
  }, [])

  return {
    user,
    loading,
    FetchGetForm,
    formContext,
    HandleJoinCollabInPlan,
    alerts,
    setAlerts,
    setFormContext,
    HandleApplyCupom,
  }
}

export default useCollaboratorSelectionSignPlan
