import { useEffect } from 'react'

import { useGlobalContext } from '../../../context/ContextGlobal'

// const { useGlobalContext } = require("../../../context/ContextGlobal")

const useNotAutorization = () => {
  const { user, setUser } = useGlobalContext()

  const not = 'Não autorizado'

  const clear = async () => {
    setUser({})
  }

  useEffect(() => {
    clear()
  }, [])

  return { user, not }
}

export default useNotAutorization
