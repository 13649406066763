import axios from 'axios'

const Decoder = (incorrectString) => {
  const decoder = new TextDecoder('utf-8')
  let correctedString = decoder.decode(new Uint8Array(incorrectString.split('').map((char) => char.charCodeAt(0))))
  correctedString = correctedString.replace('<br>', ' ').replace('</br>', ' ')
  return correctedString
}

export const RequestOmie = async (data, setAlerts = null) => {
  const url = 'https://us-central1-keap-staff.cloudfunctions.net/requestApiOmie'
  // console.log(data)
  try {
    const response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    // console.log(response)
    return response.data
  } catch (error) {
    // console.log(error)
    if (setAlerts) {
      return setAlerts((prev) => [
        ...prev,
        {
          title: 'error',
          msg: `${Decoder(error.response.data.error || error.message)}`,
          type: 'error',
        },
      ])
    } else {
      throw new Error(`${Decoder(error.response.data.error || error.message)}`)
    }
  }
}

export const ClientOmie = () => {
  const endpoint = 'https://app.omie.com.br/api/v1/geral/clientes/'
  return {
    incluirCliente: async (param) => {
      const call = 'IncluirCliente'
      // console.log(param)
      const response = await RequestOmie({ endpoint, call, param })
      return response
    },
    alterarCliente: async (param) => {
      const call = 'AlterarCliente'
      const response = await RequestOmie({ endpoint, call, param })
      return response
    },
    excluirCliente: async (cCodClientOmie) => {
      const call = 'ExcluirCliente'
      const param = [{ codigo_cliente_omie: cCodClientOmie }]
      const response = await RequestOmie({ endpoint, call, param })
      return response
    },
  }
}
export const ServicesOmie = () => {
  const endpoint = 'https://app.omie.com.br/api/v1/servicos/servico/'
  return {
    IncluirServico: async (param) => {
      const call = 'IncluirCadastroServico'
      const response = await RequestOmie({ endpoint, call, param })
      return response
    },
    AlterarServico: async (param) => {
      const call = 'AlterarCadastroServico'
      const response = await RequestOmie({ endpoint, call, param })
      return response
    },
    ExcluirServico: async (cCodIntServ) => {
      const call = 'ExcluirCadastroServico'
      const param = [{ cCodIntServ }]
      const response = await RequestOmie({ endpoint, call, param })
      return response
    },
  }
}

export const OrdemDeServico = () => {
  const endpoint = 'https://app.omie.com.br/api/v1/servicos/os/'
  return {
    consultar: async (param) => {
      const call = 'ConsultarOS'
      const response = await RequestOmie({ endpoint, call, param })
      return response
    },
    status: async (param) => {
      const call = 'StatusOS'
      const response = await RequestOmie({ endpoint, call, param })
      return response
    },
  }
}

// const data = {
//   endpoint: 'https://app.omie.com.br/api/v1/geral/clientes/',
//   call: 'IncluirCliente',
//   param: [
//     {
//       codigo_cliente_integracao: 'teste2',
//       email: 'primeiro@ccliente.com.br',
//       razao_social: 'Primeiro Cliente  Ltda Me',
//       nome_fantasia: 'Primeiro Cliente',
//       cnpj_cpf: 11111111111,
//     },
//   ],
// }

export const CNAEOmie = () => {
  const endpoint = 'https://app.omie.com.br/api/v1/produtos/cnae/'
  return {
    Listar: async (param) => {
      const call = 'ListarCNAE'
      // console.log(param)
      const response = await RequestOmie({ endpoint, call, param })
      // console.log(response)
      return response
    },
  }
}

export const AtividadesOmie = () => {
  const endpoint = 'https://app.omie.com.br/api/v1/geral/tpativ/'
  return {
    Listar: async (param) => {
      const call = 'ListarTipoAtiv'
      // console.log(param)
      const response = await RequestOmie({ endpoint, call, param })
      // console.log(response)
      return response
    },
  }
}

export const BancosOmie = () => {
  const endpoint = 'https://app.omie.com.br/api/v1/geral/bancos/'
  return {
    Listar: async (param) => {
      const call = 'ListarBancos'
      // console.log(param)
      const response = await RequestOmie({ endpoint, call, param })
      // console.log(response)
      return response
    },
  }
}

// const data = {
//   endpoint: 'https://app.omie.com.br/api/v1/geral/clientes/',
//   call: 'IncluirCliente',
//   param: [
//     {
//       codigo_cliente_integracao: 'teste2',
//       email: 'primeiro@ccliente.com.br',
//       razao_social: 'Primeiro Cliente  Ltda Me',
//       nome_fantasia: 'Primeiro Cliente',
//       cnpj_cpf: 11111111111,
//     },
//   ],
// }
