import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

import { useGlobalContext } from '../../../../../context/ContextGlobal'
import { addDocument, setDocument } from '../../../../../firebase/firestore'
import { Path } from '../../../../../router/paths'
import { DateTimeNow } from '../../../../../utils/dates'
import { TryCatch } from '../../../../../utils/general'
import { schemaDocument } from '../utils/schemas'

const useAddDocuments = () => {
  const {
    user,
    setAlerts,
    // dataContext,
    // setDataContext,
    // infosContext,
    // setInfosContext,
    formContext,
    setFormContext,
    loading,
    setLoading,
    // statusLoading,
    // setStatusLoading,
  } = useGlobalContext()

  const navigate = useNavigate()

  const getForm = () => {
    setFormContext({
      sendInRegister: 'false',
      type: 'capture&galery',
      facing: 'select',
      notDelete: 'true',
    })
  }

  const FetchGetForm = async () => {
    await TryCatch(getForm, setLoading, setAlerts)
  }

  const addDocumentation = async () => {
    const newDocumentation = {
      ...schemaDocument(formContext),
      created_by: user?.user?.nome,
      dt_created: DateTimeNow(),
    }
    const idDocument = await addDocument('documentos', newDocumentation)
    const sendInRegister = newDocumentation?.sendInRegister || null
    if (sendInRegister === 'true') {
      const newDocument = {
        name: newDocumentation?.name,
        sendDate: '',
        status: false,
        type: 'document',
      }
      await setDocument('_defaultRegister', idDocument, newDocument)
    }
    setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Docuemnto Criado',
        msg: 'Novo Docuemnto criado com sucesso.',
      },
    ])
    setFormContext({})
    return navigate(Path.AdminDocuments)
  }

  const handleAddDocumentation = async () => {
    // console.log('entrou')

    await TryCatch(addDocumentation, setLoading, setAlerts)
  }

  useEffect(() => {
    FetchGetForm()
  }, [])

  return { user, loading, formContext, setFormContext, handleAddDocumentation }
}

export default useAddDocuments
