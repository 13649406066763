import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { TryCatch } from '../../../../utils/general'
import {
  addDocument,
  deleteDocument,
  deleteSubDocument,
  generateIdSubDocument,
  getDocument,
  // addSubDocument,
  // deleteSubCollection,
  // // addSubDocument,
  // // deleteSubCollection,
  getDocuments,
  getDocumentsWithQuery,
  getSubDocuments,
  getSubDocumentsWithQuery,
  setSubDocument,
  updateSubDocument,
  // updateDocument,
} from '../../../../firebase/firestore'
// import { createSchemaClientPlans } from '../../clients/_utils'
import { DateTimeNow, TimestampNow } from '../../../../utils/dates'
import { OrdemDeServico } from '../../../../api/omie'
import { colorTagStatusPagamentos } from '../../clients/_utils'
// import { createSchemaClientPlans } from '../../clients/_utils'
// import { createSchemaClientPlans } from '../../clients/_utils'
export const useCollaboratorPayments = () => {
  const {
    user,
    setAlerts,
    loading,
    setLoading,
    dataContext,
    setDataContext, // contexto que guarda dados de lista para a pagina
    infosContext,
    setInfosContext, // contexto que guarda dados adicionais para a pagina funcionar
    formContext,
    setFormContext, // contexto que guarda dados de formularios da pagina
  } = useGlobalContext()

  const paymentsDatas = dataContext?.payments || {}
  const [search, setSearch] = useState('')
  const [modalHistory, setModalHistory] = useState(false)

  const { id } = useParams()

  const getDatas = async () => {
    const getcollab = await getDocument('colaboradores', id)
    const getPayments = await getSubDocuments('colaboradores', id, 'payments')
    const getPlans = await getSubDocumentsWithQuery('colaboradores', id, 'plans', 'status', '==', 'active')
    // salva todas as informações coletadas no context.
    setDataContext({ payments: getPayments })
    setFormContext({ ...getcollab, plans: getPlans })
  }

  const getInfos = async () => {
    // coleta as permissões globais em CONFIG/PERMISSIONS
    const getPlans = infosContext?.plans || (await getDocuments('plans'))

    setInfosContext({
      plans: getPlans,
    })
  }

  // console.log(infosContext.plans)

  const consultOsOmie = async (payment) => {
    const param = [
      {
        cCodIntOS: payment.idPayment,
        lPdfDemo: true,
        lPdfDest: true,
        lRps: true,
        lPdfRecibo: true,
      },
    ]
    const consultarOS = await OrdemDeServico().status(param)

    const responseNFE =
      consultarOS.ListaRpsNfse && consultarOS.ListaRpsNfse.length > 0 ? consultarOS.ListaRpsNfse[0] : null

    if (!responseNFE) {
      throw new Error('Nenhuma resposta válida na Omie para esta OS.')
    }

    const newResponseOmie = {
      cUrlPdfDemo: responseNFE?.cUrlPdfDemo || '',
    }

    // console.log(newResponseOmie)

    // console.log(responseNFE)

    const newPayment = {
      ...payment,
      responseOmie: {
        ...payment.responseOmie,
        ...newResponseOmie,
      },
    }
    // console.log(newPayment)

    await updateSubDocument('colaboradores', id, 'payments', newPayment.id, newPayment)

    if (newResponseOmie?.cUrlPdfDemo) {
      return window.open(newResponseOmie?.cUrlPdfDemo, '_blank')
    } else {
      return setAlerts((prev) => [
        {
          type: 'error',
          title: 'Nota fiscal não encontrada',
          msg: 'Acesse o portal da omie, e verifique a situação dessa nota fiscal.',
        },
        ...prev,
      ])
    }
  }

  const HandleConsultarOsOmie = async (value) => {
    await TryCatch(
      async () => {
        await consultOsOmie(value)
      },
      setLoading,
      setAlerts,
    )
  }

  const newPayment = async () => {
    const isConfirmed = window.prompt('Tem certeza criar uma cobrança cobrança? Digite "CONFIRMAR" para continuar.')

    // console.log(String(isConfirmed).toUpperCase())
    if (String(isConfirmed).toUpperCase() !== 'CONFIRMAR') {
      return setAlerts((prev) => [
        {
          type: 'error',
          title: 'Confirmação Recusada',
          msg: 'Para continuar com a operação é necessário confirmar.',
        },
        ...prev,
      ])
    }

    /// const getUser = await getDocument('colaboradores', id)
    const getPlans = await getSubDocuments('colaboradores', id, 'plans')

    if (getPlans.length === 0) {
      return setAlerts((prev) => [
        {
          type: 'error',
          title: 'Nenhum Plano selecionado',
          msg: 'Não foi encontrado planos vinculados a esse cliente.',
        },
        ...prev,
      ])
    }

    // coleta o plano que tem o dtEnd
    const planData = getPlans
      .sort((a, b) => {
        if (a.dtEndPlan === undefined || a.dtEndPlan === '') return 1 // Move 'a' para o final se não tiver dtEndPlan
        if (b.dtEndPlan === undefined || b.dtEndPlan === '') return -1 // Move 'b' para o final se não tiver dtEndPlan
        return a.dtEndPlan - b.dtEndPlan // Ordena pelo dtEndPlan crescente
      })
      .find((item) => {
        const dtEnd = item.dtEndPlan && item.dtEndPlan !== '' ? item.dtEndPlan : Infinity
        return dtEnd >= TimestampNow()
      })

    // console.log(planData)

    if (planData.length === 0) {
      return setAlerts((prev) => [
        {
          type: 'error',
          title: 'Nenhum Plano Ativo',
          msg: 'Não foi encontrado planos ativos nesse cliente.',
        },
        ...prev,
      ])
    }

    const idPayment = await generateIdSubDocument('colaboradores', id, 'payments')

    const newPaymentData = {
      dt_created: DateTimeNow(),
      errors: 0,
      idPayment,
      idPlan: planData.id,
      idUser: id,
      name: planData.name,
      status: 'createPayment',
      type: 'payment',
      typeUser: 'collab',
      typePayment: planData.payment || 'pix',
      value: planData.value,
      log: [
        {
          status: 'Pagamento Gerado Manualmente',
          time: TimestampNow(),
          origin: 'web/collaboradores/newPayment/' + user.user.nome,
        },
      ],
    }

    await addDocument('_payments', newPaymentData)
    await setSubDocument('colaboradores', id, 'payments', idPayment, newPaymentData)

    FetchGetDatas()

    return setAlerts((prev) => [
      {
        type: 'success',
        title: 'Pagamento gerado',
        msg: 'Um novo pagamento foi adicionado, aguarde o processamento do pagamento ser concluído.',
      },
      ...prev,
    ])
  }

  const HandleNewPayment = async () => {
    await TryCatch(newPayment, setLoading, setAlerts)
  }

  const selectPayment = async (payment) => {
    setDataContext((prev) => ({ ...prev, paymentSelected: payment }))
    setModalHistory(true)
  }

  const CancelPayment = async (payment) => {
    const isConfirmed = window.prompt(
      'Tem certeza que deseja cancelar esta cobrança? Digite "CANCELAR" para continuar.',
    )

    if (String(isConfirmed).toUpperCase() !== 'CANCELAR') {
      return setAlerts((prev) => [
        {
          type: 'error',
          title: 'Confirmação Recusada',
          msg: 'Para continuar com a operação é necessário confirmar.',
        },
        ...prev,
      ])
    }

    delete payment.id

    const getPaymentsActives = await getDocumentsWithQuery('_payments', 'idPayment', '==', id)

    const promisses = getPaymentsActives.map(async (pay) => {
      await deleteDocument('_payments', pay.id)
    })

    await Promise.all(promisses)

    const newPaymentData = {
      ...payment,
      status: 'canceling',
      log: [
        {
          status: 'Solicitação de Cancelamento Enviado',
          time: TimestampNow(),
          origin: 'web/clients/newPayment/' + user.user.nome,
        },
        ...(payment?.log || []),
      ],
    }

    await addDocument('_payments', newPaymentData)
    await deleteSubDocument('colaboradores', id, 'payments', payment.idPayment)
    await setSubDocument('colaboradores', id, 'payments', payment.idPayment, newPaymentData)

    FetchGetDatas()

    return setAlerts((prev) => [
      {
        type: 'success',
        title: 'Solicitação de Cancelamento Enviado',
        msg: 'Processo de cancelamento em andamento, aguarde o processamento ser concluído.',
      },
      ...prev,
    ])
  }

  const DeletePayment = async (payment) => {
    const isConfirmed = window.prompt('Tem certeza que deseja excluir esta cobrança? Digite "EXCLUIR" para continuar.')

    if (String(isConfirmed).toUpperCase() !== 'EXCLUIR') {
      return setAlerts((prev) => [
        {
          type: 'error',
          title: 'Confirmação Recusada',
          msg: 'Para continuar com a operação é necessário confirmar.',
        },
        ...prev,
      ])
    }

    await deleteSubDocument('colaboradores', id, 'payments', payment.id)
    await FetchGetDatas()
    return setAlerts((prev) => [
      {
        type: 'success',
        title: 'Pagamento Deletado',
        msg: 'Para continuar com a operação é necessário confirmar.',
      },
      ...prev,
    ])
  }

  const ReprocessPayment = async (payment) => {
    const isConfirmed = window.prompt(
      'Tem certeza que deseja reprocessar esta cobrança? Digite "CONFIRMAR" para continuar.',
    )

    if (String(isConfirmed).toUpperCase() !== 'CONFIRMAR') {
      return setAlerts((prev) => [
        {
          type: 'error',
          title: 'Confirmação Recusada',
          msg: 'Para reprocessar uma cobrança é necessário confirmar.',
        },
        ...prev,
      ])
    }

    const payments = await getDocumentsWithQuery('_payments', 'idPayment', '==', payment.idPayment)

    const deletartodosPayments = payments.map(async (pay) => {
      // console.log('pay.id: ', pay.id)
      await deleteDocument('_payments', pay.id)
    })

    await Promise.all(deletartodosPayments)

    const { statusOld, ...paymentData } = payment

    // console.log(statusOld)

    const newPayment = {
      ...paymentData,
      status: statusOld ?? payment?.status,
      log: [
        {
          status: 'Pagamento reprocessado com status: ' + statusOld ?? payment?.status,
          time: TimestampNow(),
          origin: 'web/clients/newPayment/' + user.user.nome,
        },
        ...(paymentData?.log || []),
      ],
    }

    await addDocument('_payments', newPayment)
    await setSubDocument('colaboradores', id, 'payments', newPayment.id, newPayment)

    await FetchGetDatas()
    return setAlerts((prev) => [
      {
        type: 'success',
        title: 'Pagamento Reprocessado',
        msg: 'Solicitação de repossesamento enviado, aguarde a conclusão.',
      },
      ...prev,
    ])
  }

  const HandleReprocessPayment = async (value) => {
    await TryCatch(
      async () => {
        await ReprocessPayment(value)
      },
      setLoading,
      setAlerts,
    )
  }

  const HandleDeletePayment = async (value) => {
    await TryCatch(
      async () => {
        await DeletePayment(value)
      },
      setLoading,
      setAlerts,
    )
  }

  const HandleCancelPayment = async (value) => {
    await TryCatch(
      async () => {
        await CancelPayment(value)
      },
      setLoading,
      setAlerts,
    )
  }

  const HandleSelectPayment = async (value) => {
    await TryCatch(
      async () => {
        await selectPayment(value)
      },
      setLoading,
      setAlerts,
    )
  }

  const FetchGetDatas = async () => {
    await TryCatch(getDatas, setLoading, setAlerts)
  }

  const FetchGetInfos = async () => {
    await TryCatch(getInfos, setLoading, setAlerts)
  }

  const dataFiltered =
    search !== ''
      ? Object.values(paymentsDatas).filter((pay) => {
          const searchString =
            `${pay.type}|${pay.name}|${pay.value}|${colorTagStatusPagamentos(pay.status).value}`.toLowerCase()
          return searchString.includes(search.toLowerCase())
        })
      : Object.values(paymentsDatas)

  useEffect(() => {
    FetchGetDatas()
    FetchGetInfos()
  }, [])

  return {
    loading,
    search,
    setSearch,
    dataFiltered,
    modalHistory,
    setModalHistory,
    // assinaturasDatas,
    dataContext,
    setDataContext,
    formContext,
    setFormContext,
    infosContext,
    FetchGetDatas,
    FetchGetInfos,
    HandleSelectPayment,
    HandleCancelPayment,
    HandleConsultarOsOmie,
    HandleDeletePayment,
    HandleNewPayment,
    HandleReprocessPayment,
  }
}
