/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { ShoppingCart } from 'lucide-react'

import { Column } from '../../../../components/layouts/columns'
import { Page } from '../../../../components/layouts/page'
import useCollaboratorSelectPlan from '../../_hooks/useCollaboratorSelectPlan'
import { PlanSelectCard } from '../../_components/PlanSelectCard'
import { Button } from '../../../../components/ui/button'
import { Path } from '../../../../router/paths'

export const CollaboratorSelectPlans = () => {
  const { user, dataContext } = useCollaboratorSelectPlan()

  return (
    <Page.Root>
      <Page.Content>
        <Column.Root
          className="mt-6"
          style={{
            color: 'rgb(151, 151, 151)',
          }}
        >
          <Column.Content
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '0 2rem 0 2rem',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
              <ShoppingCart className="has-text-link" size={60} />
              <p className="subtitle is-text-4 has-text-link">Vamos Escolher uma assinatura?</p>
            </div>

            <p className="has-text-grey-light mt-4">
              Para usar o app da Keap, é necessário escolher um plano de assinatura que ofereça os recursos adequados
              para o seu negócio. Cada plano tem funcionalidades específicas para automação de marketing e gestão de
              vendas.
            </p>

            <div
              style={{
                width: '100%',
                margin: '4rem 0 4rem 0',
              }}
            >
              {(dataContext?.plans || []).map((plan) => {
                return <PlanSelectCard key={`Plano-${plan.id}`} plan={plan} user={user} />
              })}

              <Button.A className="is-light is-fullwidth mt-6 mb-6 is-rounded" link={Path.CollabSignUpFinally}>
                Escolho meu plano depois
              </Button.A>
            </div>
          </Column.Content>
        </Column.Root>
      </Page.Content>
    </Page.Root>
  )
}
