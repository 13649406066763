import { useEffect, useState } from 'react'

import { deleteDocument, getDocuments } from '../../../../../firebase/firestore'
import { TryCatch } from '../../../../../utils/general'
import { useGlobalContext } from '../../../../../context/ContextGlobal'
import { ServicesOmie } from '../../../../../api/omie'

export const useClientPlans = () => {
  const {
    user,
    setAlerts,
    loading,
    setLoading,
    dataContext,
    setDataContext, // contexto que guarda dados de lista para a pagina
    // infosContext,
    // setInfosContext, // contexto que guarda dados adicionais para a pagina funcionar
    // formContext,
    setFormContext, // contexto que guarda dados de formularios da pagina
  } = useGlobalContext()

  const [search, setSearch] = useState('')

  const getData = async () => {
    const getPlans = await getDocuments('plans')
    setDataContext({ plans: getPlans })
    setFormContext({})
  }

  // deleta o usuario no cliente e se não tiver cadastro em cliente, ele apaga o usuario do autenticador
  const deletePlans = async (id) => {
    // console.log(id)
    if (!user?.user?.permissions?.planos_excluir) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Permissão Negada',
          msg: 'Você não possui permissão para apagar Planos',
        },
      ])
    }
    let deletePlanOmie = false
    try {
      deletePlanOmie = await ServicesOmie().ExcluirServico(id)
    } catch (error) {
      throw new Error(error.message)
    }

    if (!deletePlanOmie) return

    await deleteDocument('plans', id)
    await FetchGetData()
    return setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Plano Excluído',
        msg: 'Plano excluído da sua base de dados',
      },
    ])
  }

  const FetchGetData = async () => {
    await TryCatch(getData, setLoading, setAlerts)
  }

  const HandleDeletePlan = async (id) => {
    await TryCatch(
      async () => {
        await deletePlans(id)
      },
      setLoading,
      setAlerts,
    )
  }

  const plansFiltered =
    search !== ''
      ? (dataContext.plans || []).filter((plans) => {
          const searchString = `${plans.nome}|${plans.descricao}`.toLowerCase()
          return searchString.includes(search.toLocaleLowerCase())
        })
      : dataContext.plans || []

  // executa quando carrega a pagina e quando é auterado o getUserList
  useEffect(() => {
    FetchGetData()
  }, [])

  return {
    loading,
    search,
    setSearch,
    FetchGetData,
    HandleDeletePlan,
    plansFiltered,
  }
}
