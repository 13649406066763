import React from 'react'

import { Column } from '../../../../components/layouts/columns'
import { Input } from '../../../../components/ui/input'
import { Select } from '../../../../components/ui/select'
import { Button } from '../../../../components/ui/button'
import { useClientFormEvents } from '../_hooks/useClientFormEvents'
import { Checkbox } from '../../../../components/ui/checkbox'
import { Table } from '../../../../components/ui/table'
import { Icon } from '../../../../components/icons'
// import { estados } from '../../../../utils/stateCity'

export const ClientsFormEventsInfosLayout = ({
  loading,
  formContext,
  setFormContext,
  options,
  disabledList,
  clientId,
}) => {
  const {
    // infosContext,
    cidades,
    HandleAddCity,
    HandleDeleteCity,
  } = useClientFormEvents(formContext, setFormContext, options, clientId)

  // console.log(formContext)

  return (
    <div>
      <Column.Root>
        <Column.Content>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Nome do Evento
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'name'}
                required={true}
                disabled={disabledList?.name}
                maskValue={'title'}
                maskSetValue={'lower'}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
        <Column.Content size="is-3">
          <Input.Root className="">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Turno
            </Input.Label>
            <Select.Root>
              <Select.Content
                color="is-grey"
                className="is-fullwidth is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'round'}
                disabled={disabledList?.uf}
                required={true}
              >
                <Select.Option value={null} title={''} />
                <Select.Option value={'1º turno'} title={'1º Turno'} />
                <Select.Option value={'2º turno'} title={'2º Turno'} />
                <Select.Option value={'3º turno'} title={'3º Turno'} />
                <Select.Option value={'4º turno'} title={'4º Turno'} />
              </Select.Content>
            </Select.Root>
          </Input.Root>
        </Column.Content>
      </Column.Root>

      <Column.Root>
        <Column.Content>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Endereço
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'endereco'}
                required={true}
                disabled={disabledList?.endereco}
                maskValue={'title'}
                maskSetValue={'lower'}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>
        </Column.Content>

        {/* Modelo label input */}
        <Column.Content>
          <Input.Root className="">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Cidade/UF
            </Input.Label>
            <Select.Root>
              <Select.Search
                color="is-grey"
                className="is-fullwidth is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'city'}
                required={true}
                options={cidades}
              ></Select.Search>
            </Select.Root>
          </Input.Root>
        </Column.Content>
      </Column.Root>

      <Column.Root>
        <Column.Content>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Local de Encontro
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'local_encontro'}
                required={true}
                disabled={disabledList?.cpf}
                maskValue={'title'}
                maskSetValue={'lower'}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
      </Column.Root>

      <Column.Root>
        <Column.Content className="is-5">
          <Checkbox.Root text="Habilitar Restrição de Candidaturas por Cidades">
            <Checkbox.Content
              value={formContext}
              setValue={setFormContext}
              field={'hasCityRestrictions'}
            ></Checkbox.Content>
          </Checkbox.Root>
        </Column.Content>
      </Column.Root>

      {formContext?.hasCityRestrictions && (
        <div className="box mb-6">
          <Column.Root>
            {/* {!formContext?.newCity ? (
              // <Column.Content>
              //   <Input.Root>
              //     <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              //       Escolha as cidades permitidas:
              //     </Input.Label>
              //     <Select.Root>
              //       <Select.Content
              //         color="is-grey"
              //         className="is-fullwidth is-rounded"
              //         value={formContext}
              //         setValue={setFormContext}
              //         field={'selectCityRestrictions'}
              //         disabled={disabledList?.vestments}
              //       >
              //         <Select.Option value={null} title={''} />
              //         {(infosContext?.citys || []).map((value) => {
              //           return <Select.Option key={value.id} value={value.id} title={`${value.city} - ${value.uf}`} />
              //         })}
              //       </Select.Content>
              //     </Select.Root>
              //   </Input.Root>
              // </Column.Content>
              <Column.Content>
                <Input.Root className="">
                  <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                    Cidade/UF
                  </Input.Label>
                  <Select.Root>
                    <Select.Search
                      color="is-grey"
                      className="is-fullwidth is-rounded"
                      value={formContext}
                      setValue={setFormContext}
                      field={'selectCityRestrictions'}
                      required={true}
                      options={cidades}
                    ></Select.Search>
                  </Select.Root>
                </Input.Root>
              </Column.Content>
            ) : (
              <Column.Content>
                <Input.Root>
                  <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                    Digite o CEP para cadastrar um cidade permitida
                  </Input.Label>
                  <Input.Contents>
                    <Input.Prompt
                      value={formContext}
                      setValue={setFormContext}
                      field={'inputCEP'}
                      maskValue="cep"
                    ></Input.Prompt>
                  </Input.Contents>
                </Input.Root>
              </Column.Content>
            )} */}
            <Column.Content>
              <Input.Root className="">
                <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
                  Quais cidades podem se candidatar?
                </Input.Label>
                <Select.Root>
                  <Select.Search
                    color="is-grey"
                    className="is-fullwidth is-rounded"
                    value={formContext}
                    setValue={setFormContext}
                    field={'selectCityRestrictions'}
                    options={cidades}
                  ></Select.Search>
                </Select.Root>
              </Input.Root>
            </Column.Content>
            <Column.Content size="is-3 mt-5">
              <Button.Root align="centered">
                <Button.Content>
                  <Button.Bt
                    color="is-dark"
                    className={'is-rounded'}
                    loading={loading}
                    action={() => {
                      HandleAddCity()
                    }}
                  >
                    Adicionar
                  </Button.Bt>
                </Button.Content>
                {/* <Button.Content>
                  <Button.Bt
                    color="is-success"
                    className={'is-rounded has-text-white'}
                    loading={loading}
                    action={() => {
                      setFormContext((prev) => ({ ...prev, newCity: !prev?.newCity }))
                    }}
                  >
                    {formContext?.newCity ? 'Selecionar' : 'Digitar CEP'}
                  </Button.Bt>
                </Button.Content> */}
              </Button.Root>
            </Column.Content>
          </Column.Root>
          <Table.Root className="is-fullwidth is-hoverable is-narrow has-text-centered">
            <Table.Head>
              <Table.Row>
                <Table.Cell>Cidade</Table.Cell>
                <Table.Cell>UF</Table.Cell>
                <Table.Cell>Ações</Table.Cell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {(formContext?.cityRestrictions || []).map((value) => {
                return (
                  <>
                    <Table.Row>
                      <Table.Cell className="is-size-7 is-vcentered">{value.city}</Table.Cell>
                      <Table.Cell className="is-size-7 is-vcentered">{value.uf}</Table.Cell>
                      <Table.Cell className="is-vcentered">
                        <div style={{ marginBottom: 10, marginTop: 5 }}>
                          <Button.Root align="centered">
                            <Button.Content>
                              <Button.Bt
                                size="is-small"
                                color="is-danger"
                                className={'has-text-white'}
                                action={() => {
                                  HandleDeleteCity(value)
                                }}
                                loading={loading}
                              >
                                <Icon size={15}>trash</Icon>
                              </Button.Bt>
                            </Button.Content>
                          </Button.Root>
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  </>
                )
              })}
            </Table.Body>
          </Table.Root>
        </div>
      )}

      <Column.Root className="mt-5">
        <Column.Content>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={false}>
              Informações Adicionais
            </Input.Label>
            <Input.Contents>
              <Input.Textarea
                className="is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'infoAdditional'}
                required={false}
                disabled={disabledList?.infoAdditional}
                maskValue={'title'}
                maskSetValue={'lower'}
              ></Input.Textarea>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
      </Column.Root>
    </div>
  )
}
