import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'

import { useGlobalContext } from '../../../../../context/ContextGlobal'
import { deleteDocument, getDocument, setDocument } from '../../../../../firebase/firestore'
import { Path } from '../../../../../router/paths'
import { TryCatch } from '../../../../../utils/general'
import { schemaDocument } from '../utils/schemas'

const useEditDocuments = () => {
  const { id } = useParams()
  const {
    user,
    setAlerts,
    // dataContext,
    // setDataContext,
    // infosContext,
    // setInfosContext,
    formContext,
    setFormContext,
    loading,
    setLoading,
    // statusLoading,
    // setStatusLoading,
  } = useGlobalContext()

  const navigate = useNavigate()

  const getForm = async () => {
    const getDocumentation = await getDocument('documentos', id)
    const newDocumentation = schemaDocument(getDocumentation)
    setFormContext(newDocumentation)
  }

  const fetchGetForm = async () => {
    await TryCatch(getForm, setLoading, setAlerts)
  }

  const editDocumentation = async () => {
    const newDocumentation = schemaDocument(formContext)
    await setDocument('documentos', id, newDocumentation)

    const sendInRegister = newDocumentation?.sendInRegister || null

    if (sendInRegister === 'true') {
      const newDocument = {
        name: newDocumentation?.name,
        singDate: '',
        status: false,
        type: 'document',
      }
      await setDocument('_defaultRegister', id, newDocument)
    } else {
      try {
        // console.log('tentando apagar')
        await deleteDocument('_defaultRegister', id)
      } catch (e) {
        // console.log(e)
      }
    }

    setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Documento Editado',
        msg: `O Documento "${newDocumentation.name}" foi editado com sucesso.`,
      },
    ])

    setFormContext({})
    return navigate(Path.AdminDocuments)
  }

  const handleEditDocuments = async () => {
    await TryCatch(editDocumentation, setLoading, setAlerts)
  }

  useEffect(() => {
    fetchGetForm()
  }, [])

  return { user, loading, formContext, setFormContext, handleEditDocuments }
}

export default useEditDocuments
