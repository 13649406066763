import { useEffect, useState } from 'react'

import { useGlobalContext } from '../../../../../context/ContextGlobal'
import { getDocuments, deleteDocument } from '../../../../../firebase/firestore'
import { TryCatch } from '../../../../../utils/general'

const useAdminReports = () => {
  const {
    user,
    setAlerts,
    dataContext,
    setDataContext,
    // infosContext, todas as informações para a página funcionar
    // setInfosContext,
    // formContext,
    setFormContext,
    loading,
    setLoading,
    // statusLoading,
    // setStatusLoading,
  } = useGlobalContext()
  const [search, setSearch] = useState('')
  const reports = dataContext?.reports || []

  const getData = async () => {
    const getReports = await getDocuments('reports')

    setDataContext({ reports: getReports })
  }

  const fetchGetData = async () => {
    await TryCatch(getData, setLoading, setAlerts)
  }

  // deleta o usuario no cliente e se não tiver cadastro em cliente, ele apaga o usuario do autenticador
  const deleteReport = async (report) => {
    if (!user?.user?.permissions?.relatorio_excluir) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Permissão Negada',
          msg: 'Você não possui permissão para apagar Relatórios',
        },
      ])
    }

    const idReport = report?.id || null

    if (!idReport) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'ID do consentimento não encontrado',
          msg: 'Não conseguimos achar o ID do consentimento. Tente novamente mais tarde.',
        },
      ])
    }

    await deleteDocument('reports', idReport)
    await getData()
    return setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Relatório Excluído',
        msg: 'Relatório excluído da sua base de dados e dos clientes',
      },
    ])
  }

  const handleDeleteReport = async (consent) => {
    await TryCatch(
      async () => {
        deleteReport(consent)
      },
      setLoading,
      setAlerts,
    )
  }

  const dataFiltered =
    search !== ''
      ? Object.values(reports).filter((report) => {
          const searchString = `${report?.name}|${report?.txtButton}|${report?.termo}`.toLowerCase()
          return searchString.includes(search.toLocaleLowerCase())
        })
      : Object.values(reports)

  useEffect(() => {
    fetchGetData()
    setFormContext({})
  }, [])

  return { search, setSearch, loading, dataFiltered, fetchGetData, handleDeleteReport }
}

export default useAdminReports
