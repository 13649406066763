import { useEffect, useState } from 'react'

import { useGlobalContext } from '../../../../context/ContextGlobal'
// import { DateNow, SomarDiasAData } from '../../../../utils/dates'
import { TryCatch } from '../../../../utils/general'
import { GetStatusClient } from '../_utils'
import { getAddressByCep } from '../../../../utils/consultingCEP'
import { AtividadesOmie, BancosOmie, CNAEOmie } from '../../../../api/omie'
import { getDocuments } from '../../../../firebase/firestore'

export const useFormClients = (values, setValues, options, clientId) => {
  const { setAlerts, setLoading, infosContext, setInfosContext } = useGlobalContext()

  const [search, setSearch] = useState('')
  const [addressCompleted, setAddressCompleted] = useState(false)
  const status = GetStatusClient(values.status).value

  const [statusUserAdmin, setStatusUserAdmin] = useState('')

  const getInfos = async () => {
    try {
      // Função auxiliar para buscar todas as páginas de CNAEs
      const fetchAllCNAEs = async (paramCNAEs, totalPages) => {
        const pageRequests = Array.from({ length: totalPages - 1 }, (_, i) =>
          CNAEOmie()
            .Listar([{ ...paramCNAEs, pagina: i + 2 }])
            .then((data) => data?.cadastros || []),
        )
        const results = await Promise.all(pageRequests)
        return results.flat() // Combina todas as páginas em um único array
      }

      // Inicializa as variáveis locais
      let CNAEs = infosContext?.listCNAE || []
      let optionsCNAE = infosContext?.optionsCNAE || []
      let listAtividades = infosContext?.listAtividades || []
      let listBanks = infosContext?.listBanks || []
      let optionsBanks = infosContext?.optionsBanks || []

      // Caso os CNAEs ainda não estejam no contexto, faz a busca
      if (!infosContext?.listCNAE) {
        const paramCNAEs = { pagina: 1, registros_por_pagina: 1000 }
        const initialCNAE = await CNAEOmie().Listar([paramCNAEs])

        if (!initialCNAE) throw new Error('Falha ao buscar CNAEs.')

        const { cadastros = [], total_de_paginas: totalPages } = initialCNAE
        CNAEs = [...cadastros]

        // Busca páginas restantes, se houver mais de uma
        if (totalPages > 1) {
          const remainingCNAEs = await fetchAllCNAEs(paramCNAEs, totalPages)
          CNAEs = [...CNAEs, ...remainingCNAEs]
        }

        // Filtra resultados inválidos
        CNAEs = CNAEs.filter((item) => item.cEstrutura !== '')

        // Criação das opções de CNAEs
        optionsCNAE = CNAEs.map((item) => ({
          value: item.nCodigo,
          label: `${item.cEstrutura} - ${item.cDescricao}`,
        }))
      }

      // Caso atividades ou bancos não estejam no contexto, faz as requisições
      if (!infosContext?.listAtividades || !infosContext?.listBanks) {
        const [atividadesResponse, bancosResponse] = await Promise.all([
          !infosContext?.listAtividades
            ? AtividadesOmie().Listar([{ filtrar_por_codigo: '', filtrar_por_descricao: '' }])
            : Promise.resolve({ lista_tipos_atividade: infosContext.listAtividades }),
          !infosContext?.listBanks
            ? BancosOmie().Listar([{ pagina: 1, registros_por_pagina: 1000 }])
            : Promise.resolve({ fin_banco_cadastro: infosContext.listBanks }),
        ])

        if (!infosContext?.listAtividades) {
          listAtividades = atividadesResponse?.lista_tipos_atividade || []
        }

        if (!infosContext?.listBanks) {
          listBanks = bancosResponse?.fin_banco_cadastro || []
          optionsBanks = listBanks.map((item) => ({
            value: item.codigo,
            label: `${item.codigo} - ${item.nome}`,
          }))
        }
      }

      // Caso os planos ainda não estejam no contexto, busca no Firestore
      const getPlans = infosContext?.planos || (await getDocuments('plans'))

      const newPlans = getPlans
        .map((item) => (item.typePlan === 'client' ? item : null))
        .filter((item) => item !== null)

      // Atualizando o contexto com os dados obtidos
      setInfosContext({
        optionsCNAE,
        listCNAE: CNAEs,
        listAtividades,
        optionsBanks,
        planos: newPlans,
      })
    } catch (error) {
      console.error('Erro ao buscar informações:', error.message || error)
    }
  }

  const AddPlanInList = (idPlan) => {
    if ((values?.plans || []).some((item) => item.id === idPlan)) {
      return setAlerts((prev) => [
        ...prev,
        {
          title: 'Plano já adicionado',
          msg: 'Somente é permitido inserir planos diferente',
          type: 'error',
        },
      ])
    }

    const plan = (options?.planos || []).find((item) => item.id === idPlan)

    setValues((prev) => {
      const index = (prev.plans || []).length
      const newPlan = { ...plan, index }
      return {
        ...prev,
        plans: [...(prev.plans || []), newPlan],
      }
    })
  }

  const DeletePlansOfList = (idPlan) => {
    setValues((prev) => {
      const newPlans = (prev.plans || [])
        .map((item, index) => {
          if (item.id !== idPlan) {
            return { ...item, index }
          }
          return null
        })
        .filter((item) => item !== null)
      return {
        ...prev,
        plans: newPlans,
      }
    })
  }
  const RemoveEndDate = (idPlan) => {
    setValues((prev) => {
      const newPlans = (prev.plans || []).map((item) => {
        if (idPlan === item.id) {
          delete item.dtEndPlan
        }
        return item
      })
      return {
        ...prev,
        plans: newPlans,
      }
    })
  }
  const AddEndDate = (idPlan) => {
    setValues((prev) => {
      const newPlans = prev.plans || []

      // Ordena as assinaturas pela data de finalização
      newPlans.sort((a, b) => new Date(a.dtEndPlan) - new Date(b.dtEndPlan))

      // Pega a última assinatura
      const lastPlan = newPlans[newPlans.length - 1]

      // Se houver uma data válida, cria um objeto Date, senão usa a data de hoje
      const lastDatePlan = lastPlan?.dtEndPlan ? new Date(lastPlan.dtEndPlan) : new Date()

      // Adiciona um dia corretamente
      lastDatePlan.setDate(lastDatePlan.getDate() + 1)

      // Formata a data para YYYY-MM-DD
      const formattedDate = lastDatePlan.toISOString().split('T')[0]
      // console.log(formattedDate)

      // Atualiza a assinatura com a nova data
      const newPlansWithDate = newPlans.map((item) => {
        if (item.id === idPlan) {
          return { ...item, dtEndPlan: formattedDate }
        }
        return item
      })

      return {
        ...prev,
        plans: newPlansWithDate,
      }
    })
  }

  // const getMaxDate = (data, field) => {
  //   return data.reduce((max, item) => {
  //     return new Date(item.dtstart) > new Date(max) ? item[field] : max
  //   }, data[0][field])
  // }

  const sumDate = (date, days) => {
    const novaData = new Date(date) // Converte string para objeto Date
    novaData.setDate(novaData.getDate() + days) // Adiciona os dias
    return novaData.toISOString().split('T')[0] // Formata de volta para 'YYYY-MM-DD'
  }

  const setDtStart = (idPlan, value) => {
    // console.log(idPlan)
    // console.log(value)
    const listplans = values.plans
    const newPlan = listplans.filter((item) => item.id === idPlan)[0]

    // Localiza a última data anterior ao índice do plano atual
    const previousDate = listplans.reduce((max, item) => {
      if (item.index < newPlan.index) {
        const itemStartDate = new Date(item.dtEnd)
        return itemStartDate > new Date(max) ? item.dtEnd : max
      }
      return max
    }, '1970-01-01') // Valor inicial neutro para comparação de datas

    // console.log(previousDate)
    // Verifica se a data é válida
    if (new Date(value) < new Date(previousDate)) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Data maior',
          msg: 'Não é possível selecionar uma data menor que a última data do plano anterior.',
        },
      ])
    }

    setValues((prev) => {
      let updateLastValues = false
      let lastEndDate
      const newPlans = (prev.plans || []).map((item, index) => {
        if (item.id === idPlan) {
          // Atualiza o plano atual
          updateLastValues = true
          lastEndDate = Number(item.typeExpired) > 0 ? sumDate(value, Number(item.typeExpired)) : null
          return { ...item, dtStart: value, ...(lastEndDate !== null && { dtEnd: lastEndDate }) }
          // Calcula o dtEnd do plano atual
        }

        if (updateLastValues) {
          // Atualiza os próximos planos
          if (lastEndDate) {
            const newStartDate = sumDate(lastEndDate, 1) // Adiciona 1 dia ao dtEnd anterior
            lastEndDate = Number(item.typeExpired) > 0 ? sumDate(newStartDate, Number(item.typeExpired)) : null
            return { ...item, dtStart: newStartDate, ...(lastEndDate !== null && { dtEnd: lastEndDate }) }
          }
        }

        // Retorna o plano inalterado
        return item
      })

      return { ...prev, plans: newPlans } // Atualiza o estado com os novos planos
    })
  }

  const FetchGetInfos = async () => {
    await TryCatch(getInfos, setLoading, setAlerts)
  }

  useEffect(() => {
    const fetchAddress = async () => {
      if (values?.cep?.length === 8 && !addressCompleted) {
        const response = await getAddressByCep(values.cep)
        if (response.error) {
          setAddressCompleted(false)
          return setValues((prev) => ({
            ...prev,
            cepError: response.error,
            cepManualCompleted: true,
          }))
        }
        setValues((prev) => {
          delete prev.cepError
          delete prev.cepManualCompleted
          const newprev = {
            ...prev,
            endereco: response.logradouro,
            bairro: response.bairro,
            uf: response.uf,
            municipio: response.localidade,
          }
          return newprev
        })
        setAddressCompleted(true)
      } else if (values?.cep?.length < 8 && addressCompleted) {
        setAddressCompleted(false)
      }
    }

    fetchAddress()
  }, [values?.cep, addressCompleted])

  useEffect(() => {
    // console.log(values?.userAdmin)
    // console.log(values?.userAdmin?.authInfos?.emailVerified)

    setStatusUserAdmin(
      clientId
        ? values?.userAdmin
          ? `Usuário criado e vinculado a este cliente, ${values?.userAdmin?.authInfos?.emailVerified ? 'primeiro acesso realizado!' : 'primeiro acesso não realizado, caso necessário clique em "Resetar Senha"'}`
          : 'Nenhum usuário criado e vinculado a este cliente, aperte em "Criar Login"'
        : '',
    )
  }, [values, clientId])

  useEffect(() => {
    FetchGetInfos()
  }, [])

  useEffect(() => {
    // console.log(values)
  }, [values])

  useEffect(() => {
    // FetchClientInfos()
    const daysFreetrial = Number(values?.freeTrial) || 0

    if (daysFreetrial === 0) {
      setValues((prev) => ({ ...prev, firstPayment: prev?.dtStartContract }))
    } else {
      const dtStartContract = values?.dtStartContract || null
      if (!dtStartContract) return
      const dateStart = new Date(dtStartContract)
      dateStart.setDate(dateStart.getDate() + daysFreetrial)
      const newStartPayment = dateStart.toISOString().split('T')[0]
      setValues((prev) => ({ ...prev, firstPayment: newStartPayment, dtFreeTrial: newStartPayment }))
    }
  }, [values.freeTrial])

  return {
    search,
    setSearch,
    status,
    AddPlanInList,
    DeletePlansOfList,
    statusUserAdmin,
    infosContext,
    setInfosContext,
    setDtStart,
    RemoveEndDate,
    AddEndDate,
  }
}
