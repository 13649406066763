import React from 'react'

import { Icon } from '../../../components/icons'
import { Column } from '../../../components/layouts/columns'
import { Button } from '../../../components/ui/button'
import { Table } from '../../../components/ui/table'
import { Input } from '../../../components/ui/input'
import { Page } from '../../../components/layouts/page'
import { Path } from '../../../router/paths'
import { Pagination } from '../../../components/ui/pagination'
import { Form } from '../../../components/ui/form'
import { useAdminClients } from './_hooks'
import { useGlobalContext } from '../../../context/ContextGlobal'
import { GetStatusClient } from './_utils'
import { TransformationforMask } from '../../../utils/masks'

export const AClients = () => {
  const {
    search,
    setSearch,
    dataContext,
    HandleDelClient,
    FetchClientData,
    valuePage,
    setValuePage,
    nextPage,
    PreviousPage,
  } = useAdminClients()

  const { loading } = useGlobalContext()

  return (
    <>
      <Page.Root className="is-fullwidth">
        <Form.Root action={FetchClientData} className="mt-6 pt-6 mb-6">
          <Column.Root className="mx-3">
            <Column.Content>
              <p className="subtitle is-size-3">Clientes</p>
            </Column.Content>
            <Column.Content className="has-text-right">
              <Button.Root align="right">
                <Button.Content>
                  <Button.Bt
                    color="is-dark"
                    colorText="has-text-white"
                    action={FetchClientData}
                    loading={loading}
                    type="submit"
                  >
                    <Icon size={15}>sync</Icon>
                  </Button.Bt>
                </Button.Content>
                <Button.Content>
                  <Button.A
                    className=""
                    color="is-success"
                    colorText="has-text-white"
                    link={Path.AAdicionarClientes}
                    loading={loading}
                  >
                    +
                  </Button.A>
                </Button.Content>
              </Button.Root>
            </Column.Content>
          </Column.Root>
          <Column.Root className="mx-3">
            <Column.Content>
              <Input.Root>
                <Input.Contents alignIcon={'left'}>
                  <Input.Prompt value={search} setValue={setSearch} placeholder="Pesquisar..."></Input.Prompt>
                  <Input.Icon align="is-left">
                    <Icon size={20}>search</Icon>
                  </Input.Icon>
                </Input.Contents>
              </Input.Root>
            </Column.Content>
          </Column.Root>
        </Form.Root>
        {loading ? (
          <Column.Root className="is-mobile">
            <Column.Content></Column.Content>
            <Column.Content className="is-1">
              <Icon>loading</Icon>
            </Column.Content>
            <Column.Content></Column.Content>
          </Column.Root>
        ) : dataContext?.clientes?.data.length > 0 ? (
          <>
            <Column.Root className="mx-3">
              <Column.Content>
                <Table.Root className="is-fullwidth is-hoverable is-narrow has-text-centered ">
                  <Table.Head>
                    <Table.Row>
                      <Table.Cell size={'20%'}>Razão Social</Table.Cell>
                      <Table.Cell size={'15%'}>Nome Fantasia</Table.Cell>
                      <Table.Cell size={'15%'}>CPNJ/CPF</Table.Cell>
                      <Table.Cell size={'10%'}>Cidade</Table.Cell>
                      <Table.Cell size={'5%'}>UF</Table.Cell>
                      <Table.Cell size={'20%'}>Telefone</Table.Cell>
                      <Table.Cell size={'5%'}>Status</Table.Cell>
                      <Table.Cell size={'30%'}>Ações</Table.Cell>
                    </Table.Row>
                  </Table.Head>
                  <Table.Body>
                    {dataContext?.clientes?.data.map((value) => {
                      return (
                        <Table.Row key={value.id}>
                          {/* Adicione uma chave única */}
                          <Table.Cell className="is-size-7 is-vcentered">
                            {TransformationforMask(value.razaoSocial, 'title')}
                          </Table.Cell>
                          <Table.Cell className="is-size-7 is-vcentered">
                            {TransformationforMask(value.nomeFantasia, 'title')}
                          </Table.Cell>
                          <Table.Cell className="is-size-7 is-vcentered">{value.cpfCnpj}</Table.Cell>
                          <Table.Cell className="is-size-7 is-vcentered">{value.municipio}</Table.Cell>
                          <Table.Cell className="is-size-7 is-vcentered">{value.uf}</Table.Cell>
                          <Table.Cell className="is-size-7 is-vcentered">{value.responsavel_telefone}</Table.Cell>
                          <Table.Cell className="is-size-7 is-vcentered">
                            <p className={`tag ${GetStatusClient(value?.status).color}`}>
                              {GetStatusClient(value?.status).value}
                            </p>
                          </Table.Cell>
                          <Table.Cell className="is-vcentered">
                            <div>
                              <Button.Root align="centered">
                                <Button.Content>
                                  <Button.A
                                    size="is-small"
                                    color="is-warning"
                                    link={Path.AdminClientEdit(value.id)}
                                    loading={loading}
                                  >
                                    <Icon size={15}>pen</Icon>
                                  </Button.A>
                                </Button.Content>
                                <Button.Content>
                                  <Button.Bt
                                    size="is-small"
                                    color="is-danger"
                                    action={() => {
                                      HandleDelClient(value)
                                    }}
                                    loading={loading}
                                  >
                                    <Icon size={15}>trash</Icon>
                                  </Button.Bt>
                                </Button.Content>
                              </Button.Root>
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      )
                    })}
                  </Table.Body>
                </Table.Root>
              </Column.Content>
            </Column.Root>
            <Pagination.Functional
              nextPage={nextPage}
              PreviousPage={PreviousPage}
              paginationData={dataContext?.clientes?.pagination}
              valuePage={valuePage}
              setValuePage={setValuePage}
            ></Pagination.Functional>
          </>
        ) : (
          <p className="subtitle has-text-gray is-size-6">Nenhum Cliente encontrado.</p>
        )}
      </Page.Root>
    </>
  )
}
