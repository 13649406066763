import { ChevronRight } from 'lucide-react'

import { Path } from '../../../router/paths'
import { statusPayments } from '../_utils/paymentStatusCollab'

export const HistoryCard = ({ token, payment }) => {
  const statusPayment = statusPayments[payment?.status]

  return (
    <a
      href={Path.CollabHistorySelected(token, payment.id)}
      style={{
        color: 'rgb(151, 151, 151)',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <p>{payment?.name}</p>
          <p>
            Valor: R$ <b>{payment?.value}</b> ({payment?.typePayment})
          </p>
          <p
            className={`${statusPayment.color}`}
            style={{
              color: '#37f64a',
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            {statusPayment.icon} {statusPayment.value}
          </p>
        </div>
        <div>
          <ChevronRight />
        </div>
      </div>
      <hr />
    </a>
  )
}
