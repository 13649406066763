import React, { useState } from 'react'
// o children será o texto que é escrito no botão
// as colores recebe todas as cores do bulma sem o "IS-"
// o action recebe o função que ao apertar o botão ela será executada

export const ButtonContentDropdownRoot = ({ children, className = '', isHidden = false, loading = null }) => {
  const [isActive, setIsActive] = useState(false)

  if (!isHidden) {
    return (
      <div
        className={`dropdown ${isActive ? 'is-active' : ''} ${className}`}
        onMouseEnter={() => setIsActive(true)}
        onMouseLeave={() => setIsActive(false)}
      >
        {children}
      </div>
    )
  } else {
    return <></>
  }
}
