import { DateTimeNow, TimestampNow } from './dates'
import { addDocument, getDocument } from '../firebase/firestore'

export async function sendNotification(listUUIDs, listTokens, titleNotification, textNotification) {
  const uuidsList = Array.isArray(listUUIDs) ? listUUIDs : [listUUIDs]

  let tokensList = listTokens

  const getTokensCollab = uuidsList.map(async (id) => {
    // console.log(id)
    const { token } = await getDocument('colaboradores', id)
    return token || null // Se token for undefined, retorna um array vazio
  })

  if (listTokens.length === 0) {
    tokensList = await Promise.all(getTokensCollab)

    // Filtra valores inválidos e garante um array válido
    tokensList = tokensList.filter((token) => token) || []

    // console.log(tokensList)
  }

  const newNotifications = {
    uuids: uuidsList,
    tokens: tokensList,
    dt_created: DateTimeNow(),
    title: titleNotification,
    msg: textNotification,
    time: TimestampNow(),
  }

  // console.log(newNotifications)

  const notificationCreate = await addDocument('_notifications', newNotifications)

  return notificationCreate
}
