import { ArrowLeft, Gift } from 'lucide-react'
import { useEffect, useState } from 'react'

import useCollaborator from '../../_hooks/useCollaborator'
import useCollaboratorSignPlan from '../../_hooks/useCollaboratorSignPlan'
import { Page } from '../../../../components/layouts/page'
import { Header } from '../../_components/Header'
import { Path } from '../../../../router/paths'
import { Column } from '../../../../components/layouts/columns'
import { PlanCard } from '../../_components/PlanCard'
import { Icon } from '../../../../components/icons'

export const CollaboratorSignPlan = () => {
  const { user, token } = useCollaborator()

  const { dataContext } = useCollaboratorSignPlan()

  const [showPage, setShowPage] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setShowPage(true), 1000) // Aguarda 1 segundo antes de renderizar
    return () => clearTimeout(timer) // Limpa o timer caso o componente desmonte
  }, [])

  if (!showPage && (dataContext?.plans || []).length > 0) {
    return <Icon>loading</Icon> // Ou pode exibir um loading aqui, se preferir
  }

  return (
    <Page.Root>
      <Page.Content
        style={{
          maxWidth: '400px', // Largura máxima para telas menores
          width: '100%', // Garante que se adapte bem a telas menores
          margin: '0 auto', // Centraliza o conteúdo
        }}
      >
        <Header>
          <a href={Path.CollabHome(token)}>
            <ArrowLeft className="has-text-link" size={30} />
          </a>
        </Header>

        <Column.Root
          style={{
            color: 'rgb(151, 151, 151)',
          }}
        >
          <Column.Content
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '0 2rem 0 2rem',
            }}
          >
            <div
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}
              className="mb-5"
            >
              <Gift className="has-text-link" size={60} />
              <p className="title is-4 has-text-link">Planos Disponíveis</p>
            </div>

            {/* <p className="has-text-centered">
              <strong>Ganhe Dinheiro Trabalhando em Eventos em Todo o Brasil!</strong>
              <br />
              <br />
              Com a <strong>Keap Staff</strong>, você encontra empregos em eventos como shows, festas e muito mais –{' '}
              <strong>onde e quando quiser!</strong>
              <br />
              Por um preço superacessível, você tem acesso direto às melhores vagas. <strong>
                Não perca tempo!
              </strong>{' '}
              Escolha seu plano e comece a trabalhar agora mesmo para aumentar sua renda!
            </p> */}

            <p className="has-text-centered">
              <strong>Quer fazer parte dos melhores eventos?</strong>
              <br />
              <br />
              Com <strong>Keap Staff</strong> você terá acesso exclusivo para se candidatar nas melhores vagas, shows,
              festas e eventos - onde você poderá trabalhar de acordo com a sua disponibilidade!
              <br />
              Tudo isso custando apenas 0,29 centavos por dia. <strong>Não espere mais!</strong> escolha o seu plano e
              conecte-se as melhores oportunidades!
            </p>
            {/* 
            <p className="has-text-centered">
              <strong>Trabalhe em Eventos e Ganhe Dinheiro de Verdade!</strong>
              <br />
              <br />
              Com a <strong>Keap Staff</strong>, você encontra vagas em eventos em todo o Brasil. Escolha o que mais
              combina com você, e comece a trabalhar de forma simples e rápida!
              <br />
              Por um preço baixo, você acessa as melhores oportunidades e aumenta sua renda.{' '}
              <strong>Está esperando o quê?</strong> Se inscreva agora e comece já!
            </p> */}

            <div
              style={{
                width: '100%',
                margin: '4rem 0 4rem 0',
              }}
            >
              {(dataContext?.plans || []).map((plan) => {
                return <PlanCard key={`Plano-${plan.id}`} plan={plan} user={user} />
              })}
            </div>
          </Column.Content>
        </Column.Root>
      </Page.Content>
    </Page.Root>
  )
}
