import { getDocuments, getSubDocuments } from '../../../../firebase/firestore'
import { GetPaginationStaff } from '../../../../firebase/functions'
import { statusStaff } from '../_utils/statusCandidate'

export const GetAllStaffDataEnriched = async (
  idEvent,
  query = {},
  limit = 9999,
  orderBy = { field: 'status', direction: 'asc' },
) => {
  // coleta todos a staff
  const getStaffData = await GetPaginationStaff(1, limit, idEvent, query, orderBy)
  const getJobOffers = await getSubDocuments('events', idEvent, 'jobOffers')
  const getPositions = await getDocuments('cargos')

  // coleta o joboffer e linka com as iunformações do cargo
  const JobOfferEnriched = getJobOffers.map((joboffer) => {
    const idPosition = joboffer?.idPosition
    const position = getPositions.find((pos) => pos.id === idPosition)
    return {
      ...position,
      ...joboffer,
    }
  })

  // coleta a cindatura e linka com o joboffer
  const dataEnriched = getStaffData?.data?.map((candidacy) => {
    const idJobOffer = candidacy?.idJobOffers
    const jobOffer = JobOfferEnriched.find((job) => job.id === idJobOffer) || {}
    const status = statusStaff[candidacy?.status || 0]
    return {
      candidacy,
      jobOffer,
      status,
    }
  })

  return dataEnriched
}
