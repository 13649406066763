import { ArrowLeft, RotateCw, Tag } from 'lucide-react'

import useCollaborator from '../../_hooks/useCollaborator'
import useCollaboratorPlans from '../../_hooks/useCollaboratorPlans'
import { Page } from '../../../../components/layouts/page'
import { Header } from '../../_components/Header'
import { Path } from '../../../../router/paths'
import { Column } from '../../../../components/layouts/columns'
import { ConvertTimestampToDateTime } from '../../../../utils/dates'
import { Button } from '../../../../components/ui/button'

export const CollaboratorPlans = () => {
  const { user, token } = useCollaborator()

  const { loading, HandleCancellPlanInCollab } = useCollaboratorPlans()

  return (
    <Page.Root>
      <Page.Content
        style={{
          maxWidth: '400px', // Largura máxima para telas menores
          width: '100%', // Garante que se adapte bem a telas menores
          margin: '0 auto', // Centraliza o conteúdo
        }}
      >
        <Header titlePage="Assinatura Ativa">
          <a href={Path.CollabHome(token)}>
            <ArrowLeft className="has-text-link" size={30} />
          </a>
        </Header>

        {Object.keys(user?.plans || {}).length > 0 ? (
          <Column.Root
            className="mt-6"
            style={{
              color: 'rgb(151, 151, 151)',
            }}
          >
            <Column.Content
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '0 2rem 0 2rem',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '1rem',
                  margin: '0 0 2rem 0',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
                  <Tag color="#2e333d" size={60} />
                  <p className="title is-4">{user?.plans?.name || ''}</p>
                </div>
              </div>
              <p
                style={{
                  textAlign: 'justify',
                  paddingInline: 40,
                }}
              >
                {user?.plans?.desc}
              </p>
              <div>
                <p>
                  Pagamento via <b>{user?.plans?.payment}</b>
                </p>
                <p>
                  Valor: R$ <b>{user?.plans?.value}</b>
                </p>
                <div
                  style={{
                    display: 'flex',
                    gap: '1rem',
                    padding: '0 0 .5rem 0',
                  }}
                >
                  <RotateCw />
                  <p>
                    Cobrança há cada <b>{user?.plans?.recurrence}</b> dias
                  </p>
                </div>
              </div>

              <div
                style={{
                  fontSize: '.8rem',
                  margin: '1rem 0 3rem 0',
                  textAlign: 'justify',
                }}
              ></div>

              <div
                style={{
                  margin: '1rem 0 2rem 0',
                }}
              >
                <p>
                  Contratado em: <b>{ConvertTimestampToDateTime(user?.plans?.dtJoin)}</b>
                </p>

                <p>
                  Próxima cobrança: <b>{ConvertTimestampToDateTime(user?.nextPayment)}</b>
                  <br />
                </p>
                {user?.plans?.dtEndPlan && (
                  <p className="has-text-danger">
                    Assinatura Expira em: <b>{ConvertTimestampToDateTime(user?.plans?.dtEndPlan)}</b>
                  </p>
                )}
              </div>
              <Button.Bt
                action={() => {
                  HandleCancellPlanInCollab(user?.plans)
                }}
                loading={loading}
                disabled={user?.plans?.dtEndPlan}
              >
                Cancelar Assinatura
              </Button.Bt>
              {user?.plans?.dtEndPlan && <p className="has-text-danger">Aguarde a Assinatura expirar para cancelar</p>}
              <div style={{ height: 150 }}></div>
            </Column.Content>
          </Column.Root>
        ) : (
          <Column.Root
            className="mt-6"
            style={{
              color: 'rgb(151, 151, 151)',
            }}
          >
            <Column.Content
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '0 2rem 0 2rem',
              }}
            >
              <p>Nenhuma assinatura selecionada</p>
            </Column.Content>
          </Column.Root>
        )}
      </Page.Content>
    </Page.Root>
  )
}
