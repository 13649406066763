import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { useGlobalContext } from '../../../context/ContextGlobal'
import { TryCatch } from '../../../utils/general'
import { getSubDocuments } from '../../../firebase/firestore'
import useCollaborator from './useCollaborator'

const useCollaboratorHistory = () => {
  const { user } = useCollaborator()

  const { setAlerts, setLoading, loading, dataContext, setDataContext } = useGlobalContext()

  const { token } = useParams()

  const uid = user.id

  // const navigate = useNavigate()

  const getData = async () => {
    const getPayments = (await getSubDocuments('colaboradores', uid, 'payments')) || []

    setDataContext({ payments: getPayments })
  }

  const FetchGetData = async () => {
    await TryCatch(getData, setLoading, setAlerts)
  }

  useEffect(() => {
    FetchGetData()
  }, [])

  return { user, token, loading, dataContext, FetchGetData }
}

export default useCollaboratorHistory
