/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { Apple, Check, ShoppingCart } from 'lucide-react'

import { Column } from '../../../../components/layouts/columns'
import { Page } from '../../../../components/layouts/page'
import useCollaboratorSelectPlan from '../../_hooks/useCollaboratorSelectPlan'
import { PlanSelectCard } from '../../_components/PlanSelectCard'
import { Button } from '../../../../components/ui/button'
import { AlertFunctional } from '../../../../components/ui/alert/AlertFunctional'
import { useGlobalContext } from '../../../../context/ContextGlobal'
import { Path } from '../../../../router/paths'

export const CollaboratorRegisterFinally = () => {
  const { setAlerts, alerts } = useGlobalContext()

  return (
    <Page.Root>
      <Page.Content>
        <Column.Root
          className="mt-6"
          style={{
            color: 'rgb(151, 151, 151)',
          }}
        >
          <Column.Content
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '0 2rem 0 2rem',
            }}
          >
            {/* Seção de título e ícone */}
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
              <Check className="has-text-link" size={60} />
              <p className="subtitle is-text-4 has-text-link">Pré-Cadastro Completo!</p>
              <p className="has-text-centered">
                Agora, baixe nosso aplicativo para ver eventos e terminar seu cadastro.
              </p>
            </div>

            {/* Seção de botões de download */}
            <Column.Root className="mt-6" style={{ width: '100%' }}>
              <Column.Content>
                <Button.A
                  link="https://play.google.com/store/apps/details?id=com.seuapp" // Substitua pelo link do seu app na Google Play
                  className="is-link is-fullwidth is-rounded"
                  openNew
                >
                  Baixar na Google Play
                </Button.A>
              </Column.Content>
              <Column.Content>
                <Button.A
                  link="https://apps.apple.com/us/app/seu-app/idxxxxxxxxx" // Substitua pelo link do seu app na App Store
                  className="is-primary is-fullwidth is-rounded"
                  openNew
                >
                  Baixar na App Store
                </Button.A>
              </Column.Content>
            </Column.Root>

            {/* Botão para finalizar ou fazer uma ação adicional */}
            <Button.A
              className="is-light is-fullwidth mt-6 mb-6 is-rounded"
              link={Path.CollabLogin} // Substitua pelo link para outra página ou ação
            >
              Voltar à Página Inicial
            </Button.A>
          </Column.Content>
        </Column.Root>
      </Page.Content>
      <AlertFunctional values={alerts} setValues={setAlerts}></AlertFunctional>
    </Page.Root>
  )
}
