/* eslint-disable react/no-unknown-property */
/**
 * Cria um componente de icone.
 * Site dos icones: https://iconscout.com/unicons/free-line-icon-fonts
 *
 * @param {String} [children=any] - Aceita somente String
 * @param {Number} [size=30] - define o tamanho do icone
 * @param {String} [className=""] - adiciona classe extra do bulma ao icone
 * @returns {JSX.Element} O componente React.
 */

export const icons = {
  add: 'uil uil-plus',
  calendar: 'uil uil-calender',
  'calendar-close': 'uil uil-calendar-slash',
  users: 'uil uil-users-alt',
  'chat-bubble-user': 'uil uil-chat-bubble-user',
  info: 'uil uil-file-info-alt',
  alert: 'uil uil-exclamation-triangle',
  password: 'uil uil-key-skeleton',
  user: 'uil uil-user',
  checkin: 'uil uil-arrow-from-top',
  checkout: 'uil uil-export',
  arrowUp: 'uil uil-arrow-up',
  arrowLeft: 'uil uil-angle-left',
  arrowDown: 'uil uil-arrow-down',
  arrowRight: 'uil uil-angle-right',
  arrowDoubleRight: 'uil uil-angle-double-right',
  arrowDoubleLeft: 'uil uil-angle-double-left',
  gear: 'uil uil-setting',
  backspace: 'uil uil-backspace',
  suitcase: 'uil uil-suitcase',
  trash: 'uil uil-trash-alt',
  pen: 'uil uil-pen',
  sync: 'uil uil-sync',
  search: 'uil uil-search',
  lock: 'uil uil-lock-alt',
  unlock: 'uil uil-lock-open-alt',
  bill: 'uil uil-bill',
  history: 'uil uil-history',
  print: 'uil uil-print',
  selfie: 'uil uil-selfie',
  sectors: 'uil uil-sign-alt',
  doc: 'uil uil-files-landscapes-alt',
  'file-check': 'uil uil-file-check-alt',
  job: 'uil uil-constructor',
  block: 'uil uil-ban',
  question: 'uil uil-comment-question',
  infos: 'uil uil-map-marker-info',
  download: 'uil uil-download-alt',
  copy: 'uil uil-copy',
  'user-square': 'uil uil-user-square',
  multiply: 'uil uil-times',
  check: 'uil uil-check',
  dollar: 'uil uil-dollar-alt',
  fileExport: 'uil uil-file-export',
  invite: 'uil uil-envelope-send',
  favorite: 'uil uil-heart-alt',
  'map-pin': 'uil-map-pin',
  'file-slash': 'uil-file-slash',
  'file-blank': 'uil-file-blank',
  qrcode: 'uil-qrcode-scan',
  health: 'uil-heartbeat',
  list: 'uil-list-ol-alt',
  review: 'uil-envelope-star',
  clock: 'uil-clock',
  reason: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="lucide lucide-table-of-contents"
    >
      <path d="M16 12H3" />
      <path d="M16 18H3" />
      <path d="M16 6H3" />
      <path d="M21 12h.01" />
      <path d="M21 18h.01" />
      <path d="M21 6h.01" />
    </svg>
  ),
}

export const Icon = ({ children, size = 30, className = '', margin, style = {} }) => {
  const iconClass = icons[children]

  if (!iconClass) {
    // Renderiza elementos personalizados para casos especiais
    if (children === 'x') {
      return (
        <p className={className} style={{ fontSize: size, margin, ...style }}>
          x
        </p>
      )
    }

    if (children === 'loading') {
      return (
        <nav className="level">
          <p className="level-item has-text-centered">
            <p className="custom-loader"></p>
          </p>
        </nav>
      )
    }

    // Caso não seja encontrado, retorna vazio
    return <></>
  }

  // Renderiza o ícone padrão
  return <i className={`${iconClass} ${className}`} style={{ fontSize: size, margin, ...style }}></i>
}

// site dos icones: https://iconscout.com/unicons/free-line-icon-fonts
