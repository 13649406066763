import { useState } from 'react'

import keapLogo from '../../../assets/Prancheta 2.png'

const SupportCollab = () => {
  const [formData, setFormData] = useState({ nome: '', email: '', mensagem: '' })

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    alert('Mensagem enviada! Entraremos em contato em breve.')
  }

  return (
    <section className="section">
      <div className="container">
        <div className="has-text-centered">
          <img src={keapLogo} style={{ height: 200, width: 200 }} alt="keaplogo"></img>
        </div>
        <p className="subtitle has-text-centered">Precisa de ajuda? Entre em contato conosco.</p>

        <div className="box">
          <h2 className="subtitle is-4">Contato</h2>
          <p>Se estiver enfrentando problemas com nosso aplicativo, entre em contato pelos seguintes canais:</p>
          <ul>
            <li>
              <strong>Email:</strong> suporte@keapstaff.com.br
            </li>
            <li>
              <strong>Telefone:</strong> +55 11 97672-7694
            </li>
            {/* <li>
              <strong>FAQ:</strong>
              <a href="/" alt="teste">
                Perguntas Frequentes
              </a>
            </li> */}
          </ul>
        </div>

        <div className="box mt-4">
          <h2 className="subtitle is-4">Formulário de Suporte</h2>
          <form onSubmit={handleSubmit}>
            <div className="field mt-4">
              <p className="label">Nome</p>
              <div className="control">
                <input
                  className="input is-rounded"
                  type="text"
                  name="nome"
                  placeholder="Seu nome"
                  value={formData.nome}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="field mt-4">
              <p className="label">Email</p>
              <div className="control">
                <input
                  className="input is-rounded"
                  type="email"
                  name="email"
                  placeholder="seuemail@exemplo.com"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="field mt-4">
              <p className="label">Mensagem</p>
              <div className="control">
                <textarea
                  className="textarea is-rounded"
                  name="mensagem"
                  placeholder="Descreva seu problema"
                  value={formData.mensagem}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="control mt-4">
              <button className="button is-primary" type="submit">
                Enviar
              </button>
            </div>
          </form>
        </div>
      </div>
      <p style={{ marginTop: 100, textAlign: 'center' }}>© 2025 KeapStaff. Todos os direitos reservados.</p>
    </section>
  )
}

export default SupportCollab
