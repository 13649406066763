import { DataComTraçoParaDataComBarra, DateNow } from '../../../../utils/dates'

const translateField = (field) => {
  const translates = [
    { field: 'name', translate: 'Nome' },
    { field: 'kinship', translate: 'Parentesco' },
    { field: 'number', translate: 'Telefone' },
    { field: 'signedIn', translate: 'Assinado Em' },
    { field: 'comment', translate: 'Comentario' },
    { field: 'registerBy', translate: 'Registrado Por' },
    { field: 'time', translate: 'Data Hora' },
    { field: 'type', translate: 'Resposta' },
    { field: 'valueType', translate: 'Resposta' },
  ]

  return translates.find((item) => item.field === field)?.translate ?? field
}

function getValueByPath(obj, path) {
  if (path.includes('.')) {
    // coleta o valor no objeto
    return path.split('.').reduce((acc, key) => {
      // Verifica se o caminho contém um array (exemplo: "candidacy.terms[].name")
      if (Array.isArray(acc) && acc.every((item) => typeof item === 'object' && item !== null)) {
        // console.log(key)
        return acc.map((item) => item[key])
      }

      if (key.includes('[]')) {
        const arrayKey = key.replace('[]', '') // Remove o "[]"
        // console.log(arrayKey)
        // Retorna um array de valores da propriedade especificada para cada item do array

        return acc?.[arrayKey] || []
      }

      // Caso não seja um array, simplesmente acessa a propriedade
      return acc?.[key] || 'N/A'
    }, obj)
  } else {
    if (path === 'vazio') {
      return ''
    }
    if (path === 'dateNow') {
      return DataComTraçoParaDataComBarra(DateNow())
    }
  }
}

const mapValues = (data, mappings) => {
  const mappedData = []

  mappings.forEach(({ value, label }) => {
    let lineData = []

    const result = getValueByPath(data, value)

    const key = label.replace(/\s+/g, '_') // Substitui espaços por '_'

    // console.log(result)
    // console.log(key)

    if (typeof result === 'string' || typeof result === 'number') {
      lineData = [{ [key]: result }]
      // console.log(lineData)
    } else if (typeof result[0] === 'string' || typeof result[0] === 'number') {
      lineData = result.map((valueArray) => {
        return { [key]: valueArray }
      })
      // console.log(lineData)
    } else if (typeof result[0] === 'object') {
      const response = {}
      lineData = result.map((valueObject, index) => {
        const keysObject = Object.keys(valueObject).map((k) => ({
          newKey: `${key} [${index}] ${translateField(k)}`,
          oldKey: k,
        }))
        // console.log(keysObject)

        keysObject.forEach(({ newKey, oldKey }) => {
          const newValueobject = valueObject[oldKey]
          // console.log(newValueobject)
          response[newKey] = newValueobject
        })
        // console.log(response)
        return response
      })

      // console.log('Array de Objetos')
    } else {
      // console.log('Tipo desconhecido')
    }
    // console.log(lineData)

    lineData.forEach((value, index) => {
      mappedData[index] = { ...mappedData[index], ...value }
    })
  })

  const newMappedData = mappedData.map((data) => ({ ...mappedData[0], ...data }))

  return newMappedData
}

export const DestructureData = (data, schema) => {
  return mapValues(data, schema)
}

// testes:

//     const mappingsTest = [
//       { value: 'candidacy.name', label: 'Nome do candidato' },
//       { value: 'jobOffer.payment', label: 'Tipo de Pagamento' },
//       { value: 'candidacy.terms[]', label: 'Termos' },
//       { value: 'candidacy.workingHours[].comment', label: 'Horários de trabalho' },
//       { value: 'candidacy.contact.emergencyContacts[]', label: 'Contatato de emergecia' },
//       { value: 'candidacy.contact.emergencyContacts[].name', label: 'nome do contato de emergencia' },
//     ]

//     // criar lógica para criar o array de objetos finais

//     const Candidatura = {
//       candidacy: {
//         name: 'Michael M Santos',
//         contact: {
//           emergencyContacts: [
//             { name: 'Fulana', kinship: 'Pai', phone: '(11) 11111-1111' },
//             { name: 'Michael Matheus S', kinship: 'Filho(a)', phone: '111111111111111' },
//           ],
//         },
//         terms: [
//           { name: 'Registro Intermitente', signedIn: '22/01/2025 16:42' },
//           { name: 'Uso do Aplicativo', signedIn: '22/01/2025 16:56' },
//         ],
//         workingHours: [
//           { comment: 'Check-In', registerBy: 'joaquim vabo mayerhofer', time: '11/02/2025 10:58' },
//           { comment: 'Check-out', registerBy: 'joaquim vabo mayerhofer', time: '11/02/2025 12:00' },
//         ],
//       },
//       jobOffer: { payment: 'pix' },
//       status: { status: 'Presença Confirmada' },
//     }

//     const test = mapValues(Candidatura, mappingsTest)
