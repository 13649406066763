import React from 'react'

import { FormLayout } from './form/formSector'
import { Page } from '../../../../components/layouts/page'
import { Column } from '../../../../components/layouts/columns'
import { Form } from '../../../../components/ui/form'
import useAddSector from './hooks/useAddSector'

export const AdminAddSectors = () => {
  const {
    loading,
    setor,
    permissions,
    search,
    filterPermissions,
    setSearch,
    setSetor,
    setPermissions,
    handleCreateSector,
  } = useAddSector()

  return (
    <>
      <Form.Root
        className="mt-6 is-fullwidth"
        action={() => {
          handleCreateSector()
        }}
      >
        <Page.Root>
          <Column.Root>
            <Column.Content>
              <p className="subtitle is-size-3">Adicionar Setor</p>
            </Column.Content>
          </Column.Root>
          <Page.Content>
            <FormLayout
              loading={loading}
              values={setor}
              permitions={permissions}
              setPermissions={setPermissions}
              setValues={setSetor}
              permissionsList={filterPermissions || {}}
              search={search}
              setSearch={setSearch}
            ></FormLayout>
          </Page.Content>
        </Page.Root>
      </Form.Root>
    </>
  )
}
