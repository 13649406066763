import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useGlobalContext } from '../../../context/ContextGlobal'
import { TryCatch } from '../../../utils/general'
import {
  getDocument,
  getSubDocuments,
  setSubDocument,
  updateDocument,
  updateSubDocument,
} from '../../../firebase/firestore'
import { TimestampNow } from '../../../utils/dates'
import { Path } from '../../../router/paths'

const useCollaboratorPlanSelected = () => {
  const { user, setUser, alerts, setAlerts, setLoading, loading, formContext, setFormContext } = useGlobalContext()

  const { planId } = useParams()

  const navigate = useNavigate()

  const uid = user.id

  // const navigate = useNavigate()

  const getForm = async () => {
    const getPlan = await getDocument('plans', planId)

    setFormContext({ ...getPlan })
  }

  const FetchGetForm = async () => {
    await TryCatch(getForm, setLoading, setAlerts)
  }

  const JoinCollabInPlan = async (plan) => {
    const getUser = await getDocument('colaboradores', uid)

    // cancela planos existentes
    const getPlansInCollab = await getSubDocuments('colaboradores', uid, 'plans')
    const newPlansCanceleds = getPlansInCollab.map(async (p) => {
      await updateSubDocument('colaboradores', uid, 'plans', p.id, {
        status: 'canceled',
      })
    })
    await Promise.all(newPlansCanceleds)

    // cria um novo plano
    const newPlan = {
      idPlan: plan.id,
      idOmie: plan.idOmie,
      name: plan.name,
      value: plan.value,
      payment: plan.payment,
      recurrence: Number(plan.typeExpired || '30'),
      admission: plan.admission,
      dtJoin: TimestampNow(),
      status: 'active',
    }

    const { lastPayment, nextPayment } = getUser

    const hasTrial = getUser?.hasTrial || false
    const admissionPayment = getUser?.admissionPayment || false
    const trialDays = Number(plan.trialDays || '0')

    const updateCollab = {
      lastPayment,
      nextPayment,
      hasTrial,
    }

    if ((!admissionPayment || !hasTrial) && trialDays > 0) {
      // aplica ao collab o periodo de teste
      updateCollab.hasTrial = true

      // cria nova data de cobraça se possuir gratuidade
      const diasTrial = trialDays * 86400000 // 1 dia = 86.400.000 ms
      const dtStart = Number(newPlan.dtJoin) + diasTrial

      // adiciona o ultimo pagamento para não cobrar
      updateCollab.lastPayment = 0
      updateCollab.nextPayment = dtStart
      updateCollab.dtFreeTrial = dtStart
    } else {
      if (!updateCollab.lastPayment) {
        updateCollab.lastPayment = 0
      }
      if (!updateCollab.nextPayment) {
        updateCollab.nextPayment = TimestampNow()
        updateCollab.dtFreeTrial = TimestampNow()
        // updateCollab.nextPayment = Number(updateCollab.lastPayment) + newPlan.recurrence * 86400000;
      }
    }

    await setSubDocument('colaboradores', uid, 'plans', newPlan.idPlan, newPlan)

    await updateDocument('colaboradores', uid, updateCollab)

    setFormContext({})
    setUser({})

    return navigate(Path.CollabSignUpFinally)
  }

  const HandleJoinCollabInPlan = async (plan) => {
    await TryCatch(
      async () => {
        await JoinCollabInPlan(plan)
      },
      setLoading,
      setAlerts,
    )
  }

  useEffect(() => {
    FetchGetForm()
  }, [])

  return { user, loading, FetchGetForm, formContext, HandleJoinCollabInPlan, alerts, setAlerts }
}

export default useCollaboratorPlanSelected
