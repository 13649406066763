import { ArrowLeft } from 'lucide-react'

import { Path } from '../../../../router/paths'
import { Header } from '../../_components/Header'
import { PlanDescription } from '../../_components/PlanDescription'
import { Button } from '../../../../components/ui/button'
import { AlertFunctional } from '../../../../components/ui/alert/AlertFunctional'
import useCollaboratorPlanSelected from '../../_hooks/useCollaboratorPlanSelected'

export const CollaboratorSelectedPlan = () => {
  const { user, alerts, setAlerts, formContext, HandleJoinCollabInPlan } = useCollaboratorPlanSelected()

  return (
    <div>
      <Header classname={'mt-6'}>
        <a href={Path.CollabSelectPlans}>
          <ArrowLeft className="has-text-link" size={30} />
        </a>
      </Header>
      <PlanDescription user={user} plan={formContext} />

      <div className="has-text-centered">
        <Button.Bt
          className="mt-6"
          action={() => {
            HandleJoinCollabInPlan(formContext)
          }}
        >
          Contratar Assinatura
        </Button.Bt>
      </div>

      <div style={{ height: 150 }}></div>
      <AlertFunctional values={alerts} setValues={setAlerts}></AlertFunctional>
    </div>
  )
}
