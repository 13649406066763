import React from 'react'

export const CheckboxContent = ({ children, value, setValue, field = null, disabled = false, style }) => {
  const changeValue = (event) => {
    event.stopPropagation()
    if (field) {
      setValue((prev) => ({ ...prev, [field]: event.target.checked }))
    } else {
      setValue(event.target.checked)
    }
  }

  const eventStop = (event) => {
    event.stopPropagation() // Evita que o clique no checkbox dispare o evento da div
  }

  const checked = field ? value[field] : value

  return (
    <>
      <input
        className="mr-1"
        type="checkbox"
        disabled={disabled}
        onChange={changeValue}
        onClick={eventStop}
        checked={checked}
        style={style}
      />
      {children}
    </>
  )
}
