const phrases = [
  {
    id: 1,
    autor: 'Duke Ellington',
    frase: 'Os problemas são oportunidades para se mostrar o que sabe.',
  },
  {
    id: 2,
    autor: 'Henry Ford',
    frase: 'Nossos fracassos, às vezes, são mais frutíferos do que os êxitos.',
  },
  {
    id: 3,
    autor: 'Samuel Beckett',
    frase: 'Tente de novo. Fracasse de novo. Mas fracasse melhor',
  },
  {
    id: 4,
    autor: 'Sócrates',
    frase: 'É costume de um tolo, quando erra, queixar-se dos outros. É costume de um sábio queixar-se de si mesmo',
  },
  {
    id: 5,
    autor: 'W. F. Grenfel',
    frase: 'O verdadeiro heroísmo consiste em persistir por mais um momento, quando tudo parece perdido',
  },
  {
    id: 6,
    autor: 'Norman Bawes',
    frase: 'Cada cliente é como se fosse primeiro e único',
  },
  {
    id: 7,
    autor: 'Santo Agostinho',
    frase: 'Mesmo que já tenhas feito uma longa caminhada, há sempre um novo caminho a fazer',
  },
  {
    id: 8,
    autor: 'Ciclo dos Profissionais de Venda',
    frase: 'Vender é construir uma ponte entre você e seu cliente e fazê-lo atravessar para o seu lado',
  },
  {
    id: 9,
    autor: 'Churton Collins',
    frase: 'Na prosperidade, nossos amigos nos conhecem; na adversidade, nós é que conhecemos nossos amigos',
  },
  {
    id: 10,
    autor: 'Jean Paul Sartre',
    frase: 'A felicidade não está em fazer o que a gente quer, e sim querer o que a gente faz',
  },
  {
    id: 11,
    autor: 'Carl Sandburg',
    frase: 'Nada acontece a menos que sonhemos antes',
  },
  {
    id: 12,
    autor: 'Walt Disney',
    frase: 'É sempre divertido fazer o impossível',
  },
  {
    id: 13,
    autor: 'Jan Carlzon',
    frase: 'A coisa mais cara é transformar um cliente insatisfeito em satisfeito',
  },
  {
    id: 14,
    autor: 'Oscar Wilde',
    frase: 'Experiência é o nome que cada um dá a seus erros',
  },
  {
    id: 15,
    autor: 'Abraham Lincoln',
    frase: 'Lembre sempre que a sua vontade de triunfar é mais importante do que qualquer outra coisa',
  },
  {
    id: 16,
    autor: 'Anatole France',
    frase: 'Prefiro os erros do entusiasmo à indiferença da sabedoria',
  },
  {
    id: 17,
    autor: 'Carter Brow',
    frase: 'Na realidade, vendas e serviços são inseparáveis',
  },
  {
    id: 18,
    autor: 'Napoleão Bonaparte',
    frase: 'A arte de ser ora audacioso, ora prudente, é a arte de vencer',
  },
  {
    id: 19,
    autor: 'H. Jackson Brown Jr.',
    frase: 'Seja corajoso. Mesmo que você não seja, finja ser. Ninguém nota a diferença',
  },
  {
    id: 20,
    autor: 'Moliere',
    frase: 'Não somos responsáveis apenas pelo que fazemos, mas também pelo que deixamos de fazer',
  },
  {
    id: 21,
    autor: 'Michael Gerber',
    frase: 'A estratégia de marketing começa, termina, vive e morre com o cliente',
  },
  {
    id: 22,
    autor: 'R. H. Grant',
    frase: 'Quando você contrata pessoas mais inteligentes do que você, prova que é mais inteligente do que elas',
  },
  {
    id: 23,
    autor: 'Michael Jordan',
    frase: 'O talento vence jogos, mas só o trabalho em equipe vence campeonatos',
  },
  {
    id: 24,
    autor: 'Hugh Prather',
    frase: 'Um negócio nunca é bom se com ele conseguimos um inimigo',
  },
  {
    id: 25,
    autor: 'Joel L. Griffith',
    frase: 'Um objetivo nada mais é do que um sonho com limite de tempo',
  },
  {
    id: 26,
    autor: 'Elmer Letterman',
    frase: 'Sorte é o que acontece quando a preparação encontra a oportunidade',
  },
  {
    id: 27,
    autor: 'Phill Knight',
    frase: 'O mercado dita inovações. Quem não segue vira peça de museu',
  },
  {
    id: 28,
    autor: 'Vidal Sasson',
    frase: 'O único lugar que o sucesso vem antes do trabalho é no dicionário',
  },
  {
    id: 29,
    autor: 'William Ahmanson',
    frase: 'Se você quiser ir daqui para lá no mundo dos negócios, não encontrará nenhuma linha reta para levá-lo',
  },
  {
    id: 30,
    autor: 'R. Buckminster Fuller',
    frase: 'Integridade é a essência de todo negócio',
  },
  {
    id: 31,
    autor: 'Peter Drucker',
    frase: 'Existe o risco que você jamais pode correr. Existe o risco que você jamais pode deixar de correr',
  },
  {
    id: 32,
    autor: 'Henry Ford',
    frase: 'As duas coisas mais importantes não aparecem no balanço da empresa: sua reputação e o seu time',
  },
  {
    id: 33,
    autor: 'Orison Swett Marden',
    frase: 'A qualidade do seu trabalho tem tudo a ver com a qualidade da sua vida',
  },
  {
    id: 34,
    autor: 'Michael Treacy',
    frase: 'Nenhuma empresa pode ter sucesso tentando tudo para todos',
  },
  {
    id: 35,
    autor: 'Walter Reuther',
    frase: 'Nunca se esqueça de um cliente; e nunca deixe que um cliente esqueça você',
  },
  {
    id: 36,
    autor: 'Aristóteles',
    frase: 'Somos o que repetidamente fazemos. Portanto, a excelência não é um feito, é um hábito',
  },
  {
    id: 37,
    autor: 'Marabel Morgan',
    frase: 'Persistência é irmã gêmea da excelência. Uma é mãe da qualidade, a outra a mãe do tempo',
  },
  {
    id: 38,
    autor: 'Theodoro Levitt',
    frase: 'O propósito de uma companhia é criar e manter o cliente',
  },
  {
    id: 39,
    autor: 'Sam Walton',
    frase: 'Comemore os seus sucessos. Veja com humor os seus fracassos',
  },
  {
    id: 40,
    autor: 'Alfred Tennyson',
    frase: 'Autorrespeito, autoconhecimento e autocontrole conduzem a vida ao poder supremo',
  },
  {
    id: 41,
    autor: 'Henri Barbusse',
    frase: 'É tentando o impossível que se chega à realização do possível',
  },
  {
    id: 42,
    autor: 'Richard Whiteley',
    frase: 'Ganharão o jogo empresas que têm como missão exceder as expectativas dos clientes',
  },
  {
    id: 43,
    autor: 'Bill Gates',
    frase: 'Toda empresa precisa ter gente que erra, que não tem medo de errar e que aprende com erro',
  },
  {
    id: 44,
    autor: 'Ralph Waldo Emerson',
    frase: 'A confiança em si mesmo é o primeiro segredo do sucesso',
  },
  {
    id: 45,
    autor: 'Provérbio Gal',
    frase: 'Aquele que pretende ser um líder tem que ser uma ponte',
  },
  {
    id: 46,
    autor: 'Peter Ducker',
    frase: 'Sempre que se vê um empreendimento de sucesso é porque alguém antes tomou uma decisão destemida',
  },
  {
    id: 47,
    autor: 'John Tschohl',
    frase: 'Serviços aos clientes é uma estratégia de marketing',
  },
  {
    id: 48,
    autor: 'Norman Vincent Peale',
    frase: 'Muda tuas ideias e mudarás teu mundo',
  },
  {
    id: 49,
    autor: 'John Kao',
    frase: 'Sua tarefa como líder é criar uma atmosfera que transforme o antagonismo em energia criativa',
  },
  {
    id: 50,
    autor: 'John Tschohl',
    frase: 'A publicidade traz os clientes até a porta; mas o mau serviço envia eles direto para fora',
  },
  {
    id: 51,
    autor: 'Daryl Bernstein',
    frase: 'Único cliente bom é o cliente feliz. Lembre-se: clientes felizes adoram gastar dinheiro',
  },
  {
    id: 52,
    autor: 'Jean Rozwadowski',
    frase: 'Devemos prometer somente aquilo que podemos entregar e entregar mais do que prometemos',
  },
  {
    id: 53,
    autor: 'Georg Wilhelm',
    frase: 'Nada de importante no mundo foi realizado sem paixão',
  },
  {
    id: 54,
    autor: 'Célia Chaim',
    frase: 'Quem tem a obrigação de visualizar o futuro não é o cliente',
  },
  {
    id: 55,
    autor: 'Roland Barthes',
    frase: 'Profissional nota 10 é aquele que tem 2 pontos de esforço, 3 pontos de talento e 5 pontos de caráter',
  },
  {
    id: 56,
    autor: 'Dean Rusk',
    frase: 'Uma das melhores maneiras de convencer os outros é ouvindo. Ouça todos',
  },
  {
    id: 57,
    autor: 'Masaaki Imai',
    frase: 'O importante é mudar sempre, mesmo que estejamos crescendo e ganhando o jogo',
  },
  {
    id: 58,
    autor: 'Barrie Hopson e Mike Scally',
    frase: 'Sempre que você vai encontrar um cliente você é a empresa',
  },
  {
    id: 59,
    autor: 'Dave Weinbaum',
    frase: 'Se não puder destacar-se pelo talento, vença pelo esforço',
  },
  {
    id: 60,
    autor: 'Segundo Mandamento da TAM',
    frase: 'Em busca do ótimo não se faz o bom',
  },
  {
    id: 61,
    autor: 'Thomas Edison',
    frase: 'Se existe uma forma de fazer melhor, descubra-a',
  },
  {
    id: 62,
    autor: 'Sérgio Almeida',
    frase: 'Fechar os olhos e os ouvidos para o cliente é uma loucura. É um suicídio empresarial',
  },
  {
    id: 63,
    autor: 'Steve Jobs',
    frase: 'Não deixe o ruído das opiniões dos outros abafar a sua própria voz interior',
  },
  {
    id: 64,
    autor: 'John F. Kennedy',
    frase: 'Somente os que ousam errar muito podem realizar muito',
  },
  {
    id: 65,
    autor: 'General George S. Patton',
    frase: 'Aceite os desafios para que possa sentir o sabor da vitória',
  },
  {
    id: 66,
    autor: 'Louis Pasteur',
    frase: 'A sorte favorece a mente preparada',
  },
  {
    id: 67,
    autor: 'Karl Albrecht',
    frase: 'Quando os momentos da verdade não são gerenciados, o serviço regride até a mediocridade',
  },
  {
    id: 68,
    autor: 'Robert H. Schuller',
    frase: 'Metas são necessárias não só para nos motivar, mas para nos manter vivos',
  },
  {
    id: 69,
    autor: 'Earl Nightingate',
    frase: 'Pessoas com meta têm sucesso pois sabem onde estão indo',
  },
  {
    id: 70,
    autor: 'Marco Aurélio',
    frase: 'A pessoa comum é exigente com os outros. A superior é exigente consigo mesmo',
  },
  {
    id: 71,
    autor: 'Sófocles',
    frase: 'Não há sucesso sem dificuldade',
  },
  {
    id: 72,
    autor: 'Carl Sewell',
    frase: 'Os clientes julgam os serviços pela maneira que são tratados por aqueles com quem tem contato',
  },
  {
    id: 73,
    autor: 'Walter Gagehot',
    frase: 'Um grande prazer na vida é fazer aquilo que dizem que você não é capaz de fazer',
  },
  {
    id: 74,
    autor: 'Eugene F. Ware',
    frase: 'Toda glória advém de ousar começar',
  },
  {
    id: 75,
    autor: 'Bruce Hammonds',
    frase: 'Todas as pequenas coisas, juntas, formam uma base de clientes fiéis e mais lucrativas',
  },
  {
    id: 76,
    autor: 'Roy L. Smith',
    frase: 'A pessoa que não acredita em si mesmo não acredita em mais nada',
  },
  {
    id: 77,
    autor: 'Cotton',
    frase: 'Grandes mentes estão prontas não apenas para explorar oportunidades, mas para criá-las',
  },
  {
    id: 78,
    autor: 'Ben Stein',
    frase: 'O primeiro passo indispensável para conseguir coisas na vida é decidir o que você quer',
  },
  {
    id: 79,
    autor: 'Jack Lowenstein',
    frase: 'A percepção quando chega a ser julgada por outros é a realidade',
  },
  {
    id: 80,
    autor: 'H. W. Arnold',
    frase: 'O pior fracassado do mundo é aquele que perdeu o entusiasmo',
  },
  {
    id: 81,
    autor: 'John Ruskin',
    frase: 'A maior recompensa pelo trabalho não é o que a pessoa ganha, é o que ela se torna através dele',
  },
  {
    id: 82,
    autor: 'Steven Hronec',
    frase: 'Quando as expectativas aumentam inicia-se um ciclo interminável e contínuo de melhoria',
  },
  {
    id: 83,
    autor: 'Confúcio',
    frase: 'Aquele que cometeu um erro e não corrigiu está cometendo outro erro',
  },
  {
    id: 84,
    autor: 'Raul Follerean',
    frase: 'A vitória sempre foi de quem nunca duvidou dela',
  },
  {
    id: 85,
    autor: 'Walter Savage Landor',
    frase: 'Os satisfeitos nada mais fazem. Os insatisfeitos são os únicos propulsores do mundo',
  },
  {
    id: 86,
    autor: 'S. Marcos',
    frase: 'Todas as coisas são possíveis àqueles que creem',
  },
  {
    id: 87,
    autor: 'Norman Vincent Peale',
    frase: 'O problema é que a maioria das pessoas prefere um elogio que prejudique do que uma crítica que beneficie',
  },
  {
    id: 88,
    autor: 'Albert Hirschman',
    frase: 'A decepção surge da disparidade entre a expectativa de uma experiência agradável e a experiência real',
  },
  {
    id: 89,
    autor: 'Dom Resende Costa',
    frase: 'Prefiro a angústia da busca do que a paz da acomodação',
  },
  {
    id: 90,
    autor: 'F. Veiga',
    frase: 'Os únicos limites das pessoas são o tamanho de suas ideias e o grau de sua dedicação',
  },
  {
    id: 91,
    autor: 'Aldous Huxley',
    frase: 'Conhecimento não é aquilo que você sabe, mas o que você faz com aquilo que sabe',
  },
  {
    id: 92,
    autor: 'Rick Tate',
    frase: 'Fidelidade do cliente é a chave para lucratividade e crescimento futuro',
  },
  {
    id: 93,
    autor: 'Provérbio ',
    frase: 'Critica o que fazes, e não faças o que criticas',
  },
  {
    id: 94,
    autor: 'Lord Chesterfield',
    frase: 'O que merece ser feito, merece ser bem feito',
  },
  {
    id: 95,
    autor: 'Henry Ford',
    frase: 'Não encontre defeitos, encontre soluções. Qualquer um sabe queixar-se',
  },
  {
    id: 96,
    autor: 'Elbert Hubbard',
    frase: 'Para evitar críticas: não faça nada, não diga nada, não seja nada',
  },
  {
    id: 97,
    autor: 'Marcos Gouv',
    frase: 'As pessoas abominam a impessoalidade do negócio',
  },
  {
    id: 98,
    autor: 'J. F. Mitchell',
    frase: 'Nunca aceite o ‘não’ como resposta',
  },
  {
    id: 99,
    autor: 'Diógenes Laércio',
    frase: 'A natureza nos deu uma língua e dois ouvidos para que ouçamos mais do que falamos',
  },
  {
    id: 100,
    autor: 'Sérgio Almeida',
    frase: 'Se você não puder manter, não proponha nada; esta é a regra básica na satisfação do cliente',
  },
  {
    id: 101,
    autor: 'Kauto',
    frase: 'Feliz aquele que sabe adquirir experiência com o erro dos outros',
  },
  {
    id: 102,
    autor: 'Eugène Ionesco',
    frase: 'Querer ser de seu tempo é já estar ultrapassado',
  },
  {
    id: 103,
    autor: 'Norman Vincent Peale',
    frase: 'Planeje o seu trabalho hoje para todos os dias trabalhar o seu plano',
  },
  {
    id: 104,
    autor: 'David Freemantle',
    frase: 'Supere a expectativa do cliente com pequenas ações frequentes e não solicitadas',
  },
  {
    id: 105,
    autor: 'Earle Wilson',
    frase: 'Sucesso é apenas sorte; pergunte para qualquer fracassado',
  },
  {
    id: 106,
    autor: 'Henry Miller',
    frase: 'Destino não é exterior a nós; somos nós que criamos nosso próprio destino dia após dia',
  },
  {
    id: 107,
    autor: 'Provérbio chin',
    frase: 'O homem que removeu a montanha começou carregando as pedras',
  },
  {
    id: 108,
    autor: 'Heinz Rahnan',
    frase: 'O gosto amargo da baixa qualidade dura bem mais do que a doçura do preço inferior',
  },
  {
    id: 109,
    autor: 'Lecouve',
    frase: 'A covardia é o medo aceito; a coragem é o medo dominado',
  },
  {
    id: 110,
    autor: 'Onassis',
    frase: 'Todos os dias me levanto para vencer',
  },
  {
    id: 111,
    autor: 'Enzo Ferrari',
    frase: 'Meu sucesso se deve ao fato de me cercar de pessoas competentes',
  },
  {
    id: 112,
    autor: 'Nathiel Hover',
    frase: 'Não atrase o seu progresso com medo de fracassar',
  },
  {
    id: 113,
    autor: 'Business Week',
    frase: 'As pessoas são a força direcional por trás do crescimento',
  },
  {
    id: 114,
    autor: 'Sétimo Mandamento da TAM',
    frase: 'Quem não tem inteligência para criar tem que ter coragem para copiar',
  },
  {
    id: 115,
    autor: 'Eleanor Roosvelt',
    frase: 'Ninguém pode ferir você sem o seu consentimento',
  },
  {
    id: 116,
    autor: 'Denis Waitley',
    frase: 'O verdadeiro risco é não fazer nada',
  },
  {
    id: 117,
    autor: 'Lao Tsu',
    frase: 'Aquele que domina os outros é forte; aquele que domina a si mesmo é todo-poderoso',
  },
  {
    id: 118,
    autor: 'Theodore Roosvelt',
    frase: 'Faça o que puder, com o que tiver, onde estiver',
  },
  {
    id: 119,
    autor: 'Claus Moller',
    frase: 'A qualidade do serviço e produto é decorrência da qualidade das pessoas',
  },
  {
    id: 120,
    autor: 'William Shakespeare',
    frase: 'Nossas dúvidas são traidoras e nos fazem perder, por medo de tentar, o que poderíamos ganhar',
  },
  {
    id: 121,
    autor: 'Henry John Kaiser',
    frase: 'Os problemas são apenas oportunidades com roupas de trabalho',
  },
  {
    id: 122,
    autor: 'William J. Bennett',
    frase: 'Não nos transformamos de uma hora para outra aquilo que não cooperamos para nos tornar',
  },
  {
    id: 123,
    autor: 'Henry David Thoreau',
    frase: 'O que a pessoa pensa a respeito de si mesmo indica o seu destino',
  },
  {
    id: 124,
    autor: 'Steve Jobs',
    frase:
      'Decidir o que não fazer é tão importante quanto decidir o que fazer. Isso é verdadeiro para companhias e também para produtos',
  },
  {
    id: 125,
    autor: 'Roberto Maksoud',
    frase: 'O cliente precisa saber que nós nos importamos com ele',
  },
  {
    id: 126,
    autor: 'Gandhi',
    frase: 'Devemos ser a mudança que queremos ver no mundo',
  },
  {
    id: 127,
    autor: 'Eleanor Roosvelt',
    frase: 'Você tem que fazer as coisas que pensa não ser capaz de fazer',
  },
  {
    id: 128,
    autor: 'Publilius Syrus',
    frase: 'Em toda a iniciativa, considere aonde você poderia chegar',
  },
  {
    id: 129,
    autor: 'Henry Ford',
    frase: 'Ninguém pode construir uma reputação com base naquilo que ainda vai fazer',
  },
  {
    id: 130,
    autor: 'Fortune Magazine',
    frase: 'A comunicação é a principal fonte de poder pessoal',
  },
  {
    id: 131,
    autor: 'Effie Jones',
    frase: 'Falhar no planejamento é um planejamento para falhar',
  },
  {
    id: 132,
    autor: 'Jean de La Fontaine',
    frase: 'Em todas as situações, deve-se considerar o objetivo',
  },
  {
    id: 133,
    autor: 'AMA',
    frase: '65% dos negócios de uma empresa vêm de clientes já existentes – não de novos',
  },
  {
    id: 134,
    autor: 'Margaret Preston',
    frase: 'É o motivo que engrandece a ação; é o fazer, não o feito',
  },
  {
    id: 135,
    autor: 'Provérbio island',
    frase: 'Quem vive sem disciplina morre sem honra',
  },
  {
    id: 136,
    autor: 'Will Rogers',
    frase: 'Mesmo se você estiver no caminho certo será atropelado se ficar sentado nele',
  },
  {
    id: 137,
    autor: 'George Savile',
    frase: 'Desperdiçar o próprio tempo é uma espécie de suicídio',
  },
  {
    id: 138,
    autor: 'Lori Tansey',
    frase: 'A ética dá lucro para a empresa',
  },
  {
    id: 139,
    autor: 'George Santayana',
    frase: 'O fanatismo consiste em redobrar esforços quando o objetivo for esquecido',
  },
  {
    id: 140,
    autor: 'Laurence J. Peter',
    frase: 'Se você não sabe para onde vai, provavelmente terminará em algum outro lugar',
  },
  {
    id: 141,
    autor: 'Fred Wiersema',
    frase: 'Os atrasos deixam o cliente com os dentes rangendo',
  },
  {
    id: 142,
    autor: 'Lao Tsu',
    frase: 'Uma jornada de mil quilômetros começa pelo primeiro passo',
  },
  {
    id: 143,
    autor: 'Michael de Montaigne',
    frase: 'Algumas derrotas são mais triunfantes do que vitórias',
  },
  {
    id: 144,
    autor: 'Marco Aurélio Antonino',
    frase: 'Nossa vida é o que os nossos pensamentos fazem dela',
  },
  {
    id: 145,
    autor: 'Ditado asiático',
    frase: 'Quem torna as coisas mais fáceis para os outros, torna as coisas mais fáceis para si',
  },
  {
    id: 146,
    autor: 'Claus Moller',
    frase: 'Custa 6 vezes mais conquistar um novo cliente do que manter um antigo',
  },
  {
    id: 147,
    autor: 'Steve Jobs',
    frase:
      'Seja um parâmetro de qualidade. Algumas pessoas não estão acostumadas a um ambiente onde a excelência é esperada',
  },
  {
    id: 148,
    autor: 'John Wayne',
    frase: 'Coragem é estar morto de medo e encilhar o cavalo mesmo assim',
  },
  {
    id: 149,
    autor: 'Alexander Soljenitsyn',
    frase: 'O talento sempre tem consciência de sua própria abundância e não cria objeções em dividir',
  },
  {
    id: 150,
    autor: 'Dick Armey',
    frase: 'Não se pode progredir enquanto se ajustam contas',
  },
  {
    id: 151,
    autor: 'Charles Dickens',
    frase: 'O único bem coletivo é aquele que assegura o bem individual de cada cidadão',
  },
  {
    id: 152,
    autor: 'Provérbio ind',
    frase: 'Ouça, ou sua língua vai conservar sua surdez',
  },
  {
    id: 153,
    autor: 'Eric Berne',
    frase: 'O que você dá é o que você recebe',
  },
  {
    id: 154,
    autor: 'Robert Collier',
    frase: 'Sucesso é a soma de pequenos esforços repetidos dia sim, e no outro também',
  },
  {
    id: 155,
    autor: 'Ursula L. Le Guin',
    frase: 'É bom ter objetivo para a jornada à frente; mas, no fim, a jornada é o que importa',
  },
  {
    id: 156,
    autor: 'Abbie Hoffman',
    frase: 'Jamais imponha a sua linguagem às pessoas com as quais você deseja se comunicar',
  },
  {
    id: 157,
    autor: 'Charles Lazarus',
    frase: 'Você tem que escutar os clientes e então agir com base no que eles disseram',
  },
  {
    id: 158,
    autor: 'Ralph Waldo Emerson',
    frase: 'As pessoas só vêem aquilo que estão preparadas para ver',
  },
  {
    id: 159,
    autor: 'John Stuart Mill',
    frase: 'Quem só conhece o seu lado do problema sabe pouco sobre ele',
  },
  {
    id: 160,
    autor: 'John Heywood',
    frase: 'Duas cabeças são melhores do que uma',
  },
  {
    id: 161,
    autor: 'William Bernbach',
    frase: 'Uma ideia pode virar pó ou mágica, depende do talento que esbarra nela',
  },
  {
    id: 162,
    autor: 'Sérgio Almeida',
    frase: 'A empresa que busca encantar o cliente é inquieta e criativa',
  },
  {
    id: 163,
    autor: 'Heráclito',
    frase: 'A oposição traz concórdia. A discórdia vem da mais perfeita harmonia',
  },
  {
    id: 164,
    autor: 'Confúcio',
    frase: 'Não busque todas as qualidades em uma pessoa só',
  },
  {
    id: 165,
    autor: 'Gary Oldman',
    frase: 'Ter senso de humor é tão importante quanto ter talento',
  },
  {
    id: 166,
    autor: 'Will Rogers',
    frase: 'Todos nós somos ignorantes, só que em assuntos diferentes',
  },
  {
    id: 167,
    autor: 'Chuang-tsé, adaptado',
    frase: 'O plano de só uma pessoa pode ser incompleto; o de duas será melhor',
  },
  {
    id: 168,
    autor: 'Barrie Hopson',
    frase: 'Ninguém jamais ganha uma discussão com o cliente',
  },
  {
    id: 169,
    autor: 'Thomas Fuller',
    frase: 'O hoje é aluno do ontem',
  },
  {
    id: 170,
    autor: 'John F. Kennedy',
    frase: 'Jamais devemos negociar por medo, mas jamais devemos ter medo de negociar',
  },
  {
    id: 171,
    autor: 'Fábio Eltz',
    frase: 'O tratamento deve sempre ser positivo, independente da circunstância',
  },
  {
    id: 172,
    autor: 'Louisa May Alcott',
    frase: 'Não tenha medo de tempestades, pois estarás aprendendo a manejar o barco',
  },
  {
    id: 173,
    autor: 'Derek Bok',
    frase: 'Se você pensa que educação é uma coisa cara, tente a ignorância',
  },
  {
    id: 174,
    autor: 'Amelia Earhart',
    frase: 'A coisa mais difícil é a decisão de agir, o resto é apenas tenacidade',
  },
  {
    id: 175,
    autor: 'Elizabeth Cady Stanton',
    frase: 'O autodesenvolvimento é um dever maior do que o auto-sacrifício',
  },
  {
    id: 176,
    autor: 'Alexander Graham Bell',
    frase: 'Grandes descobertas e progressos envolvem a cooperação de várias mentes',
  },
  {
    id: 177,
    autor: 'Sérgio Quartiermeister',
    frase: 'É preciso buscar fatias na multidão',
  },
  {
    id: 178,
    autor: 'Provérbio japon',
    frase: 'Caia 7 vezes. Levante-se 8',
  },
  {
    id: 179,
    autor: 'Simone de Beauvoir',
    frase: 'Ninguém nasce um gênio; a pessoa se torna um',
  },
  {
    id: 180,
    autor: 'Lao Tzu',
    frase: 'Quando eu deixar de ser o que sou, eu irei me tornar o que poderia ser',
  },
  {
    id: 181,
    autor: 'Steve Jobs',
    frase: 'Seu tempo é limitado, então não o gaste vivendo a vida de outra pessoa',
  },
  {
    id: 182,
    autor: 'Oliver Wendell Holmes',
    frase: 'A mente humana, expandida por uma ideia nova, jamais retorna à dimensão original',
  },
  {
    id: 183,
    autor: 'Sérgio Almeida',
    frase: 'A percepção é um ponto de vista individual. Portanto, não se discute',
  },
  {
    id: 184,
    autor: 'Anderson Silva',
    frase: 'Você deve guardar um dragão dentro de si. Quando precisar, você deixa o dragão sair',
  },
  {
    id: 185,
    autor: 'Vincent Van Gogh',
    frase: 'Eu preferiria morrer de paixão do que de tédio',
  },
  {
    id: 186,
    autor: 'Gloria Steinem',
    frase: 'Sonhar, afinal, é uma forma de planejamento',
  },
  {
    id: 187,
    autor: 'Benjamin Disraeli',
    frase: 'O segredo do sucesso é a constância do objetivo',
  },
  {
    id: 188,
    autor: 'Benjamin Franklin',
    frase: 'Escreva algo que valha a pena ler, ou faça alguma coisa que valha a pena escrever',
  },
  {
    id: 189,
    autor: 'Fela Moscovici',
    frase: 'Saber ouvir e ter equilíbrio emocional: fundamentais no atendimento ao cliente',
  },
  {
    id: 190,
    autor: 'Orson Welles',
    frase: 'É preciso ter dúvidas. Só os estúpidos têm uma confiança absoluta em si mesmos',
  },
  {
    id: 191,
    autor: 'Marie Curie',
    frase: 'Devemos crer que somos dotados de alguma coisa, e que essa coisa, deve ser atingida a todo custo',
  },
  {
    id: 192,
    autor: 'Confúcio',
    frase: 'Tudo tem beleza. Mas nem todos podem ver',
  },
  {
    id: 193,
    autor: 'Robert Peterson',
    frase: 'Prometa o que não irá cumprir e seus clientes formarão um mau conceito a seu respeito',
  },
  {
    id: 194,
    autor: 'Pôster da IBM',
    frase: 'Se o índice de falha é de 1 em 1 milhão, o que você responde para esse cliente?',
  },
  {
    id: 195,
    autor: 'Abigail Van Buren',
    frase: 'Se você deseja que seus filhos acabem bem, gaste o dobro de tempo com eles, e metade do dinheiro',
  },
  {
    id: 196,
    autor: 'Manual da Nordstrom',
    frase: 'Use bom senso em todas as situações; não espere por regras novas, pois não virão',
  },
  {
    id: 197,
    autor: 'George Lucas',
    frase: 'Todo mundo tem talento, é só uma questão de se mexer até descobrir qual',
  },
  {
    id: 198,
    autor: 'John Nasbit',
    frase: 'Quanto mais nos defrontamos com tecnologia, maior nosso anseio por contatos humanos',
  },
  {
    id: 199,
    autor: 'Provérbio grego',
    frase: 'Bem começado é meio caminho andado',
  },
  {
    id: 200,
    autor: 'Buddha',
    frase: 'A mente é tudo. Você se torna aquilo que você pensa',
  },
  {
    id: 201,
    autor: 'Donald Beaver Jr.',
    frase: 'Uma queixa é alguém avisando que você não o satisfez. E isso é escrito em ouro',
  },
  {
    id: 202,
    autor: 'Júlio Ribeiro',
    frase: 'O medo de ousar, ser agressivo ou inovar fechou mais empresas do que a ousadia',
  },
  {
    id: 203,
    autor: 'H. Ross Perot',
    frase: 'Minha primeira mensagem é: escute, escute e escute o pessoal que faz o trabalho',
  },
  {
    id: 204,
    autor: 'Franklin D. Roosvelt',
    frase: 'A preguiça caminha tão devagar que a pobreza a alcança com rapidez',
  },
  {
    id: 205,
    autor: 'Denis Walker',
    frase: 'Dos clientes insatisfeitos, apenas 5% efetivamente fez uma reclamação',
  },
  {
    id: 206,
    autor: 'Henry David Thoreau',
    frase: 'Vá na direção de seus sonhos. Viva a vida que você imaginou',
  },
  {
    id: 207,
    autor: 'Marcos Gouv',
    frase: 'Não existe empresa nota 10 com empregados nota 5',
  },
  {
    id: 208,
    autor: 'Quin Guanshu',
    frase: 'Perder tempo é um castigo insuportável',
  },
  {
    id: 209,
    autor: 'Chip Bell',
    frase: 'Serviços ao cliente não consistem em satisfação, mas sim em deslumbre',
  },
  {
    id: 210,
    autor: 'Janis Joplin',
    frase: 'Você pode destruir o seu hoje se preocupando com o amanhã',
  },
  {
    id: 211,
    autor: 'Fábio Eltz',
    frase: "Todo cliente quer ser tratado como especial, não quer ser ‘mais um'",
  },
  {
    id: 212,
    autor: 'Platão',
    frase: 'Começar é a parte mais importante de qualquer trabalho',
  },
  {
    id: 213,
    autor: 'Ron Bern',
    frase: 'Nossa meta é resolver problemas antes que os clientes saibam que eles existam',
  },
  {
    id: 214,
    autor: 'Nelson Mandela',
    frase: 'Uma boa cabeça e um bom coração formam sempre uma combinação formidável',
  },
  {
    id: 215,
    autor: 'Kaoru Ishikawa',
    frase: 'Se você não tem item de controle, você não tem gerência',
  },
  {
    id: 216,
    autor: 'John Tschohl',
    frase: 'Os colaboradores evitam as queixas pois eles nunca foram instruídos a lidar com elas',
  },
  {
    id: 217,
    autor: 'Theodore Roosvelt',
    frase: 'O maior prêmio que a vida oferece é trabalhar em algo que valha a pena',
  },
  {
    id: 218,
    autor: 'George Lichtenberg',
    frase: 'Um bom porta-voz transforma leite desnatado em chantilly',
  },
  {
    id: 219,
    autor: 'J. Walters',
    frase: 'Um líder genuíno é motivador: orienta as pessoas, não as dirige; envolve, não as coage',
  },
  {
    id: 220,
    autor: 'Kevin Kruse',
    frase: 'A vida não é sobre ter, é sobre dar e ser',
  },
  {
    id: 221,
    autor: 'Karl Albrecht',
    frase: 'O resultado final de um serviço é um sentimento',
  },
  {
    id: 222,
    autor: 'Terry Vavra',
    frase: 'O objetivo do marketing de relacionamento é a retenção de clientes',
  },
  {
    id: 223,
    autor: 'Paul Deschanel',
    frase: 'Aqueles que nada fazem estão sempre dispostos a criticar os que fazem algo',
  },
  {
    id: 224,
    autor: 'Juran',
    frase: 'Cliente é todo aquele impactado por seus produtos, processos e serviços',
  },
  {
    id: 225,
    autor: 'Raimar Richers',
    frase: 'As empresas terão de adaptar-se às preferências individuais de cada consumidor ou sucumbirão',
  },
  {
    id: 226,
    autor: 'Fiódor Dostoiévski',
    frase: 'Sente-se sufocado aquele sem um objetivo de vida',
  },
  {
    id: 227,
    autor: 'Claus Moller',
    frase: 'Não critique a pessoa, somente o comportamento',
  },
  {
    id: 228,
    autor: 'Horácio',
    frase: 'É doce deixar a mente relaxar de vez em quando',
  },
  {
    id: 229,
    autor: 'Ken O',
    frase: 'O presente é o único momento que temos para fazer algo',
  },
  {
    id: 230,
    autor: 'William Shakespeare',
    frase: 'Todo mundo é capaz de dominar uma dor, exceto quem a sente',
  },
  {
    id: 231,
    autor: 'Richard Whiteley',
    frase: 'Desperdício é tudo aquilo que não representa valor para o cliente',
  },
  {
    id: 232,
    autor: 'Thomas Carlyle',
    frase: 'A convicção é inútil – a menos que se transforme em conduta',
  },
  {
    id: 233,
    autor: 'Phillip Crosby',
    frase: 'Quanto mais distante o administrador do administrado, menos eficiente a administração',
  },
  {
    id: 234,
    autor: 'Dalai Lama',
    frase: 'A felicidade não é algo pronto. Ela vem de suas próprias ações',
  },
  {
    id: 235,
    autor: 'Jefferson Le',
    frase: 'Devemos seguir o exemplo dos rios: dão suas águas ao mar e nunca ficam vazios',
  },
  {
    id: 236,
    autor: 'Peter Drucker',
    frase: 'Meu maior poder como consultor é ser ignorante e fazer algumas perguntas',
  },
  {
    id: 237,
    autor: 'L. L. Bean Inc',
    frase: 'O cliente é alguém que nos traz suas necessidades',
  },
  {
    id: 238,
    autor: 'Elbert Hubbard',
    frase: 'O pior erro que você pode cometer em sua vida é ficar com medo de cometer algum erro',
  },
  {
    id: 239,
    autor: 'Catherine Romano',
    frase: 'Se houver uma opção melhor, os clientes mudarão de fornecedor',
  },
  {
    id: 240,
    autor: 'M. J. Babcock',
    frase: 'O alvo na vida não deve ser ultrapassar os outros, mas sim ultrapassar a nós mesmos',
  },
  {
    id: 241,
    autor: 'Terrell Harris',
    frase: 'Quando são cometidos lapsos nos serviços, as vendas caem',
  },
  {
    id: 242,
    autor: 'Publilius Syrus',
    frase: 'Jamais prometa mais do que pode fazer',
  },
  {
    id: 243,
    autor: 'Milton Moore',
    frase: 'Se você for direto e honesto com seus clientes eles o tratarão como amigo',
  },
  {
    id: 244,
    autor: 'Cesare Cant',
    frase: 'Não arriscar nada é arriscar tudo',
  },
  {
    id: 245,
    autor: 'George Washington',
    frase: 'Não se comprometa a fazer o que não é capaz, mas preocupe-se em manter sua promessa',
  },
  {
    id: 246,
    autor: 'Video Business Magazine',
    frase: 'Quando você atende ao telefone, a imagem da sua empresa é percebida do outro lado da linha',
  },
  {
    id: 247,
    autor: 'Benjamin Franklin',
    frase: 'Um investimento em conhecimento sempre paga o maior juro',
  },
  {
    id: 248,
    autor: 'Lunda Goldzimer',
    frase: 'Contrate o tipo de pessoa capaz de construir relações sólidas com os clientes',
  },
  {
    id: 249,
    autor: 'George Gurdjieff',
    frase: 'Não abandone suas três grandes e inabaláveis amigas: a intuição, a inocência e a fé',
  },
  {
    id: 250,
    autor: 'Geraldo Vandré',
    frase: 'Quem sabe faz a hora, não espera acontecer',
  },
  {
    id: 251,
    autor: 'Peter Drucker',
    frase: 'A única coisa que sabemos sobre o futuro é que ele será diferente',
  },
  {
    id: 252,
    autor: 'David Clutterbuck',
    frase: 'Quando você perde um cliente, perde transações futuras e propaganda boca a boca',
  },
  {
    id: 253,
    autor: 'Harols Abbott',
    frase: 'Eu me sentia derrotado por não ter sapatos, até o dia que encontrei um homem que não tinha pés',
  },
  {
    id: 254,
    autor: 'Paulo Apsan',
    frase: 'Uma empresa que não escuta o seu cliente está fadada ao insucesso',
  },
  {
    id: 255,
    autor: 'Leo Burnett',
    frase: 'Se você não é notado, você não tem nada',
  },
  {
    id: 256,
    autor: 'Abraham Lincoln',
    frase: 'Não são os anos de sua vida que contam. É a vida em seus anos',
  },
  {
    id: 257,
    autor: 'Don Peppers',
    frase: 'O marketing de relacionamento reduz o impacto dos esforços feitos pela concorrência',
  },
  {
    id: 258,
    autor: 'Heródoto',
    frase: 'É melhor ser invejado do que lastimado',
  },
  {
    id: 259,
    autor: 'Walt Disney',
    frase: 'Você pode construir o mais belo lugar do mundo, mas serão as pessoas que tornarão realidade o sonho',
  },
  {
    id: 260,
    autor: 'Reinaldo Polito',
    frase: 'Não existe técnica de comunicação mais relevante do que a naturalidade',
  },
  {
    id: 261,
    autor: 'Carmen Miranda',
    frase: 'Na vida sempre fazemos escolhas. Ainda que escolhemos não escolher',
  },
  {
    id: 262,
    autor: 'Sales and Marketing Magazine',
    frase: 'A melhor maneira de vender é facilitando a compra',
  },
  {
    id: 263,
    autor: 'Samuel Smiles',
    frase: 'A cortesia tem um grande poder, e não custa nada',
  },
  {
    id: 264,
    autor: 'Beverly Sills',
    frase: 'Você pode ficar desapontado se falhar, mas já está derrotado se não tentar',
  },
  {
    id: 265,
    autor: 'Texas American Bank',
    frase: 'Se souber o nome do cliente, use em sua saudação',
  },
  {
    id: 266,
    autor: 'Tom Peter e Nancy Austin',
    frase: 'Ofereça ao cliente algo que vale a pena e ele pagará o que vale',
  },
  {
    id: 267,
    autor: 'Constantino C. Vigil',
    frase: 'Seja hoje melhor do que ontem e, amanhã, melhor do que hoje',
  },
  {
    id: 268,
    autor: 'Oscar Wilde',
    frase: 'O acordo é o último recurso dos que não têm imaginação',
  },
  {
    id: 269,
    autor: 'Richard Whiteley',
    frase: 'Lucro é vital, mas o cliente vem antes. Sem ele não há lucro',
  },
  {
    id: 270,
    autor: 'Norman Vaughan',
    frase: 'Sonhe grande e se atreva a falhar',
  },
  {
    id: 271,
    autor: 'David Ogilvy',
    frase: 'Eu sempre uso o produto dos meus clientes; não é bajulação, mas boas maneiras',
  },
  {
    id: 272,
    autor: 'Mark van Doren',
    frase: 'Traga novas ideias e trate-as regiamente, pois uma delas pode ser o rei',
  },
  {
    id: 273,
    autor: 'Dito popular',
    frase: 'Quem quer agradar a todo mundo não agrada a ninguém',
  },
  {
    id: 274,
    autor: 'Samuel Johnson',
    frase: 'Leve é a tarefa quando muitos dividem o trabalho',
  },
  {
    id: 275,
    autor: 'Linda Goldzimer',
    frase: 'Você seria seu próprio cliente?',
  },
  {
    id: 276,
    autor: 'Vince Lombardi',
    frase: 'Ganhar não é tudo, mas querer ganhar é',
  },
  {
    id: 277,
    autor: 'John Young',
    frase: 'Aquilo que você não pode medir não pode ser aperfeiçoado',
  },
  {
    id: 278,
    autor: 'Charles Brower',
    frase: 'O conveniente e o correto raramente são a mesma coisa',
  },
  {
    id: 279,
    autor: 'Gordon Spainhower',
    frase: 'A confiabilidade do serviço é fator mais importante de todos, duas vezes acima dos demais',
  },
  {
    id: 280,
    autor: 'Albert Einstein',
    frase: 'Uma pessoa que nunca cometeu um erro, nunca tentou nada de novo',
  },
  {
    id: 281,
    autor: 'Claus Moller',
    frase: 'Você precisa ver seus erros para poder fazer algo a respeito deles',
  },
  {
    id: 282,
    autor: 'Provérbio norte',
    frase: 'Se os ventos não vão servir, leve os remos',
  },
  {
    id: 283,
    autor: 'Sérgio Quartiermeister',
    frase: 'Ninguém cativa um cliente sabendo apenas o nome e onde ele mora',
  },
  {
    id: 284,
    autor: 'John Tschohol',
    frase: 'O trabalho de adivinhação torna inevitável a insatisfação',
  },
  {
    id: 285,
    autor: 'Eleanor Roosvelt',
    frase: 'Quando deixamos de contribuir, começamos a morrer',
  },
  {
    id: 286,
    autor: 'Laurent Svadon',
    frase: 'A chave do negócio é analisar cada cliente antes de servi-lo',
  },
  {
    id: 287,
    autor: 'Kelly Young',
    frase: 'O problema não é o problema. O problema é a atitude com relação ao problema',
  },
  {
    id: 288,
    autor: 'Tom Peters',
    frase: 'Verifique seus sentimentos à porta do escritório',
  },
  {
    id: 289,
    autor: 'Carl Sewell',
    frase: 'Diga ‘obrigado’ sempre que tiver uma oportunidade',
  },
  {
    id: 290,
    autor: 'Winston Churchill',
    frase: 'Você nunca vai chegar ao seu destino se você parar e atirar pedras em cada cão que late',
  },
  {
    id: 291,
    autor: 'Leonard Berry',
    frase: 'A confiança dos clientes é o ativo mais valioso de uma companhia de serviços',
  },
  {
    id: 292,
    autor: 'Sabedoria Persa',
    frase: 'Não ergas um edifício sem fortes alicerces ou viverás com medo',
  },
  {
    id: 293,
    autor: 'Stephen Covey',
    frase: 'Trate seus funcionários assim como quer que eles tratem seus melhores clientes',
  },
  {
    id: 294,
    autor: 'Lema do Ritz',
    frase: 'Somos damas e cavalheiros servindo damas e cavalheiros',
  },
  {
    id: 295,
    autor: 'Albert Einstein',
    frase: 'Esforce-se para não ser um sucesso, mas sim para ser valioso',
  },
  {
    id: 296,
    autor: 'Peter Francese',
    frase: 'Projete um sistema que equilibre processo, produto e medidas de satisfação',
  },
  {
    id: 297,
    autor: 'William McKnight',
    frase: 'Ouça qualquer um que tenha uma ideia original. Encoraje-o. Deixe as pessoas continuarem suas ideias',
  },
  {
    id: 298,
    autor: 'Phillip Crosby',
    frase: 'Satisfaça o cliente no começo, no fim e sempre',
  },
  {
    id: 299,
    autor: 'Benjamin Franklin',
    frase: 'Eu não falhei no teste. Eu só encontrei 100 maneiras de fazer errado',
  },
  {
    id: 300,
    autor: 'Júlio Lobos',
    frase: 'Quer segurança em um emprego? Faça um cliente feliz',
  },
  {
    id: 301,
    autor: 'Antoine de Saint',
    frase: 'O essencial é invisível aos olhos',
  },
  {
    id: 302,
    autor: 'Richard Whiteley',
    frase: 'Conheça seus clientes como sua família, satisfaça-os e você terá sucesso',
  },
  {
    id: 303,
    autor: 'Sérgio Almeida',
    frase: 'Um cliente que reclama é um consultor. Aponta o que melhorar e não cobra nada por isso',
  },
  {
    id: 304,
    autor: 'George Eliot',
    frase: 'Nunca é tarde demais para ser o que você poderia ter sido',
  },
  {
    id: 305,
    autor: 'Jay Curry e Ludovic Stora',
    frase: 'Não existe melhor publicidade do que um cliente satisfeito',
  },
  {
    id: 306,
    autor: 'Alvin Toffler',
    frase: 'Gerenciar não é mais a direção da coisa, mas o aperfeiçoamento das pessoas',
  },
  {
    id: 307,
    autor: 'Carl Sewell',
    frase: 'A pessoa no comando não pode apenas pregar. Ela precisa liderar com exemplos',
  },
  {
    id: 308,
    autor: 'Larry Light',
    frase: 'A marca é um símbolo que identifica uma promessa na cabeça do cliente',
  },
  {
    id: 309,
    autor: 'Paulo Freire',
    frase: 'Não há saber mais ou saber menos: há saberes diferentes',
  },
  {
    id: 310,
    autor: 'Jack Welck',
    frase: 'O papel do gerente é de orientar, energizar e estimular',
  },
  {
    id: 311,
    autor: 'Booker T. Washington',
    frase: 'Se você quiser se levantar, levante outra pessoa',
  },
  {
    id: 312,
    autor: 'John Sewell',
    frase: 'Você pode tosquiar o carneiro por muitos anos, mas poderá esfolá-lo somente uma vez',
  },
  {
    id: 313,
    autor: 'H. W. Beecher',
    frase: 'Quanto maior a dificuldade maior é o mérito de superá-la',
  },
  {
    id: 314,
    autor: 'Bill Bleuel',
    frase: 'Cuidar o lado emocional do cliente é mais importante do que consertar um equipamento',
  },
  {
    id: 315,
    autor: 'Leonard Berry',
    frase: 'O cliente compra uma promessa e são obrigados a confiar que a empresa ira cumpri-la',
  },
  {
    id: 316,
    autor: 'Ayn Rand',
    frase: 'A questão não é quem vai me deixar; mas sim, quem vai me impedir',
  },
  {
    id: 317,
    autor: 'Claus Moller',
    frase: 'Certifique-se de que suas palavras e seus atos estão em harmonia',
  },
  {
    id: 318,
    autor: 'Steven Leonard Jr.',
    frase: 'As pessoas estão atentas a você. A qualidade está no seu coração',
  },
  {
    id: 319,
    autor: 'Edmund Burke',
    frase: 'Nossa paciência conquistará mais do que a nossa força',
  },
  {
    id: 320,
    autor: 'Karl Albrecht',
    frase: 'Se os clientes não estão reclamando você está perdendo a oportunidade de ser melhor',
  },
  {
    id: 321,
    autor: 'Larry Wilson',
    frase: 'Você pode mudar sem crescer, mas não pode crescer sem mudar',
  },
  {
    id: 322,
    autor: 'Texas American Bank',
    frase: 'Agradeça com um sorriso a presença do cliente',
  },
  {
    id: 323,
    autor: 'Ronald Osborn',
    frase: 'Você não crescerá a não ser que tente algo além daquilo que já domina',
  },
  {
    id: 324,
    autor: 'Richard Whiteley',
    frase: 'Faça a voz do cliente ecoar em toda a empresa',
  },
  {
    id: 325,
    autor: 'George Addair',
    frase: 'Tudo o que você sempre quis está do outro lado do seu medo',
  },
  {
    id: 326,
    autor: 'Marry W. Shelley',
    frase: 'Nada contribui tanto para clarear a mente do que um objetivo claro',
  },
  {
    id: 327,
    autor: 'Norman Vincent Peale',
    frase: 'Um único pensamento pode revolucionar sua vida',
  },
  {
    id: 328,
    autor: 'John Tschohl',
    frase: 'Todo o marketing da sua empresa pode ser anulado por um único empregado grosseiro',
  },
  {
    id: 329,
    autor: 'Fábio Eltz',
    frase: 'Quando compreende-se o que o cliente deseja, torna-se mais fácil atendê-lo',
  },
  {
    id: 330,
    autor: 'Albert Schweitzer',
    frase: 'Só serão felizes aqueles que procurarem e encontrarem um meio de servir',
  },
  {
    id: 331,
    autor: 'Linda Goldzimer',
    frase: 'A percepção que o cliente tem em relação ao seu produto é o que determina sua satisfação',
  },
  {
    id: 332,
    autor: 'Coco Chanel',
    frase: 'O mais corajoso dos atos ainda é pensar com a própria cabeça',
  },
  {
    id: 333,
    autor: 'Carl Sewell',
    frase: 'A coisa mais importante para o cliente é: você fez aquilo que prometeu?',
  },
  {
    id: 334,
    autor: 'Booker T. Washington',
    frase: 'Nada que valha a pena acontece a alguém exceto o resultado do trabalho árduo',
  },
  {
    id: 335,
    autor: 'Fortune Magazine',
    frase: 'A forma mais barata de manter clientes satisfeitos é servi-los bem desde o começo',
  },
  {
    id: 336,
    autor: 'Don Peppers',
    frase: 'A melhor forma de ter clientes para sempre é oferecer a eles um negócio irrecusável',
  },
  {
    id: 337,
    autor: 'Rosa Parks',
    frase: 'Tenho aprendido ao longo dos anos que, quando a mente está pronta, isso diminui o medo',
  },
  {
    id: 338,
    autor: 'Lema da Bio',
    frase: 'O cliente é o chefe',
  },
  {
    id: 339,
    autor: 'João Guimar',
    frase: 'Mestre não é quem sempre ensina, mas quem de repente aprende',
  },
  {
    id: 340,
    autor: 'Bob Harbage',
    frase: 'Se não agrega valor, pare; se faz sentido para o cliente, prossiga',
  },
  {
    id: 341,
    autor: 'Leonard Berry',
    frase: 'Os clientes sempre reconhecem quando alguém saiu do seu caminho para ajudá-los',
  },
  {
    id: 342,
    autor: 'Albert Einstein',
    frase: 'Espíritos grandiosos sempre encontraram oposição violenta de mentes medíocres',
  },
  {
    id: 343,
    autor: 'Cesare Romiti',
    frase: 'A busca da qualidade é um estado de espírito',
  },
  {
    id: 344,
    autor: 'Frank Petro',
    frase: 'O que é realmente necessário em empresa que presta serviço são pessoas que gostem de pessoas',
  },
  {
    id: 345,
    autor: 'Bertrand Russell',
    frase: 'É importante aprender a não se aborrecer com opiniões diferentes das suas',
  },
  {
    id: 346,
    autor: 'Sérgio Almeida',
    frase: 'O melhor vendedor do mundo é um cliente encantado',
  },
  {
    id: 347,
    autor: 'Roger Crowford',
    frase: 'Ter problemas na vida é inevitável. Ser derrotado por eles é opcional',
  },
  {
    id: 348,
    autor: 'Paulo Apsan',
    frase: 'Limitar o foco ao cliente provoca uma espécie de cegueira para inovações',
  },
  {
    id: 349,
    autor: 'Jaclyn Jeffrey',
    frase: 'A prestação de bons serviços nunca é acidental',
  },
  {
    id: 350,
    autor: 'Cristóvão Colombo',
    frase: 'Você nunca pode atravessar o oceano até que você tenha coragem de perder de vista a costa',
  },
  {
    id: 351,
    autor: 'Carl Sewell',
    frase: 'O que pode sair errado? Essa é a pergunta a se fazer ao projetar os seus sistemas',
  },
  {
    id: 352,
    autor: 'Benjamin Franklin',
    frase: 'Toma conta do teu negócio ou ele tomará conta de ti',
  },
  {
    id: 353,
    autor: 'Sérgio Almeida',
    frase: 'A satisfação do cliente é uma relação entre o que ele viu e o que ele esperava ver',
  },
  {
    id: 354,
    autor: 'Provérbio espanhol',
    frase: 'Perde tudo quem perde o momento certo',
  },
  {
    id: 355,
    autor: 'Claus Moller',
    frase: 'Combata a mediocridade – em si e nos outros',
  },
  {
    id: 356,
    autor: 'Dalai Lama',
    frase: 'Lembre-se que não conseguir o que você quer é algumas vezes um lance de sorte',
  },
  {
    id: 357,
    autor: 'Arthur Riedel',
    frase: 'Uma atitude vitoriosa é meio caminho andado para o sucesso',
  },
  {
    id: 358,
    autor: 'James Belasco',
    frase: 'Agregar valor é resolver os problemas do cliente',
  },
  {
    id: 359,
    autor: 'John Young',
    frase: 'Fazer rápido obriga-o a fazer certo logo na primeira vez',
  },
  {
    id: 360,
    autor: 'Donald Beaver Jr.',
    frase: 'Devemos preferir as queixas aos cumprimentos',
  },
  {
    id: 361,
    autor: 'Carl Sewell',
    frase: 'Todos os sorrisos do mundo não irão ajudá-lo se o produto ou serviço não for o que o cliente deseja',
  },
  {
    id: 362,
    autor: 'Leonard Berry',
    frase: 'Um serviço excelente é uma estratégia de lucro',
  },
  {
    id: 363,
    autor: 'Henry Ford',
    frase: 'Você pode tudo se tiver entusiasmo',
  },
  {
    id: 364,
    autor: 'Carl Sandburg',
    frase: 'Não sei aonde vou, mas já estou no caminho',
  },
  {
    id: 365,
    autor: 'Fortune Magazine',
    frase: 'Conte a verdade – ou não conte nada. Ninguém gosta de gente mentirosa',
  },
  {
    id: 366,
    autor: 'Henry Ford',
    frase: 'O insucesso é uma oportunidade para recomeçar com mais inteligência',
  },
]

function getDayOfYear(date = new Date()) {
  const startOfYear = new Date(date.getFullYear(), 0, 0)
  const diff = date - startOfYear
  return Math.floor(diff / (1000 * 60 * 60 * 24))
}

export const phraseGenerator = () => {
  return phrases[getDayOfYear()].frase.endsWith('.')
    ? `"${phrases[getDayOfYear()].frase}" -${phrases[getDayOfYear()].autor}`
    : `"${phrases[getDayOfYear()].frase}." -${phrases[getDayOfYear()].autor}`
}
