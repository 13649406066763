/* eslint-disable no-undef */
import { Input } from '../../../components/ui/input'
import keapLogo from '../../../assets/Prancheta 2.png'
import { Button } from '../../../components/ui/button'
import useRegister from '../_hooks/useRegister'
import { AlertFunctional } from '../../../components/ui/alert/AlertFunctional'
import { Form } from '../../../components/ui/form'

export const CollabSignUp = () => {
  const { formContext, setFormContext, HandleRegisterNewColab, loading, alerts, setAlerts, messagesForm } =
    useRegister()

  return (
    <Form.Root
      action={async () => {
        await HandleRegisterNewColab()
      }}
      style={{
        width: '375px', // Largura média de um celular (iPhone X, por exemplo)
        maxWidth: '100vw', // Garante que não ultrapasse a largura da tela
        margin: '0 auto', // Centraliza no modo web
      }}
    >
      <div className="has-text-centered">
        <img src={keapLogo} style={{ height: 200, width: 200 }} alt="keaplogo"></img>
      </div>
      <p className="subtitle is-size-4 has-text-centered mb-6">Criar novo Cadastro</p>

      <p className="has-text-grey-light has-text-centered">
        Bem-vindo à tela de pré-cadastro! Aqui, coletamos algumas informações iniciais para agilizar o seu processo de
        registro. Esse é um primeiro passo simples e rápido para garantir que seu cadastro seja processado corretamente.
      </p>

      <Input.Root className="mt-6">
        <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="nome">
          Nome Completo
        </Input.Label>
        <Input.Prompt
          id="name"
          className={`is-rounded`}
          value={formContext}
          setValue={setFormContext}
          field={'name'}
          required={true}
          disabled={false}
          placeholder="Ex.: Maria da Silva"
        ></Input.Prompt>
        <Input.Error>{messagesForm?.name?.msg || ''}</Input.Error>
      </Input.Root>
      <Input.Root className="mt-4">
        <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="nome">
          CPF
        </Input.Label>
        <Input.Prompt
          id="cpf"
          className={`is-rounded`}
          value={formContext}
          setValue={setFormContext}
          field={'cpf'}
          required={true}
          disabled={false}
          placeholder="999.999.999-99"
          maskValue="cpf"
          maskSetValue="cpf"
        ></Input.Prompt>
        <Input.Error>{messagesForm?.cpf?.msg || ''}</Input.Error>
      </Input.Root>
      <Input.Root className="mt-4">
        <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="nome">
          Data de Nascimento
        </Input.Label>
        <Input.Prompt
          id="birthday"
          className={`is-rounded `}
          value={formContext}
          setValue={setFormContext}
          field={'birthday'}
          required={true}
          disabled={false}
          placeholder="01/01/1920"
          type="date"
        ></Input.Prompt>
        <Input.Error>{messagesForm?.birthday?.msg || ''}</Input.Error>
      </Input.Root>
      <Input.Root className="mt-4">
        <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="nome">
          E-mail
        </Input.Label>
        <Input.Prompt
          id="email"
          className={`is-rounded `}
          value={formContext}
          setValue={setFormContext}
          field={'email'}
          placeholder="Ex.: teste@provedor.com"
          disabled={false}
        ></Input.Prompt>
        <Input.Error>{messagesForm?.email?.msg || ''}</Input.Error>
      </Input.Root>
      <Input.Root className="mt-4">
        <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="nome">
          Senha
        </Input.Label>
        <Input.Prompt
          id="password"
          className={`is-rounded `}
          value={formContext}
          setValue={setFormContext}
          field={'password'}
          required={true}
          disabled={false}
          type="password"
          placeholder="Mínimo 8 caracteres..."
        ></Input.Prompt>
        <Input.Error>{messagesForm?.password?.msg || ''}</Input.Error>
      </Input.Root>
      <Input.Root className="mt-4 mb-6">
        <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="nome">
          Confirmar Senha
        </Input.Label>
        <Input.Prompt
          id="confirmPassword"
          className={`is-rounded `}
          value={formContext}
          setValue={setFormContext}
          field={'confirmPassword'}
          required={true}
          disabled={false}
          type="password"
          placeholder="Mínimo 8 caracteres..."
        ></Input.Prompt>
        <Input.Error>{messagesForm?.confirmPassword?.msg || ''}</Input.Error>
      </Input.Root>

      <Button.Bt className="is-link is-fullwidth mt-6 mb-6" type="submit" loading={loading}>
        Criar minha Conta
      </Button.Bt>
      <div style={{ height: 150 }}></div>
      <AlertFunctional values={alerts} setValues={setAlerts}></AlertFunctional>
    </Form.Root>
  )
}
