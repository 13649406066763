import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { logIn } from '../../../firebase/auth'
import { Page } from '../../../components/layouts/page'
import { Column } from '../../../components/layouts/columns'
import { Input } from '../../../components/ui/input'
import { Button } from '../../../components/ui/button'
import { AlertFunctional } from '../../../components/ui/alert/AlertFunctional'
import { useGlobalContext } from '../../../context/ContextGlobal'
import { Form } from '../../../components/ui/form'
import keapLogo from '../../../assets/Prancheta 6.png'
import { TryCatch } from '../../../utils/general'
import { addDocument, getDocument, getDocumentsWithQuerys } from '../../../firebase/firestore'
import { TimestampNow } from '../../../utils/dates'

const newAuthenticationWeb = async (path, email, password) => {
  const querys = [
    { value: email, operator: '==', field: 'email' },
    { value: password, operator: '==', field: 'pw' },
  ]
  const getExistAuthenticToken = await getDocumentsWithQuerys('_authentications', querys, 1)

  if ((getExistAuthenticToken ?? []).length > 0) {
    return `/colaborador/${getExistAuthenticToken[0].id}${path}`
  }

  const newAuth = {
    email,
    pw: password,
    dt_created: TimestampNow(),
  }

  const tokenAuth = await addDocument('_authentications', newAuth)

  return `/colaborador/${tokenAuth}${path}`
}

const CollaboratorLogin = () => {
  const { alerts, setAlerts, loading, setLoading, setUser, setFormContext, setDataContext } = useGlobalContext()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const navigate = useNavigate()

  const Login = async () => {
    console.log(email, password)
    const response = await logIn(email, password)
    console.log(response)
    if (response.success) {
      const uuid = response.success.uid
      try {
        await getDocument('colaboradores', uuid)
      } catch (e) {
        throw new Error('Esse acesso é somente para colaboradores, acesse a area do cliente')
      }

      const newUrl = await newAuthenticationWeb('', email, password)
      return navigate(newUrl)
      // Redirecionar ou fazer o que for necessário após o login
    } else {
      throw new Error('Usuário ou Senha Incorreta')
    }
  }

  const handleLogin = async () => {
    await TryCatch(Login, setLoading, setAlerts)
  }

  useEffect(() => {
    setLoading(false)
    setUser({})
    setDataContext([])
    setFormContext({})
  }, [])

  return (
    <Page.Root>
      <Page.Content>
        <Column.Root
          className="mt-6"
          style={{
            color: 'rgb(151, 151, 151)',
          }}
        >
          <Column.Content
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '0 2rem 0 2rem',
            }}
          >
            {/* Logo e título */}
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
              <img
                src={keapLogo} // Substitua com o caminho do seu logo
                style={{ height: 120, width: 120 }}
                alt="logo"
              />
              <p className="subtitle is-size-5 has-text-link">Área do Colaborador</p>
            </div>

            {/* Formulário de login */}
            <Form.Root
              action={async () => {
                await handleLogin()
              }}
              className="mt-6"
              style={{
                width: '100%',
                maxWidth: '400px',
                margin: '0 auto',
              }}
            >
              <Input.Root className="mt-2">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="email">
                  E-mail
                </Input.Label>
                <Input.Prompt
                  id="email"
                  className="is-rounded"
                  placeholder="Digite seu e-mail"
                  required
                  value={email}
                  setValue={setEmail}
                />
              </Input.Root>

              <Input.Root className="mt-5">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="password">
                  Senha
                </Input.Label>
                <Input.Prompt
                  id="password"
                  className="is-rounded"
                  type="password"
                  placeholder="Digite sua senha"
                  required
                  value={password}
                  setValue={setPassword}
                />
              </Input.Root>

              {/* Botão de login */}
              <Button.Bt className="is-link is-fullwidth is-rounded mt-6" type="submit" loading={loading}>
                Entrar
              </Button.Bt>
            </Form.Root>

            {/* Link para criar conta */}
            <p className="has-text-centered mt-6">
              <span>Não tem uma conta? </span>
              <br />
              <br />
              <span>Baixe nosso app!</span>
              <br />
              <Button.A
                link={'https://play.google.com/store/apps/details?id=com.keapstaff.staffapp'}
                className="has-text-link"
              >
                <b>Google Play</b>
              </Button.A>
              <br />
              <span>ou</span>
              <br />
              <Button.A link={'https://apps.apple.com/us/app/keap-staff/id6743636246'} className="has-text-link">
                <b>App Store</b>
              </Button.A>
            </p>
            <div style={{ height: 150 }}></div>
            {/* Alerta ou mensagem de erro */}
            <AlertFunctional values={alerts} setValues={setAlerts} />
          </Column.Content>
        </Column.Root>
      </Page.Content>
    </Page.Root>
  )
}

export default CollaboratorLogin
