import React, { useState, useEffect } from 'react'
import Select from 'react-select'

export const SelectContentSearch = ({
  id = '',
  value,
  setValue,
  field = null,
  disabled = null,
  required = false,
  options = [],
  placeholder = 'Selecione uma opção...',
  styleControl = {},
  limitOptions = 20,
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [highlightedOption, setHighlightedOption] = useState(null) // Para armazenar a opção destacada
  const filteredOptions = options
    .filter((option) => option.label.toLowerCase().includes(searchTerm.toLowerCase()))
    .slice(0, limitOptions) // Limita a 20 resultados

  const changeValue = (newValue) => {
    if (field) {
      setValue({ ...value, [field]: newValue.value })
    } else {
      setValue(newValue.value)
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // Impede o envio do formulário ao pressionar Enter
      event.preventDefault()

      // Se houver uma opção destacada, ela será marcada
      if (highlightedOption) {
        // Executa a lógica que você deseja ao selecionar a opção
        changeValue(highlightedOption)
      }
    }
  }

  const handleMenuOpen = () => {
    setHighlightedOption(null) // Reset quando o menu é aberto
  }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: '50px', // Borda arredondada como no Bulma
      borderColor: '#dbdbdb', // Cor da borda padrão do Bulma
      padding: '0.25rem 0.735rem', // Tamanho semelhante ao `input` do Bulma
      height: '42px', // Altura do campo
      fontSize: '14px',
      boxShadow: 'none', // Remover o shadow padrão
      ...styleControl,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 999, // Garante que o dropdown fique acima de outros elementos
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#3273dc' : 'white', // Cor de foco
      color: state.isFocused ? 'white' : '#363636', // Cor do texto
      fontSize: '14px',
    }),
  }

  const optionValue = () => {
    let v

    if (field) {
      // console.log(value[field])
      v = options.find((opt) => opt.value === value[field])
    } else {
      // console.log(value)
      v = options.find((opt) => opt.value === value)
    }
    // console.log(v)
    return v
  }

  useEffect(() => {
    // console.log('Opção destacada:', highlightedOption)
  }, [highlightedOption])

  return (
    <Select
      id={id}
      options={filteredOptions}
      value={optionValue()}
      onChange={changeValue}
      onInputChange={(value) => setSearchTerm(value)}
      placeholder={placeholder}
      isSearchable={true}
      disabled={disabled}
      required={required}
      styles={customStyles}
      onKeyDown={handleKeyDown} // Intercepta o evento de Enter
      onMenuOpen={handleMenuOpen} // Reseta ao abrir o menu
    />
  )
}
