export const schemaDocument = (value) => {
  return {
    created_by: value?.create_by || '',
    dt_created: value?.dt_created || '',
    name: value?.name || '',
    dica: value?.dica || '',
    type: value?.type || '',
    facing: value?.facing || '',
    keyboardType: value?.keyboardType || '',
    sendInRegister: value?.sendInRegister || '',
    syncField: value?.syncField || '',
    notDelete: value?.notDelete || '',
  }
}
