import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import { Page } from '../../../components/layouts/page'
import { SideBar } from '../../../components/layouts/sidebar'
import { Icon } from '../../../components/icons'
import { Path } from '../../../router/paths'
// import { useGlobalContext } from '../../../context/ContextGlobal'

export const ClientLayoutSideBarSettings = () => {
  // const [active, setActive] = useState(false)
  // const { user } = useGlobalContext()
  const location = useLocation()
  return (
    <>
      <Page.Content
        className=""
        style={{
          marginTop: 80,
          marginLeft: 80,
          marginRight: 10,
          marginBottom: 500,
        }}
      >
        <Outlet />
      </Page.Content>

      <Page.SideBar>
        <SideBar.Root>
          <SideBar.List>
            <SideBar.Item
              title={'Minha Empresa'}
              link={Path.ClientSettings}
              activate={location.pathname === Path.ClientSettings}
            >
              <Icon size={30}>info</Icon>
            </SideBar.Item>
            <SideBar.Item
              title={'Pagamentos'}
              link={Path.ClientPayments}
              activate={location.pathname === Path.ClientPayments}
            >
              <Icon size={30}>bill</Icon>
            </SideBar.Item>
            <SideBar.Item title={'Usuarios'} link={Path.ClientUsers} activate={location.pathname === Path.ClientUsers}>
              <Icon size={30}>users</Icon>
            </SideBar.Item>
            <SideBar.Item
              title={'Setores'}
              link={Path.ClientSectors}
              activate={location.pathname === Path.ClientSectors}
            >
              <Icon size={30}>sectors</Icon>
            </SideBar.Item>
            {/* <SideBar.Item title={'Cidades'} link={Path.ClientCitys} activate={location.pathname === Path.ClientCitys}>
              <Icon size={30}>map-pin</Icon>
            </SideBar.Item> */}
            <SideBar.Item
              title={'Informações'}
              link={Path.ClientInformations}
              activate={location.pathname === Path.ClientInformations}
            >
              <Icon size={30}>infos</Icon>
            </SideBar.Item>
          </SideBar.List>
        </SideBar.Root>
      </Page.SideBar>

      {/* Componente do REACT DOM que move a pagina para cima ao trocar de paginas, só funciona para as paginas que estão em children  */}
    </>
  )
}
