// import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { TransformationforMask } from '../../../../utils/masks'
import { useValidateCollaboratorModal } from './useValidateCollaboratorModal'
import { getAddressByCep } from '../../../../utils/consultingCEP'
import { isValidCPF } from '../../../../utils/validateCPF'
import { updateDocument } from '../../../../firebase/firestore'
import { DateTimeNow } from '../../../../utils/dates'
import { TryCatch } from '../../../../utils/general'
// import { sendNotification } from '../../../../utils/sendNotification'

export const useCollaboratorsForm = () => {
  const { id } = useParams()

  // const [userInfo, setUserInfo] = useState(null)

  // useEffect(() => {
  //   const fetchUser = async () => {
  //     const userData = await getDocument('colaboradores', id)
  //     setUserInfo(userData)
  //   }

  //   fetchUser()
  // }, [id])

  // const uuidAsArray = [userInfo?.id]
  // const tokenAsArray = [userInfo?.token]

  const { user, loading, setLoading, setAlerts, formContext, setFormContext } = useGlobalContext()

  const { openModal, setOpenModal, handleOpenValidateModal } = useValidateCollaboratorModal()

  const emergencyContactsGroup = formContext?.emergencyContacts ?? [{ name: '', kinship: '', phone: '' }]
  const emergencyContactsGroupCount = emergencyContactsGroup.length

  const submitFormDisabled = formContext?.estadoCivil && formContext?.tipoDocumento && formContext?.uf

  const isBlobOrFile =
    (formContext?.photoUrl && typeof Blob !== 'undefined' && formContext?.photoUrl instanceof Blob) ||
    (typeof File !== 'undefined' && formContext?.photoUrl instanceof File)

  const handleCPFField = (cpf) => {
    const formattedCPF = TransformationforMask(cpf, 'cpf')

    setFormContext((prev) => {
      return {
        ...prev,
        cpf: formattedCPF,
      }
    })

    if (formattedCPF.length === 14 && !isValidCPF(formattedCPF)) {
      return setAlerts((prev) => [
        ...prev,
        {
          title: 'CPF Inválido',
          msg: `O CPF digitado não existe!`,
          type: 'error',
        },
      ])
    }
  }

  const handleZipcodeField = async (zipcode) => {
    const formattedZipcode = TransformationforMask(zipcode, 'cep')

    setFormContext((prev) => {
      return {
        ...prev,
        cep: formattedZipcode,
      }
    })

    if (formattedZipcode.length === 9) {
      const zipcodeQueryResult = await getAddressByCep(formattedZipcode.substr(0, 9))

      if (zipcodeQueryResult.error) {
        return setAlerts((prev) => [
          ...prev,
          {
            title: 'CEP Inválido',
            msg: zipcodeQueryResult.error,
            type: 'error',
          },
        ])
      }

      setFormContext((prev) => {
        return {
          ...prev,
          logradouro: zipcodeQueryResult.logradouro,
          bairro: zipcodeQueryResult.bairro,
          cidade: zipcodeQueryResult.localidade,
          uf: zipcodeQueryResult.uf,
        }
      })
    }
  }

  const handleEmergencyFieldsChange = (index, event) => {
    const { name, value } = event.target
    const newEmergencyContactsGroup = [...emergencyContactsGroup]

    newEmergencyContactsGroup[index][name] = TransformationforMask(value)

    setFormContext((prevState) => {
      return {
        ...prevState,
        emergencyContacts: newEmergencyContactsGroup,
      }
    })
  }

  // funções para os campos para lidar com os campos de emergência
  const handleAddEmergencyContact = () => {
    setFormContext((prevState) => {
      return {
        ...prevState,
        emergencyContacts: [...prevState.emergencyContacts, { name: '', kinship: '', phone: '' }],
      }
    })
  }

  const handleDeleteEmergencyContact = (index) => {
    if (emergencyContactsGroupCount > 1) {
      const updatedContacts = emergencyContactsGroup.filter((_, i) => i !== index)

      setFormContext((prevState) => {
        return {
          ...prevState,
          emergencyContacts: updatedContacts,
        }
      })
    }
  }

  const blockUnblockCollaborator = async () => {
    if (!user?.user?.permissions?.colaboradores_bloquearDesbloquear === true) {
      return setAlerts((prev) => [
        ...prev,
        {
          title: 'Sem Permissão',
          msg: 'Você não possui permissão para bloquear/desbloquear o colaborador',
          type: 'error',
        },
      ])
    }

    // Pegue o estado atual
    setFormContext((prevState) => {
      const currentCollaboratorStatus = prevState.status
      const newCollaboratorStatus = currentCollaboratorStatus < 9 ? 9 : 3
      // console.log(newCollaboratorStatus)
      // Atualize o estado localmente
      return {
        ...prevState,
        status: newCollaboratorStatus,
      }
    })

    // Atualize no servidor
    try {
      const currentFormContext = formContext // Certifique-se de que esta seja a versão atualizada
      const newStatus = currentFormContext?.status < 9 ? 9 : 3
      // console.log(newStatus)

      await updateDocument('colaboradores', id, {
        status: newStatus,
      })

      setAlerts((prev) => [
        ...prev,
        {
          title: `Usuário ${newStatus === 9 ? 'Ativado' : 'Bloqueado'}`,
          msg: `O colaborador ${formContext.nome ?? id} foi ${newStatus === 9 ? 'bloqueado' : 'desbloqueado'} com sucesso`,
          type: 'success',
        },
      ])

      // if (newStatus === 3) {
      //   const notificationFields = {
      //     title: '🔴 Conta Suspensa',
      //     description: 'Sua conta está suspensa! Você recebeu um alerta de suspensão. Verifique seu status no suporte.',
      //   }
      //   await sendNotification(uuidAsArray, tokenAsArray, notificationFields.title, notificationFields.description)
      // } else {
      //   const notificationFields = {
      //     title: '🟢 Conta Liberada',
      //     description:
      //       'A suspensão aplicada na sua conta foi retirada! Acesse o aplicativo e preencha as informações necessárias para continuar.',
      //   }
      //   await sendNotification(uuidAsArray, tokenAsArray, notificationFields.title, notificationFields.description)
      // }
    } catch (error) {
      setAlerts((prev) => [
        ...prev,
        {
          title: `Erro ao bloquear/desbloquear`,
          msg: `Houve um erro ao bloquear/desbloquear o colaborador ${formContext.nome ?? id}: `,
          type: 'error',
        },
      ])

      console.error('Erro ao bloquear/desbloquear o colaborador:', error)
    }
  }

  const handleValidateCollaboratorCheckbox = (field, fieldDescription, fieldValue, index = null) => {
    // console.log(formContext)

    setFormContext((prevState) => {
      const inEdition = {
        ...prevState.inEdition,
      }

      const updatedInEdition = { ...inEdition }
      if (field in updatedInEdition) {
        delete updatedInEdition[field]
      } else {
        updatedInEdition[field] = { title: fieldDescription, fieldValue, reason: '', type: 'correction' }
      }

      return {
        ...prevState,
        inEdition: updatedInEdition,
      }
    })
  }

  const approveCollab = async () => {
    const newCollab = {
      status: 9,
      checked: true,
      dt_checked: DateTimeNow(),
      checked_by: user?.user?.nome || user?.user?.name || 'não identificado',
    }
    await updateDocument('colaboradores', id, newCollab)

    setFormContext((prev) => ({
      ...prev,
      ...newCollab,
      validate: false,
      inEdition: { emergencyContacts: [] },
    }))

    setOpenModal(false)

    setAlerts((prev) => [
      ...prev,
      {
        title: `Colaborador Aprovado`,
        msg: `Você Aprovou o colaborador, agora ele precisa realizar o cadastro de pagamento para poder acessar os cargos e eventos.`,
        type: 'success',
      },
    ])

    // const notificationFields = {
    //   title: '✅ Cadastro Validado',
    //   description: 'Parabéns! Seu cadastro foi válidado com sucesso. Agora você pode seguir para a próxima etapa.',
    // }
    // await sendNotification(uuidAsArray, tokenAsArray, notificationFields.title, notificationFields.description)
  }

  const HandleApproveCollab = async () => {
    await TryCatch(approveCollab, setLoading, setAlerts)
  }

  return {
    loading,
    formContext,
    setFormContext,
    openModal,
    handleOpenValidateModal,
    blockUnblockCollaborator,
    submitFormDisabled,
    handleValidateCollaboratorCheckbox,
    emergencyContactsGroup,
    emergencyContactsGroupCount,
    handleAddEmergencyContact,
    handleDeleteEmergencyContact,
    handleEmergencyFieldsChange,
    handleCPFField,
    handleZipcodeField,
    HandleApproveCollab,
    isBlobOrFile,
  }
}
