import { Icon } from '../../../../../components/icons'
import { Column } from '../../../../../components/layouts/columns'

const CardStatusHome = ({ title, value, children }) => {
  return (
    <Column.Content className="card">
      <div className="card-content has-text-left">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '0 0 1rem 0',
          }}
        >
          <div>{title}</div>
          <div>{children}</div>
        </div>
        {value !== undefined ? <p className="subtitle is-2">{value}</p> : <Icon>loading</Icon>}
      </div>
    </Column.Content>
  )
}

export default CardStatusHome
