import React from 'react'
import { Link, Outlet, useNavigate } from 'react-router-dom'

import { Navbar } from '../../../components/layouts/navbar'
import Logo from '../../../assets/logoKeap.png'
import { AlertFunctional } from '../../../components/ui/alert/AlertFunctional'
import { useGlobalContext } from '../../../context/ContextGlobal'
import { logOut } from '../../../firebase/auth'
import { RemoveItemSession } from '../../../utils/sessionStorage'
import { Path } from '../../../router/paths'
import { TransformationforMask } from '../../../utils/masks'

export const AdminLayout = () => {
  const { alerts, setAlerts, navBar, setNavBar, setUser, user } = useGlobalContext()

  const navigate = useNavigate()

  const sair = () => {
    // console.log(Path.Login)
    logOut()
    RemoveItemSession({ key: 'user' })
    setUser([])
    return navigate(Path.Login)
  }

  return (
    <>
      <Navbar.Root active={navBar} setActive={setNavBar} logo={Logo}>
        <Navbar.End>
          <Navbar.ItemInfo colorText={'has-text-grey mr-5'}>
            <p className="control ml-4">
              <Link to={Path.AdministrationHome} title="voltar à página inicial">
                <figure className="image is-48x48 mr-3 mt-1">
                  <img className="is-rounded" src={user?.user?.url_foto} alt="" />
                </figure>
              </Link>
            </p>
            <p className="control is-flex is-justify-content-center">
              <label className="mt-4 has-text-primary">{TransformationforMask(`${user?.user?.nome}`, 'title')}</label>
            </p>
          </Navbar.ItemInfo>
          <Navbar.Link link={Path.AdministrationHome}>Dashboard</Navbar.Link>
          <Navbar.Link link={Path.AClients}>Clientes</Navbar.Link>
          <Navbar.Link link={Path.AdminCollaborators}>Staff</Navbar.Link>
          <Navbar.Link link={Path.ASettings}>Configurações</Navbar.Link>
          <Navbar.Action action={sair}>Sair</Navbar.Action>
        </Navbar.End>
      </Navbar.Root>

      {/* Faz com que o conteudo mude mudando de pagina */}
      <Outlet />
      <AlertFunctional values={alerts} setValues={setAlerts}></AlertFunctional>
    </>
  )
}
