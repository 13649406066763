import { ArrowLeft } from 'lucide-react'
import { useState } from 'react'

import useCollaborator from '../../_hooks/useCollaborator'
import useCollaboratorSelectionSignPlan from '../../_hooks/useCollaboratorSelectionSignPlan'
import { Header } from '../../_components/Header'
import { PlanDescription } from '../../_components/PlanDescription'
import { Button } from '../../../../components/ui/button'
import { Path } from '../../../../router/paths'
import { Page } from '../../../../components/layouts/page'
import { Column } from '../../../../components/layouts/columns'
import { Input } from '../../../../components/ui/input'
import { AlertFunctional } from '../../../../components/ui/alert/AlertFunctional'

export const CollabSignPlanSelected = () => {
  const { user, token } = useCollaborator()

  const [aplly, setApply] = useState(false)

  const { formContext, setFormContext, HandleJoinCollabInPlan, HandleApplyCupom, alerts, setAlerts, loading } =
    useCollaboratorSelectionSignPlan()

  return (
    <Page.Root>
      <Page.Content
        style={{
          maxWidth: '400px', // Largura máxima para telas menores
          width: '100%', // Garante que se adapte bem a telas menores
          margin: '0 auto', // Centraliza o conteúdo
        }}
      >
        <Header>
          <a href={Path.CollabSignPlan(token)}>
            <ArrowLeft className="has-text-link" size={30} />
          </a>
        </Header>
        <PlanDescription user={user} plan={formContext} />

        {!aplly && (
          <div style={{ paddingInline: 40 }}>
            <Button.Bt
              className="is-fullwidth"
              color="is-light"
              colorText={'has-text-primary'}
              action={async () => {
                await setApply(true)
              }}
              loading={loading}
            >
              Eu tenho um cupom!
            </Button.Bt>
          </div>
        )}

        {aplly && (
          <Column.Root style={{ paddingInline: 40 }}>
            <Column.Content>
              <Input.Root className="mb-5">
                <Input.Label className="ml-3 has-text-grey-light" style={{ marginBottom: 0 }}>
                  Cupom promocional
                </Input.Label>
                <Input.Contents>
                  <Input.Prompt
                    className="is-rounded"
                    color="is-grey"
                    value={formContext.codCupomSelected}
                    setValue={(value) => {
                      setFormContext((prev) => ({
                        ...prev,
                        codCupomSelected: value.startsWith('#')
                          ? String(value).toUpperCase().trim()
                          : `#${String(value).toUpperCase().trim()}`,
                      }))
                    }}
                  ></Input.Prompt>
                  <Input.Error>{formContext?.cupomAplly ? 'Cupom Aplicado' : ''}</Input.Error>
                </Input.Contents>
              </Input.Root>
              <Button.Bt
                className="is-fullwidth"
                color="is-link"
                colorText={'has-text-white'}
                action={async () => {
                  await HandleApplyCupom()
                }}
                disabled={!formContext?.codCupomSelected && formContext?.cupomAplly}
                loading={loading}
              >
                Aplicar Cupom
              </Button.Bt>
            </Column.Content>
          </Column.Root>
        )}

        <div className="has-text-centered" style={{ paddingInline: 40 }}>
          <Button.Bt
            className="mt-6 is-fullwidth"
            color="is-primary"
            colorText={'has-text-link'}
            action={() => {
              HandleJoinCollabInPlan(formContext)
            }}
            loading={loading}
          >
            Contratar Agora!
          </Button.Bt>
        </div>

        <div style={{ height: 150 }}></div>
        <AlertFunctional values={alerts} setValues={setAlerts}></AlertFunctional>
      </Page.Content>
    </Page.Root>
  )
}
