import { useParams } from 'react-router-dom'

import { useGlobalContext } from '../../../../context/ContextGlobal'

export const useValidationPositionModal = () => {
  const { id } = useParams()
  const { formContext, loading } = useGlobalContext()

  return {
    formContext,
    loading,
    id,
  }
}
