export const dataColumnsOptions = [
  { value: 'vazio', label: 'Coluna Vazia' },
  { value: 'dateNow', label: 'Data Atual' },

  { value: 'status.status', label: 'Status da candidatura' },

  { value: 'candidacy.name', label: 'Nome do candidato' },
  { value: 'candidacy.cpf', label: 'CPF do candidato' },
  { value: 'candidacy.email', label: 'E-mail do candidato' },
  { value: 'candidacy.photoUrl', label: 'URL da foto do candidato' },
  { value: 'candidacy.civilStatus', label: 'Estado civil do candidato' },
  { value: 'candidacy.docType', label: 'Tipo de documento do candidato' },
  { value: 'candidacy.document', label: 'Documento do candidato' },
  { value: 'candidacy.socialName', label: 'Nome social do candidato' },
  { value: 'candidacy.birthday', label: 'Data de nascimento do candidato' },
  { value: 'candidacy.address.city', label: 'Cidade do candidato' },
  { value: 'candidacy.address.state', label: 'Estado do candidato' },
  { value: 'candidacy.address.street', label: 'Rua do candidato' },
  { value: 'candidacy.address.number', label: 'Número do endereço do candidato' },
  { value: 'candidacy.address.zipcode', label: 'CEP do candidato' },
  { value: 'candidacy.contact.phone', label: 'Telefone do candidato' },
  { value: 'candidacy.payment.bank', label: 'Banco de pagamento do candidato' },
  { value: 'candidacy.payment.agency', label: 'Agência de pagamento do candidato' },
  { value: 'candidacy.payment.account', label: 'Conta de pagamento do candidato' },
  { value: 'candidacy.payment.pix', label: 'Chave Pix do candidato' },
  { value: 'candidacy.id', label: 'ID da candidatura' },
  { value: 'candidacy.uuid', label: 'UUID da candidatura' },
  { value: 'candidacy.dt_created', label: 'Data de criação da candidatura' },
  { value: 'candidacy.dtModify', label: 'Ultima modificação da Candidatura' },
  { value: 'candidacy.dtUpdate', label: 'Ultima Atualização da Candidatura' },
  { value: 'candidacy.userUpdate', label: 'Usuário que realizou a última atualização' },
  { value: 'candidacy.motivo', label: 'Motivo da recusa da candidatura' },
  { value: 'candidacy.idJobOffers', label: 'ID da Vaga da Candidatura' },
  { value: 'candidacy.status', label: 'Código do status da candidatura' },

  // Mapeando arrays de objetos
  { value: 'candidacy.contact.emergencyContacts[]', label: 'Contatos de Emergência Geral' },
  { value: 'candidacy.contact.emergencyContacts[].name', label: 'Nome do contato de emergência' },
  { value: 'candidacy.contact.emergencyContacts[].kinship', label: 'Parentesco do contato de emergência' },
  { value: 'candidacy.contact.emergencyContacts[].phone', label: 'Telefone do contato de emergência' },
  { value: 'candidacy.form[]', label: 'Formulario Geral' },
  { value: 'candidacy.form[].response', label: 'Resposta do Formulario Preenchido' },
  { value: 'candidacy.form[].title', label: 'Pergunta do Formulario Preenchido' },
  { value: 'candidacy.financialHistory[]', label: 'Historico Financeiro Geral' },
  { value: 'candidacy.financialHistory[].desc', label: 'Descrição do Historico Financeiro' },
  { value: 'candidacy.financialHistory[].type', label: 'Tipo do Historico Financeiro' },
  { value: 'candidacy.financialHistory[].value', label: 'Valor do Historico Financeiro' },
  { value: 'candidacy.workingHours[]', label: 'Horarios de trabalho Geral' },
  { value: 'candidacy.workingHours[].comment', label: 'Comentario do Horarios de trabalho' },
  { value: 'candidacy.workingHours[].registerBy', label: 'Quem registrou o Horarios de trabalho' },
  { value: 'candidacy.workingHours[].time', label: 'Data e hora do Horarios de trabalho' },
  { value: 'candidacy.terms[]', label: 'Termos Geral' },
  { value: 'candidacy.terms[].name', label: 'Nome do Termos' },
  { value: 'candidacy.terms[].signedIn', label: 'Data Assinatura do Termos' },

  { value: 'jobOffer.id', label: 'ID da oferta de emprego' },
  { value: 'jobOffer.validation', label: 'Validação da oferta' },
  { value: 'jobOffer.payment', label: 'Tipo do pagamento da vaga' },
  { value: 'jobOffer.created_by', label: 'Criado por' },
  { value: 'jobOffer.nome', label: 'Nome da vaga' },
  { value: 'jobOffer.dt_created', label: 'Data de criação da oferta' },
  { value: 'jobOffer.hrStart', label: 'Horário de início' },
  { value: 'jobOffer.hrCheckin', label: 'Horário de check-in' },
  { value: 'jobOffer.hrCheckout', label: 'Horário de check-out' },
  { value: 'jobOffer.register', label: 'Tipo de registro' },
  { value: 'jobOffer.status', label: 'Status da oferta' },
  { value: 'jobOffer.filledJob', label: 'Vagas preenchidas' },
  { value: 'jobOffer.dtPayment', label: 'Data do pagamento' },
  { value: 'jobOffer.transportAllowance', label: 'Vale-transporte' },
  { value: 'jobOffer.food', label: 'Vale-alimentação' },
  { value: 'jobOffer.value', label: 'Valor da vaga' },
  { value: 'jobOffer.amountJob', label: 'Quantidade de vagas' },
  { value: 'jobOffer.date', label: 'Data da vaga' },
]
