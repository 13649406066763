import { Check } from 'lucide-react'
import { useEffect, useState } from 'react'

import { Column } from '../../../components/layouts/columns'
import keapLogo from '../../../assets/Prancheta 6.png'
import useCollaborator from '../_hooks/useCollaborator'
import { deleteDocument, updateDocument } from '../../../firebase/firestore'
import { Button } from '../../../components/ui/button'
import { TryCatch } from '../../../utils/general'
import { Icon } from '../../../components/icons'

const EmailVerification = () => {
  const { user, token, setAlerts, setLoading, loading, setUser } = useCollaborator(true)
  const [showPage, setShowPage] = useState(false)

  const verifyEmail = async () => {
    const uid = user?.id
    if (!uid) return
    await updateDocument('colaboradores', uid, { emailVerify: true })
    await deleteDocument('_authentications', token)

    setUser((prev) => ({ ...prev, emailVerify: true }))
  }

  const HandleVerifyEmail = async () => {
    await TryCatch(verifyEmail, setLoading, setAlerts)
  }

  useEffect(() => {
    const timer = setTimeout(() => setShowPage(true), 1000) // Aguarda 1 segundo antes de renderizar
    return () => clearTimeout(timer) // Limpa o timer caso o componente desmonte
  }, [])

  if (!showPage) {
    return <Icon>loading</Icon> // Ou pode exibir um loading aqui, se preferir
  }
  return (
    <Column.Root
      className="mt-6"
      style={{
        color: '#333',
        padding: '2rem 2rem 4rem 2rem',
        textAlign: 'center',
      }}
    >
      <Column.Content
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '2rem',
          maxWidth: '600px',
          margin: '0 auto',
        }}
      >
        {user?.emailVerify ? (
          <>
            {/* Título */}
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
              <img
                src={keapLogo} // Substitua com o caminho do seu logo
                style={{ height: 120, width: 120 }}
                alt="logo"
              />
              <h2
                style={{
                  fontSize: '2rem',
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  letterSpacing: '1px',
                }}
                className="has-text-link"
              >
                Conta Keap Staff Verificada com Sucesso!
              </h2>
            </div>
            {/* Ícone de sucesso */}
            <div
              style={{
                borderRadius: '50%',
                padding: '1.5rem',
                width: '80px',
                height: '80px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              className="has-background-primary"
            >
              <Check color="#fff" size={40} />
            </div>
            {/* Mensagem de confirmação */}
            <p
              style={{
                fontSize: '1.1rem',
                color: '#555',
                lineHeight: '1.6',
                marginBottom: '2rem',
              }}
            >
              Seu e-mail foi verificado com sucesso! Agora você pode fazer login e começar a usar todos os recursos do
              nosso app.
            </p>
          </>
        ) : (
          <>
            {/* Título */}
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
              <img
                src={keapLogo} // Substitua com o caminho do seu logo
                style={{ height: 120, width: 120 }}
                alt="logo"
              />
              <h2
                style={{
                  fontSize: '2rem',
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  letterSpacing: '1px',
                }}
                className="has-text-link"
              >
                Vamos ativar sua conta Keap Staff!
              </h2>
            </div>
            {/* Ícone de sucesso */}

            <p
              style={{
                fontSize: '1.2rem',
                color: '#444',
                lineHeight: '1.6',
                marginBottom: '2rem',
                fontWeight: '500',
              }}
            >
              Para acessar todos os recursos do nosso app, ative sua conta agora. Clique no botão abaixo e conclua sua
              verificação em segundos!
            </p>

            <Button.Bt
              action={() => {
                HandleVerifyEmail()
              }}
              loading={loading}
              color="is-primary"
            >
              Ativar Minha Conta
            </Button.Bt>

            {/* Mensagem de confirmação */}
          </>
        )}
      </Column.Content>
    </Column.Root>
  )
}

export default EmailVerification
