import { useNavigate } from 'react-router-dom'

import { useGlobalContext } from '../../../../../context/ContextGlobal'
import { TryCatch } from '../../../../../utils/general'
import { addDocument } from '../../../../../firebase/firestore'
import { Path } from '../../../../../router/paths'
import { DateTimeNow } from '../../../../../utils/dates'
import { schemaNotices } from '../utils/schemas'

const useAddNotices = () => {
  const {
    user,
    setAlerts,
    // dataContext,
    // setDataContext,
    // infosContext,
    // setInfosContext,
    formContext,
    setFormContext,
    loading,
    setLoading,
    // statusLoading,
    // setStatusLoading,
  } = useGlobalContext()

  const navigate = useNavigate()

  const AddConsent = async () => {
    const newNotices = {
      created_by: user?.user?.name || user?.user?.nome || '',
      dt_created: DateTimeNow(),
      ...schemaNotices(formContext),
    }

    await addDocument('notices', newNotices)

    setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Aviso Criado',
        msg: 'Novo Aviso criado com sucesso.',
      },
    ])
    return navigate(Path.AdminNotices)
  }
  const HandleAddConsents = async () => {
    await TryCatch(AddConsent, setLoading, setAlerts)
  }

  return { user, loading, setLoading, formContext, setFormContext, HandleAddConsents }
}

export default useAddNotices
