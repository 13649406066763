import React from 'react'

import { Icon } from '../../../../components/icons'
import { Column } from '../../../../components/layouts/columns'
import { Button } from '../../../../components/ui/button'
import { Table } from '../../../../components/ui/table'
import { Input } from '../../../../components/ui/input'
import { Page } from '../../../../components/layouts/page'
import { Path } from '../../../../router/paths'
import { useClientPlans } from './hooks/usePlans'
import { translatePlan } from './utils'

export const APlans = () => {
  const { loading, search, setSearch, HandleDeletePlan, FetchGetData, plansFiltered } = useClientPlans()

  return (
    <>
      <Page.Root className="is-fullwidth">
        <Column.Root className="mt-6 pt-5">
          <Column.Content>
            <p className="subtitle is-size-3">Planos</p>
          </Column.Content>
          <Column.Content className="has-text-right">
            <Button.Root align="right">
              <Button.Content>
                <Button.Bt
                  color="is-dark"
                  colorText="has-text-white"
                  action={() => {
                    FetchGetData()
                  }}
                  loading={loading}
                >
                  <Icon size={15}>sync</Icon>
                </Button.Bt>
              </Button.Content>
              <Button.Content>
                <Button.A
                  className=""
                  color="is-success"
                  colorText="has-text-white"
                  link={Path.AdminAddPlans}
                  loading={loading}
                >
                  +
                </Button.A>
              </Button.Content>
            </Button.Root>
          </Column.Content>
        </Column.Root>
        <Column.Root className="">
          <Column.Content>
            <Input.Root>
              <Input.Contents alignIcon={'left'}>
                <Input.Prompt value={search} setValue={setSearch} placeholder="Pesquisar..."></Input.Prompt>
                <Input.Icon align="is-left">
                  <Icon size={20}>search</Icon>
                </Input.Icon>
              </Input.Contents>
            </Input.Root>
          </Column.Content>
        </Column.Root>
        <Column.Root className="">
          <Column.Content>
            <Table.Root className="is-fullwidth is-hoverable is-narrow has-text-centered ">
              <Table.Head>
                <Table.Row>
                  <Table.Cell size={400}>Nome</Table.Cell>
                  <Table.Cell size={200}>Descrição</Table.Cell>
                  <Table.Cell>Destino</Table.Cell>
                  <Table.Cell size={100}>Valor</Table.Cell>
                  <Table.Cell size={100}>Taxa de Analise</Table.Cell>
                  <Table.Cell>Cobrança</Table.Cell>
                  <Table.Cell>Ações</Table.Cell>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {plansFiltered
                  .sort((a, b) => Number(a.value) - Number(b.value))
                  .sort((a, b) => String(a.typePlan).localeCompare(b.typePlan))

                  .map((value, index) => {
                    return (
                      <>
                        <Table.Row key={`plan_${index}`}>
                          <Table.Cell className="is-size-7 is-vcentered">{value.name}</Table.Cell>
                          <Table.Cell className="is-size-7 is-vcentered">
                            {value?.desc ? String(value.desc).slice(0, 50) + '...' : value?.codCupom}
                          </Table.Cell>
                          <Table.Cell className="is-size-7 is-vcentered">{translatePlan(value.typePlan)}</Table.Cell>
                          <Table.Cell className="is-size-7 is-vcentered">R$ {value.value}</Table.Cell>
                          <Table.Cell className="is-size-7 is-vcentered">R$ {value.admission}</Table.Cell>
                          <Table.Cell className="is-size-7 is-vcentered">{value.typeExpired} Dias</Table.Cell>
                          <Table.Cell className="is-vcentered">
                            <div style={{ marginBottom: -10, marginTop: 5 }}>
                              <Button.Root align="centered">
                                <Button.Content>
                                  <Button.A
                                    size="is-small"
                                    color="is-warning"
                                    link={Path.AEditPlans(value.id)}
                                    loading={loading}
                                  >
                                    <Icon size={15}>pen</Icon>
                                  </Button.A>
                                </Button.Content>
                                <Button.Content>
                                  <Button.Bt
                                    size="is-small"
                                    color="is-danger"
                                    action={() => {
                                      HandleDeletePlan(value.id)
                                    }}
                                    loading={loading}
                                  >
                                    <Icon size={15}>trash</Icon>
                                  </Button.Bt>
                                </Button.Content>
                              </Button.Root>
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      </>
                    )
                  })}
              </Table.Body>
            </Table.Root>
          </Column.Content>
        </Column.Root>
      </Page.Root>
    </>
  )
}
