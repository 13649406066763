import { useNavigate } from 'react-router-dom'

import { Column } from '../../../components/layouts/columns'
import { Page } from '../../../components/layouts/page'
import { Header } from '../_components/Header'
import useCollaborator from '../_hooks/useCollaborator'
import { ConvertTimestampToDateTime } from '../../../utils/dates'
import { ButtonMenu } from '../_components/ButtonMenu'
import { Path } from '../../../router/paths'
import { useGlobalContext } from '../../../context/ContextGlobal'
import { TryCatch } from '../../../utils/general'
import { AlertFunctional } from '../../../components/ui/alert/AlertFunctional'
import { deleteDocument } from '../../../firebase/firestore'
import { Icon } from '../../../components/icons'

const statusCollab = {
  0: { status: 'Em Criação' },
  1: { status: 'Em Analise' },
  2: { status: 'Em Correção' },
  3: { status: 'Bloqueado' },
  4: { status: 'Inativo' },
  5: { status: 'Pendente Pagamento' },
  6: { status: 'Em Analise de Cargo' },
  9: { status: 'Ativo' },
}

export const CollabHome = () => {
  const { user, token } = useCollaborator()

  const navigate = useNavigate()

  const { loading, setLoading, setAlerts, alerts } = useGlobalContext()

  const OpenApp = () => {
    const userAgent = navigator.userAgent.toLowerCase()
    const isAndroid = /android/.test(userAgent)
    const isIOS = /iphone|ipad|ipod/.test(userAgent)

    // Tenta abrir o app
    const appUrl = 'keapapp://'
    const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.keapapp'
    const appStoreUrl = 'https://apps.apple.com/app/id123456789' // Substituir pelo ID correto do app na App Store

    const timeout = setTimeout(() => {
      // Se depois de 2s o usuário não tiver sido redirecionado, assume que o app não está instalado
      if (isAndroid) {
        window.location.href = playStoreUrl
      } else if (isIOS) {
        window.location.href = appStoreUrl
      }
    }, 2000)

    // Tenta abrir o app
    window.location.href = appUrl

    // Cancela o timeout se o app for aberto com sucesso (detectado se o usuário sair rapidamente da página)
    window.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        clearTimeout(timeout)
      }
    })
  }

  const handleOpenApp = async () => {
    await TryCatch(OpenApp, setLoading, setAlerts)
  }

  const logOff = async () => {
    await deleteDocument('_authentications', token)
    return navigate(Path.CollabLogin)
  }

  return (
    Object.keys(user).length > 0 && (
      <Page.Root
        style={{
          width: '375px', // Largura média de um celular (iPhone X, por exemplo)
          maxWidth: '100vw', // Garante que não ultrapasse a largura da tela
          margin: '0 auto', // Centraliza no modo web
        }}
      >
        <Page.Content>
          <Header titlePage="Minha Conta"></Header>

          <Column.Root>
            <Column.Content
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              {user?.photoUrl && user?.photoUrl.includes('https://') ? (
                <img
                  alt="url_foto"
                  src={user?.photoUrl}
                  style={{
                    width: '300px',
                    height: '300px',
                    objectFit: 'cover',
                    borderRadius: '50%',
                  }}
                  className="has-shadow"
                />
              ) : (
                <div
                  style={{
                    width: '300px',
                    height: '300px',
                    borderRadius: '50%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                  }}
                  className="has-shadow has-background-grey-lighter"
                >
                  <Icon size={200}>user</Icon>
                </div>
              )}

              <p>Olá, {user?.name || 'não identificado'}</p>
              <p className="has-text-centered has-text-grey">{`Sua Situação: ${statusCollab[Number(user?.status)].status}`}</p>
              {Object.keys(user?.plans ?? {}).length > 0 ? (
                <div>
                  <p className="has-text-centered has-text-grey">{`Plano Ativo: ${user?.plans?.name}`}</p>

                  <p
                    className="has-text-centered has-text-grey"
                    style={{ marginTop: -15 }}
                  >{`Valor: R$ ${user?.plans?.value}`}</p>
                  <p className="has-text-centered has-text-grey">
                    Próximo Pagamento: {ConvertTimestampToDateTime(user?.nextPayment).split(' ')?.[0] || ''}
                  </p>
                </div>
              ) : (
                <div>
                  <p className="has-text-centered has-text-danger">Nenhum Plano ativo</p>
                </div>
              )}
            </Column.Content>
          </Column.Root>
          <Column.Root className="mt-6">
            <Column.Content>
              <ButtonMenu
                action={() => {
                  handleOpenApp()
                }}
                loading={loading}
              >
                {'Voltar ao App'}
              </ButtonMenu>
              <ButtonMenu
                href={Path.CollabPlans(token)}
                buttonDescription="Clique aqui para ver sua assinatura ativa"
                loading={loading}
              >
                Minha Assinatura
              </ButtonMenu>
              <ButtonMenu
                href={Path.CollabSignPlan(token)}
                buttonDescription="Clique aqui para escolher um plano"
                loading={loading}
              >
                Escolher Plano
              </ButtonMenu>
              <ButtonMenu
                href={Path.CollabHistory(token)}
                buttonDescription="Clique aqui para ver seu histórico de pagamentos"
                loading={loading}
              >
                Histórico de Pagamentos
              </ButtonMenu>
              <ButtonMenu
                action={async () => {
                  await logOff()
                }}
                loading={loading}
              >
                Sair
              </ButtonMenu>
            </Column.Content>
          </Column.Root>
          <div style={{ height: 40, marginTop: 150 }}>
            <p className="has-text-centered has-text-grey-light">by @Keap Staff Ltda., 2025</p>
          </div>
        </Page.Content>
        <AlertFunctional values={alerts} setValues={setAlerts}></AlertFunctional>
      </Page.Root>
    )
  )
}
