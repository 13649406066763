import { useEffect, useState } from 'react'

import { TryCatch } from '../../../../../../utils/general'
import { useGlobalContext } from '../../../../../../context/ContextGlobal'
import { dataColumnsOptions } from '../../utils/dataColumnsOptions'
import { getDocuments } from '../../../../../../firebase/firestore'
import { dataStatusCandidacyOptions } from '../../utils/dataStatusCandidacyOptions'

const useFormReports = (value, setValue) => {
  const {
    // user,
    setAlerts,
    // dataContext,
    // setDataContext,
    // infosContext, todas as informações para a página funcionar
    // setInfosContext,
    // formContext,
    // setFormContext,
    // loading,
    setLoading,
    // statusLoading,
    // setStatusLoading,
  } = useGlobalContext()

  const [optionsColumns, setOptionsColumns] = useState([])

  const getIdDocumentsOptions = async () => {
    const getDocumentos = await getDocuments('documentos')

    const newOptionsDocumentos = getDocumentos.map((doc) => {
      const fieldCollect = doc?.type === 'input' ? 'value' : 'urlSubmit'
      return { label: doc.name, value: `candidacy.documents.${doc.id}.${fieldCollect}` }
    })

    setOptionsColumns([...dataColumnsOptions, ...newOptionsDocumentos])
  }

  const addColumn = () => {
    const selectColumn = value?.selectColumn

    if (!selectColumn) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Columa não selecionada',
          msg: 'Selecione uma coluna para adiciona-la.',
        },
      ])
    }

    const column = optionsColumns.find((item) => item.value === selectColumn)

    setValue((prev) => {
      const oldColumns = prev?.columns || []

      const newColumnData = [
        ...oldColumns,
        {
          column,
          name: column.label,
          index: oldColumns.length,
        },
      ]

      return {
        ...prev,
        columns: newColumnData,
      }
    })
  }

  const deleteColumn = (column) => {
    // console.log(column)
    setValue((prev) => {
      const newColumnData = (prev?.columns || [])
        .filter((col) => col.index !== column.index)
        .sort((a, b) => a.index - b.index)
        .map((value, index) => ({ ...value, index }))

      return {
        ...prev,
        columns: newColumnData,
      }
    })
  }

  const addFilter = () => {
    const selectFilter = value?.selectFilter

    if (!selectFilter) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Filtro não selecionado',
          msg: 'Selecione uma Filtro para adiciona-lo.',
        },
      ])
    }

    const filter = dataStatusCandidacyOptions.find((item) => item.value === selectFilter)

    setValue((prev) => {
      const oldFilters = prev?.filters || []

      const newFilterData = [
        ...oldFilters,
        {
          value: filter.value,
        },
      ]

      return {
        ...prev,
        filters: newFilterData,
      }
    })
  }

  const deleteFilter = (filter) => {
    // console.log(filter)
    setValue((prev) => {
      const newFiltersData = (prev?.filter || [])
        .filter((col) => col.value !== filter.value)
        .sort((a, b) => a.index - b.index)
        .map((value, index) => ({ ...value, index }))

      return {
        ...prev,
        filters: newFiltersData,
      }
    })
  }

  const HandleAddColumn = async () => {
    await TryCatch(addColumn, setLoading, setAlerts)
  }

  const HandleDeleteColumn = async (column) => {
    const callback = async () => {
      await deleteColumn(column)
    }
    await TryCatch(callback, setLoading, setAlerts)
  }

  const HandleAddFilters = async () => {
    await TryCatch(addFilter, setLoading, setAlerts)
  }

  const HandleDeleteFilter = async (filter) => {
    const callback = async () => {
      await deleteFilter(filter)
    }
    await TryCatch(callback, setLoading, setAlerts)
  }

  const HandleGetIdDocumentsOptions = async () => {
    await TryCatch(getIdDocumentsOptions, setLoading, setAlerts)
  }

  useEffect(() => {
    HandleGetIdDocumentsOptions()
    // console.log(value)
  }, [])

  return { HandleAddColumn, HandleDeleteColumn, HandleDeleteFilter, HandleAddFilters, optionsColumns }
}

export default useFormReports
