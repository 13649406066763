import { useEffect } from 'react'

import { useGlobalContext } from '../../../../../context/ContextGlobal'

const useFormAddJobOffers = () => {
  const { formContext, setFormContext } = useGlobalContext()

  const vestmentList = [
    'camiseta',
    'calça',
    'bermuda',
    'vestido',
    'saia',
    'terno',
    'blazer',
    'social',
    'sapato',
    'tenis',
    'boné',
    'jaqueta',
    'colete',
  ]

  const typeResponseList = [
    { value: '', title: 'Selecione uma opção', selected: true, disabled: true },
    { value: 'yesOrNo', title: 'Sim ou Não' },
    { value: 'text', title: 'Texto' },
    { value: 'number', title: 'Numérico' },
    { value: 'capture', title: 'Capturar Foto' },
    { value: 'galery', title: 'Selecionar da Galeria' },
    { value: 'capture&galery', title: 'Capturar Foto ou Selecionar da Galeria' },
    { value: 'doc', title: 'Enviar Documento' },
    { value: 'clothingSizeLetters', title: 'Tamanho de Roupa (P, M, G)' },
    { value: 'clothingSizeNumbers', title: 'Tamanho de Roupa (36, 38, 40)' },
  ].sort((a, b) => a.value.localeCompare(b.value))

  const AddNewQuestInForm = async (value = { title: '', valueType: '', type: 'quest' }) => {
    return setFormContext((prev) => {
      const newForm = [...(prev?.formJobOffer?.form || [])]
      newForm.push({ ...value })
      return {
        ...prev,
        formJobOffer: {
          ...(prev.formJobOffer || {}),
          form: newForm,
        },
      }
    })
  }

  const DeleteQuetInForm = async (index) => {
    return setFormContext((prev) => {
      let newForm = [...(prev?.formJobOffer?.form || [])]

      // Filtra o array para remover o item com o índice desejado
      newForm = newForm.filter((_, i) => i !== index)

      return {
        ...prev,
        formJobOffer: {
          ...(prev.formJobOffer || {}),
          form: newForm,
        },
      }
    })
  }

  const SetNewValue = async (value, field) => {
    setFormContext((prev) => ({
      ...prev,
      formJobOffer: {
        ...(prev.formJobOffer || {}),
        [field]: value,
      },
    }))
  }

  const SetNewValueForm = async (value, field, index) => {
    setFormContext((prev) => {
      const newForm = [...(prev?.formJobOffer?.form || [])]
      newForm[index] = { ...newForm[index], [field]: value }
      return {
        ...prev,
        formJobOffer: {
          ...(prev.formJobOffer || {}),
          form: newForm,
        },
      }
    })
  }

  const moveQuestionUp = (questions, index) => {
    if (index <= 0) return questions // Não pode subir se já estiver no topo

    const newQuestions = [...questions]
    const oldQuestion = { ...newQuestions[index - 1], index: index - 1 }
    const newQuestion = { ...newQuestions[index], index }
    ;[newQuestions[index], newQuestions[index - 1]] = [oldQuestion, newQuestion]

    // console.log('Subindu: ', newQuestions)

    return newQuestions
  }

  const moveQuestionDown = (questions, index) => {
    if (index >= questions.length - 1) return questions // Não pode descer se já estiver na última posição

    const newQuestions = [...questions]
    const oldQuestion = { ...newQuestions[index + 1], index: index + 1 }
    const newQuestion = { ...newQuestions[index], index }
    ;[newQuestions[index], newQuestions[index + 1]] = [oldQuestion, newQuestion]

    // console.log('Descendu: ', newQuestions)

    return newQuestions
  }

  const SetUpQuest = (index) => {
    setFormContext((prev) => {
      const form = prev?.formJobOffer?.form || []

      const newForm = moveQuestionUp(form, index)

      return {
        ...prev,
        formJobOffer: {
          ...(prev.formJobOffer || {}),
          form: newForm,
        },
      }
    })
  }

  const SetDownQuest = (index) => {
    setFormContext((prev) => {
      const form = prev?.formJobOffer?.form || []

      const newForm = moveQuestionDown(form, index)

      return {
        ...prev,
        formJobOffer: {
          ...(prev.formJobOffer || {}),
          form: newForm,
        },
      }
    })
  }

  const AddVestments = async (vestiment) => {
    // console.log(vestiment)
    const newQuest = {
      title: 'Selecione o tamanho para ' + vestiment,
      valueType: 'clothingSizeLetters',
      type: 'vestments',
    }
    await AddNewQuestInForm(newQuest)
  }

  useEffect(() => {
    if (formContext?.formJobOffer?.vestments !== '1') {
      setFormContext((prev) => {
        const form = prev?.formJobOffer?.form || []

        const newForm = form.filter((item) => item.type !== 'vestments')
        const oldVestments = form.filter((item) => item.type === 'vestments')

        return {
          ...prev,
          formJobOffer: {
            ...(prev.formJobOffer || {}),
            form: newForm,
            oldVestments,
          },
        }
      })
    }

    if (formContext?.formJobOffer?.vestments === '1') {
      setFormContext((prev) => {
        const form = prev?.formJobOffer?.form || []

        const oldVestments = prev?.formJobOffer?.oldVestments || []

        const newForm = [...oldVestments, ...form]

        return {
          ...prev,
          formJobOffer: {
            ...(prev.formJobOffer || {}),
            form: newForm,
            oldVestments,
          },
        }
      })
    }
  }, [formContext?.formJobOffer?.vestments])

  return {
    formContext,
    setFormContext,
    SetNewValue,
    SetNewValueForm,
    AddNewQuestInForm,
    DeleteQuetInForm,
    SetUpQuest,
    SetDownQuest,
    typeResponseList,
    vestmentList,
    AddVestments,
  }
}

export default useFormAddJobOffers
