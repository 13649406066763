import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { getDocument, updateDocument } from '../../../../firebase/firestore'
import { useGlobalContext } from '../../../../context/ContextGlobal'
import { TryCatch } from '../../../../utils/general'
import { sendNotification } from '../../../../utils/sendNotification'
// import { DateTimeNow } from '../../../../utils/dates'

export const useValidateCollaboratorModal = () => {
  const [openModal, setOpenModal] = useState(false)

  const { user, setAlerts, formContext, setFormContext, setLoading, loading, infosContext } = useGlobalContext()

  const { id } = useParams()
  const [userInfo, setUserInfo] = useState(null)

  useEffect(() => {
    const fetchUser = async () => {
      const userData = await getDocument('colaboradores', id)
      setUserInfo(userData)
    }

    fetchUser()
  }, [id])

  const uuidAsArray = [userInfo?.id]
  const tokenAsArray = [userInfo?.token]

  // const modalCollaboratorSubmitDisabled = formContext.modalCollaboratorSubmitDisabled ?? true

  const handleOpenValidateModal = () => {
    setOpenModal(!openModal)
  }

  const setValueCheckbox = async (value, field) => {
    // console.log(value, field)
    setFormContext((prev) => ({
      ...prev,
      inEdition: {
        ...(prev?.inEdition || {}),
        [field]: value,
      },
    }))
  }

  const clearUpdatedInBulkFlags = () => {
    setFormContext((prevContext) => {
      // Copia a estrutura de `inEdition` atual
      const updatedInEdition = { ...prevContext.inEdition }

      // Remove a propriedade `updatedInBulk` de todos os itens
      Object.keys(updatedInEdition).forEach((itemKey) => {
        const item = updatedInEdition[itemKey]

        if (Array.isArray(item)) {
          // Caso seja um array (ex: emergencyContacts), remove a flag de todos os contatos
          item.forEach((contact) => {
            Object.keys(contact).forEach((fieldKey) => {
              if (contact[fieldKey].updatedInBulk) {
                delete contact[fieldKey].updatedInBulk
              }
            })
          })
        } else if (item.updatedInBulk) {
          // Caso seja um item singular, remove a flag diretamente
          delete item.updatedInBulk
        }
      })

      return {
        ...prevContext,
        inEdition: updatedInEdition,
      }
    })
  }

  const SendFieldsToFix = async () => {
    clearUpdatedInBulkFlags()

    const newInEdition = {
      inEdition: { ...(formContext?.inEdition ?? {}) },
      status: 2,
    }

    await updateDocument('colaboradores', id, newInEdition)

    setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Campos selecionados',
        msg: `Os campos foram enviados para correção.`,
      },
    ])

    setFormContext((prev) => ({
      ...prev,
      ...newInEdition,
      validate: false,
    }))

    setOpenModal(false)
  }

  const handleSendFieldsToFix = async () => {
    await TryCatch(SendFieldsToFix, setLoading, setAlerts)
  }

  const approveCollab = async () => {
    const newCollab = {
      status: 9,
      // checked: true,
      // dt_checked: DateTimeNow(),
      // checked_by: user?.user?.nome || user?.user?.name || 'não identificado',
    }
    await updateDocument('colaboradores', id, newCollab)

    setFormContext((prev) => ({
      ...prev,
      ...newCollab,
      validate: false,
      inEdition: { emergencyContacts: [] },
    }))

    setAlerts((prev) => [
      ...prev,
      {
        title: `Colaborador Aprovado`,
        msg: `Você Aprovou o colaborador, agora ele precisa realizar o cadastro de pagamento para poder acessar os cargos e eventos.`,
        type: 'success',
      },
    ])

    const notificationFields = {
      title: 'Cadastro Validado',
      description: 'Parabéns! Seu cadastro foi validado com sucesso. Agora você pode seguir para a próxima etapa.',
    }
    await sendNotification(uuidAsArray, tokenAsArray, notificationFields.title, notificationFields.description)
  }

  const handleApproveCollab = async () => {
    await TryCatch(approveCollab, setLoading, setAlerts)
  }

  const updateInEdition = async () => {
    const getColab = await getDocument('colaboradores', id)
    const documentsColabCurrent = getColab?.documents || {}
    const filteredEntries = Object.entries(formContext.inEdition || {}).filter(([_, value]) => value !== null)
    const fields = filteredEntries.filter((value) => value[1].type === 'field')
    const documents = filteredEntries.filter((value) => value[1].type === 'document')

    const newInEdition = { ...Object.fromEntries(fields) }

    const keysDocuments = Object.keys(documentsColabCurrent)

    const newDocuments = keysDocuments.map((docId) => {
      const docChecked = documents.find((value) => value[0] === docId)
      if (docChecked) {
        return [docId, { ...documentsColabCurrent[docId], status: false }]
      } else {
        return [docId, { ...documentsColabCurrent[docId] }]
      }
    })

    const updateDocuments = { ...Object.fromEntries(newDocuments) }

    const updatedFormContext = {
      documents: updateDocuments,
      inEdition: newInEdition,
      status: 2,
    }

    // console.log(updatedFormContext)

    await updateDocument('colaboradores', id, updatedFormContext)

    setFormContext((prev) => ({
      ...prev,
      ...updatedFormContext,
    }))

    const notificationFields = {
      title: 'Cadastro Reprovado',
      description: 'Foi encontrado irregularidades no seu cadastro e necessita correção.',
    }

    await sendNotification(getColab.id, [getColab.token], notificationFields.title, notificationFields.description)

    if (documents.length > 0) {
      const notificationFields = {
        title: 'Documento Reprovado',
        description: 'Foi encontrado irregularidade no(s) documento(s) necessita correção.',
      }
      await sendNotification(getColab.id, [getColab.token], notificationFields.title, notificationFields.description)
    }
  }

  const handleUpdateInEdition = async () => {
    await TryCatch(updateInEdition, setLoading, setAlerts)
  }

  useEffect(() => {
    // console.log(formContext)
  }, [formContext])

  return {
    user,
    loading,
    formContext,
    infosContext,
    setFormContext,
    openModal,
    setOpenModal,
    handleOpenValidateModal,
    handleSendFieldsToFix,
    handleUpdateInEdition,
    handleApproveCollab,
    // modalCollaboratorSubmitDisabled,
    setValueCheckbox,
    id,
  }
}
