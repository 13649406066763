import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { addDocument } from '../../../../firebase/firestore'
import { DateTimeNow, TimestampNow } from '../../../../utils/dates'
import { TryCatch } from '../../../../utils/general'
import { Path } from '../../../../router/paths'
import { SetHistoryEvent } from '../../../../utils/requests'

export const useClientAddEvents = () => {
  const {
    user,
    setAlerts,
    loading,
    setLoading,
    // dataContext,
    // setDataContext, // contexto que guarda dados de lista para a pagina
    // infosContext,
    // setInfosContext, // contexto que guarda dados adicionais para a pagina funcionar
    formContext,
    setFormContext, // contexto que guarda dados de formularios da pagina
  } = useGlobalContext()

  const navigate = useNavigate()

  const addEvent = async () => {
    const newEvent = {
      name: formContext?.name,
      round: formContext?.round,
      address: {
        endereco: formContext?.endereco,
        city: formContext?.city,
        uf: formContext?.uf,
        local_encontro: formContext?.local_encontro,
      },
      infoAdditional: formContext?.infoAdditional || '',
      clientId: user.client.id,
      clientName: user.client.nomeFantasia,
      createdBy: user.user.nome,
      dtCreated: DateTimeNow(),
      timestampCreated: TimestampNow(),
      status: 'open',
      openAllJobs: false,
      ...(formContext?.hasCityRestrictions && { cityRestrictions: formContext?.cityRestrictions || [] }),
      hasCityRestrictions: formContext?.hasCityRestrictions || false,
    }
    // // console.log('newEvent: ', newEvent)

    const idNewEvent = await addDocument('events', newEvent)
    // console.log(idNewEvent)
    await setFormContext({})
    await SetHistoryEvent(idNewEvent, user.user.nome, `Evento Criado`)
    setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Evento Criado',
        msg: `O evento ${newEvent.name} foi criado com sucesso.`,
      },
    ])

    return navigate(Path.ClientEditEvents(idNewEvent))
  }

  const HandleAddEvents = async () => {
    await TryCatch(addEvent, setLoading, setAlerts)
  }

  useEffect(() => {
    setFormContext({ foodValue: 0 })
  }, [])

  return { loading, formContext, setFormContext, HandleAddEvents }
}
