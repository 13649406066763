import React from 'react'

import { Page } from '../../../components/layouts/page'
import { Column } from '../../../components/layouts/columns'
import { useClientEventsjobOffers } from './_hooks/useClientEventsjobOffers'
import { Button } from '../../../components/ui/button'
import { Input } from '../../../components/ui/input'
import { Icon } from '../../../components/icons'
import { Table } from '../../../components/ui/table'
import { DataComTraçoParaDataComBarra } from '../../../utils/dates'
import ModalAddJobOffers from './_modal/modalAddJobOffers'
import { useModalAddjobOffers } from './_modal/hooks/useModalAddjobOffers'
import { TransformationforMask } from '../../../utils/masks'
import { Select } from '../../../components/ui/select'
// import { Button } from '../../../components/ui/button'
// import { Path } from '../../../router/paths'

export const ClientEventsjobOffers = () => {
  const {
    // idJobEdit,
    SetIdJobEdit,
    loading,
    search,
    setSearch,
    // formContext,
    // setFormContext,
    infosContext,
    dataContext,
    FetchGetInfos,
    FetchGetdatas,
    HandleDelJobOffers,
    HandleSelectValuesFormJob,
    HandleChangeStatusJobOffers,
    modal,
    setModal,
  } = useClientEventsjobOffers()

  const { HandleDelValuesFormJob } = useModalAddjobOffers(setModal)

  return (
    <Page.Section className="is-fullheight-with-navbar mt-6 mx-3">
      <ModalAddJobOffers
        active={modal}
        setActive={async (value = false) => {
          await HandleDelValuesFormJob()
          await setModal(value)
        }}
      ></ModalAddJobOffers>

      <Button.Root align="right">
        <Button.Content>
          <Button.Bt
            className=""
            color="is-dark"
            colorText="has-text-white"
            type={'button'}
            loading={loading}
            action={() => {
              FetchGetInfos()
              FetchGetdatas()
            }}
          >
            <Icon size={15}>sync</Icon>
          </Button.Bt>
        </Button.Content>
        <Button.Content>
          <Button.Bt
            className=""
            color={'is-success'}
            colorText="has-text-white"
            type={'button'}
            loading={loading}
            action={async () => {
              await HandleDelValuesFormJob()
              await setModal(true)
            }}
          >
            {'+'}
          </Button.Bt>
        </Button.Content>
      </Button.Root>

      <Column.Root>
        <Column.Content>
          <p className="subtitle is-size-4">
            <Icon size={30} className={'mr-3'}>
              job
            </Icon>
            Vagas do Evento
          </p>
        </Column.Content>
      </Column.Root>
      <Column.Root className="is-mobile">
        <Column.Content>
          <Input.Root>
            <Input.Contents alignIcon={'left'}>
              <Input.Prompt value={search} setValue={setSearch} placeholder="Pesquisar..."></Input.Prompt>
              <Input.Icon align="is-left">
                <Icon size={20}>search</Icon>
              </Input.Icon>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
      </Column.Root>
      <Column.Root className="is-mobile">
        <Column.Content>
          <Table.Root className="is-fullwidth is-hoverable is-narrow has-text-centered">
            <Table.Head>
              <Table.Row key={'tableEventsHead'}>
                <Table.Cell>Data</Table.Cell>
                <Table.Cell>Cargo</Table.Cell>
                <Table.Cell>Vagas</Table.Cell>
                <Table.Cell>Cache</Table.Cell>
                <Table.Cell>Candidatura</Table.Cell>
                <Table.Cell>Horário</Table.Cell>
                <Table.Cell size={200}>Situação</Table.Cell>
                <Table.Cell size={200}>Ações</Table.Cell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {(dataContext?.jobOffers || []).map((value) => {
                return (
                  <>
                    <Table.Row key={value.id}>
                      <Table.Cell className="is-size-7 is-vcentered">
                        {DataComTraçoParaDataComBarra(value.date)}
                      </Table.Cell>
                      <Table.Cell className="is-size-7 is-vcentered">
                        {infosContext?.positionsById[value.idPosition]}
                      </Table.Cell>
                      <Table.Cell className="is-size-7 is-vcentered">
                        <strong className="has-text-danger is-size-6">{value.filledJob}</strong>
                        {` / ${value.amountJob}`}
                      </Table.Cell>
                      <Table.Cell className="is-size-7 is-vcentered">
                        {`R$ ${value.value} ( ${TransformationforMask(value.payment, 'title')} )`}
                      </Table.Cell>
                      <Table.Cell className="is-size-7 is-vcentered">
                        {TransformationforMask(value.register, 'title')}
                      </Table.Cell>
                      <Table.Cell className="is-size-7 is-vcentered">{`${value.hrCheckin} - ${value.hrCheckout}`}</Table.Cell>
                      <Table.Cell className="is-size-7 is-vcentered">
                        <Select.Root>
                          <Select.Content
                            color="is-grey"
                            className="is-fullwidth is-rounded"
                            size={'is-small'}
                            value={value?.status}
                            setValue={(newStatus) => {
                              HandleChangeStatusJobOffers(value?.id, newStatus)
                            }}
                            required={true}
                          >
                            <Select.Option value={'open'} title="Aberto" />
                            <Select.Option value={'favorite'} title="Somente Favoritos" />
                            <Select.Option value={'invited'} title="Somente Convidados" />
                            <Select.Option value={'closed'} title="Fechado" />
                          </Select.Content>
                        </Select.Root>
                      </Table.Cell>
                      <Table.Cell className="is-vcentered">
                        <div style={{ marginBottom: 5, marginTop: 5 }}>
                          <Button.Root align="centered">
                            <Button.Content>
                              <Button.Bt
                                color="is-light"
                                action={() => {
                                  HandleSelectValuesFormJob(value, true)
                                }}
                                loading={loading}
                                title="Copiar Vaga"
                              >
                                <Icon size={15}>copy</Icon>
                              </Button.Bt>
                            </Button.Content>
                            <Button.Content>
                              <Button.Bt
                                color="is-warning"
                                action={() => {
                                  HandleSelectValuesFormJob(value, false)
                                  SetIdJobEdit(value.id)
                                }}
                                loading={loading}
                              >
                                <Icon size={15}>pen</Icon>
                              </Button.Bt>
                            </Button.Content>
                            <Button.Content>
                              <Button.Bt
                                color="is-danger"
                                colorText={'has-text-white'}
                                type="button"
                                action={() => {
                                  HandleDelJobOffers(value.id)
                                }}
                                loading={loading}
                              >
                                <Icon size={15}>trash</Icon>
                              </Button.Bt>
                            </Button.Content>
                          </Button.Root>
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  </>
                )
              })}
            </Table.Body>
          </Table.Root>
        </Column.Content>
      </Column.Root>
    </Page.Section>
  )
}
