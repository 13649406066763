import { ArrowLeft, DollarSign } from 'lucide-react'

import useCollaboratorSelectionHistory from '../../_hooks/useCollaboratorSelectionHistory'
import { statusPayments } from '../../_utils/paymentStatusCollab'
import { Path } from '../../../../router/paths'
import { Header } from '../../_components/Header'
import { Button } from '../../../../components/ui/button'
import { CopyText } from '../../../../utils/general'

export const CollaboratorSelectionHistory = () => {
  const { token, formContext, loading } = useCollaboratorSelectionHistory()

  const statusPayment = statusPayments?.[formContext?.status]

  return (
    <>
      <Header>
        <a href={Path.CollabHistory(token)}>
          <ArrowLeft className="has-text-link" size={30} />
        </a>
      </Header>
      <div
        className="has-text-grey"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          padding: '0 1rem 0 1rem',
        }}
      >
        <DollarSign size={40} />
        <p>{formContext.name}</p>

        <div
          style={{
            margin: '1rem 0 1rem 0',
          }}
        >
          <p
            className={`${statusPayment?.color}`}
            style={{
              color: '#37f64a',
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            {statusPayment?.icon} {statusPayment?.value}
          </p>
        </div>

        <Button.Bt
          action={() => {
            location.reload()
          }}
          loading={loading}
          fullWidth
          className="mb-6"
        >
          Atualizar Pagamento
        </Button.Bt>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '1rem 0 1rem 0',
          }}
        >
          <p>
            Valor: <b>R$ {formContext?.value}</b>
          </p>
          {Number(formContext?.value) > 0 && (
            <p>
              Pagamento via <b>{formContext?.typePayment}</b>
            </p>
          )}

          <p>
            Gerado em: <b>{formContext?.dt_created}</b>
          </p>
        </div>
        {formContext?.dt_payment && (
          <>
            <p className="has-text-primary">
              Pagamento Realizado em: <b>{formContext?.dt_payment}</b>
            </p>
            {formContext?.typePayment === 'admission' ? (
              <p className="has-text-grey">
                Agora você pode retornar ao app e terminar seu cadastro para envia-lo para analise.
              </p>
            ) : (
              <p className="has-text-grey">Agora você pode retornar ao app e se candidatar em eventos.</p>
            )}
          </>
        )}

        {!formContext?.dt_payment && (
          <>
            {formContext?.responseOmie?.cQrCode && (
              <img
                src={`data:image/png;base64,${formContext?.responseOmie?.cQrCode}`}
                style={{ width: 300, height: 300 }}
                alt="qrcodePix"
              />
            )}

            {formContext?.responseOmie?.cCopiaCola && (
              <Button.Bt
                action={() => {
                  CopyText(formContext?.responseOmie?.cCopiaCola)
                }}
                color="is-primary"
                loading={loading}
                fullWidth
              >
                Copiar chave Pix
              </Button.Bt>
            )}
          </>
        )}

        {formContext?.responseOmie?.cUrlPdfDemo && (
          <div style={{ marginTop: '3rem', width: '100%' }}>
            <Button.A
              link={formContext?.responseOmie?.cUrlPdfDemo}
              openNew
              color="is-primary"
              style={{ width: '100%' }}
            >
              Baixar Nota Fiscal
            </Button.A>
          </div>
        )}

        {!formContext?.responseOmie?.cUrlPdfDemo &&
          formContext?.dt_payment &&
          formContext?.status !== 'paymentSuccess' && (
            <div style={{ marginTop: '3rem', width: '100%' }}>
              <Button.A disabled={true} color="is-primary" style={{ width: '100%' }}>
                Gerando Nota Fiscal...
              </Button.A>
            </div>
          )}

        <div style={{ height: 150 }}></div>
      </div>
    </>
  )
}
