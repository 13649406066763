import React from 'react'
import { Link } from 'react-router-dom'

// o children será o texto que é escrito no botão
// as colores recebe todas as cores do bulma sem o "IS-"
// o action recebe o função que ao apertar o botão ela será executada

export const ButtonContentDropDownOption = ({
  children,
  className = '',
  link = null,
  action = null,
  loading = null,
  disabled = null,
  openNew = false,
  msgDisabled = '',
}) => {
  if (link && !action) {
    return (
      <Link
        className={`dropdown-item ${className}  ${loading && 'is-loading'} has-text-left`}
        link={link}
        disabled={disabled}
        title={disabled && msgDisabled}
        target={openNew ? '_blank' : null}
      >
        {children}
      </Link>
    )
  }
  if (!link && action) {
    return (
      <button
        className={`dropdown-item ${className}  ${loading && 'is-loading'} has-text-left`}
        onClick={() => {
          action()
        }}
        disabled={disabled}
        title={disabled && msgDisabled}
      >
        {children}
      </button>
    )
  }
  return null
}
