import { ChevronRight } from 'lucide-react'
import React from 'react'

import { Icon } from '../../../components/icons'

const Button = ({ action, href, id, style, children }) => {
  return (
    <a href={href} id={id} onClick={action} style={style}>
      {children}
    </a>
  )
}

const colors = {
  grey_light: '#ccc',
  danger: '#ff4d4d',
  light: '#f0f0f0',
  keap_light: '#e0e0e0',
}

export function ButtonMenu({ children, id, href, action = () => {}, buttonDescription, loading = false }) {
  if (loading) {
    return <Icon>loading</Icon>
  }
  return (
    <Button
      key="buttoninviteurlPhoto"
      action={action}
      href={href}
      id={id}
      style={{
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid ${colors.keap_light}`,
        borderTop: `1px solid ${colors.keap_light}`,
      }}
    >
      <div
        style={{
          width: '85%',
          padding: '1rem 0 1rem 1rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.10rem',
          minHeight: 100,
          justifyContent: 'center',
        }}
      >
        <p style={{ margin: 0, color: 'rgba(0, 0, 0, 0.43)' }}>{children}</p>
        <p style={{ color: colors.keap_light, fontSize: '0.75rem' }}>{buttonDescription}</p>
      </div>
      <ChevronRight size={30} color={colors.keap_light} />
    </Button>
  )
}
