import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

import { generateIdDocument, setDocument } from '../../../../../firebase/firestore'
import { Path } from '../../../../../router/paths'
import { DateTimeNow } from '../../../../../utils/dates'
import { TryCatch } from '../../../../../utils/general'
import { useGlobalContext } from '../../../../../context/ContextGlobal'
import { createSchemaPlans } from '../utils'
import { ServicesOmie } from '../../../../../api/omie'
import { createSchemaPlansOmie } from '../../../../../api/omie/schemas'

export const useClientAddPlans = () => {
  const {
    user,
    setAlerts,
    loading,
    setLoading,
    // dataContext,
    // setDataContext, // contexto que guarda dados de lista para a pagina
    // infosContext,
    // setInfosContext, // contexto que guarda dados adicionais para a pagina funcionar
    formContext,
    setFormContext, // contexto que guarda dados de formularios da pagina
  } = useGlobalContext()

  const navigate = useNavigate()

  const getForm = () => {
    setFormContext({
      typePlan: 'collab',
      value: '0',
      admission: '0',
      payment: 'pix',
      typeExpired: '30',
      trialDays: 0,
    })
  }

  const FetchGetForm = async () => {
    await TryCatch(getForm, setLoading, setAlerts)
  }

  const addPlan = async () => {
    const newIdPlan = await generateIdDocument('plans')
    // console.log(newIdPlan)
    let createPlanOmie = { nCodServ: '' }

    const newPlanOmie = createSchemaPlansOmie({ ...formContext, id: newIdPlan })
    // console.log(newPlanOmie)

    try {
      createPlanOmie = await ServicesOmie().IncluirServico([{ ...newPlanOmie }])
    } catch (error) {
      // console.log(error.message)
      throw new Error(error.message)
    }

    if (!createPlanOmie) return

    const newPlan = {
      ...createSchemaPlans(formContext),
      idOmie: createPlanOmie.nCodServ,
      created_by: user?.user?.nome,
      dt_created: DateTimeNow(),
    }
    await setDocument('plans', newIdPlan, newPlan)

    setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Plano Criado',
        msg: 'Novo Plano criado com sucesso.',
      },
    ])
    navigate(Path.APlanos)
    return navigate(Path.AdminPlans)
  }

  const HandleAddPlan = async () => {
    await TryCatch(addPlan, setLoading, setAlerts)
  }

  useEffect(() => {
    FetchGetForm()
  }, [])

  return {
    loading,
    formContext,
    setFormContext,
    HandleAddPlan,
  }
}
