import React from 'react'

import { Page } from '../../../components/layouts/page'
import { Column } from '../../../components/layouts/columns'
import { Form } from '../../../components/ui/form'
import { CollaboratorsForm } from './_components/form'
import { useEditAdminCollaborators } from './_hooks'
import { Button } from '../../../components/ui/button'
import { Input } from '../../../components/ui/input'
import { Icon } from '../../../components/icons'
import { getCollaboratorStatusType } from './_utils/collaboratorStatus'
import { ValidateCollaboratorModal } from './_modal/ValidateCollaboratorModal'

export const EditAdminCollaborator = () => {
  const {
    loading,
    formContext,
    // blockUnblockCollaborator,
    handlePrintScreen,
    handleResetPassword,
    handleEditCollaborators,
    modalValidate,
    setModalValidate,
    handleValidateCollaborator,
  } = useEditAdminCollaborators()

  // console.log(formContext)

  return (
    <>
      <ValidateCollaboratorModal
        active={modalValidate}
        setActive={() => {
          setModalValidate(false)
        }}
      />
      <Page.Section className="is-fullheight-with-navbar mt-6 pt-6">
        <Form.Root
          action={() => {
            handleEditCollaborators()
          }}
          className="mt-5"
        >
          <Column.Root>
            <Column.Content className="my-5">
              <p className="subtitle is-size-4">
                <Icon size={30} className={'mr-3'}>
                  user
                </Icon>
                Informações do Staff
              </p>
            </Column.Content>
          </Column.Root>

          {loading && (
            <Column.Root className="is-mobile">
              <Column.Content></Column.Content>
              <Column.Content className="is-1">
                <Icon>loading</Icon>
                <p className="is-size-7 has-text-grey">carregando...</p>
              </Column.Content>
              <Column.Content></Column.Content>
            </Column.Root>
          )}

          {!loading && (
            <>
              <Column.Root>
                <Column.Content>
                  <Input.Root className="mb-3">
                    <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
                      Situação
                    </Input.Label>
                    <Input.Contents>
                      <Input.ReadOnly className="is-rounded">
                        {getCollaboratorStatusType(formContext?.status)}
                      </Input.ReadOnly>
                    </Input.Contents>
                  </Input.Root>
                </Column.Content>
                <Column.Content size="">
                  <Column.Root>
                    <Column.Content className="has-text-centered is-2" type="button">
                      {/* {[0, 1, 2, 5, 6, 7, 8, 9].includes(formContext?.status) && (
                        <>
                          <Button.Bt
                            color="is-danger"
                            type="button"
                            className="mx-1"
                            size={'is-small'}
                            loading={loading}
                            action={blockUnblockCollaborator}
                          >
                            <Icon>lock</Icon>
                          </Button.Bt>
                          <p className="is-size-7 has-text-grey">Bloquear</p>
                        </>
                      )} */}
                      {/* {[3, 4].includes(formContext?.status) && (
                        <>
                          <Button.Bt
                            color="is-success"
                            type="button"
                            className="mx-1"
                            size={'is-small'}
                            loading={loading}
                            action={blockUnblockCollaborator}
                          >
                            <Icon>lock</Icon>
                          </Button.Bt>
                          <p className="is-size-7 has-text-grey">Ativar</p>
                        </>
                      )} */}
                    </Column.Content>
                    {/* {[0, 1, 2, 9].includes(formContext?.status) && ( */}
                    {[1, 2].includes(formContext?.status) && (
                      <>
                        <Column.Content className="has-text-centered is-2" type="button">
                          <Button.Bt
                            color={formContext?.status === 1 ? 'is-success' : 'is-warning'}
                            type="button"
                            className="mx-1"
                            size={'is-small'}
                            action={() => {
                              handleValidateCollaborator()
                            }}
                            loading={loading}
                          >
                            <Icon>{'check'}</Icon>
                          </Button.Bt>
                          <p className="is-size-7 has-text-grey">
                            {formContext?.status === 1 ? 'Validar' : 'Vizualizar Validação'}
                          </p>
                        </Column.Content>
                      </>
                    )}
                    {formContext?.status !== 0 && (
                      <>
                        {/* Gerar Fatura: Gera uma nova fatura */}
                        {/* <Column.Content className="has-text-centered is-2">
                          <Button.Bt
                            color="is-light"
                            type="button"
                            className="mx-1"
                            size={'is-small'}
                            loading={loading}
                          >
                            <Icon>bill</Icon>
                          </Button.Bt>
                          <p className="is-size-7 has-text-grey">Gerar Recibo</p>
                        </Column.Content> */}

                        {/* Hitórico financeiro: mostra o histórico financeiro do cliente */}
                        {/* <Column.Content className="has-text-centered is-2" type="button">
                          <Button.Bt
                            color="is-light"
                            type="button"
                            className="mx-1"
                            size={'is-small'}
                            loading={loading}
                          >
                            <Icon>history</Icon>
                          </Button.Bt>
                          <p className="is-size-7 has-text-grey">Histórico Financeiro</p>
                        </Column.Content> */}
                      </>
                    )}
                    <Column.Content className="has-text-centered is-2" type="button">
                      <Button.Bt
                        color="is-light"
                        type="button"
                        className="mx-1"
                        size={'is-small'}
                        action={handlePrintScreen}
                        loading={loading}
                      >
                        <Icon>print</Icon>
                      </Button.Bt>
                      <p className="is-size-7 has-text-grey">Imprimir</p>
                    </Column.Content>
                    <Column.Content className="has-text-centered is-2" type="button">
                      <Button.Bt
                        color="is-light"
                        type="button"
                        className="mx-1"
                        size={'is-small'}
                        action={handleResetPassword}
                        loading={loading}
                      >
                        <Icon>password</Icon>
                      </Button.Bt>
                      <p className="is-size-7 has-text-grey">Resetar Senha</p>
                    </Column.Content>
                  </Column.Root>
                </Column.Content>
              </Column.Root>
              <CollaboratorsForm />
            </>
          )}
        </Form.Root>
      </Page.Section>
    </>
  )
}
