import { Icon } from '../../../components/icons'

export const statusPayments = {
  canceled: {
    value: 'Cobrança Cancelada',
    color: 'has-text-danger',
    icon: <Icon>multiply</Icon>,
  },
  createPayment: {
    value: 'Criando cobrança',
    color: 'has-text-grey-light',
    icon: <Icon>clock</Icon>,
  },
  recreatePayment: {
    value: 'Recriando cobrança',
    color: 'has-text-grey-light',
    icon: <Icon>clock</Icon>,
  },

  generatedPayment: {
    value: 'Gerando pagamento',
    color: 'has-text-grey-light',
    icon: <Icon>clock</Icon>,
  },
  regeneratedPayment: {
    value: 'Regerando pagamento',
    color: 'has-text-grey-light',
    icon: <Icon>clock</Icon>,
  },

  awaitPayment: {
    value: 'Pagamento Pendente',
    color: 'has-text-danger',
    icon: <Icon className="has-text-danger"> alert</Icon>,
  },
  paid: {
    value: 'Processando Pagamento Realizado',
    color: 'has-text-grey-light',
    icon: <Icon>clock</Icon>,
  },

  createOs: {
    value: 'Criando NFe',
    color: 'has-text-grey-light',
    icon: <Icon>clock</Icon>,
  },
  recreateOs: {
    value: 'Re Criando NFe',
    color: 'has-text-grey-light',
    icon: <Icon>clock</Icon>,
  },

  billing: {
    value: 'Gerando NFe',
    color: 'has-text-grey-light',
    icon: <Icon>clock</Icon>,
  },
  rebilling: {
    value: 'Re Gerando NFe',
    color: 'has-text-grey-light',
    icon: <Icon>clock</Icon>,
  },

  awaitBilling: {
    value: 'Faturando NFe',
    color: 'has-text-grey-light',
    icon: <Icon>clock</Icon>,
  },
  reawaitBilling: {
    value: 'Re Faturando NFe',
    color: 'has-text-grey-light',
    icon: <Icon>clock</Icon>,
  },

  paymentSuccess: {
    value: 'Pagamento Realizado',
    color: 'has-text-success',
    icon: <Icon className="has-text-success">check</Icon>,
  },

  error: {
    value: 'Erro no processamento',
    color: 'has-text-danger',
    icon: <Icon className="has-text-danger">multiply</Icon>,
  },
}
